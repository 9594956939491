<template>
  <div style="width:100%;">
    <div class="row">
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click="clearAll">清空</div>
      <div class="key-input">
        <el-input v-model="input_number" size="mini" style="width:100%;height:100%;"></el-input>
      </div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnback"><i class="el-icon-back" style="font-size:18px;"></i></div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('1')">1</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('2')">2</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('3')">3</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('4')">4</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('5')">5</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('6')">6</div>
    </div>
    <div class="row">
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('q')">q</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('w')">w</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('e')">e</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('r')">r</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('t')">t</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('y')">y</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('u')">u</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('i')">i</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('o')">o</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('7')">7</div>
    </div>
    <div class="row">
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('a')">a</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('s')">s</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('d')">d</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('f')">f</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('g')">g</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('h')">h</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('j')">j</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('k')">k</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('p')">p</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('8')">8</div>
    </div>
    <div class="row">
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('z')">z</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('x')">x</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('c')">c</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('v')">v</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('b')">b</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('n')">n</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('m')">m</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('l')">l</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('0')">0</div>
      <div class="key-item" @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('9')">9</div>
    </div>
    <div class="row">
      <div class="queding" @mousedown="ondown" @mouseup="onup" @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input_number: ''
    }
  },
  methods: {
    btnClick(num) {
        this.input_number += num
        this.$emit('input-text',this.input_number)
    },
    // 退格键
    btnback() {
      if (this.input_number.length == 0) return
      this.input_number = this.input_number.substr(0, this.input_number.length - 1)
      this.$emit('input-text',this.input_number)
    },
    clearAll(){
      this.input_number = ''
      this.$emit('input-text',this.input_number)
    },
    confirm(){
      this.$emit('input-text',this.input_number)
      this.input_number = ''
    },
    ondown(e) {
      e.target.classList.add('keyclick')
    },
    onup(e) {
      e.target.classList.remove('keyclick')
    },
    reset(){
      this.input_number = ''
    }
  }
}
</script>

<style lang="less" scoped>
.row {
  width: 500px;
  display: flex;
  margin-bottom: 15px;
  .key-item,.queding {
    font-size: 14px;
    color: #333;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
  .queding {
    width: 100%;
  }
  .key-input {
    width: 95px;
    height: 40px;
    margin-right: 15px;
  }
}
.keyclick {
  border: 1px solid #d9ecff;
  color: #208bf5;
  background-color: #aac9e7;
  font-weight: 600;
}
</style>