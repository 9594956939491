<template>
  <div class="tuan-box" @click="closeBordInput">
    <div class="left-box">
      <div class="box-top">
        <div class="coupons-box">
          <div 
            :class="ischecked(item.id)?'coupons-item activecoup':'coupons-item'"
            v-for="item in gift_coupons" 
            :key="item.id"
            @click="chooseGift(item)"
            >
            {{item.dict_label}}
          </div>
        </div>
      </div>
      <div class="box-bottom">
        <div class="table-box">
          <el-table :data="activecoup" stripe border size="mini" style="width: 100%">
            <el-table-column prop="receive_time" label="时间" width="140">
              <template slot-scope="scope">
                <div>
                  {{scope.row.receive_time | parseTime}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="rec_name" label="券名" >
            </el-table-column>
            <el-table-column prop="rec_amt" label="金额" >
            </el-table-column>
            <el-table-column label="数量">
              <template slot-scope="scope">
                <div  @click.stop="closeOther(scope.row.id)">  
                  <table-num-bord
                    :ref="'tablebord'+scope.row.id"
                    :key="'table'+scope.row.id"
                    :defnum="Number(scope.row.rec_qty)"
                    @input-num="onTableNumInput($event,scope.row)">
                  </table-num-bord>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="券号">
              <template slot-scope="scope">
                <div>
                    <el-popover placement="bottom" width="540" trigger="click">
                      <div class="keyboard">
                        <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput($event,scope.row)"></all-keyboard>
                      </div>
                      <el-input slot="reference" size="mini" v-model="scope.row.rec_giftid" @focus.stop="all_keyboard_show=true"></el-input>
                    </el-popover>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { parseTime } from '@/utils'
import tableNumBord from '@/components/TableNumberbord/index.vue'
import AllKeyboard from '@/components/Keyboard.vue'
export default {
  components:{
    tableNumBord,
    AllKeyboard
  },
  filters:{
    parseTime(data){
      return data ? parseTime(data) : ''
    }
  },
  data() {
    return {
      activecoup:[],
      gift_coupons:[]
    }
  },
  computed:{
    ischecked(){
      return (data)=>{
        let i = this.activecoup.findIndex(item => item.typeid === data)
        return i >=0
      }
    }
  },
  async mounted(){
    await this.getTableData()
  },
  methods: {
    async getTableData(){
      let type = await this.$localforage.getItem('t_sys_dict_data')
      this.gift_coupons = type.filter(item => item.dict_type == "bus_lading_gift")
    },
    chooseGift(item){
      let i = this.activecoup.findIndex(el =>el.typeid === item.id)
      if (i>=0) {
        this.activecoup.splice(i,1)
      }else {
        let id = makeid(1)
        let obj = {
          id: id,
          typeid: item.id,
          receive_time: new Date().getTime(),
          rec_id: item.dict_value,
          rec_name: item.dict_label,
          rec_qty: 1,
          relationattri: item.relationattri || 0,
          rec_giftid: '',
          rec_gifttype:0,
        }
        obj.rec_amt = (obj.relationattri * obj.rec_qty).toFixed(2)
        this.activecoup.push(obj)
      }
    },
    onTableNumInput(num,item){
      item.rec_qty = num
      item.rec_amt = (item.relationattri * item.rec_qty).toFixed(2)
    },
    closeBordInput(){
      let that = this
      this.activecoup.forEach(el => {
        let ref = 'tablebord' + el.id
          that.$refs[ref].close()
      });
    },
    closeOther(id){
      let that = this
      this.activecoup.forEach(el => {
        if (el.id != id) {
          let ref = 'tablebord' + el.id
          that.$refs[ref].close()
        }
      });
    },
    // 键盘输入
    onKeyInput(v,item) {
      item.rec_giftid = v
    },
    close(){ // 弹窗关闭了
      this.activecoup = []
      this.$emit('tidanCanfirm',this.activecoup)
    },
    canfirm(){ // 提交礼券
      this.$emit('tidanCanfirm',this.activecoup)
    }
  }
}
</script>

<style lang="scss" scoped>
.tuan-box {
  width: 100%;
  height: 600px;
  display: flex;
  .left-box {
    height: 100%;
    margin-right: 30px;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .box-top {
      .coupons-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        padding: 5px;
        .coupons-item {
          width: 100px;
          height: 55px;
          border: 2px solid #ccc;
          color: #333;
          margin-bottom: 10px;
          margin-right: 10px;
          line-height: 55px;
          text-align: center;
          background-color: #de9c53;
          cursor: pointer;
          &:hover {
            background-color: #f56c6c;
          }
        }
        .activecoup {
          background-color: #cc3333;
        }
      }
    }
  }
  div {
    height: 100%;
  }
}
</style>
