export let tidanData = {
    coupons_table_data: []
}
export let tidanmethods = {
    cancelTidan(){
        this.tuanPayVisible = false
        this.$refs.tidanRef.close()
    },

    canfirmTidan(){
        this.tuanPayVisible = false
        this.$refs.tidanRef.canfirm()
    },
    onTidanCanfirm(table){
        this.coupons_table_data = table
        let num = 0
        table.forEach(el => {
            num += (el.rec_amt*1)
        });
        this.enterNuminPayWay(num.toFixed(2))
        //回到自动选择现金
        let k = this.PayWay[0]
        this.whichPayWay = k.dict_value
    }
}