<template>
  <div style="margin-top: 10px;">
          <!-- <el-input v-model="request_url" :readonly="!is_edit_url"></el-input>
         
          <el-button type="primary" @click="is_edit_url = true" v-show="!is_edit_url">修改</el-button>
          <el-button type="success" v-show="is_edit_url" @click="changeBaseUrl">确认</el-button> -->
       



  <el-form :inline="true" :model="form" class="form-inline">
  <el-form-item label="请求地址">
    <el-input v-model="form.request_url" :readonly="!is_edit_url" placeholder="请求地址" style="width:300px;"></el-input>
  </el-form-item>
 
  <el-form-item>
          <el-button type="primary" @click="is_edit_url = true" v-show="!is_edit_url">修改</el-button>
          <el-button type="success" v-show="is_edit_url" @click="changeBaseUrl">确认</el-button> 
  </el-form-item>
</el-form>

 </div>

</template>

<script>
import { getLocal, setLocal } from '@/utils/localstorage'
export default {
  data() {
    return {
        activeName:'配置管理',
        request_url: '',
        shopBaseMsg: {},
        is_edit_url: false,

        form: {
          request_url: '',
        }
    }
  },
    mounted() {
    let msg = getLocal('shopBaseMsg')
    this.shopBaseMsg = msg
    this.form.request_url = msg.baseAddr
  },
   methods: {
     // 修改请求地址
    changeBaseUrl() {
      console.log(this.request_url);
      this.shopBaseMsg.baseAddr =  this.form.request_url
      setLocal('shopBaseMsg', this.shopBaseMsg)
      this.is_edit_url = false
      
    },
},
}
</script>

<style lang="scss" scoped>
.contn-box {
    width: 100%;
    height: 100%;
    padding: 15px;
}
</style>