<template>
  <div class="from" v-if="isCancel">
    <el-tabs v-model="activeName" @tab-click="handleClick" >
      <el-tab-pane label="基本信息" name="first" >
         <el-row :gutter="10" class="mb8">
           <el-button v-if="formData.isEdit !='1' && formData.isEdit !='2'"  type="primary"
          icon="el-icon-plus"
          size="mini" @click="subaddForm">新 增</el-button>
         <el-button v-if="formData.isEdit !='1' && formData.isEdit !='2'"  type="success"
          icon="el-icon-edit"
          size="mini" @click="submitForm">保 存</el-button>
          </el-row>
        <base-info :defaultdata="formData" ref="cardinfo" :visible.sync="open" @getFormData="seaveBaseInfo"></base-info>
      </el-tab-pane>
      
      <el-tab-pane label="充值信息" name="second">
        <recharge-info :defaultdata="formData.id"  ref="rechargeInfo" ></recharge-info>
      </el-tab-pane>
      <el-tab-pane label="消费详情" name="third">
        <xiaofei-msg :defaultdata="formData.id" ref="xiaofeiMsg" ></xiaofei-msg>
      </el-tab-pane>
      <el-tab-pane label="结存信息" name="fourth">
        <jiecun-msg :defaultdata="formData.id" ref="JiecunMsg" ></jiecun-msg>
      </el-tab-pane>
      <el-tab-pane label="积分兑换" name="fifth">
        <jifen  :defaultdata="formData.id"  ref="jifendh" ></jifen>
      </el-tab-pane>
      <el-tab-pane label="操作日志" name="sixth">
        <caozuo-log :defaultdata="formData.id"  ref="caozuoLog" ></caozuo-log>
      </el-tab-pane>
        <el-tab-pane label="领用礼券" name="seventh">
        <gift-Collect :defaultdata="formData.id"  ref="giftCollect" ></gift-Collect>
      </el-tab-pane>
    </el-tabs>
  </div>

    <!-- 添加或修改参数配置对话框 -->
    <!-- <el-dialog  :title="isEdit?'编辑会员信息':'添加会员信息'" :visible.sync="open" :close-on-click-modal="false" width="1500px" append-to-body>
      <wuliao-ziliao-from :data="form" ref="wuliaoFromRef" @getFormData="seaveBaseInfo"></wuliao-ziliao-from>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog> -->


</template>


<script>
import baseInfo from './BaseInfoForm'
import rechargeInfo from './RechargeMsg'
import xiaofeiMsg from './XiaofeiMsg'
import JiecunMsg from './JiecunMsg'
import jifen from './Jifen'
import caozuoLog from './CaozuoLog'
import giftCollect from './GiftCollect'

// import {apiget} from "@/api/business/member/memberprofile";

export default {

  components: { baseInfo,  rechargeInfo, xiaofeiMsg, JiecunMsg, jifen, caozuoLog, giftCollect },
//  name: "memberprofile",


  props: {
    isCancel:{
      default(){
        return true;
      },
      },
    data: {
      type: [Object,String],
      default() {
        return {};
      },
    },
  },
  watch: {
    data: {
      handler(n) {
          console.log('W会员-handler-n',n)
      if (n.isEdit=='2'){
            if (n.id) {
              this.formData={}
              this.formData = n
              this.activeName='first';
              console.log('会员弹出编辑',this.formData,n)
            }
       }else if (n.isEdit=='1') {
        this.formData=n
        // this.formData={}
         console.log('添加会员', this.formData)
       }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      tempRoute: {},
      //是否显示弹出层
      open: false,
      activeName: 'first',
      card_id: '',
// 表单参数
      formData: {

      },
    };
  },
  created() {
      console.log('isCancel', this.isCancel)
      const id = this.$route.params && this.$route.params.cardId;
          if(id){
             if(id){ this.getcardByid(id)}
             console.log('会员链接编辑', id)
          }

       this.tempRoute = Object.assign({}, this.$route)
    // 获得传参
    //  console.log('created_memberdata', 9999999999999)
    // const id = this.$route.params && this.$route.params.cardId;
    // console.log('created_memberdata', id)
    // if(id){ this.getpartByid(id)}

    // let id = this.$route.query.card_id;
    // if (id) {
    //   this.card_id = id;
    // }
  },
computed: {
  lang() {
      return this.$store.getters.language
    },
},
  methods: {
     handleClick(tab, event) {
        console.log(tab.index, event);
       switch (tab.index) {
     case '0': //处理基本信息
      console.log('会员链接编辑', this.formData.id)
      this.$refs.cardinfo.getcardinfo(this.formData.id)
      
          break;
      case '1': //处理充值
      this.$refs.rechargeInfo.getcardtopitems(this.formData.id)
          break;
      case '2': //处理消费
       this.$refs.xiaofeiMsg.getpossalerecord(this.formData.id)
          break;
      case '3': //处理结存
      this.$refs.JiecunMsg.getcardinout(this.formData.id)
          break;
      case '4': //处理积分兑换
        this.$refs.jifendh.getcardscoreredeem(this.formData.id)
          break;
      case '5': //处理充值日志
       this.$refs.caozuoLog.getcardoperlog(this.formData.id)
          break;
       case '6': //处理领用礼券
       this.$refs.giftCollect.getcardgiftcollect(this.formData.id)
          break;
      }
     },


 fetchData(id) {
        // set tagsview title
        this.setTagsViewTitle()
    },

  setTagsViewTitle() {
      const title = this.lang === 'zh' ? '会员资料' : 'MemberProfile'
      const route = Object.assign({}, this.tempRoute, { title: `${title}-${this.formData.card_id_out}` })
      this.$store.dispatch('tagsView/updateVisitedView', route)
    },

//会员资料详细
    async getcardByid(Id) {
        await apiget(Id).then((response) => {
        this.formData=response.data.rows[0];
        this.formData.isEdits=true
        this.fetchData(Id)
      });
    },

/** 保存按钮 */
    submitForm: function () {
      console.log('submitForm', '基本信息-保存111',this.formData)
      this.$refs.cardinfo.seaveForm()
    },
    //新增
   subaddForm: function () {
     this.formData={}
      console.log('subaddForm', '基本信息-新增',this.formData)
    },

    async seaveBaseInfo(obj) {
        console.log('子传父来值',obj)
        this.formData.id=obj.id
        this.$emit("getFormData", obj);
    },


    },
};
</script>

<style lang="less" scoped>
.box {
  padding: 5px 20px;
}
[v-cloak]{
display: none;
}

</style>
