import router from '.'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getLocal } from '@/utils/localstorage'

NProgress.configure({ showSpinner: false })

const whiteList = ['/staff/login']

router.beforeEach((to, from, next) => {
  NProgress.start()
  let nowuser = store.state.user.currUser
  let logintime = getLocal('logintime')
  let nowtime = new Date().getTime()
  let time = 10*60*60*1000
  let timeout = nowtime - logintime - time
  if (typeof nowuser != "object" || timeout>0) {
    // 没有登录或登录过期
    if (whiteList.indexOf(to.path) !== -1) {
      next() // 如果页面在白名单(不需要登录可以访问的页面)里 in the free login whitelist, go directly
    } else {
      next(`/staff/login`) // 如果页面不在白名单.重定位到login页面
      NProgress.done()
    }
    return
  } else {
    /* has token*/
    if (to.path === '/staff/login') {
      next({ path: '/' }) // 如果已经登录 重定位到主页
      NProgress.done()
      return
    }
    NProgress.done()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})
