<template>
  <div>
    <!-- 消费详情 -->
    <div class="table-up">
      <el-table :data="tableData" ref="xiaofei" highlight-current-row @current-change="handleCurrentChange" border height="400"
       :summary-method="getSummaries" show-summary style="width: 100%; ">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="销售单号" prop="sale_id" sortable  align="center" width="200"/>
        <el-table-column label="消费时间" prop="sale_date"  sortable align="center" width="180">
          <template slot-scope="scope">{{ scope.row.sale_date===null? '' :scope.row.sale_date|parseTime }}</template>
        </el-table-column>
        <el-table-column label="门店" prop="dept_id" sortable  align="center" :show-overflow-tooltip="true" :formatter="statusFormatdept" />
        <el-table-column label="消费总金额" prop="sale_act" sortable  align="center" :show-overflow-tooltip="true" />
        <el-table-column label="销售类型" prop="sale_type" sortable  align="center" :show-overflow-tooltip="true" />
        <el-table-column label="消费后余额" prop="card_remoney" sortable  align="center" :show-overflow-tooltip="true" />
         <el-table-column label="消费后积分" prop="card_score" sortable  align="center" :show-overflow-tooltip="true" />
        <el-table-column label="备注" prop="notes" sortable  align="center" :show-overflow-tooltip="true" />
      </el-table>
    </div>
    <div class="tag-box">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="消费明细" name="first">
          <el-table :data="xiaofeiListData" border height="300"
          :summary-method="getSummaries" show-summary style="width: 100%;">
            <el-table-column label="销售单号" prop="sale_id" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="明细" prop="saledt_id"  align="center" width="100" />
            <el-table-column label="商品编号" prop="part_id" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="商品名称" prop="part_name" align="center" :show-overflow-tooltip="true"  />
            <el-table-column label="单价" prop="saledt_uprice" align="center" width="100" />
            <el-table-column label="单位" prop="saledt_unit" align="center" width="100" />
            <el-table-column label="数量" prop="saledt_qty" align="center" width="100" />
            <el-table-column label="单品折扣" prop="saledt_dis_1" align="center" width="100" />
            <el-table-column label="单品折让" prop="saledt_dis_2" align="center" width="100" :show-overflow-tooltip="true" />
            <el-table-column label="小计" prop="saledt_amount" align="center" width="100" :show-overflow-tooltip="true" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="支付方式" name="second">
          <div style="max-width:700px;">
            <el-table :data="zhifuListData" border height="300"
            :summary-method="getSummaries" show-summary style="width: 100%;">>
              <el-table-column label="支付ID" prop="pay_id" :show-overflow-tooltip="true" />
              <el-table-column label="支付方式" prop="pay_id" :show-overflow-tooltip="true" />
              <el-table-column label="支付金额" prop="pay_amt" width="100" />
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import 会员api from '../api.js'
import { parseTime } from '@/utils'
export default {
filters: {
    parseTime
  },
  data() {
    return {
      //部门
      deptOptions:[],
       // 遮罩层
      loading: true,
      // 上面table数据
      tableData: [],
      activeName: 'first',
      //   消费明细表格数据
      xiaofeiListData: [],
      // 支付方式表格数据
      zhifuListData: [],
        // 查询参数
      queryParams: {
        offset: 1,
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        cardid: '',
        saleid: '',
      },

    }
  },
 created() {
    // this.deptOptions= this.$store.state.baseData.departmentData;//门店
 },

  methods: {
 /** 查询物料列表 */
    async getpossalerecord(id) {
      this.loading = true;
      this.queryParams.cardid=id
   let [err, res] = await 会员api.查询会员消费记录({cardid:id})
      if (err) {
        return this.$message.error('查询会员消费记录失败')
      }
          this.tableData = res.data.rows;
          this.loading = false;
          this.setCurrent(this.tableData[0])
    },


     setCurrent(row) {
        //console.log('1111', row)
        this.$refs.xiaofei.setCurrentRow(row);
      },
      async handleCurrentChange(val) {
        this.currentRow = val;
        this.queryParams.pid=val.id
        //调用消费明细及支付方式
        let [err1, res1] = await 会员api.查询会员消费明细({pid:val.id});
          if (err1) {
          return this.$message.error('查询会员消费明细失败')
          }
        // console.log('this.查询会员消费明细', res1)
        this.xiaofeiListData = res1.data.rows;
            
         let [err2, res2] = await 会员api.查询会员消费支付方式({pid:val.id});
          if (err1) {
          return this.$message.error('查询会员消费支付方式失败')
          }
        this.zhifuListData = res2.data.rows;
      },
      //合计
       getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))
            && column.property != 'sale_id'
            && column.property != 'sale_date'
            && column.property != 'sale_type'
            && column.property != 'saledt_id'
            && column.property != 'part_id'
            && column.property != 'saledt_sprice'
            && column.property != 'saledt_uprice'
            && column.property != 'dept_id'
            && column.property != 'card_remoney'
            && column.property != 'card_score'
          ) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' 元';
          } else {
            sums[index] = 'N/A';
          }
        });

        return sums;
      },
  // 部门字典翻译
    statusFormatdept(row, column) {
      return this.selectDictLabel(this.deptOptions, row.dept_id);
    },
   },
}
</script>



<style lang="less" scoped>
.mingxi {
  margin-top: 10px;
  .tit {
    display: flex;

    .tit-item {
      background-color: #f5f5f5;
      color: #333;
      padding: 5px;
      border: 1px solid #ccc;
      border-bottom: none;
    }
  }
}
</style>
