<template>
  <div class="Echarts">
    <div class="title">本月各类商品销售占比</div>
    <div id="type-main" style="width: 100%;height:400px;"></div>
  </div>
</template>

<script>
export default {
  name: 'Echarts',
  data() {
    return {
      chart_data: []
    }
  },
  methods: {
    async initData() {
      let goosds_types = await this.$localforage.getItem('goosds_types') || []
      let goodstype = await this.$localforage.getItem('t_part_class') || []
      let data = []
      let now_month = new Date().getMonth() + 1; // 获得当前月份
      goosds_types.forEach(item => {
        let month = new Date(item.lct).getMonth() + 1; // 当前销售详情的月份
        if (now_month === month) {
          let i = goodstype.findIndex(el => el.id === item.type)
          if (i >= 0) {
            data.push({
              value: item.num,
              name: goodstype[i].name
            })
          }

        }
      });
      this.chart_data = data
    },
    myEcharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('type-main'));
      // 指定图表的配置项和数据
      var option = {
        title: {
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '80%',
            data: this.chart_data || [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    }
  },
  async mounted() {
    await this.initData()
    this.myEcharts();
  }
}
</script>

<style lang="less" scoped>
.title {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
  color: #333;
}
</style>