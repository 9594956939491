<template>
  <div>
    <div class="card-box">
      <div :class="checkedgoods == item.id?'box-item active':'box-item'" v-for="(item, key) in pageList" :key="key" @click="itemClick(item)">
        <div class="text-msg">
          <span class="foodName">{{ item.part_name }}</span>
          <span class="foodPrice">￥{{ item.part_price?item.part_price:0 }}元</span>
        </div>
        <div class="last-num" >
          <span class=""><span :style="{color:item.stock>0?'#333':'red'}">{{item.stock==null?0:item.stock}}</span> </span>
        </div>
        <!-- <div class="last-num" v-if="item.stock !== null">
          <span class="">库存：<span :style="{color:item.stock>0?'#333':'red'}">{{item.stock==null?0:item.stock}}</span> </span>
        </div> -->
      </div>
    </div>
    <el-pagination background @current-change="handleCurrentChange" hide-on-single-page :current-page="currentPage" :page-size="pagesize" layout="total, prev, pager, next" :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      list: [],
      pageList: [],
      total: 0,
      currentPage: 1,
      pagesize: 28,
      checkedgoods: ''
    }
  },
  watch: {
    data: {
      handler: function (val, oldval) {
        this.list = val
        this.total = val.length
        this.currentPage = 1
        this.pageMake()
      },
      deep: true//对象内部的属性监听，也叫深度监听
    }
  },
  methods: {
    itemClick(item) {
      this.checkedgoods = item.id
      this.$emit("chooseGoods", item)
    },
    handleCurrentChange(n) {
      this.currentPage = n
      this.pageMake()
    },
    pageMake() {
      let start = (this.currentPage - 1) * this.pagesize
      let end = this.currentPage * this.pagesize
      let arr = []
      for (let i = 0; i < this.list.length; i++) {
        if (i >= start && i < end) {
          arr.push(this.list[i])
        }
      }
      this.active = 0
      this.pageList = arr
     
    }
  }
}
</script>

<style lang="less" scoped>
.card-box {
  height: 520px;
}

.box-item {
  float: left;
  list-style: none;
  width: 23%;
  border: 1px solid #e5e9f2;
  height: 65px;
  overflow: hidden;
  background-color: #fff;
  padding: 2px;
  float: left;
  margin: 2px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  .text-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
    .foodName {
      font-size: 15px;
      font-weight: bold;
      padding-left: 10px;
      color: brown;
    }
    .foodPrice {
      color: #58b7ff;
      font-size: 14px;
      font-weight: bolder;
      padding-left: 10px;
      padding-top: 10px;
    }
  }
  .last-num {
    flex: 2;
    color: #333;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding-right: 5px;
  }
}
.box-item.active {
  background-color: #409eff;
  font-weight: 600;
}
.active .foodName {
  color: #fff;
}
.active .foodPrice {
  color: #fff;
}
.box-item li span {
  display: block;
  float: left;
}
</style>