import { 上传 } from '@/api'
import Http from '@/utils/request'
import {_to} from '@/utils'

export function 入库单上传(data) {
    let res = 上传({ url: '/business/invposbill/add', method: 'post', data: data })
    return res
}

export function 下载调拨单(data){
    let res = _to(Http({url:'/business/invposbill/posquerydb',method:'post',data:data}))
    return res
}

export function 获取操作类别(){
    let res = _to(Http({url:'/pos/down/invop',method:'post',data:{"uct":0}}))
    return res
}



export default {
    入库单上传,
    下载调拨单,
    获取操作类别
}

