<template>
  <div style="margin-top:10px;width:100%;">
    <div class="biao-box">
      <div class="form">
        <el-form ref="queryForm" :model="searchform" v-show="showSearch" label-width="80px" inline size="small">
       
          <el-form-item label="单据类型" prop="inv_whycat" style="width:170px;" >
            <span v-show="searchform.inv_whycat == item.invop_id" v-for="item in t_inv_op" :key="item.invop_id">{{item.invop_namec}}</span>
          </el-form-item>

          <el-form-item label="单据编号">
            <el-input v-model="searchform.inv_id"></el-input>
          </el-form-item>

           <el-form-item label="退货区域" prop="inv_region">
                    <el-select v-model="searchform.inv_region" clearable style="width:170px;">
                      <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>

      
            <el-form-item label="退回仓库" prop="inv_to">
              <el-select v-model="searchform.inv_to" clearable style="width:170px;">
                <el-option v-for="item in t_wrhus" :key="item.id" :label="item.wrhus_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="退回原因" prop="inv_reason">
              <el-select v-model="searchform.inv_reason" clearable style="width:170px;">
                <el-option v-for="item in shopreason" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
              </el-select>
            </el-form-item>

      
          <!-- <el-form-item label="确认日期">
            <el-date-picker v-model="searchform.inv_date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width:170px;">
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="确认日期" prop="dateRange">
            <el-date-picker  v-model="dateRange" value-format="timestamp" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="handel-btn">
        <el-button size="mini" type="primary" @click="add">新 增</el-button>
        <el-button size="mini" type="danger" @click="goback">关 闭</el-button>
        <el-button size="mini" type="success" style="float:right;" @click="uploadList">上传</el-button>
      </div>
      <div class="content">
        <el-table :data="tableData" border ref="tabledata" @select="onSelect" @select-all="onSelect">
          <el-table-column label="#" width="40" type="selection">
          </el-table-column>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="inv_id" label="单据编号" align="center">
          </el-table-column>

          <el-table-column label="确定时间" prop="inv_date1"  sortable align="center" width="180">
          <template slot-scope="scope">{{ scope.row.inv_date1===null? '' :scope.row.inv_date1|parseTime }}</template>
          </el-table-column>

          <el-table-column prop="inv_whycat" label="单据类型" align="center" width="120">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_whycat == item.id" v-for="item in t_inv_op" :key="item.id">{{item.invop_namec}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inv_region" label="退货区域" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_region == item.dict_value" v-for="item in t_region" :key="item.dict_value">{{item.dict_label}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="inv_to" label="退回仓库" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_to == item.id" v-for="item in t_wrhus" :key="item.id">{{item.wrhus_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inv_reason" label="退回原因" align="center" width="100">
            <template slot-scope="scope">
                 <span v-show="scope.row.inv_reason == item.dict_value" v-for="item in shopreason" :key="item.dict_value">{{item.dict_label}}</span>
            </template>
          </el-table-column>
          
          <el-table-column prop="inv_round" label="门店班次" align="center" width="180">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_round == item.dict_value" v-for="item in shopround" :key="item.dict_value">{{item.dict_label}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inv_extraid" label="单位名称" align="center" width="180">
          </el-table-column>

          <el-table-column label="上传" width="70">
            <template slot-scope="scope">
              <span v-show="scope.row.tstate == 0" style="color:#909399;">新建</span>
              <span v-show="scope.row.tstate == 1" style="color:#67c23a;">待上传</span>
              <span v-show="scope.row.tstate == 9" style="color:#67c23a;">已上传</span>
            </template>
          </el-table-column>

          <el-table-column prop="bstate" label="状态" width="60">
            <template slot-scope="scope">
              <span v-show="scope.row.bstate == 0" style="color:#909399;">起草</span>
              <span v-show="scope.row.bstate == 1" style="color:#e6a23c;">确认</span>
              <span v-show="scope.row.bstate == 2" style="color:#66b1ff;">发出</span>
              <span v-show="scope.row.bstate == 3" style="color:#67c23a;">收到</span>
            </template>
          </el-table-column>

          <el-table-column prop="lby" label="操作人" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.lby == item.id" v-for="item in users" :key="item.id">{{item.name}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="inv_billid" label="手工单号" align="center">
          </el-table-column>

          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="mini" icon="el-icon-view" @click="editRpt([scope.row],true)">查看</el-button>
              <!-- <el-button :disabled="scope.row.tstate == 1" type="text" size="mini" icon="el-icon-edit" @click="editRpt([scope.row])">修改</el-button> -->
              <el-button :disabled="scope.row.tstate == 1 || scope.row.bstate == 1" type="text" size="mini" icon="el-icon-delete" @click="det([scope.row])">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="page_data.total > 0" :total="page_data.total" :page.sync="page_data.currentPage" :limit.sync="page_data.pagesize" @pagination="initData" />

        <!-- <div style="margin-top:10px;">  
          <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total"
            background hide-on-single-page>
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import 报货api from './api.js'
import { parseTime, pagePagination } from '@/utils/index.js'
import { mapGetters } from 'vuex'
export default {
  filters: {
    parseTime
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_inv_op', 'dictdata']),
    ...mapGetters('user', ['users'])
  },
  data() {
    return {
      // 显示搜索条件
      showSearch: true,
      // 日期范围
      dateRange: [],
      activeName: 'first',
      text: '',
      pos_invop: 0,
      //发方
      invfm: [],
      //收方
      invto: [],
      //门店班组
      shopround: [],
      //门店生产班组
      shopprod: [],
      //拒收原因
      shopreason: [],
      searchform: {
        inv_id: '',
        inv_billtype: 0,//不用 门店单据类型0报货单1铺货单
        inv_region: '',//不用
        inv_date1: '',
        inv_billid: '',//门店手工单据号
        inv_whycat: null,
        inv_whycat_name: '',
        inv_fm: '',
        inv_fmcat: '',
        inv_fmshop: null,//发送门店用于门店间调拨
        inv_to: '',
        inv_tocat: '',
        inv_toshop: null,//接收门店用于门店间调拨
        inv_extraid: null,//外部单位编号
        inv_round: null,//门店班次  登录班次
        inv_prod: null,//生产班次
        inv_reason: null,//门店退库原因
        notes: '',//备注
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      radio: [],
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 20
      },
      query: {
        type: 0,
        look: 0,
        invop: 0,
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    next(vm => {
      vm.$store.commit('app/setkucunPage', to.fullPath)
    })
  },
  async created() {
    console.log(this.$route.query)
    this.pos_invop = this.$route.params.k_type//获取库存操作类别
    this.searchform.inv_whycat = this.$route.params.k_type

    // //获得操作类型数据当前行数据
    // this.t_pos_inv_op.forEach(item => {
    //   if (item.id == this.$route.params.k_type) {
    //     if (item.invop_sorc == 'KC') {
    //       this.invfm = this.t_region
    //     } else if (item.invop_dest == 'KC') {
    //       this.invto = this.t_region
    //     }
    //   }
    // })
    this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
    this.shopprod = this.dictdata.filter(item => item.dict_type == "bus_shop_prod")//门店生产班组
    this.shopreason = this.dictdata.filter(item => item.dict_type == "bus_returns_reason")//拒收原因

     console.log(1111,this.shopreason)
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      this.loading = true;
      let 库存单 = await this.$localforage.getItem('t_inv_bill') || []

        let subTime ,supTime
        subTime = this.dateRange[0];
        supTime = this.dateRange[1];
       if (this.dateRange.length==0){
          subTime=0
          supTime=4133865600000 //2100-12-31
       }
      //需要根据单据类型过滤数据
      let arr = 库存单.filter(item =>
        item.bstate <= 1
        && item.inv_whycat == this.pos_invop //生产入库
        && item.inv_id.includes(this.searchform.inv_id)
        && Number(item.inv_region).toString().includes(this.searchform.inv_region)
        && Number(item.inv_to).toString().includes(this.searchform.inv_to)
        &&  subTime <= item.inv_date1 && item.inv_date1 <= supTime
      )

         //降序排列排序
      arr.sort(function(a,b){
			return b.inv_date - a.inv_date
		  })
         
      this.tableData = pagePagination(arr, this.page_data.pagesize, this.page_data.currentPage)
      this.page_data.total = arr.length
      this.loading = false;
    },
    // 分页
    handleCurrentChange(v) {
      this.page_data.currentPage = v
      this.initData()
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.page_data.currentPage = 1;
      this.initData();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.reset()
      this.handleQuery();
    },
   //表单重置
    reset() {
     this.searchform.inv_id=''
     this.searchform.inv_region=''
     this.searchform.inv_to=''
     this.dateRange=[]
    },


    goback() {
      this.$router.push('/goodsStorage')
      this.$store.commit('app/setkucunPage', null)
    },
    add() {
      this.query = {
        type: '1',
        //  look:false,
        invop: this.pos_invop,
      }
      this.$router.push({ path: `/warehoustking/${this.$route.params.k_type}/0` })
      // this.$router.push('/warehousing/' + this.pos_invop + '?type=' + '1')
    },
    editRpt(val, look) {
      this.query = {
        type: '2',
        look: look,
        invop: this.pos_invop,
      }
      let id = val[0].id
      //  console.log(id)
      this.$router.push({ path: `/warehoustking/${this.$route.params.k_type}/${id}`})

    },

  
    // 删除
    det(list) {
      this.$confirm('是否删除选中的退货单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let table = this.tableData
        let 报货明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
        let 报货主表 = await this.$localforage.getItem('t_inv_bill') || []//主表

        list.forEach(item => {
          for (let i = table.length - 1; i >= 0; i--) {
            if (item.id == table[i].id) { // 先删除页面上表格的数据
              table.splice(i, 1)
            }
          }
          // 删除本地存储的数据
          for (let i = 报货主表.length - 1; i >= 0; i--) {
            if (item.id == 报货主表[i].id) { // 删除主表数据
              报货主表.splice(i, 1)
            }
          }
          for (let i = 报货明细表.length - 1; i >= 0; i--) {
            if (item.id == 报货明细表[i].pid) { // 删除主表id等于id的明细表
              报货明细表.splice(i, 1)
            }
          }
        });
        // c存入数据
        await this.$localforage.setItem('t_inv_bill_items', 报货明细表)
        await this.$localforage.setItem('t_inv_bill', 报货主表)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击复选框
    onSelect(val) {
      this.radio = val
    },
    //上传
    async uploadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let 报货明细表 = await this.$localforage.getItem('t_pos_inv_bill_items') || [] //明细表
      let 报货主表 = await this.$localforage.getItem('t_pos_inv_bill') || []//主表
      let master = []
      let 主表上传的下标 = [] //保存住上传的数据的下标
      报货主表.forEach((item, i) => {
        console.log(item)
        if (item.tstate == 0 && item.bstate == 1 && item.inv_whycat == this.pos_invop) {
          master.push(JSON.parse(JSON.stringify(item)))
          主表上传的下标.push(i)
        }
      })
  
      let detail = []
      let 明细下标 = []
      master.forEach(item => {
        let arr = []
        for (let i = 0; i < 报货明细表.length; i++) {
          if (报货明细表[i].pid == item.id) {
            arr.push(报货明细表[i])
            明细下标.push(i)
          }
        }
        detail.push(...arr)
      })
      if (master.length > 0) {
        let data = {
          "jsons": {
            master: master,
            detail: detail
          }
        }

        let [err, res] = await 报货api.入库单上传(data).catch(err => { loading.close() })
        if (err) return loading.close()
        if (res.statusCode == 200) {
          loading.close()
          this.$message.success('数据上传成功')
          for (let i = 0; i < 主表上传的下标.length; i++) {
            报货主表[主表上传的下标[i]].tstate = 9
          }
          for (let i = 0; i < 明细下标.length; i++) {
            报货明细表[明细下标[i]].tstate = 9
          }
          await this.$localforage.setItem('t_pos_inv_bill_items', 报货明细表)
          await this.$localforage.setItem('t_pos_inv_bill', 报货主表)
          this.initData()
          return
        } else {
          loading.close()
          return this.$message.error(res.message)
        }
      }
      this.$message.error('没有需要上传的数据')
      loading.close()
    }
  }

}
</script>

<style scoped lang="less">
.biao-box {
  width: 100%;
  // padding: 15px 40px;
}
.handel-btn {
  margin: 10px 0;
  padding: 0 10px;
}
</style>