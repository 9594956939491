// #if NOCDN
import CryptoJS from 'crypto-js'
// #endif
export function ymAesEncode(str, keyStr) {
    // key 是 16字节 就是 AES-128
    const key = CryptoJS.enc.Hex.parse(CryptoJS.MD5(keyStr).toString())
    // const iv = key

    const srcs = CryptoJS.enc.Utf8.parse(str)
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
        // iv: iv,
        mode: CryptoJS.mode.ECB, // CBC
        padding: CryptoJS.pad.Pkcs7
    })

    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

export function makeAE1pass(password) {
    return 'AE1:' + ymAesEncode(password, '1232')
}

export function cloneObj(obj) { return JSON.parse(JSON.stringify(obj)) }

export default {
    ymAesEncode,
    makeAE1pass,
    cloneObj
}
