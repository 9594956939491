<template>
  <div id="leftNav" class="left-nav">
    <ul>
      <router-link to="/" exact>
        <li @click="changetable('/')" :class="currentPage == '/' ? 'active':''">
          <i class="iconfont icon-homepage"></i>
          <div>
            首页
          </div>
        </li>
      </router-link>
      <router-link to="/cashier" exact>
        <li @click="changetable('/cashier')" :class="currentPage == '/cashier' ? 'active':''">
          <i class="iconfont icon-shouyin"></i>
          <div>
            收银
          </div>
        </li>
      </router-link>
      <router-link to="/dingdan">
        <li @click="changetable('/dingdan')" :class="currentPage == '/dingdan' ? 'active':''">
          <i class="iconfont icon-el-icon-custom-order"></i>
          <div>
            订单
          </div>
        </li>
      </router-link>
      <router-link :to="left_lingshou_currentPage?left_lingshou_currentPage:'/dianpu'">
        <li @click="changetable('/dianpu')" :class="currentPage == '/dianpu' ? 'active':''">
          <i class="iconfont icon-dianpu-1"></i>
          <div>
            零售
          </div>
        </li>
      </router-link>
      <!-- <router-link to="/goodsDetailList">
      <li @click="changetable('/goodsDetailList')" :class="currentPage == '/goodsDetailList' ? 'active':''">
        <i class="iconfont icon-shangpin"></i>
        <div>
          商品
        </div>
      </li></router-link
         > -->
      <router-link to="/vip">
        <li @click="changetable('/vip')" :class="currentPage == '/vip' ? 'active':''">
          <i class="iconfont icon-huiyuan-"></i>
          <div>
            会员
          </div>
        </li>
      </router-link>
      <router-link :to="left_baohuo_currentPage?left_baohuo_currentPage:'/baohuo'">
        <li @click="changetable('/baohuo')" :class="currentPage == '/baohuo' ? 'active':''">
          <i class="iconfont icon-baohuofahuo"></i>
          <div>
            报货
          </div>
        </li>
      </router-link>
      <router-link :to="left_kucun_currentPage?left_kucun_currentPage:'/goodsStorage'">
        <li @click="changetable('/goodsStorage')" :class="currentPage == '/goodsStorage' ? 'active':''">
          <i class="iconfont icon-kucun"></i>
          <div>
            库存
          </div>
        </li>
      </router-link>
      <router-link to="/baobiao">
        <li @click="changetable('/baobiao')" :class="currentPage == '/baobiao' ? 'active':''">
          <i class="iconfont icon-baobiao"></i>
          <div>
            报表
          </div>
        </li>
      </router-link>
      <router-link to="/setting">
        <li @click="changetable('/setting')" :class="currentPage == '/setting' ? 'active':''">
          <i class="iconfont icon-shezhi"></i>
          <div>
            设置
          </div>
        </li>
      </router-link>
    </ul>
  </div>
</template>
<script>
import { getToken, setToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
export default {
  name: "leftNav",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['currentPage']),
    ...mapGetters('app', ['left_baohuo_currentPage', 'left_kucun_currentPage', 'left_lingshou_currentPage'])
  },
  methods: {
    changetable(n) {
      this.$store.commit('user/changeCurrentPage', n)
      setToken('currentPage', n)
    }
  }
};
</script>
<style scoped>
.left-nav {
  color: #fff;
  font-size: 16px;
  background-color: #1c2438;
  margin: 0;
  float: left;
  width: 65px;
  height: 100%;
}
.iconfont {
  font-size: 20px;
  margin: 0 auto;
}
.left-nav ul {
  padding: 0;
  margin: 0;
}
.left-nav li {
  list-style: none;
  text-align: center;
  /* padding: 10px; */
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid #fff;
}
.left-nav li:hover {
  background-color: #2d8cf0;
  transition: 0.5s;
}
.active {
  color: #fff;
  background-color: #2d8cf0;
}
a {
  color: #fff;
  text-decoration: none;
}
</style>
