<template>
  <div style="padding:10px 15px;width:100%;">
    <div class="btn">
      <el-button size="small" type="success" :disabled="is_look" @click="seave(0)">保 存</el-button>
      <el-button size="small" type="primary" :disabled="is_look" @click="seave(1)">确 认</el-button>
      <el-button size="small" type="primary"  @click="handlePrint()" >打 印</el-button>
      <el-button size="small" type="primary">Excel</el-button>
      <el-button size="small" type="danger" :disabled="is_look" >删 &nbsp; 除</el-button>
      <el-button size="small" @click="goback">返 回</el-button>
    </div>
    <div class="content">
      <el-row>
        <el-col :span="15">
          <div class="form">
            <el-form ref="form" :model="searchform" :rules="rules" label-width="80px" inline size="small" :disabled="is_look">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="单据编号" prop="inv_id">
                    <el-input v-model="searchform.inv_id" readonly style="width:210px;"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="单据类型" prop="inv_whycat">
                    <el-select v-model="searchform.inv_whycat" disabled style="width:170px;">
                      <el-option v-for="item in t_inv_op" :key="item.invop_id" :label="item.invop_namec" :value="item.invop_id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="退货区域" prop="inv_region">
                    <el-select v-model="searchform.inv_region" style="width:170px;">
                      <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="退回仓库" prop="inv_to">
                    <el-select v-model="searchform.inv_to" style="width:170px;">
                      <el-option v-for="item in t_wrhus" :key="item.id" :label="item.wrhus_name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="确认日期" prop="inv_date1" >
                    <el-date-picker v-model="searchform.inv_date1" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:210px;"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </el-col>

                <!-- <el-col :span="6">
                  <el-form-item label="订单号">
                    <el-input v-model="searchform.name" disabled style="width:170px;"></el-input>
                  </el-form-item>
                </el-col> -->

                <el-col :span="6">
                  <el-form-item label="班次" prop="inv_round">
                    <el-select v-model="searchform.inv_round" style="width:170px;">
                      <el-option v-for="item in shopround" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
               <el-col :span="6">
                  <el-form-item label="退库原因" prop="inv_reason">
                    <el-select v-model="searchform.inv_reason" style="width:170px;">
                      <el-option v-for="item in shopreason" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
            
                <el-col :span="6" style="position: relative">
                  <el-form-item label="手工单号">
                    <el-popover placement="bottom" width="540" trigger="click">
                      <div class="keyboard">
                        <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput"></all-keyboard>
                      </div>
                      <el-input slot="reference" v-model="searchform.inv_billid" @focus.stop="all_keyboard_show=true" style="width:170px;"></el-input>
                    </el-popover>
                  </el-form-item>
                </el-col>
                 <el-col :span="6">
                  <el-form-item label="上传" prop="tstate">
                    <el-select v-model="searchform.tstate"  style="width:170px;"  :disabled="true">
                      <el-option label="新建" :value="0"></el-option>
                      <el-option label="待上传" :value="1"></el-option>
                      <el-option label="已上传" :value="9"></el-option>
                      
                    </el-select>
                  </el-form-item>
                </el-col>
                  <el-col :span="6">
                  <el-form-item label="状态" prop="bstate">
                    <el-select v-model="searchform.bstate"  style="width:170px;" :disabled="true">
                      <el-option label="未确定" :value="0"></el-option>
                      <el-option label="已确定" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="备注">
                    <el-input v-model="searchform.notes" style="width:300px;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-table ref="tableRef" :data="tableData" style="width: 100%" border max-height="700" highlight-current-row @row-click="onRowClick">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="part_id" label="品号">
            </el-table-column>
            <el-table-column prop="part_name" label="品名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="part_unit" label="单位" width="50">
            </el-table-column>

            <el-table-column prop="part_specs" label="规格" width="70">
            </el-table-column>

            <el-table-column prop="part_price" label="单价" width="70">
            </el-table-column>

            <el-table-column label="退货数量" width="150">
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-input-number :min="1" v-model="scope.row.invbi_plan" @focus="showkeyboard('invbi_plan')" size="mini" controls-position="right"></el-input-number>
                </div>
                <div v-else>{{scope.row.invbi_plan}}</div>
              </template>
            </el-table-column>

            <el-table-column label="成本单价" width="150">
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-input-number :min="1" v-model="scope.row.invbi_price" @focus="showkeyboard('invbi_price')" size="mini" controls-position="right"></el-input-number>
                </div>
                <div v-else>{{scope.row.invbi_price}}</div>
              </template>

            </el-table-column>

            <el-table-column prop="part_model" label="型号">
            </el-table-column>

          <el-table-column label="备注" width="320px">
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-select  v-model="scope.row.notes" multiple filterable size="mini" style="width: 300px"  controls-position="right" placeholder="请选择" >
                   <el-option v-for="item in shoprejreason" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value+''">
                   </el-option>
                   </el-select>
                </div>
                <div v-else>{{scope.row.notes}}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="80">
              <template scope="scope">
                <el-button :disabled="is_look" size="small" type="danger" @click="delItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="9" class="goods">
          <goods-menu @chooseGoods="addOrderList" @chooseType="nowType" />
        </el-col>
      </el-row>
    </div>
    <div class="keybord_box">
      <key-board right="40%" ref="keyboard" @input-num="inputNum"></key-board>
    </div>
     <div class="ticket-box" >
        <ticket-template ref="histprintcom" name="buda" :ticketdata="ticket_data" v-show="show_ticketI"></ticket-template>
      </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { NowTime } from '@/utils'
import { getLocal } from '@/utils/localstorage.js'
import KeyBoard from '@/components/NumberKeyboard'
import GoodsMenu from '@/views/收银/GoodsMenu'
import AllKeyboard from '@/components/Keyboard.vue'
import { mapGetters } from 'vuex'
import ticketTemplate from '@/components/TicketTemplate/printinvrtn.vue'//打印
export default {
  components: {
    GoodsMenu,
    KeyBoard,
    AllKeyboard,
    ticketTemplate
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_inv_op', 'dictdata'])
  },
  data() {
    return {
      ticket_data:{}, //小票数据
      show_ticketI:false,
      is_look: false,
      is_fm_look: false,
      is_to_look: false,
      editid: null,
      库存单主表: {},
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      //时间选择器
      pickerOptions: this.getPickerOptions(),
      searchform: {
        inv_id: '',
        tstate: 0,
        bstate: 0,
        inv_bustype: 0,//门店单据类型0一报1二报9订单报货
        inv_region: null,//不用
        inv_date1: null,
        inv_billid: '',//门店手工单据号
        inv_whycat: null,
        inv_fm: null,
        inv_fmcat: null,
        inv_to: null,
        inv_tocat: null,
        inv_extraid: null,//外部单位编号
        inv_round: null,//门店班次  登录班次
        inv_reason: null,//门店退库原因
        notes: '',//备注
      },
      rules: {
        inv_id: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        inv_region: [{ required: true, message: '请选择退货区域', trigger: 'change' }],
        inv_date1: [{ required: true, message: '请选择确认日期', trigger: 'change' }],
        inv_to: [{ required: true, message: '退回仓库', trigger: 'change' }],
        inv_reason: [{ required: true, message: '请选择原因', trigger: 'change' }],
      },
      //发方
      invfm: [],
      //收方
      invto: [],
      //门店班组
      shopround: [],
      //门店退库原因
      shopreason: [],
       //门店拒收原因
      shoprejreason: [],
      // 库存操作类型
      invWhycat: [],
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      nowGoodsType: null,
      all_keyboard_show: false,
      router_query: false
    }
  },
  
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.l_rpt ==0) {//新增 //单据ID号
        vm.router_query = to.params.k_type//操作类型
        vm.tableData = []
      } else {
        if (!vm.router_query) {
          vm.router_query = to.params.k_type//操作类型
        } else {
          if (to.params.k_type !== vm.router_query) {
            vm.router_query = to.params.k_type
            vm.tableData = []
          }
        }
      }
      vm.$store.commit('app/setkucunPage', to.fullPath)
      vm.initData()
    })
  },

   async created() {
      this.initData()
  },
  methods: {
    async initData() {
      await this.getGoodsData()
      await this.getTypeData()
      this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
      this.shopreason= this.dictdata.filter(item => item.dict_type == "bus_returns_reason")//门店退库原因
      this.shoprejreason= this.dictdata.filter(item => item.dict_type == "bus_rejection_reason")//门店拒收原因
      this.editid =Number(this.$route.params.l_rpt) //单据ID号 // this.$route.params {k_type: "267059179028505", l_rpt: "343089025658389"}  k_type：操作类型/l_rpt：单据ID号
      if (this.editid == 0) { // 类型为0是新增
        this.t_inv_op.forEach(item => { //获得操作类型数据当前行数据
          if (item.invop_id == this.$route.params.k_type) {
            this.searchform.inv_whycat = item.invop_id
            this.searchform.inv_fmcat = item.invop_sorc
            this.searchform.inv_tocat = item.invop_dest
          }
        })
         console.log('this.t_inv_op', this.t_inv_op,this.$route.params.k_type)
        this.setData()
        this.searchform.inv_id = this.制作编号()
        this.is_look = false
      } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
        this.initEditData(this.editid)
      }

      
      //默认
      let isdefault = this.t_region.filter(item => item.is_default == 1)//默认
      this.searchform.inv_region= isdefault[0].dict_value
      this.searchform.inv_to= this.t_wrhus.length>0 ? this.t_wrhus[0].id:null
      this.searchform.inv_reason= this.shopreason.length>0 ? this.shopreason[0].dict_value:null
   console.log('this.t_wrhus', this.t_wrhus)
    },
    async initEditData(id) {
      console.log('initEditData', id)
      let 库存单 = await this.$localforage.getItem('t_inv_bill')
      let 库存单明细 = await this.$localforage.getItem('t_inv_bill_items')
      库存单.forEach(item => {
        if (item.id == id) {
          this.库存单主表 = item
        }
      })
      for (const key in this.searchform) {
        this.searchform[key] = this.库存单主表[key]
      }
       //状态
         if (this.searchform.tstate == 0 && this.searchform.bstate == 0 ) {
          this.is_look = false
        } else {
          this.is_look = true
        }

      let arr = []
      库存单明细.forEach(item => {
        if (item.pid == id) {
          let goodsItem = this.goodsData.filter(f => f.id == item.partid)
          let obj = {
            partid: goodsItem[0].id,
            part_id: goodsItem[0].part_id,
            part_name: goodsItem[0].part_name,
            part_price: goodsItem[0].part_price,
            part_unit: goodsItem[0].part_unit,
            part_specs: goodsItem[0].part_specs,
            invbi_price: item.invbi_price,
            invbi_plan: item.invbi_plan,
            part_model: goodsItem[0].part_model,
            part_usertype: goodsItem[0].part_usertype,
            partusertype: goodsItem[0].partusertype,
            notes:item.notes==""?'':item.notes.split(','),
            库存: 0,
            销量: 0,    
          }
          arr.push(obj)
        }
      })
      this.tableData = arr
    },
    setData() {
      this.searchform.inv_date1 = parseInt(new Date().getTime())
    },
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      let type0Goods = typeData.filter(i => !i.isdel)
      if (type0Goods.length > 0) {
        this.nowGoodsType = type0Goods[0]
      }
    },
    nowType(v) {
      if (!v) return
      this.nowGoodsType = v
    },
    addOrderList(v) {
      let ishave = this.tableData.findIndex(i => i.partid == v.id)
      if (ishave >= 0) {
        console.log(ishave);
        this.$refs.tableRef.setCurrentRow(this.tableData[ishave])
        if (this.is_look) return
        this.tableData[ishave].invbi_plan++
      } else {
        if (this.is_look) return
        let data = {
          partid: v.id,
          part_id: v.part_id,
          part_unit: v.part_unit,
          part_name: v.part_name,
          part_price: v.part_price,
          part_specs: v.part_specs,
          invbi_price: v.part_price,
          invbi_plan: 1,
          part_model: v.part_model,
          part_usertype: v.part_usertype,
          partusertype: v.partusertype,
          notes: '',
          库存:  0,
          销量: 0

        }
        this.tableData.push(data)
      }
    },
    delItem(item) {
      let i = this.tableData.findIndex(v => v.id == item.id)
      if (i >= 0) {
        this.tableData.splice(i, 1)
      }
    },
    onRowClick(v) {
      this.nowGoods = v
    },
    seave(bstate) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.tableData.length < 1) return this.$message.error('请选择入库的商品')
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          console.log('this.user', user)
          let seavetime = parseInt(new Date().getTime())
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 库存单明细id = makeid(this.tableData.length)
          let 库存单明细 = []
          let 总价值 = 0
          let 库存明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
          let 库存主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
          let 总库存 = this.$store.state.user.t_pos_inv_bal //库存
      
          let  上传状态  //传输状态0-新建, 1-待上传,2-未批复, 3-已批复,4-已确认,4-完成
          if (bstate==0){
              上传状态=0
          }else if (bstate==1){
              上传状态=1
          }
           if (this.editid ==0) {//新增
            // 新增
            this.tableData.forEach((item, i) => {
               总价值 = 总价值 + (item.invbi_plan * item.part_price)
              let obj = {
                id: 库存单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: 主表id,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_plan,
                invbi_amnt: item.invbi_plan,
                invbi_cost_plan: item.invbi_plan * item.part_price,
                invbi_cost_send: item.invbi_plan * item.part_price,
                invbi_cost_amnt: item.invbi_plan * item.part_price,
                invbi_type: '',
                invbi_plan_f: 0,
                invbi_unitconv: 0,
                invbi_price: item.part_price,
                invbi_tag: 0,
                pos_id: shopBaseMsg.terminal, 
                notes: item.notes==""? '':item.notes.join(',')
              }
              库存单明细.push(obj)
            })

          let 库存单据主表 = {
              id: 主表id,
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              tstate: 上传状态, //0 未上传 1上传
              bstate: bstate,
              inv_date: parseInt(new Date().getTime()),
              inv_date1: this.searchform.inv_date1,
              inv_date2:parseInt(new Date().getTime()),
              inv_whycat: this.searchform.inv_whycat,
              inv_whyabs: "",
              inv_who1: user.id,
              inv_who2: user.id,
              inv_fmcat: this.searchform.inv_fmcat,
              inv_fm: this.searchform.inv_fm,
              inv_fmbill: "",
              inv_tocat: this.searchform.inv_tocat,
              inv_to: this.searchform.inv_to,
              inv_tobill: "",
              inv_cost: 总价值, //总价值
              inv_extraid: 0,//外部单位编号
              inv_needdate:parseInt(new Date().getTime()),
              inv_region: this.searchform.inv_region,
              inv_bustype: this.searchform.inv_bustype,
              inv_billtype: 0 , //门店单据类型0报货单1铺货单
              inv_round: this.searchform.inv_round,
              inv_billid: this.searchform.inv_billid,
              inv_reason: this.searchform.inv_reason,   
              dept_id: user.dept_id,
              pos_id: shopBaseMsg.terminal,
             notes:this.searchform.notes,
            }
           库存主表.push(库存单据主表)
            库存明细表.push(...库存单明细)

            // let 库存单据主表 = {
            //   id: 主表id,
            //   tenid: user.tenid,
            //   lct: seavetime,
            //   uct: seavetime,
            //   lby: user.id,
            //   uby: user.id,
            //   inv_id: this.searchform.inv_id,
            //   bstate: bstate,
            //   inv_date: parseInt(new Date().getTime()),
            //   inv_needdate: parseInt(new Date().getTime()),
            //   inv_date1: this.searchform.inv_date1,
            //   inv_date2: parseInt(new Date().getTime()),
            //   inv_fm: this.searchform.inv_fm,
            //   inv_cost: 总价值, //总价值
            //   inv_region: this.searchform.inv_region,
            //   inv_bustype: this.searchform.inv_bustype,
            //   inv_billid: this.searchform.inv_billid,
            //   pos_id: shopBaseMsg.terminal,
            //   tstate: 上传状态, //0 未上传 1上传
            //   inv_to: this.searchform.inv_to,
            //   inv_fm:user.dept_id,
            //   dept_id:user.dept_id,
            //   inv_whycat: this.searchform.inv_whycat,
            //   inv_fmcat: this.searchform.inv_fmcat,
            //   inv_tocat: this.searchform.inv_tocat,
            //   inv_round: this.searchform.inv_round,
            //   inv_reason: this.searchform.inv_reason,
            //   notes:this.searchform.notes,
            // }
            // 库存主表.push(库存单据主表)
            // 库存明细表.push(...库存单明细)
          } else {
            // 编辑
            this.tableData.forEach((item, i) => {
              if (bstate==1){
              let n = 总库存.findIndex(j => j.part_id == item.part_id)//库存
              if (n>=0) {
                //判断出库
                  总库存[n].bal_amnt = 总库存[n].bal_amnt - (item.invbi_plan - 0)
                  总库存[n].bal_cost = (总库存[n].bal_amnt-0) * (item.invbi_price-0)
                //修改vuex中的库存数
                this.$store.commit('user/出入库修改结存数量',item);
              }else {//没有记录时增加一条
                let balamnt ,regionid
                      balamnt = item.invbi_plan / -1
                      regionid=this.searchform.inv_region
                 
                    let obj = {
                      id: item.partid,
                      tenid: user.tenid,
                      lct: seavetime,
                      uct: seavetime,
                      lby: user.id,
                      uby: user.id,
                      part_name: item.part_name, // 品名
                      tstate: 1,
                      dept_id: user.dept_id,
                      part_id: item.part_id, // 品号
                      partid: item.partid, // 系统品号
                      region_id: regionid, //入库区域
                      bal_date: seavetime,
                      bal_amnt: balamnt, //库存
                      bal_cost: -(item.invbi_plan * item.part_price), //价值
                      sales_volume: 0,//销量
                      part_price: item.part_price,
                      part_model: item.part_model,
                      part_specs: item.part_specs,
                      part_usertype: item.part_usertype,
                      partusertype: item.partusertype
                    }
                    总库存.push(obj)
                    this.$store.commit('user/获取结存表', 总库存);
                  }   
              }
         
              总价值 = 总价值 + (item.invbi_plan * item.invbi_price)
              let obj = {
                id: 库存单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: this.editid,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_plan,
                invbi_amnt: item.invbi_plan,
                invbi_cost_plan: item.invbi_plan * item.part_price,
                invbi_cost_send: item.invbi_plan * item.part_price,
                invbi_cost_amnt: item.invbi_plan * item.part_price,
                invbi_type: '',
                invbi_plan_f: 0,
                invbi_unitconv: 0,
                invbi_price: item.part_price,
                invbi_tag: 0,
                pos_id: shopBaseMsg.terminal, 
                notes: item.notes==""? '':item.notes.join(',')
              }
              库存单明细.push(obj)
            })
            let 库存单据主表 = {
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              tstate: 上传状态, //0 未上传 1上传
              bstate: bstate,
              inv_date: parseInt(new Date().getTime()),
              inv_date1: this.searchform.inv_date1,
              inv_date2: parseInt(new Date().getTime()),
              inv_whycat: this.searchform.inv_whycat,
              inv_whyabs: "",
              inv_who1: 0,
              inv_who2: 0,
              inv_fmcat: this.searchform.inv_fmcat,
              inv_fm: this.searchform.inv_fm,
              inv_fmbill: "",
              inv_tocat: this.searchform.inv_tocat,
              inv_to: this.searchform.inv_to,
              inv_tobill: "",
              inv_cost: 总价值, //总价值
              inv_extraid: 0,//外部单位编号
              inv_needdate: parseInt(new Date().getTime()),
              inv_region: this.searchform.inv_region,
              inv_bustype: this.searchform.inv_bustype,
              inv_billtype: 0 , //门店单据类型0报货单1铺货单
              inv_round: this.searchform.inv_round,
              inv_billid: this.searchform.inv_billid,
              inv_reason: this.searchform.inv_reason,    
              dept_id: user.dept_id,
              pos_id: shopBaseMsg.terminal,
              notes:this.searchform.notes,
            }
            库存主表.forEach(item => {
              if (item.id == this.editid) {
                for (const key in 库存单据主表) {
                  // 更新主表中对应的数据
                  item[key] = 库存单据主表[key]
                }
              }
            })
            // 删除明细表中对应的数据
            for (let index = 库存明细表.length - 1; index >= 0; index--) {
              let item = 库存明细表[index]
              if (item.pid == this.editid) {
                let i = 库存明细表.findIndex(v => v.id == item.id)
                if (i >= 0) {
                  库存明细表.splice(i, 1)
                }
              }
            }
            库存明细表.push(...库存单明细)
          }
          // 储存
          await this.$localforage.setItem('t_inv_bill_items', 库存明细表)
          await this.$localforage.setItem('t_inv_bill', 库存主表)
          this.router_query = false
          
          // if (bstate) {
          //   this.$message.success('保存成功')
          //   setTimeout(() => {
          //     this.$router.go(-1)
          //   }, 500)
          // }

         if (bstate==0) {
          this.$message.success('保存成功')
           if (this.editid==0){
            this.$route.params.l_rpt=主表id
            this.editid=主表id
            this.initEditData(this.editid)
             console.log('保存成功主表id', 主表id)
            }
          // setTimeout(() => { this.$router.go(-1) }, 500)
        }else  if (bstate==1) {
            this.$message.success('确认成功')
            this.is_look = true
            this.searchform.bstate=bstate
            this.searchform.tstate=1
            // 修改本地数据库中的库存数
           this.$localforage.setItem('t_pos_inv_bal',总库存)
            // setTimeout(() => { this.$router.go(-1) }, 500)
          }
        }

      })
    },

    // 键盘输入
    onKeyInput(v) {
      this.searchform.inv_billid = v
      this.all_keyboard_show = false
    },
    showkeyboard(v) {
      this.now_row_attr = v
      this.$refs.keyboard.open()
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods[this.now_row_attr] = n
    },
    goback() {
      this.router_query = false
      this.resetForm()
      this.tableData = []
      this.$router.push(`/warehoustklist/${this.$route.params.k_type}`)
    },
    resetForm(){
      this.searchform.inv_id = ''
      this.searchform.tstate = 0
      this.searchform.inv_billtype = 0
      this.searchform.inv_region = 0
      this.searchform.inv_date1 = null
      this.searchform.inv_billid = ''
      this.searchform.inv_whycat = null
      this.searchform.inv_fm = null
      this.searchform.inv_fmcat = ''
      this.searchform.inv_fmshop = null
      this.searchform.inv_to = null
      this.searchform.inv_tocat = ''
      this.searchform.inv_toshop = null
      this.searchform.inv_extraid = null
      this.searchform.inv_round = null
      this.searchform.notes = ''
      this.editid = null
      this.$refs.form.resetFields()
    },
    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return this.searchform.inv_whycat + str + 商户号 + id
    },
     //翻译
        translate(d, value) {
          let datas = []
          let arr = value.split(',')
          arr.forEach((val, idx) => {
            d.forEach((item, index) => {
              if (val == item.dictValue) {
                datas.push(item.dictLabel)
              }
            })
          })
          if (datas.length > 0) {
            return datas.join(',')
          } else {
            return value
          }
        },
    //打印
    handlePrint(val){
   // 打印小票
        let ticketdata = {
            has_data: true,
            主表: this.searchform,
            明细arr: JSON.parse(JSON.stringify(this.tableData)),     
        } 
    this.ticket_data = ticketdata
    this.printLodop()
},

printLodop() {
  this.$refs.histprintcom.printTicket(this.ticket_data)

    },
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 10px;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.goods {
  background-color: #ccc;
  border-left: 5px solid #fff;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #82c0ff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #82c0ff;
}
.keyboard {
  width: 520px;
  padding: 10px;
  background-color: #ccc;
  z-index: 99;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
</style>