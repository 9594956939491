<template>
  <div id="pos" style="width:100%;" @click="vipinputV=false">
    <el-row>
      <el-col :span="12" class="pos-order">
        <div class="vipnumber" @click.stop>
          <div class="vip-box">
            <div class="search-box">
              <el-button type="text" @click="cardempty">会员</el-button>
              <el-popover
                placement="right-start"
                width="340"
                trigger="manual"
                v-model="vipinputV">
                <NumberBoard  
                  @bordclose="onBorderClose" 
                  @input-num="onKeyInput" 
                  @btnclick="onBtnClick"/>
              <el-input 
                slot="reference"
                v-model="queryParams.carddata" 
                @keydown.enter.native="searchVipCard(queryParams.carddata)" 
                @focus="vipinputV=true"
                style="width:160px;" 
                size="small" />
              </el-popover>
            </div>
            <span class="card_id_out">{{CardListCurren.card_id_out}}</span>
            <span v-show="CardListCurren.id > 0" class="card_name">名称:{{CardListCurren.card_name}}</span>
            <span v-show="CardListCurren.id > 0" class="card_mobile">手机号:{{CardListCurren.card_mobile}}</span>
            <span v-show="CardListCurren.id > 0" class="card_remoney">余额:{{CardListCurren.card_remoney}}</span>
            <span v-show="CardListCurren.id > 0" class="card_score">积分:{{CardListCurren.card_score}}</span>
            <span v-show="CardListCurren.id > 0" class="card_dis_rate">折扣:{{CardListCurren.card_dis_rate}}</span>
            <span>{{CardListCurren.card_sum}}</span>
            <span class="card_state" style="color: #9a9a9a;">{{ GetDictData(CardListCurren.card_state)}}</span> 
            <!-- <span class="state" style="color: #9a9a9a;" v-show="false">无效</span>   @focus="showkeyboard"-->
          </div>
          <div class="state-now">
            {{model==1?'销售中...':'退货中...'}}
          </div>
        </div>
        <el-tabs v-model="leftTopTabs" boder id="order-list" style="margin-left: 10px"  @tab-click="handleClick">
          <el-tab-pane label="点单" name="点单">
            <div class="shoping-box">
              <el-table border :data="tableData" ref="diandan" @row-click="goodsClick" highlight-current-row>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <!-- <el-table-column label="ID" width="60" v-if="false">
                  <template slot-scope="scoped">
                    <el-radio v-model="selectedGoodsId" :label="scoped.row.id" style="font-size:30px;">&nbsp;</el-radio>
                  </template>
                </el-table-column> -->
                <el-table-column prop="part_id" label="品号" width="90"></el-table-column>
                <el-table-column prop="part_name" label="品名"></el-table-column>
                <el-table-column prop="saledt_unit" label="单位" width="80"></el-table-column>
                <el-table-column prop="saledt_dis_1" label="折扣" width="80">
                  <template slot-scope="scoped">
                    <div>{{折扣金额(scoped.row)}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="saledt_dis_2" label="折让" width="80"></el-table-column>
                <el-table-column prop="count" label="量" width="150" align="center">
                  <template slot-scope="scoped">
                    <el-button type="primary" icon="el-icon-minus" circle size="mini" :disabled="scoped.row.count <= 1" @click="changeGoodsNum(scoped.row)"></el-button>
                    <span style="margin: 0 10px">{{ scoped.row.count }}</span>
                    <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addOrderList(scoped.row)"></el-button>
                  </template>
                </el-table-column>
                <el-table-column prop="part_price" label="单价" width="90">
                  <template slot-scope="scoped">
                    <span style="margin: 0 10px">{{ scoped.row.part_price?scoped.row.part_price:0 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="合计" width="90">
                  <template slot-scope="scoped">
                    <span style="margin: 0 10px">{{ scoped.row.实际价格 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="100" border>
                  <template slot-scope="scoped">
                    <el-button type="danger" @click="delGoods(scoped.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="mon-text">
              <p>
                <span>数量：<span style="color: #e4393c; font-size: 16px">{{ allCount}}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>折扣：<span style="color: #e4393c; font-size: 16px">{{ alldis1}}</span> </span>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>折让：<span style="color: #e4393c; font-size: 16px">{{ alldis2}}</span> </span>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>金额: <span style="color: #58b7ff; font-size: 16px">{{allMoney}}</span>元</span>

              </p>
            </div>
            <div class="btn">
              <pay-key-bored 
              @qingkong="delTab" 
              @dazhe="zhekou" 
              @goodsNum="inputGoodsNum" 
              @jiesuan="changeCheckoutFlag" 
              @keepList="keepList" 
              @changeModel="changeModel"
              @cardtop="handlecardtop"
              @carddh="handlecarddh"
              >
              </pay-key-bored>
            </div>
          </el-tab-pane>
          <el-tab-pane label="挂单" name="挂单">
            <el-table border :data="guadanList" ref="guadan">
              <el-table-column type="index" label="序号" width="70"></el-table-column>
              <el-table-column prop="goodsName" label="商品名称"></el-table-column>
              <el-table-column prop="count" label="数量" width="150" align="center">
                <template slot-scope="scoped">
                  <span style="margin: 0 10px">{{ scoped.row.count }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="part_price" label="合计" width="150">
                <template slot-scope="scoped">
                  <span style="margin: 0 10px">{{ scoped.row.part_price }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="100" border>
                <template slot-scope="scoped">
                  <el-button type="danger" @click="takeList(scoped.row)">取单</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- <el-tab-pane label="外卖">外卖</el-tab-pane> -->
          <el-tab-pane label="最近销售" name="最近销售">
            <lately-sale></lately-sale>
          </el-tab-pane>
          <el-tab-pane label="历史销售" name="历史销售">
            <history-sale @returnGoods="onReturnGoods"  ref="HistorySale" ></history-sale>
          </el-tab-pane>
           <el-tab-pane v-if="CardListCurren.id>0"  label="会员充值"  name="会员充值">
            <strategy-Top  @jiesuan="onCardTop"  ref="cardtop" :data="CardListCurren" ></strategy-Top>
          </el-tab-pane>
           <el-tab-pane v-if="CardListCurren.id>0" label="积分兑换"  name="积分兑换">
            <strategy-Scoredh  @jiesuan="onCardScoredh"  ref="cardscoredh" :data="CardListCurren" ></strategy-Scoredh>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="12" class="goods-order" id="goodList">
        <div class="often-goods">
        </div>
        <goods-menu @chooseGoods="addOrderList" :issale="true" />
      </el-col>
    </el-row>
    <el-dialog title="" :visible.sync="dialogVisible" width="1250px" @opened="onDialogOpend" @closed="onDialogClose" top="20px" :show-close="false" :close-on-click-modal="false">
      <div class="jiezhnagbox">
        <div class="jiezhnagbox-tit">
          <div class="tit-item">
            <span>实价:</span>
            <span>{{allMoneyType.实价}}</span>
          </div>
          <div class="tit-item">
            <span>优惠:</span>
            <span>{{allMoneyType.优惠}}</span>
          </div>
          <div class="tit-item">
            <span>应收:</span>
            <span style="color:#ea2a13;">{{应收金额}}</span>
          </div>
          <div class="tit-item">
            <span>已收:</span>
            <span>{{已收金额}}</span>
          </div>
          <div class="tit-item">
            <span>再收:</span>
            <span>{{再收金额}}</span>
          </div>
          <div class="tit-item">
            <span>找零:</span>
            <span>{{找零金额}}</span>
          </div>
          <div class="tit-item">
            <span>溢收:</span>
            <span style="color:#ea2a13;">{{溢收金额}}</span>
          </div>
        </div>
        <div class="content-box">
          <div class="content-left">
            <div class="payway-box">
              <div class="con-left-tit">支付方式</div>
              <div :class="whichPayWay === item.id?'payway-item active':'payway-item'"
                :style="mobilPayColor(item)"
                v-for="(item,i) in PayWay" :key="i" 
                @click="onPaywayChange(item)">
                {{ item.dict_label }} </div>
            </div>
            <div class="coupons-message">
              <!-- <div class="con-left-tit">礼券信息</div> -->
              <div class="con-left-tit"></div>
                <coupons-msg-table  ref="tidandataRef" :tabledata="coupons_table_data" :tabledatae="ecoupons_table_data"  :tuanPayVisibletype="tuanPayVisibletype" :salemodel="model" @tuanPaytype="tuanPaytype"></coupons-msg-table>
            </div>
          </div>
          <div class="content-right">
            <div class="con-right-top">
              <div class="con-left-tit">收款方式</div>
              <div class="show-way-box">
                <div class="show-way-box-tit">
                  <div style="flex: 0 0 50px;"></div>
                  <div class="box-tit-left">付款方式</div>
                  <div class="num">金额</div>
                </div>
                <div class="show-way-box-all">
                  <div :class="whichPayWay==item.id?'show-way-box-con paywayactive':'show-way-box-con'" 
                    v-for="(item,i) in nowPayWayname" :key="i" @click="zhifufangshi(item)">
                    <div style="width:50px;">
                      <el-radio v-model="whichPayWay" :label="item.id" style="font-size:30px;">{{empty_str}}</el-radio>
                    </div>
                    <div class="box-tit-left">{{item.name}}</div>
                    <div class="num">{{item.money}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="con-right-bottom">
              <div class="con-left-tit">操作</div>
              <key-board ref="keybord" 
                @enterNum="enterNuminPayWay" 
                @allmane="manjine"
                @cancel="dialogVisible = false" 
                @jiesuan="payforAll" 
                @zhekou="zhengZheKou" 
                @zherang="zhengZheRang"
                @qunti="consumerGroupsVisible = true">
              </key-board>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="消费群体" :visible.sync="consumerGroupsVisible" width="580px" :before-close="consumerGroupsClose">
      <div class="consumer-groups">
        <el-form ref="consumerGroupsform" :model="bus_cons_job" label-width="80px">
          <el-form-item label="性别:">
            <el-radio-group v-model="bus_cons_job.sex">
              <el-radio :label="0">男</el-radio>
              <el-radio :label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="年龄段:">
            <el-radio-group v-model="bus_cons_job.age">
              <el-radio style="margin-bottom:10px;" label="10以下">10以下</el-radio>
              <el-radio style="margin-bottom:10px;" label="10-20">10-20</el-radio>
              <el-radio style="margin-bottom:10px;" label="20-30">20-30</el-radio>
              <el-radio style="margin-bottom:10px;" label="40-50">40-50</el-radio>
              <el-radio style="margin-bottom:10px;" label="50-60">50-60</el-radio>
              <el-radio style="margin-bottom:10px;" label="60-70">60-70</el-radio>
              <el-radio style="margin-bottom:10px;" label="70以上">70以上</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="职业:">
            <div class="bus-cons-job-box">
              <div class="job-box-item" v-for="item in getBus_cons_job" :key="item.id">{{item.dict_label}}</div>
            </div>
          </el-form-item>
        </el-form>
        <div class="bus-cons-job-btn">
          <div class="btn" @click="consumerGroupsConfirm">确定</div>
          <div class="btn" @click="consumerGroupsVisible = false">返回</div>
        </div>
      </div>
    </el-dialog>
    <div class="ticket-box">
      <ticket-template ref="printcom" name="dayin" :ticketdata="ticket_data" v-show="show_ticket"></ticket-template>
    </div>
    <div class="keybord_box">
      <key-board-num ref="keyboard" @input-num="inputNum"></key-board-num>
    </div>
    <!-- 电子支付弹框 -->
    <el-dialog :title="paytitle" 
      :visible.sync="elePayVisible" 
      :close-on-click-modal="false"
      width="500px" 
      @opened="onElePayOpen"
      @close="onElePayClose">
      <electronic-payment ref="elePay" :elepaymsg="elepaymsg" @paydown="onPayDown" @payclose="elePayVisible = false"></electronic-payment>
    </el-dialog>

     <!-- 多会员选择弹出 -->
    <el-dialog :title="'选择会员'" 
      :visible.sync="MemberOpenVisible" 
      width="1000px" 
      :close-on-click-modal="false"
      >
      <member-open ref="memberopen" :data="CardList"  @cardselect="cardselect"></member-open>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submitForm">确 定</el-button> -->
        <el-button @click="cancelMemberOpen">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 提单支付弹框 -->
    <el-dialog title="提单" 
     :show-close="false"
     :visible.sync="tuanPayVisible" 
      width="800px" 
      top="20px"
      :close-on-click-modal="false"
      >
      <div>
        <tidan-pay ref="tidanRef" @tidanCanfirm="onTidanCanfirm" ></tidan-pay>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTidan">取 消</el-button>
        <el-button type="primary" @click="canfirmTidan">确 定</el-button>
      </span>
    </el-dialog>

        <!-- 电子券支付弹框 -->
    <el-dialog title=" 电子券" 
      :show-close="false"
      :visible.sync="tuanPayVisiblee" 
      width="800px" 
      top="20px"
      :close-on-click-modal="false"
      >
      <div>
        <Ecoupon ref="tidanRefe" @tidanCanfirme="onTidanCanfirme" @Ecouponclose="EcouponVisible = false"></Ecoupon>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTidane">取 消</el-button>
        <el-button type="primary" @click="canfirmTidane">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import methods from './indexMethod/index.js'
export default methods

</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>