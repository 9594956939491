<template>
  <div class="Echarts">
    <div class="title">年度销售统计图</div>
    <div id="year-main" style="width: 100%;height:400px;"></div>
  </div>
</template>

<script>
export default {
  name: 'Echarts',
  props: {
    data: {
      type: Array,
      default(){
        return []
      },
    },
  },
  data() {
    return {
      series_data:[]
    };
  },
  watch: {
    data: {
      async handler(n) {
        if(n){
          this.series_data = n
          this.myEcharts()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    myEcharts() {
      // 基于准备好的dom，初始化echarts实例
      if (!document.getElementById('year-main')) return
      var myChart = this.$echarts.init(document.getElementById('year-main'));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "5%",
          bottom: "5%"
        },
        xAxis: {
          type: 'category',
          data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月','八月','九月','十月','十一月','十二月']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.series_data,
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    }
  },
  
}
</script>

<style lang="less" scoped>
.title {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-bottom: 5px;
  color: #333;
}
</style>