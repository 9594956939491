<template>
  <div style="padding:10px 15px;width:100%;">
    <div class="btn">
      <el-button size="small" type="success" :disabled="is_look" @click="seave(0)">保 存</el-button>
      <el-button size="small" type="primary" :disabled="is_look" @click="seave(1)">确 认</el-button>
      <el-button size="small" type="primary" @click="handlePrint()">打 印</el-button>
      <el-button size="small" type="primary">Excel</el-button>
      <el-button size="small" type="danger" :disabled="is_look">删 &nbsp; 除</el-button>
      <el-button size="small" @click="goback">返 回</el-button>
    </div>
    <div class="content">
      <el-row>
        <el-col :span="15">
          <div class="form">
            <el-form ref="form" :model="searchform" :rules="rules" label-width="80px" inline size="small" :disabled="is_look">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="单据编号" prop="inv_id">
                    <el-input v-model="searchform.inv_id" readonly style="width:210px;"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="单据类型" prop="inv_whycat">
                    <el-select v-model="searchform.inv_whycat" disabled style="width:170px;">
                      <el-option v-for="item in t_pos_inv_op" :key="item.id" :label="item.invop_namec" :value="item.invop_id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="发方" prop="inv_fm">
                    <el-select v-model="searchform.inv_fm" style="width:170px;" :disabled="is_to_look">
                      <el-option v-for="item in invfm" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="收方" prop="inv_to">
                    <el-select v-model="searchform.inv_to" style="width:170px;" :disabled="is_fm_look">
                      <el-option v-for="item in invto" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="确认日期" prop="inv_date1">
                    <!-- <el-date-picker v-model="searchform.inv_date1" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width:170px;"
                      :picker-options="pickerOptions">
                    </el-date-picker> -->
                    <el-date-picker v-model="searchform.inv_date1" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:210px;"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </el-col>

                <!-- <el-col :span="6">
                  <el-form-item label="订单号">
                    <el-input v-model="searchform.name" disabled style="width:170px;"></el-input>
                  </el-form-item>
                </el-col> -->

                <el-col :span="6">
                  <el-form-item label="生产班次" prop="inv_prod">
                    <el-select v-model="searchform.inv_prod" style="width:170px;">
                      <el-option v-for="item in shopprod" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <!-- <el-col :span="6">
                  <el-form-item label="班次" prop="inv_round">
                    <el-input v-model="searchform.inv_round" readonly style="width:170px;"></el-input>
                  </el-form-item>
                </el-col> -->

                <el-col :span="6" style="position: relative">
                  <el-form-item label="手工单号">
                    <el-popover placement="bottom" width="540" trigger="click">
                      <div class="keyboard">
                        <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput"></all-keyboard>
                      </div>
                      <el-input slot="reference" v-model="searchform.inv_billid" @focus.stop="all_keyboard_show=true" style="width:170px;"></el-input>
                    </el-popover>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="上传" prop="tstate">
                    <el-select v-model="searchform.tstate" style="width:170px;" :disabled="true">
                      <el-option label="新建" :value="0"></el-option>
                      <el-option label="待上传" :value="1"></el-option>
                      <el-option label="已上传" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="状态" prop="bstate">
                    <el-select v-model="searchform.bstate" style="width:210px;" :disabled="true">
                      <el-option label="未确定" :value="0"></el-option>
                      <el-option label="已确定" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="备注">
                    <el-input v-model="searchform.notes" style="width:300px;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-table ref="tableRef" :data="tableData" style="width: 100%" border max-height="700" highlight-current-row @row-click="onRowClick"  :summary-method="getSummaries" show-summary>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="part_id" label="品号">
            </el-table-column>
            <el-table-column prop="part_name" label="品名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="part_unit" label="单位" width="50">
            </el-table-column>

            <el-table-column prop="part_specs" label="规格" width="70">
            </el-table-column>

            <el-table-column prop="part_price" label="单价" width="70">
            </el-table-column>

            <el-table-column label="计划数量" prop="invbi_plan" width="150" >
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-input-number :min="1" v-model="scope.row.invbi_plan" @focus="showkeyboard('invbi_plan')" size="mini" controls-position="right"></el-input-number>
                </div>
                <div v-else>{{scope.row.invbi_plan}}</div>
              </template>
            </el-table-column>

            <el-table-column label="成本单价" prop="invbi_price" width="150">
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-input-number :min="1" v-model="scope.row.invbi_price" @focus="showkeyboard('invbi_price')" size="mini" controls-position="right"></el-input-number>
                </div>
                <div v-else>{{scope.row.invbi_price}}</div>
              </template>
            </el-table-column>

                <!-- <el-table-column prop="invbi_plan_cost"  label="金额" width="70">
            </el-table-column> -->

            <el-table-column prop="bom_ver" label="配方版本">
            </el-table-column>

            <el-table-column prop="part_model" label="型号">
            </el-table-column>

            <el-table-column prop="notes" label="备注" show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="操作" width="80">
              <template scope="scope">
                <el-button :disabled="is_look" size="small" type="danger" @click="delItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="9" class="goods">
          <goods-menu @chooseGoods="addOrderList" @chooseType="nowType" />
        </el-col>
      </el-row>
    </div>
    <div class="keybord_box">
      <key-board right="40%" ref="keyboard" @input-num="inputNum"></key-board>
    </div>
    <div class="ticket-box">
      <ticket-template ref="histprintcom" name="inv" :ticketdata="ticket_data" v-show="show_ticketI"></ticket-template>
    </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { NowTime } from '@/utils'
import { getLocal } from '@/utils/localstorage.js'
import KeyBoard from '@/components/NumberKeyboard'
import GoodsMenu from '@/views/收银/GoodsMenu'
import AllKeyboard from '@/components/Keyboard.vue'
import { mapGetters } from 'vuex'
import ticketTemplate from '@/components/TicketTemplate/printinv.vue'//打印
export default {
  components: {
    GoodsMenu,
    KeyBoard,
    AllKeyboard,
    ticketTemplate
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_pos_inv_op', 'dictdata'])
  },
  data() {
    return {
      ticket_data: {}, //小票数据
      show_ticketI: false,
      is_look: false,
      is_fm_look: false,
      is_to_look: false,
      editid: null,
      库存单主表: {},
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      //时间选择器
      pickerOptions: this.getPickerOptions(),
      searchform: {
        inv_id: '',
        tstate: 0,
        bstate: 0,
        inv_billtype: 0,//不用 门店单据类型0报货单1铺货单
        inv_region: 0,//不用
        inv_date1: null,
        inv_billid: '',//门店手工单据号
        inv_whycat: null,
        inv_fm: null,
        inv_fmcat: '',
        inv_fmshop: null,//发送门店用于门店间调拨
        inv_to: null,
        inv_tocat: '',
        inv_toshop: null,//接收门店用于门店间调拨
        inv_extraid: null,//外部单位编号
        inv_round: null,//门店班次  登录班次
        inv_prod: null,//生产班次
        notes: '',//备注
      },
      rules: {
        inv_id: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        inv_date1: [{ required: true, message: '请选择确认日期', trigger: 'change' }],
        // inv_region: [{ required: true, message: '请选择要货区域', trigger: 'change' }],
        // inv_fm: [{ required: true, message: '请选择仓库', trigger: 'change' }],
      },
      //发方
      invfm: [],
      //收方
      invto: [],
      //门店生产班组
      shopprod: [],
      // 库存操作类型
      invWhycat: [],
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      nowGoodsType: null,
      all_keyboard_show: false,
      router_query: false
    }
  },

  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter入库明细',to);
    next(vm => {
      if (to.params.l_rpt == 0) {//新增 //单据ID号
        vm.router_query = to.params.k_type//操作类型
      } else {
        if (!vm.router_query) {
          vm.router_query = to.params.k_type//操作类型
        } else {
          if (to.params.k_type !== vm.router_query) {
            vm.router_query = to.params.k_type
            vm.tableData = []
          }
        }
      }
      vm.$store.commit('app/setkucunPage', to.fullPath)
      vm.initData()
    })

  },

  async created() {
    this.initData()
  },
  methods: {
    async initData() {
      await this.getGoodsData()
      await this.getTypeData()
      console.log('this.$route.params.l_rpt',this.$route.params.k_type);
      this.editid = Number(this.$route.params.l_rpt) //单据ID号 // this.$route.params {k_type: "267059179028505", l_rpt: "343089025658389"}  k_type：操作类型/l_rpt：单据ID号
      if (this.editid == 0) { // 类型为0是新增
        this.t_pos_inv_op.forEach(item => { //获得操作类型数据当前行数据
          if (item.invop_id ==this.$route.params.k_type) {
            this.searchform.inv_whycat = item.invop_id
            this.searchform.inv_fmcat = item.invop_sorc
            this.searchform.inv_tocat = item.invop_dest
          }
        })
        this.setData()
        this.searchform.inv_id = this.制作编号()
        this.is_look = false
      } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
        this.initEditData(this.editid)
      }

      this.shopprod = this.dictdata.filter(item => item.dict_type == "bus_shop_prod")//门店生产班组
      //默认
      let isdefault = this.t_region.filter(item => item.is_default == 1)//默认

      //获得操作类型数据当前行数据
      this.t_pos_inv_op.forEach(item => {
        if (item.invop_id == this.$route.params.k_type) {
          if (item.invop_sorc == 'KC') {
            this.invfm = this.t_region
            this.invto = ''
            this.is_to_look = false
            this.is_fm_look = true
            this.searchform.inv_fm = isdefault[0].dict_value
          } else if (item.invop_dest == 'KC') {
            this.is_to_look = true
            this.is_fm_look = false
            this.invfm = ''
            this.invto = this.t_region
            this.searchform.inv_to = isdefault[0].dict_value
          }
        }
      })

    },
    async initEditData(id) {
      console.log('initEditData', id)
      let 库存单 = await this.$localforage.getItem('t_pos_inv_bill')
      let 库存单明细 = await this.$localforage.getItem('t_pos_inv_bill_items')
      库存单.forEach(item => {
        if (item.id == id) {
          this.库存单主表 = item
        }
      })
      for (const key in this.searchform) {
        this.searchform[key] = this.库存单主表[key]
      }
      //状态
      if (this.searchform.tstate == 0 && this.searchform.bstate == 0) {
        this.is_look = false
      } else {
        this.is_look = true
      }

      let arr = []
      库存单明细.forEach(item => {
        if (item.pid == id) {
          let goodsItem = this.goodsData.filter(f => f.id == item.partid)
          let obj = {
            partid: goodsItem[0].id,
            part_id: goodsItem[0].part_id,
            part_name: goodsItem[0].part_name,
            part_price: goodsItem[0].part_price,
            part_unit: goodsItem[0].part_unit,
            part_specs: goodsItem[0].part_specs,
            invbi_price: item.invbi_price,
            invbi_plan: item.invbi_plan,
            bom_ver: item.bom_ver,
            part_model: goodsItem[0].part_model,
            part_usertype: goodsItem[0].part_usertype,
            partusertype: goodsItem[0].partusertype,
            notes: item.notes,
          }
          arr.push(obj)
        }
      })
      this.tableData = arr
    },
    setData() {
      this.searchform.inv_date1 = parseInt(new Date().getTime())
    },
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      let type0Goods = typeData.filter(i => !i.isdel)
      if (type0Goods.length > 0) {
        this.nowGoodsType = type0Goods[0]
      }
    },
    nowType(v) {
      if (!v) return
      this.nowGoodsType = v
    },
    addOrderList(v) {
      let ishave = this.tableData.findIndex(i => i.partid == v.id)
      if (ishave >= 0) {
        console.log(ishave);
        this.$refs.tableRef.setCurrentRow(this.tableData[ishave])
        if (this.is_look) return
        this.tableData[ishave].invbi_plan++
      } else {
        if (this.is_look) return
        let data = {
          partid: v.id,
          part_id: v.part_id,
          part_unit: v.part_unit,
          part_name: v.part_name,
          part_price: v.part_price,
          part_specs: v.part_specs,
          invbi_price: v.part_price,
          invbi_plan: 1,
          invbi_plan_cost: Number(1)*Number( v.part_price),
          bom_ver: '',
          part_model: v.part_model,
          part_usertype: v.part_usertype,
          partusertype: v.partusertype,
          notes: '',
        }
        this.tableData.push(data)
      }
    },
    delItem(item) {
      let i = this.tableData.findIndex(v => v.id == item.id)
      if (i >= 0) {
        this.tableData.splice(i, 1)
      }
    },
    onRowClick(v) {
      this.nowGoods = v
    },

    seave(bstate) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.tableData.length < 1) return this.$message.error('请选择入库的商品')

          if (this.searchform.inv_fmcat == 'KC' && !this.searchform.inv_fm) {
            return this.$message.error('请选择发方仓库')
          } else if (this.searchform.inv_tocat == 'KC' && !this.searchform.inv_to) {
            return this.$message.error('请选择收方仓库')
          }

          let  上传状态  //传输状态0-新建, 1-待上传,2-未批复, 3-已批复,4-已确认,4-完成
          if (bstate==0){
              上传状态=0
          }else if (bstate==1){
              上传状态=1
          }
            console.log('上传状态', 上传状态)
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          let seavetime = parseInt(new Date().getTime()) 
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 库存单明细id = makeid(this.tableData.length)
          let 库存单明细 = []
          let 总价值 = 0
          let 库存明细表 = await this.$localforage.getItem('t_pos_inv_bill_items') || [] //明细表
          let 库存主表 = await this.$localforage.getItem('t_pos_inv_bill') || []//主表
          let 总库存 = this.$store.state.user.t_pos_inv_bal||[] //库存
          // let 总库存 = await this.$localforage.getItem('t_pos_inv_bal') || []
          if (this.editid == 0) {//新增
            // 新增
            this.tableData.forEach((item, i) => {
              总价值 = 总价值 + (item.invbi_plan * item.invbi_price)
              let obj = {
                id: 库存单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: 主表id,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_plan,
                invbi_amnt: item.invbi_plan,
                invbi_cost_plan: (item.invbi_plan + 0) * (item.invbi_price + 0),
                invbi_cost_send: (item.invbi_plan + 0) * (item.invbi_price + 0),
                invbi_cost_amnt: (item.invbi_plan + 0) * (item.invbi_price + 0),
                invbi_type: '',
                invbi_price: item.invbi_price,
                pos_id: shopBaseMsg.terminal,
                notes:item.notes,
              }
              库存单明细.push(obj)
            })

            let 库存单据主表 = {
                    id: 主表id,
                    tenid: user.tenid,
                    lct: seavetime,
                    uct: seavetime,
                    lby: user.id,
                    uby: user.id,
                    inv_id: this.searchform.inv_id,
                    tstate: 上传状态, //1 未上传 5上传
                    bstate: bstate,
                    inv_date: this.searchform.inv_date1,
                    inv_date1: this.searchform.inv_date1,
                    inv_date2: this.searchform.inv_date1,
                    inv_whycat: this.searchform.inv_whycat,
                    inv_whyabs: "" ,
                    inv_who1: 0,
                    inv_who2: 0,
                    inv_fmcat: this.searchform.inv_fmcat,
                    inv_fm: this.searchform.inv_fm,
                    inv_fmshop:this.searchform.inv_fmshop,
                    inv_fmbill: "",
                    inv_tocat: this.searchform.inv_tocat,
                    inv_to: this.searchform.inv_to,
                    inv_toshop:this.searchform.inv_toshop,
                    inv_tobill: "",
                    inv_cost: 总价值, //总价值
                    inv_extraid: 0,
                    inv_region: this.searchform.inv_region,
                    inv_bustype: 0,
                    inv_billtype: this.searchform.inv_billtype,
                    inv_round: this.searchform.inv_round,//门店班次
                    inv_prod: this.searchform.inv_prod,//生产班次
                    inv_billid: this.searchform.inv_billid,
                    dept_id: user.dept_id,
                    pos_id: shopBaseMsg.terminal,
                    notes: this.searchform.notes,
            }
            库存主表.push(库存单据主表)
            库存明细表.push(...库存单明细)
          } else {
            // 编辑
            this.tableData.forEach((item, i) => {
              if (bstate == 1) {
                let n = 总库存.findIndex(j => j.partid == item.partid)//库存
                if (n >= 0) {
                  //判断入库-出库
                  if (this.searchform.inv_fmcat == 'KC') {
                    总库存[n].bal_amnt = 总库存[n].bal_amnt - (item.invbi_plan - 0)
                    总库存[n].bal_cost = (总库存[n].bal_amnt - 0) * (item.invbi_price - 0)
                  } else if (this.searchform.inv_tocat == 'KC') {
                    总库存[n].bal_amnt = 总库存[n].bal_amnt + (item.invbi_plan - 0)
                    总库存[n].bal_cost = (总库存[n].bal_amnt - 0) * (item.invbi_price - 0)
                  }
                  //修改vuex中的库存数
                  this.$store.commit('user/出入库修改结存数量', item);
                }else {//没有记录时增加一条
                let balamnt ,regionid
                 if (this.searchform.inv_fmcat == 'KC') { //出
                      balamnt = item.invbi_plan / -1
                      regionid=this.searchform.inv_fm
                 }else if (this.searchform.inv_tocat == 'KC') { //入
                    balamnt = item.invbi_plan
                    regionid=this.searchform.inv_to
                    }
                    let obj = {
                      id: item.partid,
                      tenid: user.tenid,
                      lct: seavetime,
                      uct: seavetime,
                      lby: user.id,
                      uby: user.id,
                      part_name: item.part_name, // 品名
                      tstate: 1,
                      dept_id: user.dept_id,
                      part_id: item.part_id, // 品号
                      partid: item.partid, // 系统品号
                      region_id: regionid, //入库区域
                      bal_date: seavetime,
                      bal_amnt: balamnt, //库存
                      bal_cost: balamnt * item.part_price, //价值
                      sales_volume: 0,//销量
                      part_price: item.part_price,
                      part_model: item.part_model,
                      part_specs: item.part_specs,
                      part_usertype: item.part_usertype,
                      partusertype: item.partusertype
                    }
                    总库存.push(obj)

                    // this.$localforage.setItem('t_pos_inv_bal', 库存信息)
                    this.$store.commit('user/获取结存表', 总库存);
                    // this.$store.commit('user/收银修改结存数量', el);
                  }
              }
              总价值 = 总价值 + (item.invbi_plan * item.invbi_price)
              let obj = {
                id: 库存单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: this.editid,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_plan,
                invbi_amnt: item.invbi_plan,
                invbi_cost_plan: (item.invbi_plan + 0) * (item.invbi_price + 0),
                invbi_cost_send: (item.invbi_plan + 0) * (item.invbi_price + 0),
                invbi_cost_amnt: (item.invbi_plan + 0) * (item.invbi_price + 0),
                invbi_type: '',
                invbi_price: item.invbi_price,
                pos_id: shopBaseMsg.terminal,
                notes:item.notes,
              }
              库存单明细.push(obj)
            })
            let 库存单据主表 = {
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              inv_round: this.searchform.inv_round,//门店班次
              inv_prod: this.searchform.inv_prod,//生产班次
              bstate: bstate,
              // inv_date: parseInt(new Date().getTime()),
              inv_date: this.searchform.inv_date1,
              inv_date1: this.searchform.inv_date1,
              inv_date2: this.searchform.inv_date1,
              inv_region: this.searchform.inv_region,
              inv_billtype: this.searchform.inv_billtype,
              inv_billid: this.searchform.inv_billid,
              pos_id: shopBaseMsg.terminal,
              tstate: 上传状态, //0 未上传 1上传
              dept_id: user.dept_id,
              inv_to: this.searchform.inv_to,
              inv_fm: this.searchform.inv_fm,
              inv_fmshop: 0,
              inv_toshop: 0,
              inv_cost: 总价值, //总价值
              inv_whycat: this.searchform.inv_whycat,
              inv_fmcat: this.searchform.inv_fmcat,
              inv_tocat: this.searchform.inv_tocat,
              notes:this.searchform.notes,
              // inv_bustype: 0,
              // inv_extraid: 0,
              // inv_fmbill: "",
              // inv_tobill: "",
              // inv_who1: 0,
              // inv_who2: 0,
              // inv_whyabs: "",
            }
            库存主表.forEach(item => {
              if (item.id == this.editid) {
                for (const key in 库存单据主表) {
                  // 更新主表中对应的数据
                  item[key] = 库存单据主表[key]
                }
              }
            })
            // 删除明细表中对应的数据
            for (let index = 库存明细表.length - 1; index >= 0; index--) {
              let item = 库存明细表[index]
              if (item.pid == this.editid) {
                let i = 库存明细表.findIndex(v => v.id == item.id)
                if (i >= 0) {
                  库存明细表.splice(i, 1)
                }
              }
            }
            库存明细表.push(...库存单明细)
          }
          // 储存
          await this.$localforage.setItem('t_pos_inv_bill_items', 库存明细表)
          await this.$localforage.setItem('t_pos_inv_bill', 库存主表)
          this.router_query = false

          // if (bstate) {
          //   this.$message.success('保存成功')
          //   setTimeout(() => {
          //     this.$router.go(-1)
          //   }, 500)
          // }

          if (bstate == 0) {
            this.$message.success('保存成功')
            if (this.editid == 0) {
              this.$route.params.l_rpt = 主表id
              this.editid = 主表id
              this.initEditData(this.editid)
            }
          } else if (bstate == 1) {
            this.$message.success('确认成功')
            this.searchform.bstate=bstate
            this.searchform.tstate=1
            this.is_look = true
            // 修改本地数据库中的库存数
            this.$localforage.setItem('t_pos_inv_bal', 总库存)
          }

          // let src = `/warehouslist/${this.$route.params.k_type}`
          // setTimeout(() => { 
          //   this.resetForm()
          //   this.tableData = []
          //   this.$router.push(src) 
          //   this.is_look = false
          //   }, 200)
        }

      })
    },

    // 键盘输入
    onKeyInput(v) {
      this.searchform.inv_billid = v
      this.all_keyboard_show = false
    },
    showkeyboard(v) {
      this.now_row_attr = v
      this.$refs.keyboard.open()
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods[this.now_row_attr] = n
    },
    goback() {
      this.router_query = false
      this.resetForm()
      this.tableData = []
      this.is_look = false
      this.$router.push(`/warehouslist/${this.$route.params.k_type}`)
    },
    resetForm() {
      this.searchform.inv_id = ''
      this.searchform.tstate = 0
      this.searchform.inv_billtype = 0
      this.searchform.inv_region = 0
      this.searchform.inv_date1 = null
      this.searchform.inv_billid = ''
      this.searchform.inv_whycat = null
      this.searchform.inv_fm = null
      this.searchform.inv_fmcat = ''
      this.searchform.inv_fmshop = null
      this.searchform.inv_to = null
      this.searchform.inv_tocat = ''
      this.searchform.inv_toshop = null
      this.searchform.inv_extraid = null
      this.searchform.inv_round = null
      this.searchform.inv_prod = null
      this.searchform.notes = ''
      this.editid = null
      this.$refs.form.resetFields()
    },
    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return this.$route.params.k_type + str + 商户号 + id
    },
    //打印
    handlePrint(val) {
      // 打印小票
      let ticketdata = {
        has_data: true,
        主表: this.searchform,
        明细arr: JSON.parse(JSON.stringify(this.tableData)),
      }
      this.ticket_data = ticketdata
      console.log('ticket_data', this.ticket_data)
      this.printLodop()
    },

    printLodop() {
      this.$refs.histprintcom.printTicket(this.ticket_data)

    },
   //合计
    getSummaries(param) {
       console.log('param', param)
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 1) {
        sums[index] = '合计:';
        return;
      }
      const values = data.map(item => Number(item[column.property]));

    console.log('values', values)

      if (!values.every(value => isNaN(value)) 
            && column.property != 'invbi_price' 
            && column.property != 'part_price'
            && column.property != 'part_specs'
            && column.property != 'part_model'
         ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        sums[index] = '';
      }
    });

    return sums;
  },
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 10px;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.goods {
  background-color: #ccc;
  border-left: 5px solid #fff;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #82c0ff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #82c0ff;
}
.keyboard {
  width: 520px;
  padding: 10px;
  background-color: #ccc;
  z-index: 99;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
</style>