<template>
  <div class="pay-box">
    <div class="pay-tit">支付金额：{{recpaydata.money}}</div>
    <!-- <div class="pay-tit" v-show="recpaydata.type ==='wx'|| (recpaydata.type ==='sb' && recpaydata.pay_type ==='400') ">微信扫码支付金额：{{recpaydata.money}}</div>
    <div class="pay-tit" v-show="recpaydata.type ==='zfb' || (recpaydata.type ==='sb' && recpaydata.pay_type ==='300') ">支付宝扫码支付金额：{{recpaydata.money}}</div> -->
    <!-- <div class="pay-tit" v-show="recpaydata.type ==='sb' && recpaydata.pay_type ==='400' ">微信扫码支付金额：{{recpaydata.money}}</div>
    <div class="pay-tit" v-show="recpaydata.type ==='sb'">微信扫码支付金额：{{recpaydata.money}}</div> -->
    <div class="search-box">
      <el-popover
        placement="right-start"
        width="340"
        trigger="manual"
        key="authcodebord"
        v-model="visible">
        <NumberBoard  @bordclose="onBorderClose" @input-num="onKeyInput" @btnclick="onBtnClick"/>
        <el-input ref="inputbox"
          slot="reference" 
          v-model="queryParams.auth_code" 
          @input="valueChange" 
          @focus="visible = true"
          @keyup.enter.native="sedPayMsg"
          size="small" />
      </el-popover>
        
        <el-alert v-show="rtnmsg" :title="rtnmsg" type="info" center :closable="false"></el-alert>
    </div>
    

    <div class="code-box"></div>
    <!-- <KeyBoard ref="keyboardz"  @input-num="onKeyInput" /> -->
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { getLocal } from '@/utils/localstorage'
import { mapGetters } from 'vuex'
import NumberBoard from '@/components/NumberBord'
import pay from '@/api/payapi.js'
export default {
  components: {
    NumberBoard,
  },
  props: {
    elepaymsg: {
      type: Object,
      required: true
    }
  },
  watch: {
    elepaymsg: {
      handler(n) {
        console.log('n', n)
        this.recpaydata = n
        this.rtnmsg=''
        this.err_code=''
        this.out_trade_no=''
      },
      deep: true
    }
  },
  data() {
    return {
      rtnmsg:'',
      err_code:'',
      out_trade_no:'',
      recpaydata: {}, //接收支付
      //支付结果返回
      rtnpaydata:{
        status:false,
        api_type:0,//1微信2支付宝
        api_trade_no:'',
      },
      // 查询参数
      queryParams: {
        auth_code: "",
      },
      wxQuery_result: null,
      zfbQuery_result: null,
      visible: false
    }
  },
    computed: {
    ...mapGetters('user', ['currUser'])
  },
  created() {
    // this.queryParams.auth_code = ''
    this.recpaydata = this.elepaymsg
  },
  methods: {
     //监听input框内的输入事件
    valueChange(e){
        this.err_code='';
    },

    showkeyboard() {
      this.$refs.keyboardz.open()
    },
    onKeyInput(num){
      // this.queryParams.auth_code = num
      this.visible = false
      this.sedPayMsg()
    },
    onBtnClick(num){
      this.queryParams.auth_code = num

    },
    onpageClose(){
      this.visible = false
    },
    onBorderClose(){
      this.visible = false
      this.$emit('payclose')
    },
    // 发起支付请求
//     err_code: "USERPAYING"
// err_code_des: "需要用户输入支付密码"

    async sedPayMsg() {
       console.log('sedPayMsg',this.recpaydata);
      if (this.recpaydata.type == 'wx'&& this.recpaydata.model == 1) {//微信销售支付
        if ( this.err_code!="USERPAYING"){
        let { res, out_trade_no } = await this.micropay()
        console.log('微信销售支付', res) 
        this.out_trade_no=out_trade_no
        this.rtnmsg=res[1].data.err_code_des||res[1].data.return_msg
        this.err_code=res[1].data.err_code||res[1].data.return_code
        // 交易成功判断条件：return_code和result_code都为SUCCESS且trade_type为MICROPAY
        if (res[1].statusCode == 200) {
          if ( res[1].data.return_code === "SUCCESS"
            && res[1].data.result_code === "SUCCESS"
            && res[1].data.trade_type === "MICROPAY") {
              //支付结果返回
            this.rtnpaydata={
              status:false,
              api_type:1,//1微信2支付宝
              api_trade_no:this.out_trade_no,
            },
            this.$emit('paydown', this.rtnpaydata)
             console.log('res', res)
          } 
              
        } else {
              //支付结果返回
            this.rtnpaydata={
              status:false,
              api_type:1,//1微信2支付宝
              api_trade_no:this.out_trade_no,
            },
          this.$emit('paydown', this.rtnpaydata)
        }
        }else{
           this.queryOrderQuery( this.out_trade_no)
           console.log('queryOrderQuery', this.out_trade_no)
        }
      }else if (this.recpaydata.type == 'wx'&& this.recpaydata.model == -1) {//微信销售支付退款
        if(!this.queryParams.auth_code){return this.$message.error("微信退款不存在");}
           if(this.queryParams.auth_code!=this.recpaydata.out_trade_no){return this.$message.error("退款码不对");}
        let { res, out_trade_no } = await this.WxrefundResult(this.queryParams.auth_code)
           this.out_trade_no=out_trade_no
          if (res[1].statusCode == 200) {
            this.rtnmsg=res[1].data.err_code_des
            this.err_code=res[1].data.err_code
              if ( res[1].data.return_code === "SUCCESS"
                && res[1].data.result_code === "SUCCESS"
                && res[1].data.return_msg === "OK") {
                  //支付结果返回
                this.rtnpaydata={
                  status:true,
                  api_type:1,//1微信2支付宝
                  api_trade_no:this.out_trade_no,
                },
                this.$emit('paydown', this.rtnpaydata)
                console.log('res', res)
              } else {
                  //支付结果返回
                this.rtnpaydata={
                  status:false,
                  api_type:1,//1微信2支付宝
                  api_trade_no:this.out_trade_no,
                },
              this.$emit('paydown', this.rtnpaydata)
            }
          }  
      }
      if (this.recpaydata.type == 'zfb'&& this.recpaydata.model == 1) {//支付宝销售支付
        if ( this.err_code!="WAIT_BUYER_PAY"){
        let {res,out_trade_no} = await this.Authpay()
         console.log('alipay_trade_pay_response',res);
         this.out_trade_no=out_trade_no
         this.rtnmsg=res[1].data.alipay_trade_pay_response.msg
         this.err_code=res[1].data.alipay_trade_pay_response.code
        if (res[1].statusCode == 200) {
          if (res[1].data.alipay_trade_pay_response.code == '10000' 
           && res[1].data.alipay_trade_pay_response.trade_status == "TRADE_SUCCESS "
                  ) {
              //支付结果返回
            this.rtnpaydata={
              status:true,
              api_type:2,//1微信2支付宝
              api_trade_no:this.out_trade_no,
            },
            this.$emit('paydown', this.rtnpaydata)
             } else{
             this.queryZFBOrderQuery( this.out_trade_no)
             console.log('queryZFBOrderQuery', this.out_trade_no)
             }
          }else{ 
                //支付结果返回
                this.rtnpaydata={
                  status:false,
                  api_type:2,//1微信2支付宝
                  api_trade_no:this.out_trade_no,
                },
              this.$emit('paydown', this.rtnpaydata)
          }
          }else{//支付不成功，需要调用查询接口
             this.queryZFBOrderQuery( this.out_trade_no)
             console.log('queryZFBOrderQuery', this.out_trade_no)
           }
        } else if (this.recpaydata.type == 'zfb'&& this.recpaydata.model == -1) { //退货是否加入退款查询
          if(!this.queryParams.auth_code){return this.$message.error("支付宝退款不存在");}
           if(this.queryParams.auth_code!=this.recpaydata.out_trade_no){return this.$message.error("退款码不对");}
            let { res, out_trade_no } = await this.ZfbrefundResult(this.queryParams.auth_code)
                this.out_trade_no=out_trade_no
                if (res[1].statusCode == 200) {
                  this.rtnmsg=res[1].data.alipay_trade_refund_response.msg
                  this.err_code=res[1].data.alipay_trade_refund_response.code
                if ( res[1].data.alipay_trade_refund_response.code === "10000" 
                  // && res[1].data.alipay_trade_refund_response.trade_status == "TRADE_SUCCESS "
                  ) {
                    //支付结果返回
                  this.rtnpaydata={
                    status:true,
                    api_type:2,//1微信2支付宝
                    api_trade_no:this.out_trade_no,
                  },
                  this.$emit('paydown', this.rtnpaydata)
                  console.log('ZfbrefundResultres', res)
                } else {
                  this.rtnmsg=res[1].data.alipay_trade_refund_response.msg
                  this.err_code=res[1].data.alipay_trade_refund_response.code
                    //支付结果返回
                    this.rtnpaydata={
                    status:false,
                    api_type:2,//1微信2支付宝
                    api_trade_no:this.out_trade_no,
                  },
                this.$emit('paydown', this.rtnpaydata)
              }
            }  
        }


    if (this.recpaydata.type == 'sb'&& this.recpaydata.model == 1) {//收呗销售支付
        if ( this.err_code!="PAY_IN_PROCESS"){//支付中
        let { res, out_trade_no,qd_trade_no } = await this.shoubAuthpay()
        console.log('sbauthpay', res,out_trade_no)
        this.out_trade_no=out_trade_no
        this.rtnmsg=res[1].data.return_msg
        this.err_code=res[1].data.result_code
        // 交易成功判断条件
        if (res[1].statusCode == 200) {
          if ( res[1].data.return_code === "SUCCESS"
            && res[1].data.result_code === "PAY_SUCCESS"
             ) {
              //支付结果返回
            this.rtnpaydata={
              status:true,
              api_type:1,//1微信2支付宝
              api_trade_no:this.out_trade_no +","+ qd_trade_no,
            },
            this.$emit('paydown', this.rtnpaydata)
             console.log('res', res)
          } 
              
        } else {
              //支付结果返回
            this.rtnpaydata={
              status:false,
              api_type:1,//1微信2支付宝
              api_trade_no:this.out_trade_no +","+ qd_trade_no,
            },
            console.log('支付结果返回', res,this.rtnpaydata)
          this.$emit('paydown', this.rtnpaydata)
        }
        }else{
           this.sbqueryOrderQuery( this.out_trade_no)
           console.log('sbqueryOrderQuery', this.out_trade_no)
        }
      }else if (this.recpaydata.type == 'sb'&& this.recpaydata.model == -1) {//收呗销售支付退款
        let ss = this.recpaydata.out_trade_no.split(",");
        console.log('收呗销售支付退款',this.recpaydata.out_trade_no,ss)
        if(!this.queryParams.auth_code){return this.$message.error("退款不存在");}
        if(this.queryParams.auth_code!=ss[1]){return this.$message.error("退款码不对");}
        let { res, out_trade_no } = await this.sbrefundorderWXResult(ss[0]) //trade_no	收呗订单号
        //需要判断退款码是否和系统中一致

        console.log('sbrefundorderWXResult', res,out_trade_no)
           this.out_trade_no=out_trade_no
          if (res[1].statusCode == 200) {
              this.rtnmsg=res[1].data.return_msg
              this.err_code=res[1].data.result_code
              if ( res[1].data.return_code === "SUCCESS"
                && res[1].data.result_code === "PAY_SUCCESS"
                ) {

                  //支付结果返回
                this.rtnpaydata={
                  status:true,
                  api_type:1,//1微信2支付宝
                  api_trade_no:this.out_trade_no,
                },
                this.$emit('paydown', this.rtnpaydata)
                console.log('res', res)
              } else {
                  //支付结果返回
                this.rtnpaydata={
                  status:false,
                  api_type:1,//1微信2支付宝
                  api_trade_no:this.out_trade_no,
                },
              this.$emit('paydown', this.rtnpaydata)
            }
          }  
      }



    },
//支付宝交易查询
     async queryZFBOrderQuery(out_trade_no) {
              let msg = await this.queryZFBResult(out_trade_no)
              console.log('queryZFBOrderQuery',msg);
              if (msg[0]) {
                  //支付结果返回
                  this.rtnpaydata={
                    status:false,
                    api_type:1,//1微信2支付宝
                    api_trade_no:this.out_trade_no,
                  },
                this.$emit('paydown', this.rtnpaydata)
                return
              }
              let msginfo = msg[1].data.alipay_trade_query_response
               this.rtnmsg=this.zfb_trade_status(msginfo.trade_status)||msginfo.sub_msg//支付宝状态转换
               this.err_code=msginfo.trade_status    
              if (msginfo.code == '10000' && msginfo.trade_status == "TRADE_SUCCESS") {
                //支付结果返回
                      this.rtnpaydata={
                        status:true,
                        api_type:2,//1微信2支付宝
                        api_trade_no:this.out_trade_no,
                      },
                  this.$emit('paydown', this.rtnpaydata)
              }
     },

      // 微信查询 交易成功判断条件： return_code、result_code和trade_state都为SUCCESS
       async queryOrderQuery(out_trade_no) {
              let msg = await this.queryWXResult(out_trade_no)
               console.log('queryWXResult', msg)
              // if (msg[0]) {
              //      //支付结果返回
              //     this.rtnpaydata={
              //       status:false,
              //       api_type:1,//1微信2支付宝
              //       api_trade_no:this.out_trade_no,
              //     },
              //   this.$emit('paydown', this.rtnpaydata)
              //   return
              // }
            
              if ( msg[1].data.return_code === 'SUCCESS'
                && msg[1].data.result_code === 'SUCCESS'
                && msg[1].data.trade_state === 'SUCCESS') { //支付成功
                     //支付结果返回
                      this.rtnpaydata={
                        status:true,
                        api_type:1,//1微信2支付宝
                        api_trade_no:this.out_trade_no,
                      },
                this.$emit('paydown', this.rtnpaydata)
                return
              }else   //撤销订单
              if ( msg[1].data.return_code === 'SUCCESS'
                && msg[1].data.result_code === 'SUCCESS'
                && msg[1].data.trade_state === 'PAYERROR') {
                  this.rtnmsg=msg[1].data.trade_state_desc
                  this.err_code=msg[1].data.result_code 
                     //支付结果返回
                      this.rtnpaydata={
                        status:false,
                        api_type:1,//1微信2支付宝
                        api_trade_no:this.out_trade_no,
                      },
                this.$emit('paydown', this.rtnpaydata)
                return
              }else{
                this.rtnmsg=msg[1].data.return_msg
                this.err_code=msg[1].data.result_code 
                  //支付结果返回
                  this.rtnpaydata={
                    status:false,
                    api_type:1,//1微信2支付宝
                    api_trade_no:this.out_trade_no,
                  },
                this.$emit('paydown', this.rtnpaydata)
                return
              }
             
          },

          
      // 收呗查询
       async sbqueryOrderQuery(out_trade_no) {
              let msg = await this.sbqueryWXResult(out_trade_no)
               console.log('msg', msg)
              if (msg[0]) {
                   //支付结果返回
                  this.rtnpaydata={
                    status:false,
                    api_type:1,//1微信2支付宝
                    api_trade_no:this.out_trade_no,
                  },
                   console.log('msg[0]', this.rtnpaydata)
                this.$emit('paydown', this.rtnpaydata)
                return
              }
              if ( msg[1].data.return_code === 'SUCCESS'
                && msg[1].data.result_code === 'PAY_SUCCESS'
                && msg[1].data.trade_state === 'SUCCESS') {
                     //支付结果返回
                      this.rtnpaydata={
                        status:true,
                        api_type:1,//1微信2支付宝
                        api_trade_no:this.out_trade_no +','+ msg[1].data.qd_trade_no,
                      },
                    console.log('msg[1]', this.rtnpaydata)
                this.$emit('paydown', this.rtnpaydata)
                return
              }
             
          },

    // 支付宝支付
    async Authpay() {
      let Authpayjson = {
        out_trade_no: this.编号(),
        scene: 'bar_code',
        auth_code: this.queryParams.auth_code,
        product_code: 'FACE_TO_FACE_PAYMENT',//产品码，默认 FACE_TO_FACE_PAYMENT（当面付）
        subject: '测试商品2',//商品标题/交易标题/订单标题/订单关键字等
        buyer_id: '',//买家的支付宝用户 ID若为空，则从传入的码值信息中获取用户 ID新当面资金授权场景必填
        seller_id: '',//如果该值为空，则默认为商户签约账号对应的支付宝用户ID。新当面资金授权场景必填。
        total_amount: this.recpaydata.money, //订单总金额
        trans_currency: 'CNY',//标价币种
        settle_currency: 'CNY',//商户指定的结算币种
        discountable_amount: this.recpaydata.money, //参与优惠计算的金额
        body: this.currUser.name,//订单描述
        goods_detail: '',//订单订单包含的商品列表信息，json格式。GoodsDetail[]
        operator_id: this.currUser.user,//商户操作员编号
        store_id: this.currUser.dept_id,//	商户门店编号-指商户创建门店时输入的门店编号
        terminal_id: getLocal('shopBaseMsg').terminal,//	商户机具终端编号
        timeout_express: '10m', //该笔订单允许的最晚付款时间，逾期将关闭交易。
        // extend_params: { "sys_service_provider_id": "2088511833207846" },//业务扩展参数对象{"sys_service_provider_id":"2088511833207846"}
      }
      // let goods_detail = [{
      //   goods_id: 'apple-01',
      //   goods_name: 'ipad',
      //   quantity: 1,
      //   price: '0.01',
      // }]

  let goodsdetail=[]
    this.recpaydata.goodslist.forEach((item,i)=>{ //数组循环
          let obj={
                      goods_id:item.part_id,
                      goods_name:item.part_name,
                      quantity:item.count,
                      price:Number(item.实际价格/item.count).toFixed(2),
                      }
        goodsdetail.push(obj)
    })

  let goods_detail =goodsdetail
      // let extend_params = {
      //   sys_service_provider_id: '2088511833207846',
      // }

      Authpayjson.goods_detail = goods_detail
      // Authpayjson.extend_params = extend_params
      console.log('Authpayjson', Authpayjson)
      let res = await pay.Authpay(Authpayjson)
      return { res, out_trade_no: Authpayjson.out_trade_no }
    },
    // 查询支付宝支付结果
    async queryZFBResult(info) {

      let queryjson = {
        out_trade_no: info,
        trade_no: ''

      }
      let res = await pay.query(queryjson)
      return res
    },
// 支付宝退款
  async ZfbrefundResult(auth_code) {
        let json={
                out_trade_no: auth_code,
                operator_id: this.currUser.user,
                refund_amount:Math.abs(this.recpaydata.money),
                refund_reason: '退款'
            //   refund_reason: request.payload.refund_reason,
            //   out_request_no: request.payload.out_request_no,
            //   store_id: request.payload.store_id,
            //   terminal_id: request.payload.terminal_id
              
        }
       console.log('refund',json)
       let res= await pay.refund(json)
        console.log('refundres',res)
      return  { res, out_trade_no: json.out_trade_no }
      //  console.log('res',res)
    },

    // 微信支付
    async micropay() {
      let json = {
        version: '1.0',
        out_trade_no: this.编号(),
        auth_code: this.queryParams.auth_code,
        total_fee: this.recpaydata.money * 100, //订单总金额
        // spbill_create_ip: '192.168.0.1',//终端IP
        fee_type: 'CNY',//商户指定的结算币种
        sub_mch_id: '', //微信支付分配的子商户号，开发者模式下必填
        body:  this.currUser.name,//订单描述
        detail: '',//订单订单包含的商品列表信息
        device_info: this.currUser.user,//商户操作员编号
        scene_info: '',//	场景信息
      
      }
     //样例
    // let detail ={"cost_price":1,"receipt_id":"wx123","goods_detail":[{"goods_id":"商品编码","wxpay_goods_id":"1001","goods_name":"iPhone6s 16G","quantity":1,"price":1},{"goods_id":"商品编码","wxpay_goods_id":"1002","goods_name":"iPhone6s 32G","quantity":1,"price":1}]}
    // json.detail=JSON.stringify(detail);
    let goodsdetail=[]
    this.recpaydata.goodslist.forEach((item,i)=>{ //数组循环
          let obj={
                      goods_id:item.part_id,
                      wxpay_goods_id:item.part_id,
                      goods_name:item.part_name,
                      quantity:item.count,
                      price:Number(item.实际价格/item.count).toFixed(2) * 100,
                      }
        goodsdetail.push(obj)
    })
    let goods_detail=goodsdetail
    json.detail=JSON.stringify(goods_detail);
  
     console.log('micropay_json', json)
      let res = await pay.micropay(json)
      return { res, out_trade_no: json.out_trade_no }
    },

   // 微信支付退款 
    async WxrefundResult(auth_code) {
      let json={
            // transaction_id: '4200001011202105302804549959',//微信订单号
            out_trade_no:  auth_code, //info.out_trade_no,//商户单号 退款
            out_refund_no: this.编号(),//商户退款单号
            total_fee: Math.abs(this.recpaydata.money)* 100,//订单金额
            refund_fee: Math.abs(this.recpaydata.money)* 100,//申请退款金额
           }
          console.log('WxrefundResult', json)
          let res = await pay.wxrefund(json)
          return  { res, out_trade_no: json.out_trade_no }
        },

    // 查询微信支付结果
    async queryWXResult(info) {
      let json = {
        transaction_id: '',//微信订单号
        out_trade_no: info,//商户订单号
      }
      let res = await pay.orderquery(json)
      return res
    },
  //收呗支付
  async shoubAuthpay() {
    // let paytype
    // if (this.recpaydata.pay_type=='13'){paytype='400'}else if (this.recpaydata.pay_type=='14'){paytype='300'} else{paytype='000'}
     let Authpayjson = {
        pay_trace_no: this.编号(),//请求流水号（必须在商户系统内唯一；且长度不超过64字节）
        pay_type: this.recpaydata.pay_type,
        auth_code: this.queryParams.auth_code,
        total_amount: Math.abs(this.recpaydata.money)* 100, //订单总金额
        order_body: this.currUser.name,//订单描述
        goods_detail: '',//订单订单包含的商品列表信息，json格式。GoodsDetail[]
      }
    let goodsdetail=[]
    this.recpaydata.goodslist.forEach((item,i)=>{ //数组循环
          let obj={
                      goods_id:item.part_id,
                      wxpay_goods_id:item.part_id,
                      goods_name:item.part_name,
                      goods_quantity:item.count,
                      goods_price:Number(item.实际价格/item.count).toFixed(2) * 100,
                      }
        goodsdetail.push(obj)
    })
    let goods_detail=goodsdetail
    Authpayjson.goods_detail=JSON.stringify(goods_detail);

      
      console.log('Authpayjson', Authpayjson)
      let res = await pay.sbauthpay(Authpayjson)
       console.log('sbauthpay', res,res[1].data.trade_no)
        return { res, out_trade_no: res[1].data.trade_no , qd_trade_no: res[1].data.qd_trade_no } // 返回扫码号
  },
   //收呗查询
  async sbqueryWXResult(info) { 
      let json = {
        query_trace_no:this.编号(),//查询请求流水号
        trade_no: info,//支付订单号（微信/支付宝/收呗订单号）
        pay_trace_no: '',//支付请求流水号（支付订单号和支付请求流水号二选其一必传）
      }
      let res = await pay.sbqueryorder(json)
      return res
  },
 //收呗退款
  async sbrefundorderWXResult(info) { 
      let json = {
        refund_trace_no:this.编号(),//查询请求流水号
        refund_fee: Math.abs(this.recpaydata.money)* 100,//退款金额
        trade_no: info,//支付请求流水号（支付订单号和支付请求流水号二选其一必传）
      }
      let res = await pay.sbrefundorder(json)
       return  { res, out_trade_no: res[1].data.refund_no }
  },
            
    // stopQuery() {
    //   if (this.wxQuery_result) {
    //     clearInterval(this.wxQuery_result)
    //     this.wxQuery_result = null
    //   }

    //   if (this.zfbQuery_result) {
    //     clearInterval(this.zfbQuery_result)
    //     this.zfbQuery_result = null
    //   }
    // },
    openPay(){
        this.queryParams.auth_code = ''
        this.$refs.inputbox.focus()
    },
    //支付宝状态转换
    zfb_trade_status(trade_status){
           switch (trade_status) {
              case 'WAIT_BUYER_PAY':
                return '支付宝有交易记录，没付款'
              case 'TRADE_FINISHED':
                return '交易完成'
              case 'TRADE_SUCCESS':
                 return '交易完成'
              case 'TRADE_CLOSED':
                return '交易关闭'
              
            }
      },

   编号() {
      let shopmsg = getLocal('shopBaseMsg')
      // let nowtime=this.generateTimeReqestNumber()//生成yyyymmddhhmmss格式时间戳
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return str + 商户号 + id
    },

  }
}
</script>

<style lang="scss" scoped>
.pay-box {
  width: 100%;
  height: 100%;
  .pay-tit {
    text-align: center;
    font-size: 16px;
  }
  .code-box {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    background-color: pink;
    margin-bottom: 10px;
  }
}
</style>