<template>
  <div class="keybox">
    <div class="keyleft">
      <el-tabs boder id="order-list" style="margin-left: 10px" type="card">
        <el-tab-pane label="业务">
          <el-row>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="dazhe(1)">单品折扣</div>
            </el-col>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="dazhe(2)">单品折让</div>
            </el-col>
              <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="dazhe(3)">全单折扣</div>
            </el-col>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="dazhe(4)">全单折让</div>
            </el-col>
          
          </el-row>
          <el-row>
              <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="keepList(1)">挂单</div>
            </el-col>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="keepList(2)">取单</div>
            </el-col>

            <el-col :span="6">
              <div :class="model<0?'btn-same':'btn-same keyclick'" @click="chooseModel(1)">销售</div>
            </el-col>
            <el-col :span="6">
              <div :class="model>0?'btn-same':'btn-same keyclick'" @click="chooseModel(-1)">退货</div>
            </el-col>
          
          </el-row>
      <el-row>
        <el-col :span="6">
              <div class="btn-same qingkong" @mousedown="ondown" @mouseup="onup" @click="qingkong">清空</div>
            </el-col>
    </el-row>

        </el-tab-pane>
         <!-- <el-tab-pane label="会员">
          <el-row>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="cardtop" >充值</div>
            </el-col>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="carddh">兑换</div>
            </el-col>
          </el-row>
        </el-tab-pane> -->

        <!-- <el-tab-pane label="辅助">
          <el-row>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" >打印</div>
            </el-col>
            <el-col :span="6">
              <div class="btn-same" @mousedown="ondown" @mouseup="onup" >补打</div>
            </el-col>
          </el-row>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="keyright">
      <el-row>
        <el-col :span="24" style="display:flex;">
          <span style="width:40px;line-height:30px;">输入:</span>
          <el-input size="small" style="margin-left:5px;" v-model="moneyInput" @input="overInput"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="goodsNum">数量</div>
        </el-col>
        <el-col :span="8">
         &nbsp;
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="btnback">退格</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('7')">7</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('8')">8</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('9')">9</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('4')">4</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('5')">5</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('6')">6</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('1')">1</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('2')">2</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('3')">3</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('0')">0</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('.')">.</div>
        </el-col>
        <el-col :span="8">
          <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="jiesuan">结算</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters('user',['model'])
  },
  data() {
    return {
      moneyInput: ''
    }
  },
  methods: {
    enterNamber(num) {
      if (num == '.') {
        if (this.moneyInput.length == 0) {
          this.moneyInput = '0.'
        } else {
          let i = this.moneyInput.indexOf('.')
          if (i == -1) this.moneyInput += '.'
        }
      } else {
        this.moneyInput += num
      }
      let number = this.moneyInput
      if (number.indexOf('.') != -1) {
        number = number.substring(0, number.indexOf('.') + 3)
      }
      this.moneyInput = number
    },
    btnback() {
      if (this.moneyInput.length == 0) return
      this.moneyInput = this.moneyInput.substr(0, this.moneyInput.length - 1)
    },
    overInput() { },
    ondown(e) {
      e.target.classList.add('keyclick')
    },
    onup(e) {
      e.target.classList.remove('keyclick')
    },
    // 清空
    qingkong() {
      this.$emit('qingkong')
    },
    // 折扣,折让
    dazhe(type) {
      this.$emit('dazhe', { type, num: Number(this.moneyInput) })
      this.moneyInput = ''
    },
    // 商品数量
    goodsNum(){
      this.$emit('goodsNum', parseInt(this.moneyInput))
      this.moneyInput = ''
    },
    // 挂单
    keepList(v){
      this.$emit('keepList',v)
    },
    // 结算
    jiesuan(){
      this.$emit('jiesuan')
    },
    // 选择模式
    chooseModel(v){
      this.$store.commit('user/changeModel', v)
      this.$emit('changeModel')
    },
  // 会员充值
    cardtop(){
      this.$emit('cardtop')
    },
     // 会员兑换
    carddh(){
      this.$emit('carddh')
    },
  }
}
</script>

<style scoped>
.keybox {
  display: flex;
  border-top: 1px solid #ccc;
}
.keyleft {
  padding-right: 5px;
  border-right: 1px solid #ccc;
  flex: 3;
}
.keyright {
  flex: 2;
  padding-top: 5px;
}
.el-row {
  margin-bottom: 10px;
}
.el-col {
  padding: 0 5px;
}
.keyclick {
  border: 1px solid #d9ecff;
  color: #208bf5;
  background-color: #aac9e7;
  font-weight: 600;
}
.bus-cons-job-box {
  overflow: hidden;
  height: 200px;
  overflow-y: scroll;
}
.btn-same {
  height: 55px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 55px;
}
.qingkong {
  background-color: #f56c6c;
  color: #fff;
  font-weight: 600;
}
</style>