<template>
  <div style="padding:10px 15px;width:100%;" >
    <div class="btn">
      <el-button size="mini" type="success" :disabled="is_look" @click="seave(0)">保 存</el-button>
      <el-button size="mini" type="primary" :disabled="is_look" @click="seave(1)">确 认</el-button>
      <!-- <el-button size="mini" type="primary">打 印</el-button> -->
      <el-button size="mini" type="primary">Excel</el-button>
      <el-button size="mini" type="primary"  @click="Clearing()">负数清零</el-button>
      <el-button size="mini" type="primary" @click="Refresh()">刷新</el-button>
      <el-button size="mini" type="danger" :disabled="is_look" > 删 除</el-button>
      <el-button size="mini" @click="goback">返 回</el-button>
       <!-- <el-button size="mini" type="primary" @click="nopage">不分页</el-button> -->
    </div>
    <div class="content">
      <el-row>
        <el-col :span="15">
          <div class="form">
            <el-form ref="form" :model="searchform" :rules="rules" label-width="80px" inline size="mini" >
              <el-row>
                <el-col :span="6">
                  <el-form-item label="单据编号" prop="chk_id" >
                    <el-input v-model="searchform.chk_id" readonly style="width:220px;" :disabled="is_look"></el-input>
                  </el-form-item>
                </el-col>
               
                <el-col :span="6">
                  <el-form-item label="盘点对象" prop="region_id" > 
                    <el-select v-model="searchform.region_id" style="width:170px;" :disabled="is_look">
                      <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
               
                <el-col :span="6">
                  <el-form-item label="盘点方式" prop="invchk_manner" >
                    <el-select v-model="searchform.invchk_manner" style="width:180px;" :disabled="is_look">
                      <el-option label="循环盘点" :value="0"></el-option>
                      <el-option label="冻结盘点" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
            
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="开始时间" prop="invchk_bgn_date" >
                    <el-date-picker v-model="searchform.invchk_bgn_date"  :disabled="is_look" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:190px;"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
               
               <el-col :span="6">
                  <el-form-item label="结束时间" prop="invchk_end_date" >
                    <el-date-picker v-model="searchform.invchk_end_date" :disabled="is_look" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:190px;"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                     
                <el-col :span="12">
                  <el-form-item label="备注" prop="notes" >
                    <el-input v-model="searchform.notes" style="width:300px;" :disabled="is_look"></el-input>
                  </el-form-item>
                </el-col>
                 <el-col :span="6">
                  <el-form-item label="上传" prop="tstate">
                    <el-select v-model="searchform.tstate"  style="width:180px;"  :disabled="true">
                      <el-option label="新建" :value="0"></el-option>
                      <el-option label="待上传" :value="1"></el-option>
                      <el-option label="已上传" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                  <el-col :span="6">
                  <el-form-item label="状态" prop="bstate">
                    <el-select v-model="searchform.bstate"  style="width:180px;" :disabled="true">
                      <el-option label="未确定" :value="0"></el-option>
                      <el-option label="已确定" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-table ref="tableRef" :data="tableData" style="width: 100%"  border max-height="700" highlight-current-row @row-click="onRowClick">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="part_id" label="品号">
            </el-table-column>
            <el-table-column prop="part_name" label="品名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="part_unit" label="单位" width="50">
            </el-table-column>

            <el-table-column prop="part_specs" label="规格" width="70">
            </el-table-column>
            <el-table-column prop="part_specs" label="类型" width="70">
            </el-table-column>

            <el-table-column prop="part_price1" label="单价" width="70">
            </el-table-column>
            <el-table-column prop="invchk_acc_qty" label="账存数量" width="80">
            </el-table-column>
            <el-table-column prop="invchk_qty_ch" label="盈亏数量" width="80">
            </el-table-column>
           
            <el-table-column label="实际数量" width="150">
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-input  v-model="scope.row.invchk_act_qty" type="number" @focus="showkeyboard('invchk_act_qty')" @input="inputchange(scope.row)" size="mini" controls-position="right"></el-input>
                </div>
                <div v-else>{{scope.row.invchk_act_qty}}</div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="操作" width="80">
              <template scope="scope">
                <el-button :disabled="is_look" size="mini" type="danger" @click="delItem(scope.row)">删除</el-button>
              </template>
            </el-table-column> -->
          </el-table>
             <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" 
              :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total" background
                hide-on-single-page>
              </el-pagination>
        </el-col>
      
        <el-col :span="9" class="goods">
          <goods-menu @chooseGoods="addOrderList" @chooseType="nowType" />
        </el-col>
      </el-row>
    </div>
    <div class="keybord_box">
      <key-board right="40%" ref="keyboard" @input-num="inputNum"></key-board>
    </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { NowTime } from '@/utils'
import { getLocal } from '@/utils/localstorage.js'
import KeyBoard from '@/components/NumberKeyboard'
import GoodsMenu from '@/views/收银/GoodsMenu'
import AllKeyboard from '@/components/Keyboard.vue'
import { mapGetters } from 'vuex'
import { parseTime, pagePagination } from '@/utils/index.js'
export default {
  components: {
    GoodsMenu,
    KeyBoard,
    AllKeyboard
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_pos_inv_op', 'dictdata'])
  },
  data() {
    return {
       page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28
      },
      is_look: false,
      editid: null,
      库存单主表: {},
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      //时间选择器
      pickerOptions: this.getPickerOptions(),
      searchform: {
        chk_id: '',
        tstate: 0,
        bstate: 0,
        region_id: 0,//盘点区域
        invchk_manner: 0,//盘点方式
        invchk_bgn_date: parseInt(new Date().getTime()),
        invchk_end_date: parseInt(new Date().getTime()),
        notes: '',//备注
      },
      rules: {
        chk_id: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        region_id: [{ required: true, message: '请选择盘点对象', trigger: 'change' }],
        invchk_manner: [{ required: true, message: '请选择盘点方式', trigger: 'change' }],
        invchk_bgn_date: [{ required: true, message: '请选择盘点开始时间', trigger: 'change' }],
        invchk_end_date: [{ required: true, message: '请选择盘点结束时间', trigger: 'change' }],
      },
    
      tables: [],
      tableData: [],
      all_table_data: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      inv_bal: [],
      nowGoodsType: null,
      all_keyboard_show: false

    }
  },

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      let isadd = to.params.rptid
      if (isadd) {
        vm.initData(isadd)
      }else{
        vm.tableData = []
      }
      vm.$store.commit('app/setlingshouPage', to.fullPath)
       vm.initData()
    })
  },

  async created() {
    await this.initData()
  },

  methods: {
    async initData(){
      
      await this.getStock()
      await this.getGoodsData()
      await this.getTypeData()
      this.editid = Number(this.$route.params.rptid) //单据ID号 
        if (this.editid == 0) { // 类型为0是新增
        //默认
          let isdefault = this.t_region.filter(item => item.is_default == 1)//默认
          console.log('this.t_region', this.t_region,isdefault[0].dict_value)
          this.searchform.region_id= isdefault[0].dict_value
          this.setData()
          this.searchform.chk_id = this.制作编号()
          this.is_look = false
      } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
        this.initEditData(this.editid)
      }
    },
//刷新
  Refresh(){
    if (this.editid == 0) { // 类型为0是新增
    // if (this.$route.query.type === '1') { // 类型为1是新增
       //默认
        let isdefault = this.t_region.filter(item => item.is_default == 1)//默认
        console.log('this.t_region1111', this.t_region,isdefault[0].dict_value)
        this.searchform.region_id= isdefault[0].dict_value
        this.setData()
        this.searchform.chk_id = this.制作编号()
        this.is_look = false
    } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
      // this.editid = this.$route.params.rptid
      //  console.log('this.$route.params', this.$route.params)
      this.initEditData(this.editid)
    }
    },
    async initEditData(id) { //修改
      let 盘点单     = await this.$localforage.getItem('t_pos_inv_chk')
      let 盘点单明细 = await this.$localforage.getItem('t_pos_inv_chk_items')
      盘点单.forEach(item => {
        if (item.id == id) {
          this.库存单主表 = item
        }
      })
      for (const key in this.searchform) {
        this.searchform[key] = this.库存单主表[key]
      }
       if (this.searchform.bstate == 0) {
        this.is_look = false
      } else {
        this.is_look = true
      }
  console.log('this.is_look', this.is_look)
      let arr = []
      盘点单明细.forEach(item => {
        if (item.pid == id) {
          let goodsItem = this.goodsData.filter(f => f.id == item.partid)
          let obj = {
            partid: goodsItem[0].id,
            part_id: goodsItem[0].part_id,
            part_name: goodsItem[0].part_name,
            part_specs: goodsItem[0].part_specs,
            part_model: goodsItem[0].part_model,
            invchk_price: item.part_price,
            invchk_unit: item.part_unit,
            pid: item.pid,
            invchk_acc_qty: item.invchk_acc_qty,
            invchk_act_qty: item.invchk_act_qty,
            invchk_qty_ch: item.invchk_qty_ch,  
            part_usertype: goodsItem[0].part_usertype,
            partusertype: goodsItem[0].partusertype,
            notes: item.notes,
          }
          arr.push(obj)
        }
      })

      console.log('initEditData',arr)
      this.all_table_data = arr 
      this.page_data.total = arr.length 
      this.makeData()
    },
    //初始化-新增
    setData() {
      let shopBaseMsg = getLocal('shopBaseMsg')
      // this.searchform.inv_needdate = NowTime()
       let arr = []
       let balamnt
      this.goodsData.forEach(item => {
         let balItem = this.inv_bal.filter(f => f.partid == item.id)
    
          if (balItem.length>0){
              balamnt=balItem[0].bal_amnt
              }
              else{
              balamnt=0
              }

          let obj = {
            partid: item.id,
            part_id: item.part_id,
            part_name: item.part_name,
            part_specs: item.part_specs,
            part_model: item.part_model,
            invchk_price: item.part_price,
            invchk_unit: item.part_unit,
            region_id: item.r_store,
            invchk_acc_qty: balamnt,//结存数量
            invchk_act_qty: balamnt,//结存数量
            invchk_qty_ch:  0,
            pos_id: shopBaseMsg.terminal,
            notes: '',
          }
          arr.push(obj)
      })   
      // arr = this.goodsData
      // this.tableData =this.goodsData 
      console.log('setData',arr)
      this.all_table_data = arr //this.inv_bal
      this.page_data.total = arr.length //this.inv_bal.length
      this.makeData()
    },
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
      console.log('t_part',this.goodsData)
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      let type0Goods = typeData.filter(i => !i.isdel)
      if (type0Goods.length > 0) {
        this.nowGoodsType = type0Goods[0]
      }
    },
     // 获取库存信息
    async getStock() {
      let inv_bal = await this.$localforage.getItem('t_pos_inv_bal') || []
      // if (inv_bal.length <= 0) return this.$message.error('库存信息丢失,请前往设置重置库存信息')
      this.inv_bal = inv_bal
       console.log('this.inv_bal', this.inv_bal)
    },
    nowType(v) {
      if (!v) return
      this.nowGoodsType = v
    },
    addOrderList(v) {
      console.log('v',v);
      let ishave = this.tableData.findIndex(i => i.partid == v.id)
      if (ishave >= 0) {
        console.log(ishave);
        this.$refs.tableRef.setCurrentRow(this.tableData[ishave])
        if (this.is_look) return
        this.tableData[ishave].invbi_plan++
      } else {
        if (this.is_look) return
        let data = {
          partid: v.id,
          part_id: v.part_id,
          part_unit: v.part_unit,
          part_name: v.part_name,
          part_specs: v.part_specs,
          part_model: v.part_model,
          invchk_price: v.part_price,
          invchk_unit: v.part_unit,
          region_id: v.r_store,
          invchk_acc_qty: 0,//结存数量
          invchk_act_qty: 0,//结存数量
          invchk_qty_ch:  0,
          pos_id: shopBaseMsg.terminal,
          part_usertype: v.part_usertype,
          partusertype: v.partusertype,
          notes: '',
        }
        this.tableData.push(data)
      }
    },
    delItem(item) {
      let i = this.tableData.findIndex(v => v.id == item.id)
      if (i >= 0) {
        this.tableData.splice(i, 1)
      }
    },

 nopage(v) {
     let pagetotal=this.page_data.total
     this.page_data.total=0
     this.tableData = this.all_table_data
      console.log('tableData',this.tableData )
    //  this.tableData = pagePagination(this.all_table_data, 1000, 0)
    this.page_data.total=pagetotal
    this.makeData()
    },

     // 分页
    handleCurrentChange(n) {
      this.page_data.currentPage = n
      this.makeData()
      console.log('handleCurrentChange',n)
    },
    makeData(){
      
    this.tableData = pagePagination(this.all_table_data, this.page_data.pagesize, this.page_data.currentPage)
     var tabless=[]
    tabless.push(this.tableData) 
      console.log('tables',tabless)
    },


    onRowClick(v) {
      this.nowGoods = v
    },
//负数清零
  Clearing() {
        let pagetotal=this.page_data.total
        this.page_data.total=0
        this.tableData = this.all_table_data
          this.tableData.map((i, index) => {
            if (i.invchk_act_qty<0){
              
              console.log('(this.tableData[index].invchk_act_qty',this.tableData[index])
              this.tableData[index].invchk_act_qty=0
              this.tableData[index].invchk_qty_ch=this.tableData[index].invchk_act_qty - this.tableData[index].invchk_acc_qty//盈亏数量=实际数量-账面数量
            } 
      })
          this.page_data.total=pagetotal
          this.makeData()
      },

           
       
    seave(bstate) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          console.log('this.user', user)
          let seavetime = parseInt(new Date().getTime())
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 盘点单明细id = makeid(this.page_data.total)
          let 盘点单明细 = []
          let 总价值 = 0
          let 盘点明细表 = await this.$localforage.getItem('t_pos_inv_chk_items') || [] //明细表
          let 盘点主表 =   await this.$localforage.getItem('t_pos_inv_chk') || []//主表
          let 总库存 = this.$store.state.user.t_pos_inv_bal||[] //库存
            let  上传状态  //传输状态0-新建, 1-待上传,2-未批复, 3-已批复,4-已确认,4-完成
          if (bstate==0){
              上传状态=0
          }else if (bstate==1){
              上传状态=1
          }
        if (this.editid == 0) { // 类型为0是新增
            console.log('新增')
            // 新增
            let pagetotal=this.page_data.total
            this.page_data.total=0
            this.tableData = this.all_table_data
            console.log('tableData',this.tableData )

            this.tableData.forEach((item, i) => {
              let obj = {
                id: 盘点单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: 主表id,
                partid: item.partid,
                invchk_price: item.invchk_price,
                invchk_unit: item.invchk_unit,
                region_id: item.region_id,
                invchk_acc_qty: item.invchk_acc_qty,
                invchk_act_qty: item.invchk_act_qty,
                invchk_qty_ch: item.invchk_act_qty - item.invchk_acc_qty,
                pos_id: item.pos_id,
                notes: item.notes,
              }
              盘点单明细.push(obj)
            })
            this.page_data.total=pagetotal
            this.makeData()
            console.log('新增盘点单明细',盘点单明细)

            let 盘点单据主表 = {
              id: 主表id,
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              chk_id: this.searchform.chk_id,
              bstate: bstate,
              invchk_bgn_date:  this.searchform.invchk_bgn_date,
              invchk_end_date:  this.searchform.invchk_end_date,
              region_id: this.searchform.region_id,
              invchk_manner: this.searchform.invchk_manner,
              invchk_diff_amnt: 0,
              invchk_diff_value: 0,
              invchk_bal_amnt: 0,
              invchk_bal_value: 0,
              notes:this.searchform.notes,
              pos_id: shopBaseMsg.terminal,
              tstate: 上传状态, //0 未上传 1上传
              dept_id: user.dept_id,
            }
            console.log('盘点单据主表',盘点单据主表)
            盘点主表.push(盘点单据主表)
            盘点明细表.push(...盘点单明细)
          } else {
            // 编辑
            console.log('编辑')
            let pagetotal=this.page_data.total
            this.page_data.total=0
            this.tableData = this.all_table_data
            console.log('编辑tableData',this.tableData )

            this.tableData.forEach((item, i) => {
              let obj = {
                id: 盘点单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid:  item.pid,
                partid: item.partid,
                invchk_price: item.invchk_price,
                invchk_unit: item.invchk_unit,
                region_id: item.region_id,
                invchk_acc_qty: item.invchk_acc_qty,
                invchk_act_qty: item.invchk_act_qty,
                invchk_qty_ch: item.invchk_act_qty - item.invchk_acc_qty, //盈亏数量=实际数量-账面数量
                pos_id: item.pos_id,
                notes: item.notes,
              }
              盘点单明细.push(obj)

              // 库存数量
              if (bstate==1) {
               let n = 总库存.findIndex(j => j.part_id == item.part_id)//库存
              if (n>=0) {
                   总库存[n].bal_amnt = item.invchk_act_qty
                   总库存[n].bal_cost = (总库存[n].bal_amnt-0) * (item.invchk_price-0)

                //修改vuex中的库存数
                this.$store.commit('user/盘点修改结存数量',item);



              // let j = this.inv_bal.findIndex(c => c.partid === item.partid)
              // if (j>= 0) {
              //   this.tableData[j].invchk_qty_ch = item.invchk_act_qty - item.invchk_acc_qty, //盈亏数量=实际数量-账面数量
              //   // this.inv_bal[j].bal_amnt = item.invchk_act_qty // 库存数量
              //   // this.inv_bal[j].bal_cost = item.invchk_act_qty * item.invchk_price// 结存金额

              //      总库存[n].bal_amnt = item.invchk_act_qty
              //      总库存[n].bal_cost = (总库存[n].bal_amnt-0) * (item.invchk_price-0)


              //   this.$store.commit('user/盘点修改结存数量',item);
              }else {//没有记录时增加一条
                  let balamnt ,regionid
                      balamnt = item.invchk_act_qty
                      regionid=this.searchform.region_id
                 
                    let obj = {
                      id: item.partid,
                      tenid: user.tenid,
                      lct: seavetime,
                      uct: seavetime,
                      lby: user.id,
                      uby: user.id,
                      part_name: item.part_name, // 品名
                      tstate: 1,
                      dept_id: user.dept_id,
                      part_id: item.part_id, // 品号
                      partid: item.partid, // 系统品号
                      region_id: regionid, //入库区域
                      bal_date: seavetime,
                      bal_amnt: balamnt, //库存
                      bal_cost: -(item.invbi_plan * item.part_price), //价值
                      sales_volume: 0,//销量
                      part_price: item.part_price,
                      part_model: item.part_model,
                      part_specs: item.part_specs,
                      part_usertype: item.part_usertype,
                      partusertype: item.partusertype
                    }
                    总库存.push(obj)
                    this.$store.commit('user/获取结存表', 总库存);
                  }
               } 
            })
           
           this.page_data.total=pagetotal
           this.makeData()


            let 盘点单据主表 = {
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              chk_id: this.searchform.chk_id,
              bstate: bstate,
              invchk_bgn_date:  this.searchform.invchk_bgn_date,
              invchk_end_date:  this.searchform.invchk_end_date,
              region_id: this.searchform.region_id,
              invchk_manner: this.searchform.invchk_manner,
              invchk_diff_amnt: 0,
              invchk_diff_value: 0,
              invchk_bal_amnt: 0,
              invchk_bal_value: 0,
              notes:this.searchform.notes,
              pos_id: shopBaseMsg.terminal,
              tstate: 上传状态, //0 未上传 1上传
              dept_id: user.dept_id,
            }
            盘点主表.forEach(item => {
              if (item.id == this.editid) {
                for (const key in 盘点单据主表) {
                  // 更新主表中对应的数据
                  item[key] = 盘点单据主表[key]
                }
              }
            })
            // 删除明细表中对应的数据
            for (let index = 盘点明细表.length - 1; index >= 0; index--) {
              let item = 盘点明细表[index]
              if (item.pid == this.editid) {
                let i = 盘点明细表.findIndex(v => v.id == item.id)
                if (i >= 0) {
                  盘点明细表.splice(i, 1)
                }
              }
            }
            盘点明细表.push(...盘点单明细)
          }
          // 储存
          await this.$localforage.setItem('t_pos_inv_chk_items', 盘点明细表)
          await this.$localforage.setItem('t_pos_inv_chk', 盘点主表)
          if (bstate==1) {
          await this.$localforage.setItem('t_pos_inv_bal', 总库存)
          }
          //  console.log('储存inv_bal',this.inv_bal )
          if (bstate==0) {
            this.$message.success('保存成功')
            if (this.editid == 0) {
              this.$route.params.rptid = 主表id
              this.editid = 主表id
              this.initEditData(this.editid)
            }
            // setTimeout(() => { this.$router.go(-1) }, 500)
          }else  if (bstate==1) {
            this.$message.success('确认成功')
             this.searchform.bstate=bstate
            this.searchform.tstate=1
            this.is_look = true
            // setTimeout(() => { this.$router.go(-1) }, 500)
          }
        }
      })
    },

 // 删除
    det(list) {
      this.$confirm('是否删除选中的盘点单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let table = this.tableData
        let 报货明细表 = await this.$localforage.getItem('t_pos_inv_chk_items') || [] //明细表
        let 报货主表 = await this.$localforage.getItem('t_pos_inv_chk') || []//主表
        list.forEach(item => {
          for (let i = table.length - 1; i >= 0; i--) {
            if (item.id == table[i].id) { // 先删除页面上表格的数据
              table.splice(i, 1)
            }
          }
          // 删除本地存储的数据
          for (let i = 报货主表.length - 1; i >= 0; i--) {
            if (item.id == 报货主表[i].id) { // 删除主表数据
              报货主表.splice(i, 1)
            }
          }
          for (let i = 报货明细表.length - 1; i >= 0; i--) {
            if (item.id == 报货明细表[i].pid) { // 删除主表id等于id的明细表
              报货明细表.splice(i, 1)
            }
          }
        });
        // c存入数据
        await this.$localforage.setItem('t_pos_inv_chk_items', 报货明细表)
        await this.$localforage.setItem('t_pos_inv_chk', 报货主表)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

inputchange(v){
  v.invchk_qty_ch=v.invchk_act_qty - v.invchk_acc_qty, //盈亏数量=实际数量-账面数量
console.log('inputchange',v )
},
    // 键盘输入
    onKeyInput(v) {
      this.searchform.inv_billid = v
      this.all_keyboard_show = false
    },
    showkeyboard(v) {
      this.now_row_attr = v
      this.$refs.keyboard.open()
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods[this.now_row_attr] = n
    },
    // goback() {
    //   this.$router.push('/shopcheck')
    // },

    
      goback() {
      this.editid = 0
      this.is_look = false
      this.$refs.form.resetFields()
      this.tableData = []
      this.$router.push('/shopcheck')
    },

    
    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return 'PD' + str + 商户号 + id
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 10px;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.goods {
  background-color: #ccc;
  border-left: 5px solid #fff;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #82c0ff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #82c0ff;
}
.keyboard {
  width: 520px;
  padding: 10px;
  background-color: #ccc;
  z-index: 99;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
</style>