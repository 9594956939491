<template>
  <div class="chart-box">
    <div class="top">
      <div class="allme">
        <div class="left">
          <div>本月营业额</div>
          <div class="num">{{month_money}}</div>
        </div>
        <div class="right">
          <type-sales-volume></type-sales-volume>
        </div>
      </div>
      <sales-volume :data="sales_volume" style="margin-bottom:15px;"></sales-volume>
      <year-sales-volume :data="year_sales"></year-sales-volume>
    </div>
  </div>
</template>

<script>
import SalesVolume from "@/views/首页/cpmponents/SalesVolume.vue";
import YearSalesVolume from "@/views/首页/cpmponents/YearSalesVolume.vue";
import TypeSalesVolume from "@/views/首页/cpmponents/TypeSalesVolume.vue";
export default {
  components: {
    SalesVolume,
    YearSalesVolume,
    TypeSalesVolume,
  },
  data() {
    return {
      t_pos_inv_bal: [],
      sales_volume: [],
      year_sales:[],
      month_money: 0,
    };
  },
  methods: {
    async initData() {
      let 销售列表 = (await this.$localforage.getItem("t_pos_sale_detail")) || [];
      this.t_pos_inv_bal =
        (await this.$localforage.getItem("t_pos_inv_bal")) || [];
      let now_month = new Date().getMonth() + 1; // 获得当前月份
      let arr = [];
      let year_sales = []; // 年度销售统计
      let money = 0;
      for (let i = 0; i < 销售列表.length; i++) {
        let item = 销售列表[i];
        let month = new Date(item.lct).getMonth() + 1; // 当前销售详情的月份
        console.log(month === now_month);
        if (month === now_month) {
          // 找到当月销售的商品
          money = money + Number(item.saledt_amount);
          let w = arr.findIndex((v) => v.part_id === item.part_id);
          console.log(w);
          if (w < 0) {
            // 如果商品不在数组中 直接加入
            let j = this.t_pos_inv_bal.findIndex(
              (v) => v.part_id === item.part_id
            );
            let obj = {
              part_id: item.part_id,
              saledt_qty: item.saledt_qty,
              name: "",
            };
            if (j >= 0) {
              // 找到商品名称
              obj.name = this.t_pos_inv_bal[j].part_name;
            }
            arr.push(obj);
          } else {
            // 如果商品在数组中 就加上现在的商品数量
            arr[w].saledt_qty = arr[w].saledt_qty + item.saledt_qty;
          }
        }
        // 年度销售额
        year_sales[month - 1] = year_sales[month - 1] || 0
        year_sales[month - 1] = year_sales[month - 1] + item.saledt_amount || 0;
      }   
      arr = arr.sort((a, b) => b.saledt_qty - a.saledt_qty);
      this.sales_volume = arr;
      this.month_money = money.toFixed(2);
      this.year_sales = year_sales
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less" scoped>
.chart-box {
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    .allme {
      display: flex;
      .left {
        font-size: 20px;
        font-weight: 600;
        line-height: 37px;
        text-align: center;
        color: #333;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .num {
          color: #ff9900;
        }
      }
      .right {
        width: 70%;
      }
    }
  }
}
</style>