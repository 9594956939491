<template>
  <div>
      <div class="card-box">
          <div :class="active == i ?'card-item active':'card-item'"  v-for="(item,i) in pageList" :key="item.id" @click="itemClick(item,i)">{{item.name}}</div>
      </div>
      <el-pagination
      style="margin-top:5px;"
       background
       hide-on-single-page
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pagesize"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
    props:{
        data:{
            type:Array,
            default: function () {
                return []
            }
        }
    },
    data(){
        return {
            list:[],
            pageList:[],
            active:0,
            total:0,
            currentPage:1,
            pagesize:12
        }
    },
    watch:{
        data:{
            handler:function(val,oldval){
                this.list = val
                this.total = val.length
                this.pageMake()
            },
            deep:true//对象内部的属性监听，也叫深度监听
        }
    },
    methods:{
        itemClick(item,i){
            this.active = i
            this.$emit("clickitem",item)
        },
        handleCurrentChange(n){
            this.currentPage = n
            this.pageMake()
        },
        pageMake(){
            let start = (this.currentPage - 1)*this.pagesize
            let end = this.currentPage*this.pagesize
            let arr = []
            for (let i = 0; i < this.list.length; i++) {
                if (i>=start && i< end) {
                    arr.push(this.list[i])
                }
            }
            this.active = 0
            this.$emit("clickitem",arr[0])
            this.pageList = arr
        }
    }
}
</script>

<style scoped>
    .card-box {
        height: 110px;
    }
    .card-item {
        float: left;
        width: 80px;
        height: 50px;
        border: 1px solid #ccc;
        background-color: #fff;
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #333;
        border-radius: 5px;
    }
    .card-item.active {
        background-color: #2d8cf0;
        border: 1px solid #666;
        color: #fff;

    }
</style>