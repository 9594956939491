import 会员api from '../../会员/api.js'
import { makeid } from '@/components/makeid.js'
export default {
    onKeyInput(num) {
        this.vipinputV = false
        this.searchVipCard(this.queryParams.carddata)
    },
    onBtnClick(num) {
        this.queryParams.carddata = num
    },
    onBorderClose() {
        this.vipinputV = false

    },
    
    // 清空会员信息
    cardempty: function () {
        this.queryParams.carddata = ''
        this.resetcard()//会员表单重置
    },
    // 搜索vip卡号
    async searchVipCard(card) {
        this.vipinputV = false
        this.resetcard()//会员表单重置
        if (!card) { return this.$message.warning('请输入查询条件') }
        this.loading = true;
        let [err, res] = await 会员api.查询会员卡列表(this.queryParams)

        if (err) {
            this.resetcard()//会员表单重置
            return this.$message.error('查询会员卡列表失败' + err)
        }
        if (res.statusCode != 200) {
            this.resetcard()//会员表单重置
            return this.$message.error('查询会员卡列表失败')
        }
        console.log('res.data.rows', res)
        this.CardList = res.data.card.rows;//卡
        let giftdata = res.data.gift;//2使用状态电子券
        this.CardgiftList=[]//清空
        let id = makeid(1)
        giftdata.forEach(item => {
        let obj = {
          id: id,
          typeid: item.giftid,
          receive_time: new Date().getTime(),
          rec_id: item.giftid,
          rec_name: item.card_name,
          rec_qty: 1,
          relationattri: item.card_remoney || 0,
          rec_giftid: item.gift_id_out,
          rec_gifttype:1,
        }
        obj.rec_amt = (obj.relationattri * obj.rec_qty).toFixed(2)
        this.CardgiftList.push(obj)
        })
      
        this.ecoupons_table_data=this.CardgiftList //2使用状态电子券
        // this.ecoupons_table_data=this.CardgiftList //2使用状态电子券
        console.log('this.ecoupons_table_data', this.CardgiftList);
        this.loading = false;
        if (this.CardList !== undefined && this.CardList !== null && this.CardList.length > 0) {
            if (this.CardList.length > 1) {
                this.MemberOpenVisible = true//多会员弹窗
                return
            }
            this.CardListCurren = this.CardList[0]
            console.log('CardListCurren', this.CardListCurren, this.CardList.length)
            //获得当前行
            for (let i = 0; i < this.tableData.length; i++) {
                this.goodsClick(this.tableData[i])
                if (this.CardListCurren.id == 0) {
                    // 没有会员信息
                    this.resetcard()//会员表单重置

                } else {
                    this.cardid = this.CardListCurren.id
                    this.zhekou({ type: 1, num: this.CardListCurren.card_dis_rate * 100 })//  zhekou {type: 1, num: 80} 
                }
                this.getAll()
                this.leftTopTabs = '点单'
            }
        } else {
            this.resetcard()//会员表单重置
            for (let i = 0; i < this.tableData.length; i++) {
                this.goodsClick(this.tableData[i])
                this.zhekou({ type: 1, num: 0 })
            }
            this.getAll()
            this.leftTopTabs = '点单'
            return this.$message.error('会员卡信息不存在')
        }
    },
}