<template>
  <div class="key-body">
    <el-row>
      <el-col :span="8" style="line-height: 70px;"><span>输入:</span>
        <el-input style="width:152px;margin-left:5px;" v-model="moneyInput" @input="overInput"></el-input>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="confirm">确认</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="btnback"><i class="el-icon-back" style="font-size:18px;"></i></div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('000')">置零</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same">舍入金额</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('1')">1</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('2')">2</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('3')">3</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('10')">10</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('-')">-</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same">去零金额</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('4')">4</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('5')">5</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('6')">6</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('20')">20</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup"  @click="listDiscount(1)">整单折扣</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup"  @click="listDiscount(2)">整单折让</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('7')">7</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('8')">8</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('9')">9</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('50')">50</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="allmaney">满金额</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="()=>{this.$emit('qunti')}">消费群体</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('0')">0</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('00')">00</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('.')">.</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="enterNamber('100')">100</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @click="cancel">取消</div>
      </el-col>
      <el-col :span="4">
        <div class="btn-same" @mousedown="ondown" @mouseup="onup" @click="()=>{this.$emit('jiesuan')}">结算</div>
      </el-col>
    </el-row>
    <el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moneyInput: ''
    }
  },
  methods: {
    enterNamber(num) {
      if (num == '000') {
        this.moneyInput = ''
        return
      }
      if (num == '-') {
        if (this.moneyInput.indexOf('-') == -1) {
          this.moneyInput = '-' + this.moneyInput
        }
        return
      }
      if (num == '0' || num == '00') {
        if (this.moneyInput.length > 0) {
          this.moneyInput += num
        }
      } else if (num == '.') {
        if (this.moneyInput.length == 0) {
          this.moneyInput = '0.'
        } else {
          let i = this.moneyInput.indexOf('.')
          if (i == -1) this.moneyInput += '.'
        }
      } else {
        this.moneyInput += num
      }
      let number = this.moneyInput
      if (number.indexOf('.') != -1) {
        number = number.substring(0, number.indexOf('.') + 3)
      }
      this.moneyInput = number
    },
    confirm() {
      this.$emit('enterNum', Number(this.moneyInput))
      this.moneyInput = ''
    },
    ondown(e) {
      e.target.classList.add('keyclick')
    },
    onup(e) {
      e.target.classList.remove('keyclick')
    },
    overInput() {
      let number = this.moneyInput
      if (number.indexOf('.') != -1) {
        number = number.substring(0, number.indexOf('.') + 3)
      }
      this.moneyInput = number
    },
    btnback() {
      if (this.moneyInput.length == 0) return
      this.moneyInput = this.moneyInput.substr(0, this.moneyInput.length - 1)
    },
    payforAll() {

    },
    allmaney() {
      this.$emit('allmane')
      this.$emit('enterNum', Number(this.moneyInput))
      this.moneyInput = ''
    },
    // 整单折扣
    listDiscount(type) {
      if (type == 1) this.$emit('zhekou',this.moneyInput)
      if (type == 2) this.$emit('zherang',this.moneyInput)
      this.moneyInput = ''
    },
    // 取消
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.key-body .el-row {
  margin-bottom: 10px;
}
.keyclick {
  border: 1px solid #d9ecff;
  color: #208bf5;
  background-color: #aac9e7;
  font-weight: 600;
}
.bus-cons-job-box {
  overflow: hidden;
  height: 200px;
  overflow-y: scroll;
}
.btn-same {
  width: 95px;
  height: 70px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 70px;
}
</style>