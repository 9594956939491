<template>
  <div class="table-box">
     <el-tabs v-model="activeName" ref="giftRef" type="border-card" @tab-click="handleClick">
        <el-tab-pane :disabled="sale_model" label="礼券信息" name="first">
          <el-table :data="tableData" stripe border size="mini" style="width: 100%">
            <el-table-column prop="receive_time" label="时间" width="140">
              <template slot-scope="scope">
                <div>
                  {{scope.row.receive_time | parseTime}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="rec_name" label="券名" width="100">
            </el-table-column>
            <el-table-column prop="rec_amt" label="金额" width="100">
            </el-table-column>
            <el-table-column prop="rec_qty" label="数量">
            </el-table-column>
            <el-table-column prop="rec_giftid" label="券号">
            </el-table-column>
          </el-table>
       </el-tab-pane>
        <el-tab-pane  :disabled="sale_model"  label="电子券信息" name="second">
           <el-table :data="tableDatae" stripe border size="mini" style="width: 100%">
            <el-table-column prop="receive_time" label="时间" width="140">
              <template slot-scope="scope">
                <div>
                  {{scope.row.receive_time | parseTime}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="rec_name" label="券名" width="100">
            </el-table-column>
            <el-table-column prop="rec_amt" label="金额" width="100">
            </el-table-column>
            <el-table-column prop="rec_qty" label="数量">
            </el-table-column>
            <el-table-column prop="rec_giftid" label="券号">
            </el-table-column>
          </el-table>
          </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
export default {
  props:{
    tabledata:{
      type: Array,
      default(){
        return []
      }
    },
      tabledatae:{
      type: Array,
      default(){
        return []
      }
    },
    tuanPayVisibletype:{
      type:Number,
      default:0
    },
      salemodel:{
      type:Number,
      default:0
    },
  },
  filters:{
    parseTime(data){
      return data ? parseTime(data) : ''
    }
  },
  watch:{
    tabledata:{
      handler(n){
        //  this.tableData   = n.filter(item => item.rec_gifttype== 0)v-if:disabled="true"
        //  this.tableDatae  = n.filter(item => item.rec_gifttype== 1)
        this.tableData = n
         console.log('CouponsMsgTable——tableData',n,this.salemodel)
      },
      deep: true,
    },
      tabledatae:{
      handler(n){
        this.tableDatae = n
         console.log('CouponsMsgTable——tableDatae',n)
      },
      deep: true,
    },
     tuanPayVisibletype(n){
        console.log('couponstype',n)
        this.tuantype=n
        if(this.tuantype==1){
          this.activeName='first'
          }else if(this.tuantype==2){
             this.activeName='second'
          }else{
            this.activeName='first' 
          }
     },
     salemodel(n){
        console.log('salemodel',n)
        if (n){this.sale_model=true}else{this.sale_model=false}
     },  
   
  },
  created(){
    // this.tableData = this.tabledata
  },
  data() {
    return {
         tableData: [] ,
         tableDatae: [] ,
         tuantype:0,
         sale_model:false,//销售类型
         activeName: 'first',
    }
  },
    methods: {
     handleClick(tab, event) {
       switch (tab.index) {
     case '0': //提单
     this.$emit("tuanPaytype", 1)
     this.activeName='first' 
      break;
      case '1': //电子券
      this.$emit("tuanPaytype", 2)
       this.activeName='second'
      break;
      }
     },
  }

}
</script>

<style lang="scss" scoped>
.table-box {
  width: 100%;
  height: 100%;
}
</style>