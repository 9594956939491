<template>
  <div>
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <el-card class="box-card">
        <div class="title-container">
          <h3 class="title">收银系统</h3>
        </div>
        <el-form-item prop="company">
          <el-input v-model="loginForm.company" placeholder="请输入公司名称" @focus="setNowInput('company')" type="text" auto-complete="off" />
        </el-form-item>
        <el-form-item prop="machineNum">
          <el-input v-model="loginForm.machineNum" placeholder="请输入终端号码" @focus="setNowInput('machineNum')" type="text" auto-complete="off" />
        </el-form-item>
        <el-form-item prop="name">
          <el-input v-model="loginForm.name" placeholder="登录名" @focus="setNowInput('name')" type="text" auto-complete="off" />
        </el-form-item>
        <el-form-item prop="password">
          <span class="svg-container">
          </span>
          <el-input ref="password" v-model="loginForm.password" placeholder="密码" @focus="setNowInput('password')" name="password" tabindex="2" autocomplete="on" @keyup.enter.native="handleLogin" />
        </el-form-item>
        <el-form-item prop="baseAddr">
          <el-input v-model="loginForm.baseAddr" placeholder="请求地址" type="text" auto-complete="off" />
        </el-form-item>
        <el-button :loading="loading" type="primary" style="width:100%;font-size:16px;margin-bottom:30px;" @click.native.prevent="handleLogin">注 册</el-button>
        <div class="key-board">
          <key-board ref="keyboardRef" @input-text="onKeyInput"></key-board>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import tools from '@/utils/tools'
import axios from 'axios'
import { getLocal, setLocal } from '@/utils/localstorage'
import { NowTime } from '@/utils'
import KeyBoard from '@/components/Keyboard.vue'
export default {
  components: {
    KeyBoard
  },
  data() {
    var validateUrl = (rule, value, callback) => {
      var reg = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/;
      if (reg.test(value)) {
        callback()
      }
      callback(new Error('地址必须以http://https://ftp://ftps://开头,不能包含非链接特殊字符'))
    };
    return {
      loginForm: {
        company: '166917360844808',
        machineNum: '500002',
        name: '',
        password: '',
        baseAddr: 'https://erp.djinfo.top'

        // company: '',
        // machineNum: '',
        // name: '',
        // password: '',
        // baseAddr: ''
      },
      loginRules: {
        company: [{ required: true, trigger: 'blur', message: '请输入公司名称' }], //, validator: validateUsername
        machineNum: [{ required: true, trigger: 'blur', message: '请输入商户号' }], //, validator: validateUsername
        name: [{ required: true, trigger: 'blur', message: '请输入用户名' }], //, validator: validateUsername
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }],//, validator: validatePassword
        baseAddr: [
          { required: true, trigger: 'blur', message: '请输请求地址' },
          { validator: validateUrl, trigger: 'blur' }
        ]//, validator: validatePassword
      },
      loading: false,
      now_input:''
    }
  },
    async created() {
      let shopBaseMsg = getLocal('shopBaseMsg')
      if (!shopBaseMsg){return}
      this.loginForm.company=shopBaseMsg.tenid
      this.loginForm.machineNum=shopBaseMsg.terminal
      this.loginForm.baseAddr=shopBaseMsg.baseAddr
    },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          let data = {
            tenid: Number(this.loginForm.company),
            username: this.loginForm.name,
            password: tools.makeAE1pass(this.loginForm.password),
            logintype: '1',
            terminal: this.loginForm.machineNum,
            info: 1
          }
          let res = await axios({ url: `${this.loginForm.baseAddr}/api/v1/loginpos`, method: 'post', data }).catch(e => {
            this.$message.error('请求失败请检查请求地址')
            this.loading = false
          })
          if (res.status == 200) {
            if (res.data.statusCode == 200) {
              setLocal('Admin-Token', res.data.data.token)
              setLocal('shopBaseMsg', { tenid: Number(this.loginForm.company), terminal: this.loginForm.machineNum, baseAddr: this.loginForm.baseAddr })
              let companyInfo = res.data.data.userinfo.部门
              companyInfo.公司ID = res.data.data.userinfo.用户.公司ID
              companyInfo.公司名称 = res.data.data.userinfo.用户.公司名称
              setLocal('companyInfo', companyInfo)
              this.$store.commit('user/SET_TOKEN', res.data.data.token)
              // 获取数据
              await this.getgoodsData(res.data.data.userinfo)
              this.loading = false
            } else {
              this.$message.error(res.data.message)
              this.loading = false
            }
          } else {
            this.$message.error('注册失败')
            this.loading = false
          }

        }
      })
    },

   // 下载数据
    async getgoodsData() {
      await this.getTableUpdateTime() //后台下载获得t_pos_lastdowndatatime数据
      let updateTime = await this.$localforage.getItem('t_pos_lastdowndatatime')
      let news
      //下载数据
      for (const item of updateTime) {
        // partTime = item.lastdowntime
        if (item.isstop == 0 && item.isupdown == 1 && item.ismore == 0) {  //使用、下载、单表
          let res = await this.$http({ url: item.url, method: item.method, data: { uct: item.lastdowntime } })
          if (res.statusCode != 200) return this.$message.error("数据下载失败")
          news = res.data
          item.lastdowntime = parseInt(new Date().getTime())
          //  console.log(item.tableid,news, item.lastdowntime);
          //获取数据后存入indexdDB中
          let olds = await this.$localforage.getItem(item.tableid) || []
          news = this.replaceData(news, olds)
          this.$localforage.setItem(item.tableid, news)
          console.log('下载数据updateTime', updateTime)
          this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)//下载完成后更新t_pos_lastdowndatatime表当前数据下载时间
        }
        //报货特殊处理
        if (item.isstop == 0 && item.isupdown == 1 && item.ismore == 1) { //使用、下载、多表&& item.tableid == 't_inv_bill'
          let deptid = getLocal('companyInfo').id
          let data = {
            "uct": item.lastdowntime,
            "deptid": deptid
          }
          let res = await this.$http({ url: item.url, method: item.method, data })
          //  let res = await this.$http({ url: '/business/inventbill/posneed', method:'post', data})
          if (res.statusCode != 200) return this.$message.error("数据下载失败")
          console.log('t_inv_bill', res);
          let 下载报货单 = res.data.master
          let 下载报货单明细 = res.data.detail
          item.lastdowntime = parseInt(new Date().getTime())
          //获取数据后存入indexdDB中
          let 本地报货单 = await this.$localforage.getItem(item.tableid) || []
          let 本地报货单明细 = await this.$localforage.getItem(item.tableids) || []
          let 报货单 = this.replaceData(下载报货单, 本地报货单)
          let 报货单明细 = this.replaceData(下载报货单明细, 本地报货单明细)
          this.$localforage.setItem(item.tableid, 报货单)
          this.$localforage.setItem(item.tableids, 报货单明细)
          console.log('下载数据多表', updateTime)
          this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)//下载完成后更新t_pos_lastdowndatatime表当前数据下载时间
        }
      }
      // this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)
      this.$message.success('注册成功')
       let list = await this.$localforage.getItem('sys_user')
              this.$store.commit('user/setUsers', list)
              this.$emit('canlogin')
    },
    //   // 下载数据
    // async getgoodsData(u) {
    //   await this.$localforage.clear()//从数据库中删除所有的 key，重置数据库。
    //   await this.getTableUpdateTime() //后台下载获得t_pos_lastdowndatatime数据
    //   let updateTime = await this.$localforage.getItem('t_pos_lastdowndatatime')
    //   let news
    //    //下载数据
    //   for (const item of updateTime) {
    //       // partTime = item.lastdowntime
    //       if (item.isstop == 0 && item.isupdown == 1 ) { 
    //       let res = await this.$http({ url: item.url, method: item.method, data: { uct: 0 } })
    //         news = res.data
    //         this.$localforage.setItem(item.tableid, news)
    //         console.log('item.tableid',item.tableid)
    //         if  (item.tableid=='t_part'){
    //           console.log('t_part',item.tableid)
    //             this.initializeinvbal(news,u) // 门店结存表初始化
    //         }
    //       } 
    //   }
    //   // this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)
    //   this.$message.success('注册成功')
    // },

    //  async getTableUpdateTime() {
    //   const data = {
    //     uct: 0,
    //   }
    //   let res = await this.$http({ url: '/pos/down/lastdowndatatime', method: 'post', data: data })
    //   let updateTable = res.data
    //   await this.$localforage.setItem('t_pos_lastdowndatatime', updateTable)
    // },
    //  // 门店结存表初始化
    //   async initializeinvbal(goods,u) { 
    //   let seavetime = parseInt(new Date().getTime())
    //   let 商品结存 = []
    //   goods.forEach(item => {
    //     let obj = {
    //         id:item.id,
    //         tenid:item.tenid,
    //         lct:seavetime,
    //         lby:u.用户.id,
    //         part_name:item.part_name, // 品名
    //         tstate:0,
    //         dept_id: u.部门.id,
    //         partid:item.partid,
    //         part_id:item.part_id, // 品好
    //         region_id:item.r_store,
    //         bal_date:seavetime,
    //         bal_amnt:0, //库存
    //         bal_cost:0, //价值
    //         sales_volume:0,//销量
    //         part_price: item.part_price,
    //         part_model:item.part_model,
    //         part_specs:item.part_specs,
    //         part_usertype:item.part_usertype,
    //         partusertype:item.partusertype
    //     }
    //     商品结存.push(obj)
    //   })
    //   await this.$localforage.setItem('t_pos_inv_bal', 商品结存)
    // },
      async getTableUpdateTime() {
      const data = {
        uct: getLocal('lastGettime') || 0,
      }
      let res = await this.$http({ url: '/pos/down/lastdowndatatime', method: 'post', data: data })
      let updateTable = res.data
      console.log('lastdowndatatime', updateTable)
      //获取数据后存入indexdDB中
      let oldtime = await this.$localforage.getItem('t_pos_lastdowndatatime') || []
      let newTime = this.replaceData(updateTable, oldtime)
      this.$localforage.setItem('t_pos_lastdowndatatime', newTime)

      setLocal('lastGettime', parseInt(new Date().getTime()))
    },
     // 更新本地数据
    replaceData(newData, oldData) {
      if (oldData.length == 0) return newData
      if (newData.length == 0) return oldData

      newData.forEach(n => {
        let i = oldData.findIndex(o => o.id == n.id)
        if (i == -1) {
          oldData.push(n)
        } else {
          oldData[i] = JSON.parse(JSON.stringify(n))
        }
      });
      return oldData
    },
    // 重置库存数据
    async initializeinvbal() {
      // 门店结存表初始化
      const data = {
        uct: 0,
      }
      let goods = await this.$localforage.getItem('t_part') || []
      if (goods.length <= 0) return this.$message.error('商品信息丢失,重新下载商品信息')
      let seavetime =parseInt(new Date().getTime())
      let 商品结存 = []
      let u = this.$store.state.user.currUser
      goods.forEach(item => {
        let obj = {
          id: item.id,
          tenid: item.tenid,
          lct: seavetime,
          lby: u.id,
          part_name: item.part_name, // 品名
          tstate: 0,
          dept_id: u.dept_id,
          partid: item.id, // 物料系统id
          part_id: item.part_id, // 品号
          region_id: item.r_store,
          bal_date: seavetime,
          bal_amnt: 0, //库存
          bal_cost: 0, //价值
          sales_volume: 0,//销量
          part_usertype: item.part_usertype,//商品类型
          partusertype: item.partusertype,//商品类型
          part_price: item.part_price,
          part_model: item.part_model,
          part_specs: item.part_specs
        }
        商品结存.push(obj)
      })
      await this.$localforage.setItem('t_pos_inv_bal', 商品结存)
    },
    setNowInput(v){
      this.now_input = v
      this.$refs.keyboardRef.reset()
    },
    onKeyInput(v){
      this.loginForm[this.now_input] = v
    }
  }
}
</script>

<style scoped>
.box-card {
  background: rgba(255, 255, 255, 0.4);
  border: 0;
}
.login-form {
  position: relative;
  width: 611px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.title-container {
  position: relative;
}
.title {
  font-size: 28px;
  color: #fff;
  margin: 10px auto 30px auto;
  text-align: center;
  font-weight: bold;
}
</style>