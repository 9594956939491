import {  getSession } from '@/utils/sessionStorage.js'
import { getLocal } from '@/utils/localstorage.js'
import KeyBoard from '../KeyBoard.vue'
import PayKeyBored from '../PayKeyBored.vue'
import KeyBoardNum from '@/components/NumberKeyboard'
import LatelySale from '@/views/收银/LatelySale'
import HistorySale from '@/views/收银/HistorySale'

import { mapGetters } from 'vuex'
import GoodsMenu from '../GoodsMenu'
import ticketTemplate from '@/components/TicketTemplate/printsales.vue'//销售
import ElectronicPayment from '@/components/ElectronicPayment' // 支付宝,微信支付弹框

import StrategyTop from "@/views/会员/strategytop";//会员充值
import StrategyScoredh from "@/views/会员/strategyscoredh";//会员积分兑换

import MemberOpen from "@/views/会员/memberopen";//会员选择弹框
import CouponsMsgTable from '../CouponsMsgTable.vue' //支付界面礼券信息

import TidanPay from "@/components/TidanPay"//弹出礼券信息
import Ecoupon from "@/components/TidanPay/Ecoupon.vue"//弹出电子券信息


import NumberBoard from '@/components/NumberBord'

import indexMethods from './methods.js'
import {tidanData} from './tidanmethods.js'
import {tidanDatae} from './tidanmethodse.js'

export default {
  components: {
    GoodsMenu,
    KeyBoard,
    PayKeyBored,
    KeyBoardNum,
    LatelySale,
    HistorySale,
    ticketTemplate,
    ElectronicPayment,
    StrategyTop,
    StrategyScoredh,
    MemberOpen,
    TidanPay,
    Ecoupon,
    CouponsMsgTable,
    NumberBoard
  },
  data: () => ({
    elePayVisible:false,//电子支付
    EcouponVisible:false,//电子券支付
    empty_str: '',
    //微信支付宝弹出层标题
    paytitle:'',
    // 遮罩层
    loading: false,
    cardtopitem: {},//充值记录
    cardsaletopflag: 1,//会员是消费1还是充值2
    cardsaleredeemflag: 1,//会员是消费1还是兑换2
    cardredeemitem:{},//兑换记录
    // 会员表格数据
    CardList: [],
    //会员关联电子券表格数据
    CardgiftList: [],
    //当前会员数据
    CardListCurren: {
      id: 0,
      card_id_out: '',
    },
    //消费后会员数据
    CardListCurren_new: {
      id: 0,
      card_id_out: '',
    },
    // cardid:0,
    vipNum: '',
    leftTopTabs: '点单', // 点单,挂单 tab栏
    clickcolor:  "#de9c53", // 支付方式点击颜色
    // 所有的商品数据
    goodsData: [],
    tableData: [],
    // 点单列表中选择到的商品
    selectedGoodsId: '',
    // 挂单中的商品
    guadanList: [],
    //商品显示框的商品数据
    goodsBoxData: [],
    // 付款方式列表
    PayWay: [],
    // 当前编辑的是哪种付款方式
    whichPayWay: '',
    nowPayWayname: [],// 选择的付款方式名字 多种
    rtnnowPayWayname: [],// 按单退货选择的付款方式名字 多种
    sale_rtntype:0,//退货类型 0一般退货1按单退货
    salesourceid:0,//按单退货原单号
    apitradeno:0,//微信支付宝退货原单号
    apipayrefund:false,//标记微信支付宝退货true是退货
    oftenGoods: [],
    type0Goods: [],
    typeName: [],
    // 结算的总金额
    allMoney: 0,
    allCount: 0,
    alldis1: 0,//折扣
    alldis2: 0,//折让
    money: 0,
    // 切换结算弹窗
    checkoutFlag: false,
    //找零金额
    cashChange: null,
    dialogVisible: false,
    allMoneyType: {
      原应收: 0,
      原优惠: 0,//单品时的优惠
      整单优惠: 0,
      实价: 0, // 实价sale_amount
      优惠: 0, // 总优惠
      找零: 0, //找零sale_again
      溢收: 0,//溢收sale_more
      已收: 0,
      应收: 0,
      再收: 0,
      现金: 0,
      其它: 0,
      sale_dis_1: 0, //折扣
      sale_dis_2: 0 //折让
    },
    moneyInput: '',
    // 消费群体
    consumerGroupsVisible: false,
    //多会员选择弹出
    MemberOpenVisible: false,
    // 消费群体数据
    getBus_cons_job: [],
    bus_cons_job: {
      sex: 0, //性别
      age: ''
    },
    //卡状态状态
    stateoptions: [
      {
        dictValue: 0,
        dictLabel: "未启用",
      },
      {
        dictValue: 1,
        dictLabel: "有效",
      },
      {
        dictValue: 2,
        dictLabel: "挂失",
      },
      {
        dictValue: 3,
        dictLabel: "失效",
      },
      {
        dictValue: 4,
        dictLabel: "作废",
      },
    ],
    // 查询参数
    queryParams: {
      offset: 0,
      limit: 0,
      carddata: "",
      deptid: getLocal('companyInfo').id,
    },
    //分页步长
    querypaging: {
      pageNum: 0,
      pageSize: 10,
    },
    ticket_data: {}, //小票数据
    show_ticket: false,
    elepaymsg:{},//支付传入   
    paydata:{},//支付结果返回
    tuanPayVisible: false, //提单显示
    tuanPayVisiblee: false, //电子券显示
    tuanPayVisibletype: 0, //提单显示1提单2电子券主要区分talbe
    Returnrec_gift:[],//提单电子券退货数据
    vipinputV:false,
    ...tidanData,
    ...tidanDatae,
  }),
  watch: {
    whichPayWay(n) {
      if (this.$refs.keybord) {
        this.$refs.keybord.moneyInput = ''
      }
    }
  },
  computed: {
    // 选购的商品栏
    折扣金额() {
      return (item) => {
        // if (this.sale_rtntype===1){//按单退货
        //       return (item.saledt_dis_1).toFixed(2)
        // }else{
            // console.log('折扣金额',item,item.saledt_dis_1,item.count,item.part_price)
            if (!item.saledt_dis_1) return 0
            let all = item.count * item.part_price
            return (all * (1 - item.saledt_dis_1 / 100)).toFixed(2)
        // }
      }
    },
    // 结算栏
    已收金额() {
      let num = 0;
      this.nowPayWayname.forEach(el => {
        num = num + parseFloat(el.money)
      })
      this.allMoneyType.已收 = num.toFixed(2)
      return num.toFixed(2)
    },
    应收金额() {
      this.allMoneyType.应收 = (this.allMoneyType.原应收 - this.allMoneyType.整单优惠).toFixed(2)
      return (this.allMoneyType.原应收 - this.allMoneyType.整单优惠).toFixed(2)
    },
    // 再收金额() {
    //   let num = this.allMoneyType.应收 - this.allMoneyType.已收
    //   this.allMoneyType.再收 = num.toFixed(2)
    //   return num >= 0 ? num.toFixed(2) : 0
    // },
    再收金额() {
      let num = this.allMoneyType.应收 - this.allMoneyType.已收
      // this.allMoneyType.再收 = num.toFixed(2)
       num >= 0 ? this.allMoneyType.再收 =num.toFixed(2) : this.allMoneyType.再收 =0
       return this.allMoneyType.再收 
    },
    找零金额() {
      let 现金=0
      let 其它=0
      let 总和=0
      this.nowPayWayname.forEach(el => {
          总和 = 总和 + parseFloat(el.money)
        if (el.id=='11') {
          现金 = 现金 + parseFloat(el.money)
        }else{
          其它 = 其它 + parseFloat(el.money)
        }
      })
      this.allMoneyType.现金=现金
      this.allMoneyType.其它=其它
      let num
      console.log('找零金额',  this.allMoneyType.现金,this.allMoneyType.其它)

      if ( (this.allMoneyType.已收-现金)<=this.allMoneyType.应收){
        console.log('找零',  this.allMoneyType.应收,总和)
        num= 总和-this.allMoneyType.应收  //找零
      } else{//不找零
        console.log('不找零',  this.allMoneyType.现金)
        num=this.allMoneyType.现金
      }
       num >= 0 ? this.allMoneyType.找零 =num.toFixed(2) : this.allMoneyType.找零 = 0
     return this.allMoneyType.找零
      // console.log('现金',  this.nowPayWayname,现金)


      // let num = this.allMoneyType.已收 - this.allMoneyType.应收
      // this.allMoneyType.找零 = num.toFixed(2)
      // return num >= 0 ? num.toFixed(2) : 0
    },
    // 找零金额() {
    //   let 现金=0
    //   this.nowPayWayname.forEach(el => {
    //     if (el.id=='11') {
    //       现金 = 现金 + parseFloat(el.money)
    //     }
    //   })
    //   console.log('现金',  this.nowPayWayname,现金)
    //   let num = this.allMoneyType.已收 - this.allMoneyType.应收
    //   this.allMoneyType.找零 = num.toFixed(2)
    //   return num >= 0 ? num.toFixed(2) : 0
    // },
    溢收金额() {
      console.log('111')
      console.log('溢收金额',  this.allMoneyType.其它, this.allMoneyType.应收)
      let num = this.allMoneyType.其它 - this.allMoneyType.应收
     if (this.model > 0) {//销售
      num >= 0 ? this.allMoneyType.溢收 =num.toFixed(2) : this.allMoneyType.溢收 =0
     }else{//退货需要判断现金还是其它
       if (Math.abs(this.allMoneyType.其它)< Math.abs(this.allMoneyType.应收)){
        this.allMoneyType.溢收 =0
       }else{
        this.allMoneyType.溢收 =num.toFixed(2)
       }
     
      // num < 0 ? this.allMoneyType.溢收 =num.toFixed(2) : this.allMoneyType.溢收 =0
     }
      return  this.allMoneyType.溢收
    },

    ...mapGetters('user', ['model'])
  },
  async created() {
    this.tableData = getSession('orderList') || []
    this.guadanList = getSession('guadanList') || []
    this.getAll();
    this.getPay_way()
  },
  methods: indexMethods,
}