<template>
  <div class="formbox">
    <!-- 基本信息 -->
    <el-form ref="baseform" :model="formData" :rules="formDataRules" inline label-width="80px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="卡号" prop="card_id_out">
            <el-input  :disabled="formData.isEdits ? true:false" v-model="formData.card_id_out"></el-input>
          </el-form-item>
        </el-col>
         <el-col :span="8">
            <el-form-item label="密码" prop="card_pass">
            <el-input v-enter-number v-model="formData.card_pass"  show-password :placeholder="formData.isEdits?'仅修改密码时填写':'请输入初始密码'" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
            <el-form-item label="卡类型" prop="card_type">
         <el-select v-model="formData.card_type" placeholder="请选择" clearable  >
          <el-option
            v-for="item in typeOptions"
            :key="item.dictValue"
            :label="item.dictLabel"
            :value="item.dictValue">
          </el-option>
        </el-select>
        </el-form-item>
        </el-col>

<el-col :span="8">
          <el-form-item label="名称">
            <el-input v-model="formData.card_name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="使用密码">
            <el-switch v-model="formData.card_passuse" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
            </el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="性别">
            <el-select v-model="formData.card_sex">
              <el-option label="男" :value="0"></el-option>
              <el-option label="女" :value="1"></el-option>
              <el-option label="未知" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

           <el-col :span="8">
          <el-form-item label="信用额度">
            <el-input v-model="formData.card_credit_level"></el-input>
          </el-form-item>
        </el-col>

         <el-col :span="8">
          <el-form-item label="折扣%">
            <el-input v-model="formData.card_dis_rate" placeholder="0.0000" type="number" @input="toNumber(formData.card_dis_rate, 'formData.card_dis_rate')"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="卡来源">
            <el-select v-model="formData.card_source">
              <el-option label="本地" :value="0"></el-option>
              <el-option label="微信" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="手机号码">
            <el-input v-model="formData.card_mobile"></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="8">
          <el-form-item label="传真">
            <el-input v-model="formData.card_fax"></el-input>
          </el-form-item>
        </el-col>


         <el-col :span="8">
          <el-form-item label="证件类型">
            <el-select v-model="formData.card_identity_type">
              <el-option label="身份证" :value="1"></el-option>
              <el-option label="工作证" :value="2"></el-option>
              <el-option label="军官证" :value="3"></el-option>
              <el-option label="学生证" :value="4"></el-option>
              <el-option label="驾驶证" :value="5"></el-option>
              <el-option label="护照" :value="6"></el-option>
              <el-option label="警官证" :value="7"></el-option>
              <el-option label="其他证件" :value="9"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

           <el-col :span="8">
          <el-form-item label="电话">
            <el-input v-model="formData.card_tel" ></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="8">
          <el-form-item label="微信号">
            <el-input v-model="formData.card_wxid"></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="8">
          <el-form-item label="证件号码">
            <el-input v-model="formData.card_identity"></el-input>
          </el-form-item>
        </el-col>

         <el-col :span="8">
          <el-form-item label="邮箱">
            <el-input v-model="formData.card_email"></el-input>
          </el-form-item>
        </el-col>

          <el-col :span="8">
          <el-form-item label="QQ">
            <el-input v-model="formData.card_qq"></el-input>
          </el-form-item>
        </el-col>

          <el-col :span="8">
              <el-form-item label="关联部门" prop="card_ass_dept" >
              <el-cascader
                v-model="formData.card_ass_dept"
                :clearable="true"
                :show-all-levels="false"
                :options="cascaderOptions"
                :props="cascaderProps"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
        </el-col>

        <!-- <el-col :span="5">
          <el-form-item label="开始日期">
            <el-date-picker v-model="formData.card_bgn_date" :editable="false" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 185px"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="结束日期">
            <el-date-picker v-model="formData.card_end_date" :editable="false" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 185px"></el-date-picker>
          </el-form-item>
        </el-col> -->
         <el-col :span="8">
          <el-form-item label="卡有效期">
            <el-date-picker v-model="formData.card_expire_date" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" placeholder="选择日期时间" :picker-options="pickerOptions"  />
          </el-form-item>
        </el-col>


          <el-col :span="8">
          <el-form-item label="允许充值">
            <el-switch v-model="formData.card_top_flag" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
            </el-switch>
          </el-form-item>
        </el-col>

          <el-col :span="8">
          <el-form-item label="充值方式">
            <el-select v-model="formData.card_recharge_flag">
              <el-option label="前后台充值" :value="0"></el-option>
              <el-option label="后台充值" :value="1"></el-option>
              <el-option label="前台充值" :value="2"></el-option>
              <el-option label="不允许充值" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

         <el-col :span="8">
          <el-form-item label="生日">
            <el-date-picker v-model="formData.card_birthday" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="timestamp" ></el-date-picker>
          </el-form-item>
        </el-col>
          <el-col :span="8">
          <el-form-item label="黑名单">
            <el-switch v-model="formData.card_blacklist" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
            </el-switch>
          </el-form-item>
        </el-col>

          <el-col :span="8">
          <el-form-item label="状态">
            <el-select v-model="formData.card_state">
              <el-option label="未启用" :value="0"></el-option>
              <el-option label="有效" :value="1"></el-option>
              <el-option label="挂失" :value="2"></el-option>
              <el-option label="失效" :value="3"></el-option>
              <el-option label="作废" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
       </el-row>

    </el-form>
<!-- :rules="formDataRules" -->
   <el-form ref="baseform" :model="formData"   label-width="80px">
    <el-row>
        <el-col :span="24">
          <el-form-item label="地址">
            <el-input v-model="formData.addr" ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="formData.notes" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
</el-form>
    <div class="table">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="消费信息" name="first">
           <!-- style="max-width:1000px;margin:0 auto;" -->
          <div>
            <el-form ref="form" :model="formData"  inline label-width="120px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="第一次消费时间" >
                     <el-date-picker v-model="formData.card_first_date" :disabled="true" :editable="false" type="datetime"  value-format="timestamp"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="第一次消费金额" >
                    <el-input v-model="formData.card_first_amount" :disabled="true" type="number" @input="toNumber('formData.card_first_amount')"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="当日消费时间">
                    <el-date-picker v-model="formData.card_day_date" :disabled="true" :editable="false" type="datetime"  value-format="timestamp"></el-date-picker>
                  </el-form-item>
                </el-col>
                 <el-col :span="8">
                  <el-form-item label="当日消费金额">
                    <el-input v-model="formData.card_day_amount" :disabled="true" type="number" @input="toNumber('formData.card_day_amount')"></el-input>
                  </el-form-item>
                </el-col>
                 <el-col :span="8">
                  <el-form-item label="当日消费次数">
                    <el-input v-model="formData.card_day_count" :disabled="true" type="number" @input="toNumber('formData.card_day_count')"></el-input>
                  </el-form-item>
                </el-col>

                  <el-col :span="8">
                  <el-form-item label="最近消费时间">
                    <el-date-picker v-model="formData.card_latest_date" :disabled="true" :editable="false" type="datetime"  value-format="timestamp"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="最近消费金额">
                    <el-input v-model="formData.card_latest_amount" :disabled="true" type="number" @input="toNumber('formData.card_latest_amount')"></el-input>
                  </el-form-item>
                </el-col>
              <el-col :span="8">
                  <el-form-item label="最近消费单号">
                    <el-input v-model="formData.card_latest_saleid" :disabled="true" ></el-input>
                  </el-form-item>
                </el-col>
              <el-col :span="8">
                  <el-form-item label="当日充值金额">
                    <el-input v-model="formData.card_day_top_amount" :disabled="true" type="number" @input="toNumber('formData.card_day_top_amount')" ></el-input>
                  </el-form-item>
                </el-col>
              <el-col :span="8">
                  <el-form-item label="卡消费总金额">
                    <el-input v-model="formData.card_car_sum" :disabled="true" type="number" @input="toNumber('formData.card_car_sum')"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="充值总金额">
                    <el-input v-model="formData.card_top_total" :disabled="true" type="number" @input="toNumber('formData.card_top_total')"></el-input>
                  </el-form-item>
                </el-col>
                   <el-col :span="8">
                  <el-form-item label="消费总次数">
                    <el-input v-model="formData.card_count" :disabled="true" type="number" @input="toNumber('formData.card_count')"></el-input>
                  </el-form-item>
                </el-col>
                 <el-col :span="8">
                  <el-form-item label="消费总金额">
                    <el-input v-model="formData.card_sum" :disabled="true" type="number" @input="toNumber('formData.card_sum')"></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :span="8">
                  <el-form-item label="预存金额">
                    <el-input v-model="formData.card_money" :disabled="true" type="number" @input="toNumber('formData.card_money')"></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :span="8">
                  <el-form-item label="积分">
                    <el-input v-model="formData.card_score" :disabled="true" type="number" @input="toNumber('formData.card_score')"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="余额">
                    <el-input v-model="formData.card_remoney" :disabled="true" type="number" @input="toNumber('formData.card_remoney')"></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
            </el-form>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="微信" name="second"></el-tab-pane>
        <div>
          <el-form>
         <el-col :span="5">
          <el-form-item label="微信绑定">
            <el-switch v-model="formData.card_wx_bound" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
            </el-switch>
          </el-form-item>
         </el-col>
         </el-form>
      </div> -->

   <el-tab-pane label="微信" name="second">
          <!-- <div style="max-width:1100px;margin:0 auto;"> -->
            <el-form ref="form" :model="formData" label-width="120px">
              <el-col :span="5">
          <el-form-item label="微信绑定">
            <el-switch v-model="formData.card_wx_bound" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
            </el-switch>
          </el-form-item>
         </el-col>
            </el-form>
          <!-- </div> -->
        </el-tab-pane>

      </el-tabs>
    </div>
      <!-- 添加或修改参数配置对话框 -->
    <!-- <el-dialog :title="isEdit =='2'?'编辑会员充值信息':'添加会员充值信息'" :visible.sync="open" :close-on-click-modal="false" width="1000px" append-to-body>
      <member-top-form :defaultdata="form" ref="topFromRef" @getFormData="seaveBaseInfo"></member-top-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import 会员api from '../api.js'
// import { getOneID } from '@/api/makeid/makeid' //获得系统ID
// import { GetDataCom } from "@/api/system/dict/datacom";
// import {getdetpall } from "@/api/system/dept";
// import {  apiupdate,apiadd,apiget } from "@/api/business/member/memberprofile";
// import { getmembertypedict } from "@/api/business/member/cardgroup";//卡分类


//  import memberTopForm from "@/views/business/member/memberprofile/components/MemberTopForm";
//  import {addcardtop} from "@/api/business/member/cardtop";//充值
// import { string } from 'jszip/lib/support';
export default {
// components: { memberTopForm },
  props: {
    defaultdata: {
      type: [Object,Boolean,String],
      default:false
    }
  },
 watch: {
    defaultdata: {
      handler(n) {
        console.log('BaseInfoForm-handler-n',n)
        if (n.id){//编辑
        this.formData = n
         console.log('编辑', this.formData)
        }else{//添加
        this.reset()
        console.log('添加',this.formData,n)
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      isEdit:'0',
  // 是否显示弹出层
      open: false,
       // 表单参数
      form: {},
       formDataRules: {
        card_id_out: [
          { required: true, message: "会员卡号不能为空", trigger: "blur" },
        ],
        card_type: [
          { required: true, message: "会员卡类型不能为空", trigger: "blur" },
        ],
      },
     //时间选择器
      pickerOptions: this.getPickerOptions(),
       //卡类型
       typeOptions:[],
        //部门列表
       deptOptions:[],
      //类别级联菜单
      cascaderOptions: [],
      cascaderProps: {
        expandTrigger: "hover",
        label: "name",
        checkStrictly: true,
        emitPath: true,
        value: "id",
      },

      formData: {
        // 基本信息
        isEdits: false,
        card_id_out: '',
        card_pass: "",
        card_passuse: 0,//是否使用密码支付0否1是
        card_type: '', //卡类型
        card_state: 0,//会员卡状态0未启用1有效2挂失3失效
        card_name: '',
        card_sex: 0,//性别0男1女
        card_birthday: null,//生日
        card_birthday_flag: 0,//0阴历1阳历
        card_source: 0,//卡来源0本地1微信
        card_identity_type: 1,//证件类型1身份证2工作证3军官证4学生证5驾驶证6护照证7警官证9其他证件
        card_identity: '',
        card_blacklist: 0, //黑名单0否1是
        card_mobile: '',
        card_wxid: '',
        card_email: '',
        card_dis_rate: 1.00,
        card_top_flag: 0,//是否允许充值0否1是
        card_tel: '',
        card_qq: '',
        card_fax: '',
        card_wx_bound: 0,//是否解绑0否1是
        card_recharge_flag: 0,//充值类型0前后台充值1后台充值2前台充值3不允许充值
        card_bgn_date: null,
        card_end_date: null,
        card_credit_level: 0, //信用额度(1、2、3、4、5)
        card_ass_dept: 0,
        addr: '',
        notes: '',
        // 消费信息
        card_first_date: null,
        card_first_amount: 0.00,
        card_day_date: null,
        card_count: 0,
        card_day_count: 0,
        card_car_sum: 0.00,
        card_latest_date: null,
        card_day_amount: 0.00,
        card_sum: 0.00,
        card_latest_amount: 0.00,
        card_day_top_amount: 0.00,
        card_money: 0.00,
        card_latest_saleid: '',
        card_top_total: 0.00,
        card_remoney: 0.00
      },
      activeName: 'first'
    }
  },

    created() {
  //this.getdeptall();//部门级联
    this.listDataCom();//查询公共字典
  },
  methods: {
  /** 查询卡类型字典列表 */
    async listDataCom() {
       let [err, res] = await 会员api.查询会员卡分类列表({type:'card_group'})
       console.log(res)
      if (err) {
        return this.$message.error('查询会员卡分类列表失败')
      }
     this.typeOptions = res.data.rows;
    },

 //部门类型级联菜单
    async getdeptall() {
        await getdetpall().then((response) => {
        this.deptOptions=response.data.rows
        // dictLabel
        // dictValue
        console.log('this.deptOptions', this.deptOptions)
         let dataList = this.parse2Tree(response.data.rows);
         this.cascaderOptions = JSON.parse(JSON.stringify(dataList));
      });
    },
      // 级联选择器选择
    handleChange(value) {
      if (value.length == 0) {
        this.formData.card_ass_dept = 0;
      } else {
        this.formData.card_ass_dept = value[value.length - 1];
      }
    },
 // 表单重置
    reset() {
      this.formData={
      isEdits: false,
         // 基本信息
        card_id_out: '',
        card_pass: "",
        card_passuse: 0,//是否使用密码支付0否1是
        card_type: null, //卡类型
        card_state: 0,//会员卡状态0未启用1有效2挂失3失效
        card_name: '',
        card_sex: 0,//性别0男1女
        card_birthday: null,//生日
        card_birthday_flag: 0,//0阴历1阳历
        card_source: 0,//卡来源0本地1微信
        card_identity_type: 1,//证件类型1身份证2工作证3军官证4学生证5驾驶证6护照证7警官证9其他证件
        card_identity: '',
        card_blacklist: 0, //黑名单0否1是
        card_mobile: '',
        card_wxid: '',
        card_email: '',
        card_dis_rate: 1.00,
        card_top_flag: 0,//是否允许充值0否1是
        card_tel: '',
        card_qq: '',
        card_fax: '',
        card_wx_bound: 0,//是否解绑0否1是
        card_recharge_flag: 0,//充值类型0前后台充值1后台充值2前台充值3不允许充值
        card_bgn_date: null,
        card_end_date: null,
        card_credit_level: 0, //信用额度(1、2、3、4、5)
        card_ass_dept: 0,
        addr: '',
        tstate:1,
        notes: '',
        // 消费信息
        card_first_date: null,
        card_first_amount: 0.00,
        card_day_date: null,
        card_count: 0,
        card_day_count: 0,
        card_car_sum: 0.00,
        card_latest_date: null,
        card_day_amount: 0.00,
        card_sum: 0.00,
        card_score: 0.00,
        card_latest_amount: 0.00,
        card_day_top_amount: 0.00,
        card_money: 0.00,
        card_latest_saleid: '',
        card_top_total: 0.00,
        card_remoney: 0.00,
        card_cash_sum: 0.00,
        }
      },
   async seaveForm() {  
      console.log('BaseInfoForm-seaveForm',this.formData);
      if (this.formData.card_id_out==''||this.formData.card_id_out==null){
          this.$message.error('卡号不能为空')
          return
         }
    if (this.formData.card_type==''||this.formData.card_type==null){
          this.$message.error('卡类型不能为空')
          return
         }
       if (this.formData.id != undefined) {
          let [err, res] = await 会员api.修改会员信息(this.formData)
          if (err) {
            return this.$message.error('修改会员信息失败')
          }
          if (res.statusCode === 200) {
                this.$message.success("修改成功");
                this.open = false;
                  this.$emit("getFormData", this.formData);
              }
          
          } else {
            const newId = await  会员api.getOneID()//获得id
            this.formData.id=newId

            let [err, res] = await 会员api.新增会员信息(this.formData)
              if (err) {
                return this.$message.error('新增会员信息失败')
              }
              if (res.statusCode === 200) {
                    this.$message.success("新增成功");
                    this.formData.isEdits=true
                    this.open = false;
                    this.$emit("getFormData", this.formData);
                  }
          }
    },


     // 提交表单
    async seaveData() {
        this.$refs["baseform"].validate((valid) => {

         if (this.formData.card_id_out==''||this.formData.card_id_out==null){
          this.$message.error('卡号不能为空')
          return
         }
    if (this.formData.card_type==''||this.formData.card_type==null){
          this.$message.error('卡类型不能为空')
          return
         }

        if (valid) {
          // let obj = {
          //   tenid: this.$store.state.user.tenid,
          // };
          // Object.assign(
          //   obj,
          //   this.formData,
          // );
          console.log('getFormData1111', this.formData)
          this.$emit("getFormData", this.formData);
        }
      });
    },

     toNumber(data, str) {
      let key = str.split(".")[0];
      let val = str.split(".")[1];
      if (!this[key][val]) {
        this[key][val] = 0;
      } else {
        this[key][val] =
          parseFloat(this[key][val]) == NaN ? 0 : parseFloat(this[key][val]);
      }
    },

//密码生成随机数
     passRandom() {
      var code = '';
      //设置长度，这里看需求，我这里设置了4
      var codeLength = 6;
      //设置随机字符
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
      //循环codeLength 我设置的4就是循环4次
      for (var i = 0; i < codeLength; i++) {
          //设置随机数范围,这设置为0 ~ 36
          var index = Math.floor(Math.random() * 9);
          //字符串拼接 将每次随机的字符 进行拼接
          code += random[index];
      }
      //将拼接好的字符串赋值给展示的code
      this.formData.card_pass = code;
  },
    // //密码生成随机数
    // passRandom() {
    //         this.formData.card_pass = Math.random().toString(36).substring(5)
    //     },

     toNumber(data, str) {
      let key = str.split(".")[0];
      let val = str.split(".")[1];
      if (!this[key][val]) {
        this[key][val] = 0;
      } else {
        this[key][val] =
          parseFloat(this[key][val]) == NaN ? 0 : parseFloat(this[key][val]);
      }
    },
    /** 查询会员卡结存列表 */
   async getcardinfo(id) {
     console.log('getcardinfo', id)
       let [err, res] = await 会员api.查询会员卡列表详细({id})
      if (err) {
        return this.$message.error('查询会员卡列表详细失败')
      }
       if ( res.data.rows.length==0){
          this.reset();
       }else{
          this.formData = res.data.rows[0];
        if (this.formData.id){
             this.formData.isEdits=true
          }else{
            this.formData.isEdits=false
          }
    }
  
    },

    // 表单重置
    resettop() {
      this.form = {
              id: null,
              isEdit:'0',
              dept_id: 0,
              pos_id: '',
              computer_no: '',
              top_date:null,
              cardid: '',
               card_id_out: '',
              top_type: 0,
              top_source: 0,
              top_money: 0,
              grant_money: 0,
              grant_score: 0,
              top_remoney: 0,
              recharge_flag: 0,
              associate_id: 0,
              bstate: 0,
              salesid: 0,
              top_audit_flag: 0,
              top_audit_date:null,
              top_audit_staff:null,
              top_serialid:null,
              notes:'',

      };
      this.resetForm("form");
    },
  /** 充值新增按钮操作 */
    handleAddTop() {
      this.resettop();
      this.isEdit = '1'
      this.form.isEdit='1'
      this.form.cardid=this.formData.id
      if (this.formData.id==undefined||this.formData.id==''||this.formData.id==null){
        this.msgWarning("没有卡信息不能充值");
        return
      }
      this.open = true;
      this.title = "添加充值";
    },
   // 充值取消按钮
    cancel() {
      this.open = false;
      // this.reset();
    },
     /** 充值提交按钮 $refs.baseform*/
    submitForm: function () {
        console.log('submitForm', this.$refs.topFromRef)
      this.$refs.topFromRef.seaveData()
    },
    /** 充值提交按钮 */
      seaveBaseInfo(val) {
     console.log('seaveBaseInfo充值提交',val)
            this.$delete(this.form,'isEdit')//删除对象中某一项
            this.$delete(this.form,'card_id_out')//删除对象中某一项
            this.form.bstate=1
            this.form.top_date=parseInt(new Date().getTime())
            console.log('seaveBaseInfo',val);
            addcardtop(val).then(response => {
              if (response.statusCode === 200) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.formData.card_money=Number(this.formData.card_money) + Number(this.form.top_money) + Number(this.form.grant_money)
                this.formData.card_remoney=Number(this.formData.card_remoney) + Number(this.form.top_money) + Number(this.form.grant_money)
                this.formData.card_top_total=Number(this.formData.card_top_total) + Number(this.form.top_money) + Number(this.form.grant_money)
                this.formData.card_score=Number(this.formData.card_score) +  Number(this.form.grant_score)

              }
            });
    },
  //  seaveBaseInfo(val) {
  //    console.log('seaveBaseInfo1111111111',val.jsons.master[0])
  //           this.$delete(val.jsons.master[0],'isEdit')//删除对象中某一项
  //           val.jsons.master[0].bstate=1
  //           val.jsons.master[0].top_date=this.formattransdate(this.parseTime(new Date()))
  //           console.log('seaveBaseInfo',val);
  //           addcardtop(val).then(response => {

  //             if (response.statusCode === 200) {
  //               this.msgSuccess("新增成功");
  //               this.open = false;
  //               // this.getList();
  //             }
  //           });
  //   },
  },
}
</script>

<style>

</style>