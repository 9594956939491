<template>
  <div>
    <!-- 消费详情 -->
    <div class="filter-box">
        <div class="form">
        <el-form ref="queryForm" :model="searchform" v-show="showSearch" label-width="80px" inline size="small">
       
          <el-form-item label="销售单号" prop="sale_id">
            <el-input v-model="searchform.sale_id" @keydown.enter.native="handleQuery(searchform.sale_id)" size="small"></el-input>
          </el-form-item>

          <el-form-item label="消费日期" prop="dateRange">
            <el-date-picker size="small" v-model="dateRange" value-format="timestamp" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- <el-input v-model="filter_data" placeholder="请输入查询条件" style="width:160px;" size="small">
      </el-input>
      <el-button style="float: right;margin-right: 10px;" size="mini" @click="handleQuery" type="success"> <i class="el-icon-refresh"></i> 查询</el-button>
      <el-button style="float: right;margin-right: 10px;" size="mini" @click="initData" type="success"> <i class="el-icon-refresh"></i> 刷新</el-button> -->
    </div>
    <div class="table-up">
      <el-table :data="tableData" ref="xiaofei" highlight-current-row @current-change="handleCurrentChange" border height="400" :summary-method="getSummaries" show-summary style="width: 100%; ">
        <!-- <el-table-column label="ID" prop="id" sortable align="center" show-overflow-tooltip /> -->
        <el-table-column label="销售单号" prop="sale_id" sortable align="center" width="200" />
        <el-table-column label="消费时间" prop="sale_date" sortable align="center" width="180">
          <template slot-scope="scope">{{ scope.row.sale_date===null? '' :scope.row.sale_date|parseTime }}</template>
        </el-table-column>
        <el-table-column label="消费金额" prop="sale_amount" sortable align="center" :show-overflow-tooltip="true" />
        <el-table-column label="消费类型" prop="sale_type" sortable align="center" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              <span v-show="scope.row.sale_type == -1">退货</span>
              <span v-show="scope.row.sale_type == 1">销售</span>
            </div>
          </template>
         </el-table-column>
         <el-table-column label="交易类型" prop="api_type" sortable align="center" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              <span v-show="scope.row.api_type == 0">常规</span>
              <span v-show="scope.row.api_type == 1">微信</span>
              <span v-show="scope.row.api_type == 2">支付宝</span>
            </div>
          </template>
         </el-table-column>
         <!-- <el-table-column label="支付宝/微信交易单号" prop="api_trade_no" sortable align="center" :show-overflow-tooltip="true" /> -->
         <!-- <el-table-column label="备注" prop="notes" sortable align="center" :show-overflow-tooltip="true" /> scope.row.sale_type>0 &&-->
        <el-table-column label="操作" align="center" width="220" class-name="small-padding fixed-width">
          <template v-if="scope.row.id>0" slot-scope="scope">
            <el-button  v-if=" scope.row.rtnflag !=1 " size="mini" type="text" icon="el-icon-sell" @click="handleReturn(scope.row)">退货</el-button>
            <el-button size="mini" type="text" icon="el-icon-printer" @click="handlePrint(scope.row)">补打</el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <div class="tag-box">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="消费明细" name="first">
          <el-table :data="xiaofeiListData" border height="250" :summary-method="getSummaries" show-summary style="width: 100%;">
            <!-- <el-table-column label="销售单号" prop="sale_id" align="center" :show-overflow-tooltip="true" /> -->
            <el-table-column label="明细" prop="saledt_id" align="center" width="100" />
            <el-table-column label="商品编号" prop="part_id" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="商品名称" prop="part_name" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="单价" prop="saledt_uprice" align="center" width="100" />
            <el-table-column label="单位" prop="saledt_unit" align="center" width="100" />
            <el-table-column label="数量" prop="saledt_qty" align="center" width="100" />
            <el-table-column label="单品折扣" prop="saledt_dis_1" align="center" width="100" />
            <el-table-column label="单品折让" prop="saledt_dis_2" align="center" width="100" :show-overflow-tooltip="true" />
            <el-table-column label="小计" prop="saledt_amount" align="center" width="100" :show-overflow-tooltip="true" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="支付方式" name="second">
          <div style="max-width:700px;">
            <el-table :data="zhifuListData" border height="250" :summary-method="getSummaries" show-summary style="width: 100%;">>
              <el-table-column label="支付ID" prop="pay_id" :show-overflow-tooltip="true" />
              <el-table-column label="支付方式" prop="pay_id" :show-overflow-tooltip="true" :formatter="FormatPayname" />
              <el-table-column label="支付金额" prop="pay_amt" width="100" />
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="ticket-box">
      <ticket-template1 ref="histprintcom" name="buda" :ticketdata="ticket_data" v-show="show_ticketI"></ticket-template1>
    </div>
  </div>
</template>

<script>

import { pagePagination } from '@/utils/index.js'
import { parseTime } from '@/utils'
import ticketTemplate1 from '@/components/TicketTemplate/printsalesadd.vue'//销售补打
export default {
  components: {
    ticketTemplate1
  },
  filters: {
    parseTime
  },
  data() {
    return {
       rtnflag: false,//记录是否已经退货
       // 日期范围
      dateRange: [],
       // 显示搜索条件
      showSearch: true,
      filter_data: '',
      all_table_data: [],
      tableData: [],
      activeName: 'first',
      goods_data: [],
      //消费主表表格数据
      xiaofeiData: [],
      //消费明细表格数据
      xiaofeiListData: [],
      pos_sale: [],
      pos_sale_detail: [],
      sale_detail: [],
      // 支付方式表格数据
      zhifuListData: [],
      pos_sale_cur: [],
      pos_rec_gift: [],
      posrecgift: [],
      // 付款方式列表
      PayWay: [],
      ticket_data: {}, //小票数据
      show_ticketI: false,
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28
      },
     searchform: {
        sale_id: '',
        inv_date1: '',
      },
    }
  },
  computed: {
  },
  mounted() {
    // this.initData()
  },
  async created() {
    this.tableData=[{
      sale_date:parseInt(new Date().getTime()),
    }]
    this.xiaofeiListData=[{}]
    this.zhifuListData=[{}]
    this.getPay_way()
  },

  methods: {
  
      async initData() {
      this.pos_sale = await this.$localforage.getItem('t_pos_sale') || []
      this.pos_sale_detail = await this.$localforage.getItem('t_pos_sale_detail') || []
      this.pos_sale_cur = await this.$localforage.getItem('t_pos_sale_cur') || []
      this.pos_rec_gift = await this.$localforage.getItem('t_pos_rec_gift') || []//提单电子券
      this.goods_data = await this.$localforage.getItem('t_part') || []

      //需要根据单据类型过滤数据
      let subTime ,supTime
        subTime = this.dateRange[0];
        supTime = this.dateRange[1];
       if (this.dateRange.length==0){
          subTime=0
          supTime=4133865600000 //2100-12-31
       }
      let arr = this.pos_sale.filter(item =>
        item.bstate <= 1
        && item.sale_id.includes(this.searchform.sale_id)
        &&  subTime <= item.sale_date && item.sale_date <= supTime
      )
         //降序排列排序
      arr.sort(function(a,b){
			return b.sale_date - a.sale_date
		  })
      
      //标记是否退货
        let rtnflag= {rtnflag:1}
       arr.forEach(item => {
         let i = this.pos_sale.findIndex(v => v.sale_source_id === item.id ||item.sale_type == -1 )
        if (i>= 0){
           
          //  console.log('标记是否退货1',  item)
           Object.assign(item,rtnflag);

          // return this.$message.error(item.sale_id + ' 单据已退货，不能在退货')
          }
       
      })
 this.tableData = arr
   console.log('标记是否退货',  this.tableData)

      this.setCurrent(this.tableData[0])
    },


    setCurrent(row) {
      this.$refs.xiaofei.setCurrentRow(row);
    },
    async handleCurrentChange(val) {
      this.filterChange(val)
      //  console.log('xiaofeiListData', this.xiaofeiListData)
    },

    async filterChange(val) {
      console.log('handleCurrentChange', val.id, val.sale_id)
      if (val) {
        this.xiaofeiData = this.tableData.filter(f => f.id == val.id)
        this.sale_detail = this.pos_sale_detail.filter(f => f.pid == val.id)
        this.zhifuListData = this.pos_sale_cur.filter(f => f.pid == val.id)
        this.posrecgift = this.pos_rec_gift.filter(f => f.pid == val.id)
      } else {
        this.xiaofeiData = []
        this.sale_detail = []
        this.zhifuListData = []
        this.posrecgift = []
      }

      let arr = []
      this.sale_detail.forEach(item => {
        let i = this.goods_data.find(v => v.id === item.partid)
        let obj = {
          id:i.id,
          saledt_id: item.saledt_id,
          part_id: i.part_id ? i.part_id : '',
          part_name: i.part_name ? i.part_name : '',
          saledt_uprice: item.saledt_uprice,
          saledt_unit: item.saledt_unit,
          saledt_qty: item.saledt_qty,
          saledt_dis_1: item.saledt_dis_1,
          saledt_dis_2: item.saledt_dis_2,
          saledt_amount: item.saledt_amount,
          优惠: item.saledt_dis_2 + item.saledt_dis_1
        }
        arr.push(obj)
      })
      this.xiaofeiListData = arr
      console.log('xiaofeiListData', this.xiaofeiListData)

    },

    // 获取支付方式 获取消费群体类型
    async getPay_way() {
      let dictdata = await this.$localforage.getItem('t_sys_dict_data') || []
      let arr = dictdata.filter(i => i.dict_type == 'bus_pay_way')
      this.PayWay = arr.filter(i => !i.isdel)

    },
 /** 搜索按钮操作 */
    handleQuery() {
      this.initData();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.reset()
      this.handleQuery();
    },
    //表单重置
    reset() {
     this.searchform.sale_id=''
     this.dateRange=[]
    },

    // 部门字典翻译
    FormatPayname(row, column) {
      return this.selectDictLabellocal(this.PayWay, row.pay_id);

    },
    onFilterChange() {
      this.initData()
    },

    handlePrint(val) {
      //  var LODOP; //声明为全局变量 
      //  console.log('handlePrint',val)
      this.filterChange(val)

      //  // 打印小票
      let ticketdata = {
        has_data: true,
        销售主表: this.xiaofeiData[0],
        销售明细arr: JSON.parse(JSON.stringify(this.xiaofeiListData)),
        结算明细arr: JSON.parse(JSON.stringify(this.zhifuListData))
      }
      this.ticket_data = ticketdata
      // console.log('ticket_data',this.ticket_data)

      this.printLodop()
    },

    printLodop() {
      this.$refs.histprintcom.printTicket(this.ticket_data)

    },
 
    // 退货
    handleReturn(item) {
       console.log('退货', item)
      if (item.sale_type<0){return this.$message.error(item.sale_id + ' 是退货单，不能在退货')}
        let i = this.pos_sale.findIndex(v => v.sale_source_id === item.id)
        if (i>= 0){
          return this.$message.error(item.sale_id + ' 单据已退货，不能在退货')
          }
      this.filterChange(item)//切换到当前行.toFixed(2)
      //查找单据是否退货
      let t_pos_sale_detail = JSON.parse(JSON.stringify(this.xiaofeiListData))//明细
      let t_pos_sale_cur = JSON.parse(JSON.stringify(this.zhifuListData))//支付
      let t_pos_rec_gift = JSON.parse(JSON.stringify(this.posrecgift))//提单
      // let table = []
      t_pos_sale_detail.forEach(item => {
        item.count = item.saledt_qty
        // item.saledt_unit= item.saledt_unit
        item.part_price = item.saledt_uprice
        item.实际价格 = item.saledt_amount
      })
      // t_pos_sale_cur.forEach(item => {
      //   id: pay_id,
      //   name:dict_label,
      //   item.实际价格 = item.saledt_amount
      // })

      // this.PayWay

      this.$emit('returnGoods',
      {sale:item,
       sale_detail:t_pos_sale_detail,
       sale_cur:t_pos_sale_cur,
       rec_gift:t_pos_rec_gift,
       sale_rtntype:1,//按单退货
      }
      )
    },
     //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))
          && column.property != 'sale_id'
          && column.property != 'sale_date'
          && column.property != 'sale_type'
          && column.property != 'saledt_id'
          && column.property != 'part_id'
          && column.property != 'saledt_uprice'
          && column.property != 'pay_name'
          && column.property != 'api_type'
          && column.property != 'api_trade_no'
          && column.property != 'notes'
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
              // console.log('value',value,curr)
            if (!isNaN(value)) {
              //  console.log('prev + curr',Number(prev.toFixed(2)) , curr)
              return Number(prev.toFixed(2)) + Number(curr.toFixed(2)) ;
            } else {
              return Number(prev.toFixed(2));
            }
          }, 0);
         sums[index] = sums[index].toFixed(2) + '';
        } else {
          sums[index] = '';
        }
       
      });
      return sums;
    },
  }
}
</script>

<style lang="scss" scoped>
.lately-sale-box {
  width: 100%;
  height: 100%;
  .filter-box {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>