<template>
  <div style="margin-top:10px;width:100%;height:100%;">
    <el-tabs type="border-card" v-model="activeName" tab-position="left" style="width:100%;height:100%;" @tab-click="SetupDatacount">
      <el-tab-pane label="数据传输" name="数据传输">
        <el-button @click="GetupData">下载</el-button>
        <el-button @click="SetupData">上传</el-button>
        <!-- <el-button @click="resetStock">重置库存信息</el-button> -->
        <!-- <div style="margin-top: 10px;">
          <el-input v-model="request_url" :readonly="!is_edit_url"></el-input>
          <el-button type="primary" @click="is_edit_url = true" v-show="!is_edit_url">修改</el-button>
          <el-button type="success" v-show="is_edit_url" @click="changeBaseUrl">确认</el-button>
        </div> -->
         <div class="contn-box">
        <!-- <div style="margin-top:15px;">
          <h5>未上传数据</h5> -->
          <el-tabs v-model="ActiveNameUpload" style="height: 100%;" type="border-card"   @tab-click="handleClick">
          <el-tab-pane name="未上传数据" label="未上传数据">
          <el-table :data="tableData" border ref="tabledata" height="800">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="tableid" label="表明" width="180">
            </el-table-column>
            <el-table-column prop="tablename" label="表说明" width="200">
            </el-table-column>
            <el-table-column prop="upcount" label="未上传条数" width="100">
            </el-table-column>
          </el-table>
         </el-tab-pane>
         <el-tab-pane name="下载数据" label="下载数据" >
            <el-table :data="tableDatadown" border ref="tabledata" height="800">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="tableid" label="表明" width="180">
            </el-table-column>
            <el-table-column prop="tablename" label="表说明" width="200">
            </el-table-column>
             <el-table-column label="最近下载时间" prop="lastdowntime" sortable align="center" width="180">
                <template slot-scope="scope">{{ scope.row.lastdowntime===null? '' :scope.row.lastdowntime|parseTime }}</template>
              </el-table-column>
          </el-table>
            </el-tab-pane>
          </el-tabs>
        <!-- </div> -->
      </div>
      </el-tab-pane>
      <el-tab-pane label="系统设置" name="系统设置">
        <div class="contn-box">
          <el-tabs v-model="settingActiveName" style="height: 100%;" type="border-card">

            <el-tab-pane name="主页" label="主页">
               <peizhi-Info style="height: 100%;" />
            </el-tab-pane>
            <el-tab-pane name="打印设置" label="打印设置">
                <print-Set style="height: 100%;" />
            </el-tab-pane>
            <el-tab-pane name="广告设置" label="广告设置">
              <!-- <ku-Cun /> -->
            </el-tab-pane>
            <el-tab-pane name="参数设置" label="参数设置">
              <!-- <hui-Yuan /> -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
       <el-tab-pane label="数据管理" name="数据管理">
        <div class="contn-box">
          <el-tabs v-model="settingActiveName" style="height: 100%;" type="border-card">

            <el-tab-pane name="主页" label="主页">
              <!-- <peizhi-Info style="height: 100%;" /> -->
            </el-tab-pane>
            <el-tab-pane name="打印设置" label="打印设置">
              <!-- <ling-lin /> -->
            </el-tab-pane>
            <el-tab-pane name="广告设置" label="广告设置">
              <!-- <ku-Cun /> -->
            </el-tab-pane>
            <el-tab-pane name="参数设置" label="参数设置">
              <!-- <hui-Yuan /> -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  </div>
</template>

<script> 
import { getLocal, setLocal } from '@/utils/localstorage'
import { NowTime } from '@/utils'
import ticketTemplate from '@/components/TicketTemplate/printsales.vue'
import { parseTime } from '@/utils'
import peizhiInfo from './cpmponents/基础信息.vue'
import printSet from './cpmponents/打印设置.vue'
export default {
   filters: {
    parseTime
  },
  components: { 
    ticketTemplate, 
    peizhiInfo,
    printSet,
    },
  data() {
    const checkCoding = (rule, value, callback) => {
      const reg = /^[0-9]\d*$/g;
      if (value === '') {
        callback(new Error('登录账号不能为空'));
      } else {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error('登录账号为员工工号，应为纯数字'));
        }
      }
    }
    return {
      total: 0,
      activeName: '数据传输',
      settingActiveName: '主页',
      ActiveNameUpload: '未上传数据',
      tableData: [],
      tableDatadown: [],
      user: {
        coding: this.$store.state.user.currUser.tenid,
        password: '',
        username: this.$store.state.user.currUser.user,
      },
      checkUser: {
        coding: { required: true, validator: checkCoding, trigger: 'blur' },
        password: { required: true, message: '登录密码不能为空', trigger: 'blur' },
        username: { required: true, message: '用户名不能为空', trigger: 'blur' },
      },
      shopBaseMsg: {},
      request_url: '',
      is_edit_url: false,
      ticket_list: [],
      
    }
  },
  mounted() {
    let msg = getLocal('shopBaseMsg')
    this.shopBaseMsg = msg
    this.request_url = msg.baseAddr
    this.geiTicketTemplate()
    this.SetupDatacount()//上传数据统计
  },


  async created() {
    // let updateTime = await this.$localforage.getItem('t_pos_lastdowndatatime')||[]
    // this.tableData=updateTime
  },

  methods: {

       handleClick(tab, event) {
        console.log(tab.index, event);
       switch (tab.index) {
     case '0': //处理基本信息
     
          break;
      case '1': //处理充值
      this.SetdownDatacount()
          break;
      case '2': //处理消费
      
      }
     },


    async GetupData() {
      // this.$refs.login.validate(async (valid) => {
      //   if (valid) {
      this.user.password = new Buffer(this.$store.state.user.currUser.passBase, 'base64').toString();
      let flag = true
      let res = await this.$store.dispatch('user/Login', this.user)
      if (res.data.token) {
        // this.$refs['login'].resetFields();
        // 获取数据
        this.getData()
        flag = false
      }
      if (flag) {
        this.$message.error('用户名或密码错误');
      }
    },
    // 下载数据
    async getData() {
      await this.getTableUpdateTime() //后台下载获得t_pos_lastdowndatatime数据
      let updateTime = await this.$localforage.getItem('t_pos_lastdowndatatime') || []
      let news
      //下载数据
      for (const item of updateTime) {
        // partTime = item.lastdowntime
        if (item.isstop == 0 && item.isupdown == 1 && item.ismore == 0) {  //使用、下载、单表
          let res = await this.$http({ url: item.url, method: item.method, data: { uct: item.lastdowntime } })
          if (res.statusCode != 200) return this.$message.error("数据下载失败")
          news = res.data
          // if(JSON.stringify(obj) === '{}'){return true;}
          if(JSON.stringify(news) === '[]'){continue}

          item.lastdowntime = parseInt(new Date().getTime())
          //  console.log(item.tableid,news, item.lastdowntime);
          //获取数据后存入indexdDB中
          let olds = await this.$localforage.getItem(item.tableid) || []
          news = this.replaceData(news, olds)
          this.$localforage.setItem(item.tableid, news)
          console.log('下载数据updateTime', item.lastdowntime )
          this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)//下载完成后更新t_pos_lastdowndatatime表当前数据下载时间
        }
        //报货特殊处理
        if (item.isstop == 0 && item.isupdown == 1 && item.ismore == 1) { //使用、下载、多表&& item.tableid == 't_inv_bill'
          let deptid = getLocal('companyInfo').id
          let data = {
            "uct": item.lastdowntime,
            "deptid": deptid
          }
          let res = await this.$http({ url: item.url, method: item.method, data })
          //  let res = await this.$http({ url: '/business/inventbill/posneed', method:'post', data})
          if (res.statusCode != 200) return this.$message.error("数据下载失败")
          let 下载主表 = res.data.master
          let 下载明细表 = res.data.detail
           if(JSON.stringify(下载主表) === '[]'||JSON.stringify(下载明细表) === '[]'){ continue }
          item.lastdowntime = parseInt(new Date().getTime())
          //获取数据后存入indexdDB中
          let 本地主表 = await this.$localforage.getItem(item.tableid) || []
          let 本地明细表 = await this.$localforage.getItem(item.tableids) || []
          let 更新主表 = this.replaceData(下载主表, 本地主表)
          let 更新明细表 = this.replaceData(下载明细表, 本地明细表)
          this.$localforage.setItem(item.tableid, 更新主表)
          this.$localforage.setItem(item.tableids, 更新明细表)
          console.log('下载数据多表', item.lastdowntime)
          this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)//下载完成后更新t_pos_lastdowndatatime表当前数据下载时间
        }
      }
      // this.$localforage.setItem('t_pos_lastdowndatatime', updateTime)
      // this.$store.dispatch('user/获取结存表')
      this.$store.dispatch('user/getUsers')
      this.$store.dispatch('app/getSys_dept')
      this.$store.dispatch('app/getT_wrhus')
      this.$store.dispatch('app/getYhqy')
      this.$store.dispatch('app/getPosinvop')//出入库单据类型
      this.$store.dispatch('app/getinvop')//出入库单据类型
      this.$store.dispatch('app/getDictdata')//公共数据
      this.$message.success('数据下载完成')
      await this.SetdownDatacount()
    },
    // 未上传数据统计
    async SetupDatacount() {
      let arr = await this.$localforage.getItem('t_pos_lastdowndatatime')
      let updateTime = arr.filter(item => item.isupdown == 2) // item.isstop == 0 && 
      //上传数据
      for (let i = 0; i < updateTime.length; i++) {
        this.total = 0
        let updata = await this.$localforage.getItem(updateTime[i].tableid) || []
        let 上传数据 = []

        updata.forEach((item, index) => {
          if (item.tstate==1  ) {
            this.total += 1
          }
        })
        updateTime[i].upcount = this.total
      }
      this.tableData = updateTime
    },

    // 下载数据
    async SetdownDatacount() {
    this.tableDatadown = await this.$localforage.getItem('t_pos_lastdowndatatime')|| []
    },


    // 同步数据(定单)上传
    async SetupData() {
      let updateTime = await this.$localforage.getItem('t_pos_lastdowndatatime')
      //上传数据
       console.log('updateTime',updateTime)
      for (const item of updateTime) {
        if (item.isstop == 0 && item.isupdown == 2 ) { //使用、上传、单表
          let updata = await this.$localforage.getItem(item.tableid) || []
          console.log('上传数据', updata, item.tableid)
          let 上传数据 = []
          updata.forEach(i => {
            if (i.tstate==1) {
              上传数据.push(i)
              // i.tstate = 9//上传完成
            }
          })

          if (上传数据.length > 0) {
            let 主表data = { "jsons": { id:item.tableid,data: 上传数据 } }
            console.log('上传数据', item, 上传数据)
              let res = await this.$http({ url: '/pos/up', method: 'post', data: 主表data })
            // let res = await this.$http({ url: item.url, method: item.method, data: 主表data })
            if (res.statusCode != 200) return this.$message.error(item.tablename +"数据上传失败")

             updata.forEach(i => {
                if (i.tstate==1) {
                 i.tstate = 9//上传完成
                }
             })
            await this.$localforage.setItem(item.tableid, updata)
          }
        }
      
      }
      this.$message.success('数据上传完成')
      await this.SetupDatacount()
     



    },
    async getTableUpdateTime() {
      const data = {
        uct: getLocal('lastGettime') || 0,
      }
      let res = await this.$http({ url: '/pos/down/lastdowndatatime', method: 'post', data: data })
      let updateTable = res.data
      console.log('lastdowndatatime', updateTable)
      //获取数据后存入indexdDB中
      let oldtime = await this.$localforage.getItem('t_pos_lastdowndatatime') || []
      let newTime = this.replaceData(updateTable, oldtime)
      this.$localforage.setItem('t_pos_lastdowndatatime', newTime)
      setLocal('lastGettime', parseInt(new Date().getTime()))
    },
   
    // 更新本地数据
    replaceData(newData, oldData) {
      if (oldData.length == 0) return newData
      if (newData.length == 0) return oldData

      newData.forEach(n => {
        let i = oldData.findIndex(o => o.id == n.id)
        if (i == -1) {
          oldData.push(n)
        } else {
          oldData[i] = JSON.parse(JSON.stringify(n))
        }
      });
      return oldData
    },
    // 重置库存数据
    async resetStock() {
      // 门店结存表初始化
      const data = {
        uct: 0,
      }
      // let res = await this.$http({ url: '/pos/down/part', method: 'post', data })
      let goods = await this.$localforage.getItem('t_part') || []
      if (goods.length <= 0) return this.$message.error('商品信息丢失,重新下载商品信息')
      // let goods = res.data
      let seavetime = parseInt(new Date().getTime())
      let 商品结存 = []
      let u = this.$store.state.user.currUser
      goods.forEach(item => {
        let obj = {
          id: item.id,
          tenid: item.tenid,
          lct: seavetime,
          lby: u.id,
          part_name: item.part_name, // 品名
          tstate: 0,
          dept_id: u.dept_id,
          partid: item.id, // 物料系统id
          part_id: item.part_id, // 品号
          region_id: item.r_store,
          bal_date: seavetime,
          bal_amnt: 0, //库存
          bal_cost: 0, //价值
          sales_volume: 0,//销量
          part_usertype: item.part_usertype,//商品类型
          partusertype: item.partusertype,//商品类型
          part_price: item.part_price,
          part_model: item.part_model,
          part_specs: item.part_specs
        }
        商品结存.push(obj)
      })
      await this.$localforage.setItem('t_pos_inv_bal', 商品结存)
    },
    // 修改请求地址
    changeBaseUrl() {
      console.log(this.request_url);
      this.shopBaseMsg.baseAddr = this.request_url
      setLocal('shopBaseMsg', this.shopBaseMsg)
      this.is_edit_url = false
    },
    // 获取小票模板
    async geiTicketTemplate() {
      let res = await this.$localforage.getItem('t_pos_print_template') || []
      console.log(res);
      this.ticket_list = res
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket {
  display: flex;
  background-color: #f1f1f1;
  padding: 10px 15px;
  .ticket-item {
    width: 400px;
    margin-right: 10px;
  }
}
.contn-box {
  width: 100%;
  height: 100%;
  padding: 15px;
}
</style>


