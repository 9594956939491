<template>
  <el-collapse-transition>
    <div v-show="show" class="keybord_box" :style="{right:right}">
      <div class="box">
        <div class="row">
          <el-input class="input" readonly v-model="input_number"></el-input>
        </div>
        <div class="row">
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('1')" class="key-item">1</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('2')" class="key-item">2</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('3')" class="key-item">3</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnback" class="key-item"><i class="el-icon-back" style="font-size:18px;"></i></div>
        </div>
        <div class="row">
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('4')" class="key-item">4</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('5')" class="key-item">5</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('6')" class="key-item">6</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="close" class="key-item">取消</div>
        </div>
        <div class="row">
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('7')" class="key-item">7</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('8')" class="key-item">8</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('9')" class="key-item">9</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('0')" class="key-item">0</div>
        </div>
        <div class="row">
          <div class="queding" @mousedown="ondown" @mouseup="onup" @click="confirm">确 定</div>
          <div @mousedown="ondown" @mouseup="onup" @click.stop="btnClick('.')" class="key-point">.</div>
        </div>
      </div>
    </div>
  </el-collapse-transition>
</template>

<script>
export default {
  props:{
    right:{
      type:String,
      default:'50%'
    }
  },
  data() {
    return {
      show: false,
      input_number: ''
    }
  },
  methods: {
    btnClick(num) {
      if (num == '0') {
        if (this.input_number.length > 0) {
          this.input_number += num
        }
      } else if (num == '.') {
        if (this.input_number.length == 0) {
          this.input_number = '0.'
        } else {
          let i = this.input_number.indexOf('.')
          if (i == -1) this.input_number += '.'
        }
      }else {
        this.input_number += num
      }
    },
    // 退格键
    btnback() {
      if (this.input_number.length == 0) return
      this.input_number = this.input_number.substr(0, this.input_number.length - 1)
    },
    open(){
      this.show = true
    },
    // 取消
    close() {
      this.show = false
      this.input_number = ''

    },
    // 确定
    confirm() {
      this.$emit('input-num', this.input_number)
      this.input_number = ''
      this.show = false
    },
    ondown(e) {
      e.target.classList.add('keyclick')
    },
    onup(e) {
      e.target.classList.remove('keyclick')
    },
  }
}
</script>

<style lang="less" scoped>
.keybord_box {
  position: fixed;
  width: 310px;
  z-index: 999;
  bottom: 55px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.hiddn {
  display: none;
}
.box {
  width: 100%;
  height: 100%;
  padding: 5px;
  .row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    .queding {
      height: 50px;
      flex: 1;
      margin-right: 5px;
      line-height: 50px;
      text-align: center;
      border: 1px solid #ccc;
      font-size: 18px;
      border-radius: 5px;
      cursor: pointer;
    }
    .key-point {
      cursor: pointer;
      width: 70px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #ccc;

    }
    .input {
      margin-right: 5px;
    }
    .key-item {
      cursor: pointer;
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }
}

.keyclick {
  border: 1px solid #d9ecff;
  color: #208bf5;
  background-color: #aac9e7;
  font-weight: 600;
}
</style>