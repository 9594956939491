<template>
  <div>
    <div class="table-up">
      <el-table :data="tableData" stripe style="width: 100%"  ref="Jifen"   border height="400">
        <el-table-column prop="redeem_id" label="兑换流水号"  align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="cardid" label="卡号"  align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="date" label="兑换时间" align="center" ></el-table-column>
        <el-table-column prop="redeem_old_score" label="兑换前积分" align="center" ></el-table-column>
        <el-table-column prop="redeem_score" label="兑换使用积分" align="center" ></el-table-column>
        <el-table-column prop="lby" label="操作人" align="center" ></el-table-column>
        <el-table-column prop="pos_id" label="POS机号"  align="center" ></el-table-column>
        <el-table-column prop="strategy_score_id" label="兑换策略号"  align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="date" label="兑换策名称" align="center" ></el-table-column>
      </el-table>
    </div>
    <div class="mingxi">
      <div class="tit">
        <div class="tit-item">兑换明细</div>
      </div>
      <div class="table-box">
        <el-table :data="mingxiTableData" border stripe style="width: 100%"  height="200">
          <el-table-column prop="sale_id" label="销售单号" align="center"  :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="date" label="明细编号" align="center" ></el-table-column>
          <el-table-column prop="date" label="品号" align="center" ></el-table-column>
          <el-table-column prop="date" label="品名" align="center" ></el-table-column>
          <el-table-column prop="date" label="单位"  align="center" ></el-table-column>
          <el-table-column prop="date" label="价格" align="center" ></el-table-column>
          <el-table-column prop="date" label="数量" align="center" ></el-table-column>
          <el-table-column prop="date" label="折扣"  align="center" ></el-table-column>
          <el-table-column prop="date" label="折让"  align="center" ></el-table-column>
          <el-table-column prop="date" label="金额"  align="center" ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import 会员api from '../api.js'
import { parseTime } from '@/utils'
export default {
filters: {
    parseTime
  },
  data() {
    return {
      tableData: [],
      mingxiTableData:[],
       // 查询参数
      queryParams: {
        offset: 1,
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        cardid: '',
      },

    }
  },
  methods: {
 /** 查询会员积分兑换 */
      async getcardscoreredeem(id) {
      this.loading = true;
      this.queryParams.cardid=id
      let [err, res] = await 会员api.查询会员积分兑换(this.queryParams)
      if (err) {
        return this.$message.error('查询会员积分兑换失败')
      }
       this.tableData = res.data.rows;
       this.loading = false;  
    },

      //合计
       getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计:';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' ';
          } else {
            sums[index] = 'N/A';
          }
        });

        return sums;
      },

   },

}
</script>

<style lang="less" scoped>
.mingxi {
  margin-top: 10px;
  .tit {
    display: flex;

    .tit-item {
      background-color: #f5f5f5;
      color: #333;
      padding: 5px;
      border: 1px solid #ccc;
      border-bottom: none;
    }
  }
}
</style>