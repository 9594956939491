<template>
  <div class="ticket">
    <!-- 结账单 -->
    <div class="ticket-item" style="width:400px;">
      <div class="bill-preview">
        <div class="detail ticket-box" id="ticket-box" style="padding: 0 17px;font-size: 12px;">
          <h5 style="text-align: center;font-size: 16px;">结账单</h5>
          <h5 style="text-align: center;padding:15px 0;border-bottom: 1px solid #333;font-size: 16px;">{{companyInfo.公司名称+'('+companyInfo.名称+')'}}</h5>
          <div style="margin-top:8px;margin-bottom:8px; text-align:right;"> 
              <span>——</span><span style="margin-left:6px;">{{saletypename}}</span>
            </div>
          <div style="font-size: 12px;border-bottom: 1px solid #333;">
            <!-- <div style="margin-top: 8px;">
              <span>桌号:</span>
              <span style="margin-left:8px;">{{sale_msg.rep_counter}}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>人数:</span>
              <span style="margin-left:8px;">{{sale_msg.rep_counter_ple}}</span>
            </div> -->
            <div style="margin-top: 8px;margin-bottom:8px;">
              <span>单号:</span>
              <span style="margin-left:8px;">{{sale_msg.sale_id}}</span>
            </div>
          </div>
          <div style="border-bottom: 1px solid #333;">
            <div style="margin-top: 8px;">
              <div style="margin-bottom:8px;display:flex;justify-content: flex-start;">
                <span style="font-size:12px;flex:0 0 100px;">名称</span>
                <span style="font-size:12px;flex:1;text-align:center;">单价</span>
                <span style="font-size:12px;flex:1;text-align:center;">数量</span>
                <span style="font-size:12px;flex:1;text-align:center;">金额</span>
                <span style="font-size:12px;flex:1;text-align:center;">优惠</span>
              </div>
            </div>
            <div style="margin-top: 8px;">
              <div v-for="item in goods_data" :key="item.id">
                <div style="margin-bottom:8px;display:flex;justify-content: flex-start;">
                  <span style="font-size:12px;flex:0 0 100px;">{{item.part_name}}</span>
                  <span style="font-size:12px;flex:1;text-align:center;">{{item.saledt_uprice}}</span>
                  <span style="font-size:12px;flex:1;text-align:center;">{{item.saledt_qty}}</span>
                  <span style="font-size:12px;flex:1;text-align:center;">{{item.saledt_amount}}</span>
                  <span style="font-size:12px;flex:1;text-align:center;">{{item.优惠}}</span>
                </div>
              </div>
            </div>
          </div>

             <div style="font-size: 12px;border-bottom: 1px solid #333;">
            <div style="margin-top: 8px;">
              <span>整单折扣:</span>
              <span style="margin:8px;text-align:center;">{{sale_msg.sale_dis_1}}</span>
              <span>整单折让:</span>
              <span style="margin:8px;text-align:center;">{{sale_msg.sale_dis_2}}</span>
            </div>
            <div style="margin:8px;text-align:right;">
              <span>应收: </span><span style="margin-left:6px;">{{sale_msg.sale_amount}}</span>
            </div>
          </div>

          <div style="font-size: 12px;border-bottom: 1px solid #333;">
            <div style="margin-top: 8px;" v-for="item in pay_way" :key="item.id">
              <span>{{ getPayWay(item.pay_id)}}:</span>
              <span style="margin-left:8px;">{{item.pay_amt}}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>找零:</span>
              <span style="margin-left:8px;">{{sale_msg.sale_again}}</span>
            </div>
             <div v-show="sale_msg.sale_more!=0" style="margin-top: 8px;">
              <span>溢收:</span>
              <span style="margin-left:8px;">{{sale_msg.sale_more}}</span>
            </div>
            <div style="margin:8px;text-align:right;">
              <span>实收: </span><span style="margin-left:6px;">{{sale_msg.sale_rece}}</span>
            </div>
          </div>
          <div style="font-size: 12px;border-bottom: 1px solid #333;">
            <div v-show="sale_msg.cardid>0" style="margin-top: 8px;">
              <span>卡号:</span>
              <span style="margin-left:8px;">{{sale_msg.card_id_out}}</span>
            </div>
            <div v-show="sale_msg.cardid>0" style="margin-top: 8px;">
              <span>余额:</span>
              <span style="margin-left:8px;">{{sale_msg.card_remoney}}</span>
            </div>
            <div v-show="sale_msg.cardid>0" style="margin-top: 8px;">
              <span>积分:</span>
              <span style="margin-left:8px;">{{sale_msg.card_score}}</span>
            </div>
          </div>
          <div style="font-size: 12px;">
            <div style="margin-top: 8px;">
              <span>地址:</span>
              <span style="margin-left:8px;">{{companyInfo.addr}}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>电话:</span>
              <span style="margin-left:8px;">{{companyInfo.tel}}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>制单人:</span>
              <span style="margin-left:8px;">{{currUser.name}}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>时间:</span>
              <span style="margin-left:8px;">{{new Date().getTime() | parseTime}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLocal } from '@/utils/localstorage'
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
import { getLodop } from '@/assets/LodopFuncs' //导入模块
export default {
  props: {
    ticketdata: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
  },
  filters: { parseTime },
  watch: {
    ticketdata: {
      handler(n) {
        if (n.has_data) {
          this.showData()
        }
      },
      deep: true
    },
     
  },
  data() {
    return {
      saletypename:'',
      companyInfo: {},
      goods_data: [],
      sale_msg: {},
      pay_way: [],
      dictdata: {}
    }
  },
  computed: {
    ...mapGetters('user', ['currUser'])
  },
  async mounted() {
    let companyInfo = getLocal('companyInfo')
    this.companyInfo = companyInfo
    let sys_dept = await this.$localforage.getItem('sys_dept') || []
    let cur_sys_dept = sys_dept.filter(i => i.id == this.companyInfo.id)
    if (cur_sys_dept.length>0){
    Object.assign(this.companyInfo, {addr:cur_sys_dept[0].addr,tel:cur_sys_dept[0].tel})
    console.log('this.cur_sys_dept',this.companyInfo);
    }else{
     Object.assign(this.companyInfo, {addr:'',tel:''})
    }
    this.dictdata = await this.$localforage.getItem('t_sys_dict_data') || []
  },
  methods: {
    printTicket() {
      var LODOP; //声明为全局变量 
      LODOP = getLodop();
      LODOP.PRINT_INIT(this.ticketdata.销售主表.sale_id);
      LODOP.ADD_PRINT_HTML(0, 0, '100%', '100%', document.getElementById('ticket-box').innerHTML);
       // 打印小票
        if (getLocal('shopBaseMsg').salepreview==0){
          LODOP.PREVIEW();
        }else{
          LODOP.PRINT();
        }
   
    },
    async showData() {
      this.goods_data = this.ticketdata.销售明细arr //arr
      this.sale_msg = this.ticketdata.销售主表
        if(this.sale_msg.sale_type== 1){
        this.saletypename='销售'
        }else if(this.sale_msg.sale_type== -1){
        this.saletypename='退货'
        }
      this.pay_way = this.ticketdata.结算明细arr
      console.log(' this.pay_way', this.pay_way);
    },



    getPayWay(id) {
      if (!id) {
        return ''
      }
      let arr = this.dictdata.filter(i => i.dict_type == 'bus_pay_way')
      this.PayWay = arr.filter(i => !i.isdel)
      let way = this.PayWay.find(v => v.dict_value === id)
      if (way) {
        return way.dict_label
      }
      return '付款'
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket {
  display: flex;
  background-color: #f1f1f1;
  padding: 10px 15px;
  width: 400px;
}
</style>