<template>
  <div >
       <div class="table-box">
          <!-- <el-button style="float: right;margin-right: 10px;" size="mini" @click="jiesuan" type="success"> <i class="el-icon-refresh"></i> 结算</el-button> -->
      <el-table v-loading="loading" :data="tableList" ref="xiaofei" border highlight-current-row  @current-change="handleCurrentChange" @row-dblclick="handleRowDblclick" >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="card_id_out" label="卡号"  sortable width="150"/>
        <el-table-column prop="card_name" label="名称"  sortable width="160"/>
        <el-table-column prop="card_mobile" label="手机号"  sortable width="130"/>
        <el-table-column prop="card_remoney" label="余额"  sortable width="100"/>
          <el-table-column prop="card_score" label="积分"  sortable width="100"/>
            <el-table-column prop="card_dis_rate" label="折扣"  sortable width="80"/>
             <el-table-column prop="card_state" label="状态"  sortable width="80"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleSelect(scope.row)"
          >选择</el-button>
        </template>
      </el-table-column>
      </el-table>
          <!-- <pagination v-show="total>0" :total="total" :page.sync="querypaging.pageNum" :limit.sync="querypaging.pageSize" @pagination="getList" /> -->
    </div>
  </div>
</template>
<script>
// import {listsyslist} from "@/api/business/print/print_sys";
// import vueEasyPrint from "vue-easy-print";
// import invFahuodan from "./template/发货单模板.vue";
// import invPeihuodan from "./template/配货单模板.vue";
export default {
  //  components: {
  //   invFahuodan ,
  //   invPeihuodan,
  //   vueEasyPrint ,

  //   },
  props: {
    data: {
      type: [Object,String,Array,Number],
      default() {
        return {};
      },
    },
  },
   watch: {
    data: {
      handler(n) {
        console.log('n',n)
        this.tableList=n
        setTimeout(() => {
       this.setCurrent(this.tableList[0])
      }, 500)
        
      },
      deep: true,
      immediate: true
    },
  },
   data() {
  return {
    tableList:[],
    selectCom:'',
    show_ticket:false,
    billData: [],
    //遮罩层
    loading:false,
    // 总条数
    total: 0,
    //分页步长
    querypaging: {
    pageNum:0,
    pageSize: 10,
  },
  }
   },
created() {
  
},
 methods: {
   handleSelect(val) {
      console.log('val', val)
       this.$emit('cardselect',val)
},
  setCurrent(row) {
    console.log('setCurrent111111', row)
    this.$refs.xiaofei.setCurrentRow(row);
  },
async handleRowDblclick(val) {
      this.$emit('cardselect',val) 
},
async handleCurrentChange(val) {
      // console.log('val', val)
    },
}
}
</script>
