<template>
  <div class="login-container">
    <div class="login-wrap" />
    <el-form v-show="!hasBaseData" ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <el-card class="box-card">
        <div class="title-container">
          <h3 class="title">收银系统</h3>
        </div>
      
        <el-form-item  prop="round">
         <el-select v-model="loginForm.banci" placeholder="请选择班次" style="width:100%;">
            <el-option v-for="item in ShopRound" :key="item.id" :label="item.dict_label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
             
        <el-form-item prop="user">
          <el-select v-model="loginForm.user" placeholder="请选择登录用户" style="width:100%;" value-key="id" @change="selectUser">
            <el-option v-for="item in userList" :key="item.id" :label="item.user+' | '+ item.name" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name">
          <el-input ref="username" v-model="loginForm.name" placeholder="姓名" @focus="setNowInput('name')"  type="text" auto-complete="off" :readonly="hasBaseData" />
        </el-form-item>
        <el-form-item prop="password">
          <span class="svg-container">
          </span>
          <el-input :key="passwordType" ref="password" v-model="loginForm.password" @focus="setNowInput('password')" :type="passwordType" placeholder="密码" name="password" tabindex="2" autocomplete="on"
            @keyup.enter.native="handleLogin" />
        </el-form-item>
        <el-button :loading="loading" type="primary" style="width:100%;font-size:16px;margin-bottom:30px;" @click.native.prevent="handleLogin">登 录</el-button>
        <div class="key-board">
          <key-board ref="keyboardRef" @input-text="onKeyInput"></key-board>
        </div>
      </el-card>
    </el-form>
    <register v-show="hasBaseData" @canlogin="canlogin" />
  </div>
</template>

<script>
import { getLocal, setLocal } from '@/utils/localstorage'
import { getpass } from '@/utils/login'
import register from './register'
import KeyBoard from '@/components/Keyboard.vue'
import { mapGetters } from 'vuex'
export default {
  components: { register, KeyBoard},
   computed: {
    ...mapGetters('app', [ 'dictdata'])
  },
  name: 'Login',
  data() {
    return {
      //班次
      ShopRound:[],
      hasBaseData: false,
      userList: [],
      loginForm: {
        banci: '',
        user: '',
        name: '',
        password: ''
      },
      loginRules: {
        banci: [{ required: true, trigger: 'blur', message: '请选择班次' }], //, validator: validateUsername
        name: [{ required: true, trigger: 'blur', message: '请输入用户名' }], //, validator: validateUsername
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]//, validator: validatePassword
      },
      loading: false,
      passwordType: 'password',
      redirect: '/',
      now_input:''
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  async created() {
     console.log('bus_shop_round')
     let dictdata = await this.$localforage.getItem('t_sys_dict_data') || []
      let arr = dictdata.filter(i => i.dict_type == 'bus_shop_round')
      this.ShopRound = arr.filter(i => !i.isdel)
       console.log('bus_shop_round', this.ShopRound,this.dictdata)

    let list = await this.$localforage.getItem('sys_user')
    if (!list) return this.hasBaseData = true
    this.$store.commit('user/setUsers', list)
    this.hasBaseData = false
    this.getUser()

  },
  methods: {
    selectUser(v) {
      this.loginForm.name = v.name
      this.loginForm.user = v.user
    },
    handleLogin() {
      const users = this.$store.state.user.users.userList;
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          let flag = true
          for (let i = 0, len = users.length; i < len; i++) {
            if (users[i].user === this.loginForm.user) {
              let salt = users[i].salt
              if (users[i].pass === getpass(this.loginForm.password, salt)) {
                // 将当前用户信息更改为users[i]
                let loginTime = new Date().getTime()
                this.$store.commit('user/setCurrUser', users[i]);
                const key = "passBase";
                const value = new Buffer(this.loginForm.password).toString('base64');
                Reflect.set(users[i], key, value)
                setLocal('currUser', users[i])
                setLocal('logintime', loginTime)
                let arr = this.ShopRound.filter(i => i.id == this.loginForm.banci)
                console.log(arr)
                setLocal('classesType',arr[0])
                this.$message.success('登录成功');
                this.$store.commit('user/changeloginFlag', false)
                flag = false
                let url = sessionStorage.getItem('currentPage') || '/'
                  //  this.$router.push(url)
                // this.$router.push('/openinitial')
                  this.openinitial(url)
              }
            }
          }
          if (flag) {
            this.$message.error('用户名或密码错误');
          }

        } else {
          this.$message.error('表单验证失败');
        }
      })
    },
    //开市
     async openinitial(url) {

      //   let 日结主表 = await this.$localforage.getItem('t_pos_settlement') || []//日结主表
      //   let max_result=Math.max.apply(Math,日结主表.map(item => { return item.settle_bgn_date}))// 最大值 
      //   if (isFinite(max_result)){
      //     let curr_日结主表 = 日结主表.filter(f => f.settle_bgn_date == max_result)
      //   }

      //   // var sString= "2021-04-02 00:00:00";   
      //   // var dTime = parseInt(new Date(sString).getTime());
      //   let ldtdate= this.NowTime()
      //   // let fatdate=this.formatConversion(ldtdate)
   
      //   let fatdate1=this.getNowFormatDate(ldtdate)//获取当前时间，格式YYYY-MM-DD
      // console.log('Date',ldtdate,fatdate1)


        let Settlement = getLocal('Settlement')||[]//开市数据
        let NowFormatDate=this.getNowFormatDate(this.NowTime())//获取当前时间，格式YYYY-MM-DD
      
        // console.log('Settlement',2,Settlement.length)
        if (Settlement.length==0){ //没有日结数据
                console.log('Settlement','没有开市数据，进入钱箱初始化')
                this.$router.push('/openinitial')//钱箱初始化 开市 
          }else{
            //  console.log('Settlement',3)
              let settle_bgn_date=this.getNowFormatDate(this.formatConversion(Settlement.settle_bgn_date))//时间戳转化成格式时间
              if (Settlement && Settlement.settle_bstate>0 && NowFormatDate!=settle_bgn_date){//已日结
                   console.log('Settlement','今天时间已日结但开市时间不是今天，进入钱箱初始化')
                  this.$router.push('/openinitial')//钱箱初始化  
              }else if (Settlement && Settlement.settle_bstate>0 && NowFormatDate==settle_bgn_date){//未日结
                //  if(){//是否跨天
                 console.log('Settlement','今天时间已日结可以直接进入')
                  this.$router.push(url)
                // if (NowFormatDate!=settle_bgn_date){
                //     //日结上期
                //     this.$router.push('/openinitial')//钱箱初始化  
                //    }else{
                //       this.$router.push(url)
                //    }


                  //  }else{
                  //    this.$router.push(url)
                  // }
              }else  if (Settlement && Settlement.settle_bstate==0 && NowFormatDate!=settle_bgn_date){
                 console.log('Settlement','今天时间没有日结，开市时间不是今天')
                   this.$router.push('/openinitial')//钱箱初始化  
              }else  if (Settlement && Settlement.settle_bstate==0 && NowFormatDate==settle_bgn_date){
                 console.log('Settlement','今天时间没有日结，开市时间是今天')
                   this.$router.push(url) 
              }
          }
       

      //  let Settlement = getLocal('Settlement')||[]//开市数据


      //  if (Settlement && Settlement.settle_bstate>0){//已日结
      //       console.log('Settlement',0,Settlement)
      //       this.$router.push('/openinitial')//钱箱初始化  
      //  }else{//未日结
      //    //是否跨天
      //     if (!Settlement) //没有日结数据
      //         {
      //           console.log('Settlement',1,Settlement)
      //           this.$router.push('/openinitial')//钱箱初始化  
      //         }else{
      //           let NowFormatDate=this.getNowFormatDate(this.NowTime())//获取当前时间，格式YYYY-MM-DD
      //           let settle_bgn_date=this.getNowFormatDate(this.formatConversion(Settlement.settle_bgn_date))//时间戳转化成格式时间
      //           console.log('Settlement',2,Settlement,NowFormatDate,settle_bgn_date)
      //           //  console.log('Settlement',2,Settlement,NowFormatDate,settle_bgn_date)
      //           if (NowFormatDate!=settle_bgn_date){
      //             //日结上期
      //             this.$router.push('/openinitial')//钱箱初始化  
      //             }else{
      //                 this.$router.push(url)
      //             }
      //       }
      //  }



      //   let 日结主表 = await this.$localforage.getItem('t_pos_settlement') || []//日结主表
      //    console.log('日结主表',日结主表)
      //   let max_result=Math.max.apply(Math,日结主表.map(item => { return item.settle_bgn_date}))// 最大值 
      // //  let result=Math.min.apply(Math,单据列表.map(item => { return item.shift_date }))// 最小值 
      //   if (isFinite(max_result)){
      //       let curr_日结主表 = 日结主表.filter(f => f.settle_bgn_date == SettleBgnDate)
      //       console.log('result',max_result,curr_日结主表)
      //       if(curr_日结主表.length>0 && curr_日结主表[0].bstate==1){//有结市数据
      //         this.$router.push('/openinitial')//钱箱初始化
      //       }else{
      //         console.log('有数据')
      //         this.$router.push(url)
      //       }  
      //   }else{
      //       this.$router.push('/openinitial')//钱箱初始化
      //              console.log('没有数据',isFinite(max_result))
      //   }
     },

    
    async getUser() {
      let list = await this.$localforage.getItem('sys_user')
      let arr = []
      list.forEach(el => {
        let o = {
          id: el.id,
          user: el.user,
          name: el.name
        }
        arr.push(o)
      });
      this.userList = arr
    },
    canlogin() {
      this.hasBaseData = false
      this.getUser()
    },
    setNowInput(v){
      this.now_input = v
      this.$refs.keyboardRef.reset()
    },
    onKeyInput(v){
      this.loginForm[this.now_input] = v
    }
  }
}
</script>

<style scoped>
.login-container {
  position: relative;
  background-size: 100%;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.login-wrap {
  /* background: linear-gradient(135deg, #002329, #01596e); */
  background-color: #01596e;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
}
.box-card {
  background: rgba(255, 255, 255, 0.4);
  border: 0;
}
.login-form {
  position: relative;
  width: 611px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.title-container {
  position: relative;
}
.title {
  font-size: 28px;
  color: #fff;
  margin: 10px auto 30px auto;
  text-align: center;
  font-weight: bold;
}
</style>

