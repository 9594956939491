<template>
  <div style="padding:10px 15px;width:100%;" >
    <div class="btn">
      <el-button size="mini" type="success" :disabled="is_look" @click="seave(0)">保 存</el-button>
      <el-button size="mini" type="primary" :disabled="is_look" @click="seave(1)">确 认</el-button>
      <el-button size="mini" type="primary"  @click="handlePrint()" >打 印</el-button>
      <el-button size="mini" type="primary" @click="SaveAsFile()">Excel</el-button>
      <el-button size="mini" type="danger" :disabled="is_look" > 删 &nbsp; 除</el-button>
      <el-button size="mini" @click="goback">返 回</el-button>
       <!-- <el-button size="mini" type="primary" @click="nopage">不分页</el-button> -->
    </div>
    <div class="content" >
      <el-row>
        <el-col :span="15">
          <div class="form">
            <el-form ref="form" :model="searchform" :rules="rules" label-width="110px" inline size="mini" >
              <el-row>
                <el-col :span="6">
                  <el-form-item label="单据编号" prop="shift_id" >
                    <el-input v-model="searchform.shift_id" readonly style="width:220px;" :disabled="is_look"></el-input>
                  </el-form-item>
                </el-col>
          
               <el-col :span="6">
                  <el-form-item label="下一班次" prop="round_i">
                    <el-select v-model="searchform.round_i" style="width:220px;" :disabled="is_look">
                      <el-option v-for="item in shopround" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


                 <el-col :span="6">
                  <el-form-item label="交班人" prop="handover" >
                   <el-select v-model="searchform.handover" style="width:170px;" :disabled="true">
                      <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
    
            <el-col :span="6">
                  <el-form-item label="接班人" prop="successor" >
                    <el-select v-model="searchform.successor" style="width:170px;" :disabled="is_look">
                      <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="上次交班时间" prop="last_date" >
                    <el-date-picker v-model="searchform.last_date"  :disabled="true" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:220px;"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="本次交班时间" prop="shift_date" >
                    <el-date-picker v-model="searchform.shift_date"  :disabled="true" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:220px;"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
            <el-col :span="6">
                  <el-form-item label="本班初始金额" prop="shift_bgn_cash_b" >
                    <el-input v-model="searchform.shift_bgn_cash_b" readonly style="width:170px;" :disabled="is_look"></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :span="6">
                  <el-form-item label="留存金额" prop="shift_bgn_cash_x" >
                    <el-input v-model="searchform.shift_bgn_cash_x" readonly style="width:170px;" :disabled="is_look"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="备注" prop="notes" >
                    <el-input v-model="searchform.notes" style="width:600px;" :disabled="is_look"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="上传" prop="tstate">
                    <el-select v-model="searchform.tstate"  style="width:170px;"  :disabled="true">
                      <el-option label="新建" :value="0"></el-option>
                      <el-option label="待上传" :value="1"></el-option>
                      <el-option label="已上传" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>  
                
                
                  <el-col :span="6">
                  <el-form-item label="状态" prop="bstate">
                    <el-select v-model="searchform.bstate"  style="width:170px;" :disabled="true">
                      <el-option label="未确定" :value="0"></el-option>
                      <el-option label="已确定" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-table ref="tableRef" :data="tableData" :summary-method="getSummaries" show-summary  style="width: 100%"  border max-height="700" highlight-current-row @row-click="onRowClick">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="pay_id" label="支付方式">
            </el-table-column>
             <el-table-column prop="pay_name" label="支付名称">
            </el-table-column>
            <el-table-column prop="settle_act" label="钱箱总金额" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="交班金额" prop="settle_pay" width="150">
              <template slot-scope="scope">
                <div v-if="!is_look">
                  <el-input  v-model="scope.row.settle_pay" type="number"   size="mini" controls-position="right"></el-input>
                </div>
                <div v-else>{{scope.row.settle_pay}}</div>
              </template>
            </el-table-column>

          </el-table>
             <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" 
              :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total" background
                hide-on-single-page>
              </el-pagination>
        </el-col>
    
      </el-row>
    </div>
    <div class="keybord_box">
      <key-board right="40%" ref="keyboard" @input-num="inputNum"></key-board>
    </div>
     <div class="ticket-box" >
        <ticket-template ref="histprintcom" name="buda" :ticketdata="ticket_data" v-show="show_ticketI"></ticket-template>
      </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { getLocal } from '@/utils/localstorage.js'
import KeyBoard from '@/components/NumberKeyboard'
import AllKeyboard from '@/components/Keyboard.vue'
import { mapGetters } from 'vuex'
import { parseTime, pagePagination } from '@/utils/index.js'
import ticketTemplate from '@/components/TicketTemplate/printshift.vue'//打印
export default {
  components: {
    KeyBoard,
    AllKeyboard,
    ticketTemplate,
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_pos_inv_op', 'dictdata']),
    ...mapGetters('user', ['users'])
  },
  data() {
    return {
      ticket_data:{}, //小票数据
      show_ticketI:false,
      //总金额
      allAmount:0,
       //门店班组
      shopround: [],
       // 付款方式列表
       PayWay: [],
       page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28
      },
      is_look: false,
      editid: null,
      库存单主表: {},
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      //时间选择器
      pickerOptions: this.getPickerOptions(),
      searchform: {
        shift_id: '',
        tstate: 0,
        bstate: 0,
        last_date: null,//上传交班时间
        shift_date: parseInt(new Date().getTime()),
        handover:null,
        successor: null,
        round_o:  getLocal('classesType').dict_value||null,//交班班次
        round_i: null,//接班班班次
        shift_bgn_cash_b: 0,
        shift_bgn_cash_x: 0,
        notes: '',//备注
      },

      rules: {
        shift_id: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        handover: [{ required: true, message: '请选择交班人', trigger: 'change' }],
        successor: [{ required: true, message: '请选择接班人', trigger: 'change' }],
        shift_date: [{ required: true, message: '请选择交班时间', trigger: 'change' }],
        round_i: [{ required: true, message: '请选择下一班次', trigger: 'change' }],
      },
    
      tables: [],
      tableData: [],
      all_table_data: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      inv_bal: [],
      nowGoodsType: null,
      all_keyboard_show: false

    }
  },

  //  beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.$store.commit('app/setlingshouPage', to.fullPath)
  //   })
  // },

  
   beforeRouteEnter(to, from, next) {
    next(async vm => {
      let isadd = to.params.rptid
      if (isadd) {
        vm.initData(isadd)
      }else{
        vm.tableData = []
      }
      vm.$store.commit('app/setlingshouPage', to.fullPath)
       vm.initData()
    })
  },


  async created() {
    this.initData()
  },
  
  methods: {
    async initData(){
      await this.getPay_way()
      this.editid = Number(this.$route.params.rptid) //单据ID号 
  // console.log('this.$route.params.l_rpt',this.$route.params.rptid);
      if (this.editid == 0) { // 类型为0是新增

          this.searchform.handover = getLocal('currUser').id||''
          this.setData()
          this.searchform.shift_id = this.制作编号()
          this.is_look = false
      } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
        // this.editid = this.$route.params.rptid
        //  console.log('this.$route.params', this.$route.params,this.editid)
        this.initEditData(this.editid)
      }
    },
      // 获取支付方式 获取消费群体类型
    async getPay_way() {
      // let dictdata = await this.$localforage.getItem('t_sys_dict_data') || []
      let arr = this.dictdata.filter(i => i.dict_type == 'bus_pay_way')
      this.PayWay = arr.filter(i => !i.isdel)
       console.log('bus_pay_way',arr)
       this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
       console.log('shopround',this.shopround)
      // let job = dictdata.filter(i => i.dict_type == 'bus_cons_job')
      // this.getBus_cons_job = job.filter(i => !i.isdel)
    },

    async initEditData(id) { //修改
      let 列表单     = await this.$localforage.getItem('t_pos_shift')
      let 明细 = await this.$localforage.getItem('t_pos_shift_items')
      列表单.forEach(item => {
        if (item.id == id) {
          this.库存单主表 = item
        }
      })
      for (const key in this.searchform) {
        this.searchform[key] = this.库存单主表[key]
      }
       if (this.searchform.bstate == 0) {
        this.is_look = false
      } else {
        this.is_look = true
      }
  console.log('this.is_look', this.is_look)
      let arr = []
      明细.forEach(item => {
        if (item.pid == id) {
          let goodsItem = this.PayWay.filter(f => f.dict_value == item.pay_id)
          let obj = {
            pay_id: item.pay_id,
            pay_name: goodsItem[0].dict_label,
            pid: item.pid,
            settle_act: item.settle_act,//钱箱总金额
            settle_pay: item.settle_pay,//交班金额
            settle_remain: item.settle_remain,
            notes: item.notes,
          }
          arr.push(obj)
        }
      })

      console.log('initEditData',arr)
      this.all_table_data = arr 
      this.page_data.total = arr.length 
      this.makeData()
    },
    //初始化-新增
    async setData() {
      let SettleBgnDate = getLocal('SettleBgnDate')//当前开市时间
      let 单据列表 = await this.$localforage.getItem('t_pos_shift') || []
         单据列表 = 单据列表.filter(f => f.bstate==1 )
      let max_date=Math.max.apply(Math,单据列表.map(item => { return item.shift_date }))// 交班单最大值
     
       if (isFinite(max_date)){
          if (max_date<SettleBgnDate){ 
                      max_date=SettleBgnDate
                    }
      
       }else{
           max_date=SettleBgnDate
         }
        console.log('max_date',max_date)  
      this.searchform.last_date=max_date//上次交班时间
      let sale_cur =  await this.$localforage.getItem('t_pos_sale_cur') || []
      let shopBaseMsg = await getLocal('shopBaseMsg')
      let arr = []
      this.PayWay.forEach(item => {
         let salecurItem = sale_cur.filter(f => f.pay_id == item.dict_value && f.uct>=max_date && f.uct<=this.searchform.shift_date)
         console.log('salecurItem',salecurItem)
          let i = salecurItem.findIndex(v => v.pay_id === item.dict_value)
            if (i >= 0) {
                var s = 0;
                salecurItem.forEach((item ,i)=> { 
                  s += item.pay_amt        
                  })   
                 console.log('data_arr',s)   
            }else{s=0}
                 
          let obj = {
            pay_id: item.dict_value,
            pay_name: item.dict_label,
            settle_act: s, //item.settle_act,//钱箱总金额
            settle_pay: s,//交班金额
            settle_remain: item.settle_remain,
            pos_id: shopBaseMsg.terminal,
            notes: '',
          }
          arr.push(obj)
      })   
      // arr = this.goodsData
      // this.tableData =this.goodsData 
      console.log('setData',arr)
      this.all_table_data = arr //this.inv_bal
      this.page_data.total = arr.length //this.inv_bal.length
      this.makeData()
    },
   
 
    nowType(v) {
      if (!v) return
      this.nowGoodsType = v
    },
   
    delItem(item) {
      let i = this.tableData.findIndex(v => v.id == item.id)
      if (i >= 0) {
        this.tableData.splice(i, 1)
      }
    },

 nopage(v) {
     let pagetotal=this.page_data.total
     this.page_data.total=0
     this.tableData = this.all_table_data
      console.log('tableData',this.tableData )
    //  this.tableData = pagePagination(this.all_table_data, 1000, 0)
    this.page_data.total=pagetotal
    this.makeData()
    },

     // 分页
    handleCurrentChange(n) {
      this.page_data.currentPage = n
      this.makeData()
      console.log('handleCurrentChange',n)
    },
    makeData(){
      
    this.tableData = pagePagination(this.all_table_data, this.page_data.pagesize, this.page_data.currentPage)
     var tabless=[]
    tabless.push(this.tableData) 
      console.log('tables',tabless)
    },


    onRowClick(v) {
      this.nowGoods = v
    },

    submit() {
            this.tableData.map((i, index) => {
        })
              console.log('submit',this.tableData)
        },
       
    seave(bstate) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          console.log('this.user', user)
          let seavetime = parseInt(new Date().getTime())
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 明细id = makeid(this.page_data.total)
          let 明细 = []
          let 明细表 = await this.$localforage.getItem('t_pos_shift_items') || [] //明细表
          let 主表 =   await this.$localforage.getItem('t_pos_shift') || []//主表
          let  上传状态  //传输状态0-新建, 1-待上传,2-未批复, 3-已批复,4-已确认,4-完成
          if (bstate==0){
              上传状态=0
          }else if (bstate==1){
              上传状态=1
          }
           if (this.editid == 0) {//新增
            console.log('新增')
            // 新增
            let pagetotal=this.page_data.total
            this.page_data.total=0
            this.tableData = this.all_table_data
            console.log('tableData',this.tableData )

            this.tableData.forEach((item, i) => {
              let obj = {
                id: 明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: 主表id,
                pay_id: item.pay_id,
                settle_act: item.settle_act,
                settle_pay: item.settle_pay,
                settle_remain: item.settle_act -item.settle_pay,
                pos_id: item.pos_id,
                notes: item.notes,
              }
              明细.push(obj)
            })
            this.page_data.total=pagetotal
            this.makeData()
            console.log('新增明细',明细)
            let 单据主表 = {
              id: 主表id,
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              shift_id: this.searchform.shift_id,
              bstate: bstate,
              last_date:  this.searchform.last_date,
              shift_date:  this.searchform.shift_date,
              handover:  this.searchform.handover,
              successor: this.searchform.successor,
              round_o: this.searchform.round_o,
              round_i: this.searchform.round_i,
              shift_bgn_cash_b: this.searchform.shift_bgn_cash_b,
              shift_bgn_cash_x: this.searchform.shift_bgn_cash_x,
              notes:this.searchform.notes,
              pos_id: shopBaseMsg.terminal,
              tstate: 上传状态, //0 未上传 1上传
              dept_id: user.dept_id,
            }
            console.log('单据主表',单据主表)
            主表.push(单据主表)
            明细表.push(...明细)
          } else { // 编辑
            console.log('编辑')
            let pagetotal=this.page_data.total
            this.page_data.total=0
            this.tableData = this.all_table_data
            console.log('编辑tableData',this.tableData )

            this.tableData.forEach((item, i) => {
              let obj = {
                id: 明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid:  item.pid,
                pay_id: item.pay_id,
                settle_act: item.settle_act,
                settle_pay: item.settle_pay,
                settle_remain: item.settle_act -item.settle_pay,
                pos_id: shopBaseMsg.terminal,
                notes: item.notes,
              }
              明细.push(obj)
            })
           this.page_data.total=pagetotal
           this.makeData()
            let 单据主表 = {
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              shift_id: this.searchform.shift_id,
              bstate: bstate,
              shift_date:  this.searchform.shift_date,
              handover:  this.searchform.handover,
              successor: this.searchform.successor,
              round_o: this.searchform.round_o,
              round_i: this.searchform.round_i,
              shift_bgn_cash_b: this.searchform.shift_bgn_cash_b,
              shift_bgn_cash_x: this.searchform.shift_bgn_cash_x,
              notes:this.searchform.notes,
              pos_id: shopBaseMsg.terminal,
              tstate: 上传状态, //0 未上传 1上传
              dept_id: user.dept_id,
            }
            主表.forEach(item => {
              if (item.id == this.editid) {
                for (const key in 单据主表) {
                  // 更新主表中对应的数据
                  item[key] = 单据主表[key]
                }
              }
            })
            // 删除明细表中对应的数据
            for (let index = 明细表.length - 1; index >= 0; index--) {
              let item = 明细表[index]
              if (item.pid == this.editid) {
                let i = 明细表.findIndex(v => v.id == item.id)
                if (i >= 0) {
                  明细表.splice(i, 1)
                }
              }
            }
            明细表.push(...明细)
          }
          // 储存
          await this.$localforage.setItem('t_pos_shift_items', 明细表)
          await this.$localforage.setItem('t_pos_shift', 主表)

          if (bstate==0) {
            this.$message.success('保存成功')
             if (this.editid == 0) {
              this.$route.params.rptid = 主表id
              this.editid = 主表id
              this.initEditData(this.editid)
            }
            // setTimeout(() => { this.$router.go(-1) }, 500)
          }else  if (bstate==1) {
            this.$message.success('确认成功')
            this.searchform.bstate=bstate
            this.searchform.tstate=1
            this.is_look = true
            // setTimeout(() => { this.$router.go(-1) }, 500)
          }
        }

      })
    },

 // 删除
    det(list) {
      this.$confirm('是否删除选中的交班单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let table = this.tableData
        let 报货明细表 = await this.$localforage.getItem('t_pos_shift_items') || [] //明细表
        let 报货主表 = await this.$localforage.getItem('t_pos_shift') || []//主表
        list.forEach(item => {
          for (let i = table.length - 1; i >= 0; i--) {
            if (item.id == table[i].id) { // 先删除页面上表格的数据
              table.splice(i, 1)
            }
          }
          // 删除本地存储的数据
          for (let i = 报货主表.length - 1; i >= 0; i--) {
            if (item.id == 报货主表[i].id) { // 删除主表数据
              报货主表.splice(i, 1)
            }
          }
          for (let i = 报货明细表.length - 1; i >= 0; i--) {
            if (item.id == 报货明细表[i].pid) { // 删除主表id等于id的明细表
              报货明细表.splice(i, 1)
            }
          }
        });
        // c存入数据
        await this.$localforage.setItem('t_pos_shift_items', 报货明细表)
        await this.$localforage.setItem('t_pos_shift', 报货主表)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
     //计算价格
    getAll: function () {
      // 总数量和总金额清零
      this.allAmount = 0;
      let num = 0
      if (this.tableData) {
        // 计算总金额和数量
        this.tableData.forEach((el) => {
          num += el.settle_pay;
        });
        this.allAmount = num.toFixed(2)
      }
    },
 //打印
    handlePrint(){
      this.getAll()
      let  主表=this.searchform
           主表.sale_amount=this.allAmount
   // 打印小票
        let ticketdata = {
            has_data: true,
            主表: 主表,
            明细arr: JSON.parse(JSON.stringify(this.tableData)),     
        } 
    this.ticket_data = ticketdata
    this.printLodop()
},

printLodop() {
  this.$refs.histprintcom.printTicket(this.ticket_data)
},


	 SaveAsFile(){ 
  
	},


    // 键盘输入
    onKeyInput(v) {
      this.searchform.inv_billid = v
      this.all_keyboard_show = false
    },
    showkeyboard(v) {
      this.now_row_attr = v
      this.$refs.keyboard.open()
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods[this.now_row_attr] = n
    },
    // goback() {
    //   this.$router.go(-1)
    // },

      goback() {
      this.editid = 0
      this.is_look = false
      this.$refs.form.resetFields()
      this.tableData = []
      this.$router.push('/shopshift')
    },

    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return 'JB' + str + 商户号 + id
    },
      //合计
       getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = '合计:';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value)) && column.property != 'pay_id') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' ';
          } else {
            sums[index] = '';
          }
        });

        return sums;
      },
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 10px;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.goods {
  background-color: #ccc;
  border-left: 5px solid #fff;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #82c0ff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #82c0ff;
}
.keyboard {
  width: 520px;
  padding: 10px;
  background-color: #ccc;
  z-index: 99;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
</style>