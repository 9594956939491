<template>
  <div style="margin-top:10px;width:100%;">
    <div class="biao-box">
      <div class="form">
        <el-form ref="queryForm" :model="searchform"  v-show="showSearch" label-width="80px" inline size="small"> 
          <!-- <el-form-item label="单据类型" prop="inv_whycat">
            <el-select v-model="searchform.inv_whycat" style="width:170px;">
              <el-option v-for="item in t_pos_inv_op" :key="item.id" :label="item.invop_namec" :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="单据编号" prop="chk_id">
            <el-input v-model="searchform.chk_id"></el-input>
          </el-form-item>

           <el-form-item label="盘点结束日期" prop="invchk_end_date">
                <el-date-picker
                size="small"
                  v-model="invchk_end_date"
                  value-format="timestamp"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>

          <el-form-item label="状态" prop="bstate">
          <el-select v-model="searchform.bstate" placeholder="数据状态" clearable size="small" >
                <el-option label="起草" value= "0"></el-option>
                <el-option label="确定" value= "1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上传" prop="tstate">
          <el-select v-model="searchform.bstate" placeholder="上传状态" clearable size="small" >
                <el-option label="新建" :value="0"></el-option>
                      <el-option label="待上传" :value="1"></el-option>
                      <el-option label="已上传" :value="9"></el-option>
          </el-select>
        </el-form-item>


             <el-form-item>
                <el-button
                  type="cyan"
                  icon="el-icon-search"
                  size="small"
                  @click="handleQuery"
                  >搜索</el-button
                >
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
                  >重置</el-button
                >
              </el-form-item>
        </el-form>
      </div>
      <div class="handel-btn">
        <el-button size="small" type="primary" @click="add">新 增</el-button>
         
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="small"
          :disabled="multiple"
          @click="handleDelete"
    
        >删除</el-button>

         <el-button size="small" type="danger" @click="goback">关 闭</el-button>
        
        <el-button size="small" type="success" style="float:right;" @click="uploadList">上传</el-button>
      </div>
      <div class="content">
        <el-table :data="tableData" border ref="tabledata" @select="onSelect" @select-all="onSelect" @selection-change="handleSelectionChange">
          <el-table-column label="#" width="40" type="selection">
          </el-table-column>
          <el-table-column  label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="chk_id" label="单据编号" align="center"> 
          </el-table-column>
          <el-table-column label="开始时间" prop="invchk_bgn_date"  sortable align="center" width="180">
              <template slot-scope="scope">{{ scope.row.invchk_bgn_date===null? '' :scope.row.invchk_bgn_date|parseTime }}</template>
              </el-table-column>
          <el-table-column label="结束时间" prop="invchk_end_date"  sortable align="center" width="180">
              <template slot-scope="scope">{{ scope.row.invchk_end_date===null? '' :scope.row.invchk_end_date|parseTime }}</template>
              </el-table-column>

             <el-table-column prop="region_id" label="盘点对象" sortable align="center" width="110">
                <template slot-scope="scope">
                  <span v-show="scope.row.region_id == item.dict_value" v-for="item in t_region" :key="item.dict_value">{{item.dict_label}}</span>
                </template>
              </el-table-column>


          <el-table-column label="盘点方式" sortable width="110">
            <template slot-scope="scope">
              <span v-show="scope.row.invchk_manner == 0" >循环盘点</span>
              <span v-show="scope.row.invchk_manner == 1" >冻结盘点</span>
            </template>
          </el-table-column>


          <el-table-column label="上传" sortable width="80">
            <template slot-scope="scope">
              <span v-show="scope.row.tstate == 0" style="color:#909399;">新建</span>
              <span v-show="scope.row.tstate == 1" style="color:#67c23a;">待上传</span>
              <span v-show="scope.row.tstate == 9" style="color:#67c23a;">已上传</span>
            </template>
          </el-table-column>

          <el-table-column prop="bstate" label="状态" width="60">
            <template slot-scope="scope">
              <span v-show="scope.row.bstate == 0" style="color:#909399;">起草</span>
              <span v-show="scope.row.bstate == 1" style="color:#e6a23c;">确认</span>
              <span v-show="scope.row.bstate == 2" style="color:#66b1ff;">发出</span>
              <span v-show="scope.row.bstate == 3" style="color:#67c23a;">收到</span>
            </template>
          </el-table-column>

          <el-table-column prop="lby" label="操作人" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.lby == item.id" v-for="item in users" :key="item.id">{{item.name}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="inv_billid" label="备注" align="center">
          </el-table-column>

          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="mini" icon="el-icon-view" @click="editRpt([scope.row],true)">查看</el-button>
              <!-- <el-button :disabled="scope.row.tstate == 1" type="text" size="mini" icon="el-icon-edit" @click="editRpt([scope.row])">修改</el-button> -->
              <el-button :disabled="scope.row.bstate == 1" type="text" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

          <pagination v-show="page_data.total > 0" :total="page_data.total" :page.sync="page_data.currentPage" :limit.sync="page_data.pagesize" @pagination="initData" />
          
        <!-- <div style="margin-top:10px;">  
          <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total"
            background hide-on-single-page>
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import 盘点api from '../api.js'
import { parseTime,pagePagination } from '@/utils/index.js'
import { mapGetters } from 'vuex'
export default {
  filters: {
    parseTime
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_pos_inv_op','dictdata']),
    ...mapGetters('user', ['users'])
  },
  data() {
    return {
       // 选中数组
      ids: [],
      // 选中数组
      idsname: [],
      // 非单个禁用
      single: true,
       // 非多个禁用
      multiple: true,
       // 显示搜索条件
      showSearch: true,
       // 日期范围
      invchk_end_date: [],
      activeName: 'first',
      text: '',
      pos_invop: 0,
      //发方
      invfm: [],
      //收方
      invto: [],
      //门店班组
      shopround: [],
      //门店生产班组
      shopprod: [],

      searchform: {
        chk_id: '',
        bstate: '',
        tstate: '',
        notes: '',//备注
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      radio: [],
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 20
      },
        query: {
         type:0,
         look:0,
         invop:0,
       }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('app/setlingshouPage', to.fullPath)
    })
  },
  async created() {
    console.log(this.$route.params)
    this.pos_invop=this.$route.params.rptid//获取库存操作类别
    this.searchform.inv_whycat=this.$route.params.rptid

    //获得操作类型数据当前行数据
      this.t_pos_inv_op.forEach(item => {
          if (item.id == this.$route.params.rptid ) {
              if (item.invop_sorc == 'KC' ) {
              this.invfm=this.t_region
              }else if (item.invop_dest == 'KC' ) {
                this.invto=this.t_region
              }
          } 
        })
this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
this.shopprod = this.dictdata.filter(item => item.dict_type == "bus_shop_prod")//门店生产班组

//  console.log(1111,shop_round,shop_prod)
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      this.loading = true;
      let 盘点单 = await this.$localforage.getItem('t_pos_inv_chk') || []
      //需要根据单据类型过滤数据
      console.log('盘点单',盘点单)
      let arr = 盘点单.filter(item => 
      item.bstate <= 1 
      // && item.inv_whycat == this.pos_invop //生产入库
      && item.chk_id.includes(this.searchform.chk_id ) 
       && Number(item.bstate).toString().includes(this.searchform.bstate)
       && Number(item.tstate).toString().includes(this.searchform.tstate)
      ) 
      this.tableData = pagePagination(arr, this.page_data.pagesize, this.page_data.currentPage)
      this.page_data.total = arr.length
      this.loading = false;
    },
    // 分页
    handleCurrentChange(v) {
      this.page_data.currentPage = v
      this.initData()
    },

     /** 搜索按钮操作 */
    handleQuery() {
      this.page_data.currentPage = 1;
      this.initData();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.reset()
      this.handleQuery();
    },
    // 表单重置
    reset() {
      this.searchform = {
        inv_id: '',
        inv_fm: '',
        inv_to: '',
        inv_needdate: '',
        inv_date1: ''
      };
      this.resetForm("queryForm");
    },

 goback() {
      this.$router.push('/dianpu')
    },
    add() {
       this.query={
         type:'1',
       }
       this.$router.push({path:"/shopchecking/" +'0'})

    },
    editRpt(val, look) {
       this.query={
         type:'2',
       }
      let id = val[0].id
      //  console.log(id)
        this.$router.push({path:"/shopchecking/" + id })


    },

 // 多选框选中数据
    handleSelectionChange(selection) {
      console.log('selection', selection)
      //  this.ids = selection.map(item => item)
       this.idsname = selection.map(item => item.chk_id)
      this.ids=[]
      let obj = this.ids
     selection.forEach(function (c) {
                    if(c.bstate=== 0){
                       obj.push(c);
                    }
                  })
      this.single = selection.length!=1
      this.multiple = !selection.length
        console.log('this.ids',this.ids)
    },

           
     /** 删除按钮操作 */
    handleDelete(row) {
       console.log('row',row.id)
       let listIds = row.id || this.ids;
       const listIdsname = row.chk_id || this.idsname;
       if (!Array.isArray(listIds)){listIds=[row]}//是 Array 类型的一个静态方法
       this.$confirm('是否删除选中的盘点单"' + listIdsname + '"的数据项(确认及上传单据不删除)?', "警告", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let table = this.tableData
        let 报货明细表 = await this.$localforage.getItem('t_pos_inv_chk_items') || [] //明细表
        let 报货主表 = await this.$localforage.getItem('t_pos_inv_chk') || []//主表
        listIds.forEach(item => {
          for (let i = table.length - 1; i >= 0; i--) {
            if (item.id == table[i].id) { // 先删除页面上表格的数据
              table.splice(i, 1)
            }
          }
          // 删除本地存储的数据
          for (let i = 报货主表.length - 1; i >= 0; i--) {
            if (item.id == 报货主表[i].id) { // 删除主表数据
              报货主表.splice(i, 1)
            }
          }
          for (let i = 报货明细表.length - 1; i >= 0; i--) {
            if (item.id == 报货明细表[i].pid) { // 删除主表id等于id的明细表
              报货明细表.splice(i, 1)
            }
          }
        });
        // c存入数据
        await this.$localforage.setItem('t_pos_inv_chk_items', 报货明细表)
        await this.$localforage.setItem('t_pos_inv_chk', 报货主表)
        this.handleQuery()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击复选框
    onSelect(val) {
      this.radio = val
    },
    //上传
    async uploadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let 报货明细表 = await this.$localforage.getItem('t_pos_inv_chk_items') || [] //明细表
      let 报货主表 = await this.$localforage.getItem('t_pos_inv_chk') || []//主表
      let master = []
      let 主表上传的下标 = [] //保存住上传的数据的下标
      报货主表.forEach((item, i) => {
           console.log(item)
        if (item.tstate == 0 && item.bstate == 1 ) {
          master.push(JSON.parse(JSON.stringify(item)))
          主表上传的下标.push(i)
        }
      })
      // master.forEach(item => {
      //   item.inv_date = (new Date(item.inv_date).getTime())
      //   item.inv_date1 = (new Date(item.inv_date1).getTime())
      //   item.inv_date2 = (new Date(item.inv_date2).getTime())
      // })
      let detail = []
      let 明细下标 = []
      master.forEach(item => {
        let arr = []
        for (let i = 0; i < 报货明细表.length; i++) {
          if (报货明细表[i].pid == item.id) {
            arr.push(报货明细表[i])
            明细下标.push(i)
          }
        }
        detail.push(...arr)
      })
      if (master.length > 0) {
        let data = {
          "jsons": {
            master: master,
            detail: detail
          }
        }
        
        let [err, res] = await 盘点api.盘点单上传(data).catch(err => { loading.close() })
        if (err) return loading.close()
        if (res.statusCode == 200) {
          loading.close()
          this.$message.success('数据上传成功')
          for (let i = 0; i < 主表上传的下标.length; i++) {
            报货主表[主表上传的下标[i]].tstate = 9
          }
          for (let i = 0; i < 明细下标.length; i++) {
            报货明细表[明细下标[i]].tstate = 9
          }
          await this.$localforage.setItem('t_pos_inv_chk_items', 报货明细表)
          await this.$localforage.setItem('t_pos_inv_chk', 报货主表)
          this.initData()
          return
        } else {
          loading.close()
          return this.$message.error(res.message)
        }
      }
      this.$message.error('没有需要上传的数据')
      loading.close()
    }
  }

}
</script>

<style scoped lang="less">
.biao-box {
  width: 100%;
  // padding: 15px 40px;
}
.handel-btn {
  margin: 10px 0;
  padding: 0 10px;
}
</style>