<template>
  <div style="margin-top: 10px;">
          <!-- <el-input v-model="request_url" :readonly="!is_edit_url"></el-input>
         
          <el-button type="primary" @click="is_edit_url = true" v-show="!is_edit_url">修改</el-button>
          <el-button type="success" v-show="is_edit_url" @click="changeBaseUrl">确认</el-button> -->
       



  <el-form :model="form" class="form-inline" label-width="130px">
  <!-- <el-form-item label="收银是否打印预览">
    <el-input v-model="form.request_url" :readonly="!is_edit_url" placeholder="请求地址" style="width:300px;"></el-input>
  </el-form-item> -->
    <el-form-item label="收银是否打印">
            <el-switch v-model="form.saleprint" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1" @change="handleSaleprintChange()">
            </el-switch>
    </el-form-item> 
   <el-form-item label="收银是否打印预览">
            <el-switch v-model="form.salepreview" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1" @change="handleSalepreviewChange()">
            </el-switch>
    </el-form-item> 
  <!-- <el-form-item>
          <el-button type="primary" @click="is_edit_url = true" v-show="!is_edit_url">修改</el-button>
          <el-button type="success" v-show="is_edit_url" @click="changeBaseUrl">确认</el-button> 
  </el-form-item> -->
</el-form>

 </div>

</template>

<script>
import { getLocal, setLocal } from '@/utils/localstorage'
export default {
  data() {
    return {
        activeName:'配置管理',
        request_url: '',
        shopBaseMsg: {},
        is_edit_url: false,

        form: {
          saleprint: '0',
          salepreview: '0',
        }
    }
  },
    mounted() {
    let msg = getLocal('shopBaseMsg')
    this.shopBaseMsg = msg
    this.form.saleprint = msg.saleprint||0
    this.form.salepreview = msg.salepreview||0
     console.log('mounted',this.shopBaseMsg)
  },
   methods: {
   
     // 销售打印状态修改
    handleSaleprintChange(row) {
        let msg = getLocal('shopBaseMsg')
        this.shopBaseMsg = msg
        // this.form.saleprint = msg.saleprint||0
        let text =  this.form.saleprint === 0 ? "启用" : "停用";
        this.$confirm(
      '确认要' + text + '收银打印预览吗?',
      "警告",
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.shopBaseMsg)
        this.shopBaseMsg.saleprint =  this.form.saleprint
        setLocal('shopBaseMsg', this.shopBaseMsg);
        this.$message({
          type: 'success',
          message: '修改成功!'
        });
      }).catch(() => { this.form.saleprint = this.form.saleprint == 0 ? 1 : 0;});
    },
   // 销售打印预览
    handleSalepreviewChange(row) {
        let msg = getLocal('shopBaseMsg')
        this.shopBaseMsg = msg
        // this.form.saleprint = msg.saleprint||0
        let text =  this.form.salepreview === 0 ? "启用" : "停用";
        this.$confirm(
      '确认要' + text + '收银打印预览吗?',
      "警告",
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.shopBaseMsg)
        this.shopBaseMsg.salepreview =  this.form.salepreview
        setLocal('shopBaseMsg', this.shopBaseMsg);
        this.$message({
          type: 'success',
          message: '修改成功!'
        });
      }).catch(() => { this.form.salepreview = this.form.salepreview == 0 ? 1 : 0;});
    },


},
}
</script>

<style lang="scss" scoped>
.contn-box {
    width: 100%;
    height: 100%;
    padding: 15px;
}
</style>