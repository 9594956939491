export let tidanDatae = {
    ecoupons_table_data: []
}
export let tidanmethodse = {
    cancelTidane(){
        this.tuanPayVisiblee = false
        this.$refs.tidanRefe.close()
    },
    canfirmTidane(){
        this.tuanPayVisiblee = false
        this.$refs.tidanRefe.canfirm()
    },
 
    onTidanCanfirme(table){
        this.ecoupons_table_data = table
        let num = 0
        table.forEach(el => {
            num += (el.rec_amt*1)
        });
        this.enterNuminPayWay(num.toFixed(2))
          //回到自动选择现金
          let k = this.PayWay[0]
          this.whichPayWay = k.dict_value
    }
}