<template>
  <div class="ticket">
    <!-- 报货单 -->
    <div class="ticket-item" style="width:270px;">
      <div class="bill-preview">
        <div class="detail ticket-box" id="ticket-box-need" style="padding: 0 17px;font-size: 12px;">
          <h5 style="text-align: center;padding:15px 0;border-bottom: 1px solid #333;font-size: 16px;">{{companyInfo.公司名称+'('+companyInfo.名称+')'}}</h5>
          <div style="margin-top:8px;margin-bottom:8px; text-align:right;">
            <span>——</span><span style="font-size:12px;">{{doctype}}</span>
          </div>
          <div style="font-size: 12px;border-bottom: 1px solid #333;">
            <div style="margin-top: 8px;">
              <span>手工单号:</span>
              <span style="margin-left:8px;">{{sale_msg.inv_billid}}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>需求时间:</span>
              <span style="margin-left:8px;">{{sale_msg.inv_date1===null? '' :sale_msg.inv_date1|parseTime }}</span>
            </div>
            <div style="margin-top: 8px;">
              <span>报货时间:</span>
              <span style="margin-left:8px;">{{sale_msg.inv_needdate===null? '' :sale_msg.inv_needdate|parseTime }}</span>
            </div>
            <!-- <div style="margin-top: 8px;">
              <span>班次:</span>
              <span style="margin-left:8px;">{{sale_msg.inv_round}}</span>
            </div> -->

            <div style="margin-top: 8px;display:flex;">
              <div style="margin-right:10px;">
                <span>发方:</span>
                <span style="margin-left:8px;">{{ GetWrhus(sale_msg.inv_fm)}}</span>
              </div>
              <div>
                <span>收方:</span>
                <span style="margin-left:8px;">{{ GetDictData(sale_msg.inv_region,'bus_shop_region')}}</span>
              </div>
            </div>
            <div style="margin-top: 8px;margin-bottom:8px;">
              <span>单号:</span>
              <span style="margin-left:8px;">{{sale_msg.inv_id}}</span>
            </div>
          </div>
          <div style="border-bottom: 1px solid #333;">
            <div style="margin-top: 10px;">
              <div style="margin-bottom:8px;display:flex;justify-content: flex-start;">
                <span style="font-size:12px;flex:0 0 40px;margin-right:8px;">编码</span>
                <span style="font-size:12px;flex:0 0 65px;font-size: 12px;">名称</span>
                <span style="font-size:12px;flex:0 0 25px;text-align:center;">单位</span>
                <span style="font-size:12px;flex:0 0 45px;text-align:center;">单价</span>
                <span style="font-size:12px;flex:0 0 36px;text-align:center;">数量</span>
                <span style="font-size:12px;flex:0 0 43px;text-align:center;">金额</span>
              </div>
            </div>
            <div style="margin-top: 10px;">
              <div v-for="item in goods_data" :key="item.id">
                <div style="margin-bottom:8px;display:flex;justify-content: flex-start;">
                  <span style="font-size:12px;flex:0 0 40px;margin-right:8px;">{{item.part_id}}</span>
                  <span style="font-size:12px;flex:0 0 65px;font-size: 12px;">{{item.part_name}}</span>
                  <span style="font-size:12px;flex:0 0 25px;text-align:center;">{{item.part_unit}}</span>
                  <span style="font-size:12px;flex:0 0 45px;text-align:center;">{{item.invbi_price}}</span>
                  <span style="font-size:12px;flex:0 0 36px;text-align:center;">{{item.invbi_plan}}</span>
                  <span style="font-size:12px;flex:0 0 43px;text-align:center;">{{item.invbi_cost_plan}}</span>
                </div>
              </div>
            </div>
          </div>

          <div style="font-size: 12px;border-bottom: 1px solid #333;">
            <div style="margin:8px;text-align:right;">
              <span>总金额: </span><span style="margin-left:6px;">{{sale_msg.sale_amount}}</span>
            </div>
          </div>

          <div style="font-size: 12px;display:flex;margin-top: 10px;">
            <div style="margin-right: 8px;flex: 0 0 110px;">
              <span>收银员:</span>
              <span style="margin-left:8px;">{{currUser.name}}</span>
            </div>
            <div style="flex: 0 0 120px;">
              <span>送货员:</span>
              <span style="margin-left:8px;">________</span>
            </div>
          </div>
          <div style="font-size: 12px;display:flex;margin-top: 10px;">
            <div style="margin-right: 8px;flex: 0 0 110px;">
              <span>收货员:</span>
              <span style="margin-left:8px;">________</span>
            </div>
            <div style="flex: 0 0 120px;">
              <span>审核员:</span>
              <span style="margin-left:8px;">________</span>
            </div>
          </div>
          <div style="margin-top: 8px;font-size: 12px;">
            <span>打印时间:</span>
            <span style="margin-left:8px;">{{new Date().getTime() | parseTime}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLocal } from '@/utils/localstorage'
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
import { getLodop } from '@/assets/LodopFuncs' //导入模块
export default {
  filters: { parseTime },
  data() {
    return {
      doctype: '报货单',
      companyInfo: {},
      goods_data: [],
      sale_msg: {},
      dictdata: [],
      wrhus: [],
    }
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region']),
    ...mapGetters('user', ['currUser'])
  },
  async mounted() {
    let companyInfo = getLocal('companyInfo')
    this.companyInfo = companyInfo
    let sys_dept = await this.$localforage.getItem('sys_dept') || []
    let cur_sys_dept = sys_dept.filter(i => i.id == this.companyInfo.id)
    if (cur_sys_dept.length > 0) {
      Object.assign(this.companyInfo, { addr: cur_sys_dept[0].addr, tel: cur_sys_dept[0].tel })
      console.log('this.cur_sys_dept', this.companyInfo);
    } else {
      Object.assign(this.companyInfo, { addr: '', tel: '' })
    }
  },
  methods: {
    async printTicket(data) {

      console.log('printTicket', data);
      this.ticketdata = data
      this.sale_msg = this.ticketdata.主表

      if (this.sale_msg.bstate == 0 || this.sale_msg.bstate == 1) {
        this.doctype = '报货单'
      } else if (this.sale_msg.bstate == 2 || this.sale_msg.bstate == 3) {
        this.doctype = '收货单'
      }
      this.goods_data = this.ticketdata.明细arr
      console.log('22', this.ticketdata, this.sale_msg);
      let goods = await this.$localforage.getItem('t_sys_dict_data') || []  //关闭 后打印显示出问题

      var LODOP; //声明为全局变量 
      LODOP = getLodop();
      LODOP.PRINT_INIT(this.sale_msg.inv_id);
      LODOP.ADD_PRINT_HTML(0, 0, '100%', '100%', document.getElementById("ticket-box-need").innerHTML);
      LODOP.PREVIEW();
      // LODOP.PRINT();
    },

    //业务数据字典
    GetDictData(id, type) {
      if (!id) {
        return ''
      }
      let dictdata = this.t_region.filter(v => v.id === id && v.dict_type == type && v.isdel != 1)
      if (dictdata.length > 0) {
        return dictdata[0].dict_label
      }
      return ''
    },
    //仓库
    GetWrhus(id) {
      this.wrhus = []
      if (!id) {
        return ''
      }
      let dictdata = this.t_wrhus.filter(v => v.id === id && v.isdel != 1)
      if (dictdata.length > 0) {
        return dictdata[0].wrhus_name
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.ticket {
  display: flex;
  background-color: #f1f1f1;
  padding: 10px 15px;
  width: 400px;
}
</style>