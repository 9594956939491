import Vue from 'vue'
import Router from 'vue-router'
import Vuex from 'vuex'

Vue.use(Vuex);
Vue.use(Router)

// 引入组件
import appMain from '@/views/goodscontent.vue'
import goodsStorage from '@/views/库存'
import goodsDetai from '@/views/商品'
import setting from '@/views/设置'
import vip from '@/views/会员'
import dingdan from '@/views/订单'
import baobiao from '@/views/报表'
import baohuo from '@/views/报货'
import login from '@/views/login.vue'
import openinitial from '@/views/openinitial.vue'
import rptgoods from '@/views/报货/report_goods'
import editrpt from '@/views/报货/edit_report'
import warehousdbring from '@/views/库存/调拨入库单明细.vue'
import warehousdbcing from '@/views/库存/调拨出库单明细.vue'
import warehousdblist from '@/views/库存/调拨单列表.vue'
import warehoustking from '@/views/库存/退库单明细.vue'
import warehoustklist from '@/views/库存/退库单列表.vue'
import warehousing from '@/views/库存/入库单明细.vue'
import warehouslist from '@/views/库存/库存单列表.vue'
import shopcheck from '@/views/店铺/盘点/盘点单列表.vue'
import shopchecking from '@/views/店铺/盘点/盘点单明细.vue'
// import shopshift  from '@/views/店铺/交班'
import settlement from '@/views/店铺/日结/日结单列表.vue'
import settlementing from '@/views/店铺/日结/日结单明细.vue'
import shopshift from '@/views/店铺/交班/交班单列表.vue'
import shopshifting from '@/views/店铺/交班/交班单明细.vue'
import bankmoney from '@/views/店铺/货款'
import showinfo from '@/views/报货/收货/showinfo.vue'
// 将pos引入
import Pos from '@/views/收银'
import dianpu from '@/views/店铺'

import homepage from '@/views/首页'

export default new Router({
  routes: [
    {
      path: '/',
      component: appMain,
      redirect: '/homepage',
      children: [
        {
          path: '/homepage',
          component: homepage,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/cashier',
          component: Pos,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/goodsStorage',
          component: goodsStorage,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/goodsDetailList',
          component: goodsDetai,
          meta: {
            keepAlive: false
          }
        },

        {
          path: '/setting',
          component: setting,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/vip',
          component: vip,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/dingdan',
          component: dingdan,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/baobiao',
          component: baobiao,
          meta: {
            keepAlive: false
          }
        },
        //店铺零售
        {
          path: '/dianpu',
          component: dianpu,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/shopcheck',
          component: shopcheck,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/shopchecking/:rptid',
          component: shopchecking,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/shopshift',
          component: shopshift,
          meta: {
            keepAlive: false
          }
        },
        //日结
        {
          path: '/settlement',
          component: settlement,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/settlementing/:rptid',
          component: settlementing,
          meta: {
            keepAlive: false
          }
        },
        //交班
        {
          path: '/shopshift',
          component: shopshift,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/shopshifting/:rptid',
          component: shopshifting,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/bankmoney',
          component: bankmoney,
          meta: {
            keepAlive: false
          }
        },
        //以上店铺零售
        {
          path: '/baohuo',
          component: baohuo,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/rptgoods',
          component: rptgoods,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/editrpt/:rptid',
          component: editrpt,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/warehousing/:k_type/:l_rpt',
          component: warehousing,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/warehouslist/:k_type',
          component: warehouslist,
          meta: {
            keepAlive: false
          }
        },
        {//退库
          path: '/warehoustking/:k_type/:l_rpt',
          component: warehoustking,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/warehoustklist/:k_type',
          component: warehoustklist,
          meta: {
            keepAlive: false
          }
        },
         {//调拨入库
          path: '/warehousdbring/:k_type/:l_rpt',
          component: warehousdbring,
          meta: {
            keepAlive: true
          }
        },
        {//调拨出库
          path: '/warehousdbcing/:k_type/:l_rpt',
          component: warehousdbcing,
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/warehousdblist/:k_type',
          component: warehousdblist,
          meta: {
            keepAlive: false
          }
        },
        {
          path: '/showinfo/:rptid',
          component: showinfo,
          meta: {
            keepAlive: true
          }
        },
      ]
    },
    {
      path: '/staff/login',
      component: login
    },
    {
      path: '/openinitial',
      component: openinitial
    },

  ]
})
