const mylib = require('ym-mylib');
import tools from '@/utils/tools'
export function getpass(pwd,salt) {
    let inputPwd = tools.makeAE1pass(pwd)
    if (inputPwd.length <= 4) return '密码格式错误';
    let p1 = inputPwd.substring(0, 3);
    let p2 = inputPwd.substring(4, inputPwd.length);
    let md1 = ''
    if (p1 == 'AE1') { p2 = mylib.encode.ymAesDecode(p2, '1232'); p1 = 'ORG'; }
    if (p1 == 'ORG') { md1 = mylib.util.md5('1232' + p2); }
    else if (p1 == 'MD1') { md1 = p2 }
    else {
        return '不认识的密码格式';
    }
    // 方便导入 登录 若pass为空 则随机产生盐值,计算后存入pass MD5(MD5('1232' + pass) + 盐) 同时将firstpass置空
    return mylib.util.md5(md1 + salt)
}