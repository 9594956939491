<template>
  <div>
    <!-- 消费详情 -->
      <div class="filter-box">
      <el-input v-model="queryParams.carddata" @keydown.enter.native="searchVipCard(queryParams.carddata)"  placeholder="请输入查询条件"  style="width:160px;" size="small" >
      </el-input>

      <el-button style="float: right;margin-right: 10px;" size="small" @click="handlecardhb" type="success"> <i class="el-icon-refresh"></i> 合并</el-button>
    </div>
    <div class="table-up">
       <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="主卡" name="first">
      <el-table :data="tableDataz" ref="xiaofei" highlight-current-row    @current-change="handleCurrentChange" border height="150"
       style="width: 100%; ">
        <el-table-column label="卡号" align="center" prop="card_id_out" :show-overflow-tooltip="true"/>
        <el-table-column label="名称" sortable align="center" prop="card_name" :show-overflow-tooltip="true"/>
        <el-table-column label="折扣" sortable align="center" prop="card_dis_rate" :show-overflow-tooltip="true"/>
        <el-table-column label="余额" sortable align="center" prop="card_remoney" :show-overflow-tooltip="true"/>
         <el-table-column label="状态" prop="card_state" align="center">
            <template slot-scope="scope"> 
              <div>
                <span v-show="scope.row.card_state == 0">未启用</span>
                <span v-show="scope.row.card_state == 1">有效</span>
                <span v-show="scope.row.card_state == 2">挂失</span>
                <span v-show="scope.row.card_state == 3">失效</span>
              </div>
            </template>
          </el-table-column>
        <el-table-column label="备注" prop="notes" sortable  align="center" :show-overflow-tooltip="true" />
      </el-table>
      </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tag-box">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="副卡" name="first">
          <el-table :data="tableDataf" border height="400"
          :summary-method="getSummaries" show-summary style="width: 100%;">
            <el-table-column label="卡号" align="center" prop="card_id_out" :show-overflow-tooltip="true"/>
            <el-table-column label="名称" sortable align="center" prop="card_name" :show-overflow-tooltip="true"/>
            <el-table-column label="折扣" sortable align="center" prop="card_dis_rate" :show-overflow-tooltip="true"/>
            <el-table-column label="余额" sortable align="center" prop="card_remoney" :show-overflow-tooltip="true"/> 
            <el-table-column label="状态" prop="card_state" align="center">
            <template slot-scope="scope"> 
              <div>
                <span v-show="scope.row.card_state == 0">未启用</span>
                <span v-show="scope.row.card_state == 1">有效</span>
                <span v-show="scope.row.card_state == 2">挂失</span>
                <span v-show="scope.row.card_state == 3">失效</span>
              </div>
            </template>
          </el-table-column>
           <el-table-column label="备注" prop="notes" sortable  align="center" :show-overflow-tooltip="true" />
             <el-table-column label="操作" fixed="right" width="100" border>
                  <template slot-scope="scoped">
                    <el-button type="danger" size="mini" @click="delCard(scoped.row)">删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getLocal } from '@/utils/localstorage'
import 会员api from '../api.js'
import { parseTime } from '@/utils'
import ticketTemplate1 from '@/components/TicketTemplate/printsales.vue'
export default {
   components: {
    ticketTemplate1
  },
  filters: {
    parseTime
  },
  props: {
    isCancel:{
      default(){
        return true;
      },
      },
    data: {
      type: [Object,Array,String],
      default() {
        return {};
      },
    },
  },
    watch: {
    data: {
      handler(n) {
      console.log('watch-data-hb',n)
      this.tableDataz=[n]
      // this.tableData=[]
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      tableDataz: [],
      tableDataf: [],
      activeName: 'first',
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28
      },
      // 查询参数
    queryParams: {
      offset: 0,
      limit: 0,
      carddata: "",
      deptid: getLocal('companyInfo').id,
    },
    }
  },
  computed: {
  },

created(){
  this.tableDataf=[{}]
  // this.tableDataf=[]
},
  methods: {
      // 合并
   async handlecardhb(){
     let arr=[]
       this.tableDataf.forEach(item => {
        let obj = {
            id:item.id,
          }
          arr.push(obj)
      })
       console.log('this.tableDataz.id', this.tableDataz)
      let json={
        cardkey:this.tableDataz[0].id,
        dept_id: this.$store.state.user.currUser.dept_id,
        uby:     this.$store.state.user.currUser.id,
        pos_id:getLocal('shopBaseMsg').terminal,
        computer_no: "计算机名",
        top_source: 1,
        associate_id: this.tableDataz[0].card_id_out,
        data:arr
      }    
       console.log('json', json)
      this.loading = true;
      // let data = { json: JSON.stringify(json) }
       let data = { json }
      let [err,res] = await 会员api.会员卡合并(data)
          if (err) {
            return this.$message.error('会员卡合并失败'+err)
           }
          if (res.statusCode!=200) {
             return this.$message.error('会员卡合并失败')
           }
      // let arr = res.data.rows;
       console.log('合并res', res)
        this.$emit("getFormData",res );
    },
//删除卡号
    delCard: function (item) {
      this.tableDataf = this.tableDataf.filter((o) => o.card_id != item.card_id);
    },

  async searchVipCard(card) {
    
      if (!card) { return this.$message.warning('请输入查询条件') }
      
      this.loading = true;
      let [err,res] = await 会员api.查询会员卡列表(this.queryParams)
          if (err) {
            return this.$message.error('查询会员卡列表失败'+err)
           }
          if (res.statusCode!=200) {
             return this.$message.error('查询会员卡列表失败')
           }
      let arr = res.data.rows;
      console.log('res.data.rows', res.data.rows)
      let arrs=[...arr,... this.tableDataf];
       //根据id去重
      let map=new Map();
      for(let item of arrs){
          if(!map.has(item.card_id)&& item.card_id!=this.tableDataz[0].card_id){
              map.set(item.card_id,item)
          }
      }
      
       let newArray=[...map.values()];
       let newArrays = newArray.filter(value => Object.keys(value).length !== 0);//如何删除空的{}?
       this.tableDataf =newArrays
      this.loading = false;
      this.queryParams.carddata=''
    },
 


    setCurrent(row) {
    console.log('setCurrent', row)
     console.log('111', this.$refs)
     this.$refs.xiaofei.setCurrentRow(row);
    },
    async handleCurrentChange(val) {
      console.log('val', val)
  
    },

// 提交表单
    async seaveData() {
       console.log('seaveData')
      let arr=[]
       this.tableDataf.forEach(item => {
        let obj = {
            id:item.id,
          }
          arr.push(obj)
      })
  let json={
    cardkey:this.tableDataz.id,
    dept_id: this.$store.state.user.currUser.dept_id,
    uby:     this.$store.state.user.currUser.id,
    pos_id:getLocal('shopBaseMsg').terminal,
    computer_no: "计算机名",
    top_source: 1,
    associate_id: this.tableDataz.card_id_out,
    data:arr
  }    

      //  let hb={z:this.tableDataz,
      //          f:arr
      //  }
        this.$emit("getFormData",json );
    },
  //合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value)) 
            && column.property != 'card_name' 
            && column.property != 'card_dis_rate'
            && column.property != 'card_state'
            && column.property != 'saledt_id'
            && column.property != 'notes'
         ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        sums[index] = '';
      }
    });

    return sums;
  },
  }
}
</script>

<style lang="scss" scoped>
.lately-sale-box {
  width: 100%;
  height: 100%;
  .filter-box {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>