import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
Vue.use(Vuex)
// 参考文档 https://github.com/robinvdvleuten/vuex-persistedstate

const store = new Vuex.Store({
    modules: {
      app,
      user
    }
})

export default store
