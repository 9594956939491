import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getLocal } from '@/utils/localstorage'
// create an axios instance

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API +'/api/v1', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout

})
// request interceptor
service.interceptors.request.use(
  config => {
    let baseURL = 'http://erp.djinfo.cc:6001/api/v1'
    let shopBaseMsg = getLocal('shopBaseMsg')
    if (shopBaseMsg && shopBaseMsg.baseAddr) {
      baseURL = shopBaseMsg.baseAddr + '/api/v1'
    }
    config.baseURL = baseURL
    if (store.getters['user/token']) {
      //与后台api一致
      config.headers['authorization'] = getLocal('Admin-Token')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // // if (res.statusCode === 200) {
    // //     console.log('路由请求返回数据')
    // //     console.log(res)
    // //     return res
    // // }

    // // if the custom code is not 20000, it is judged as an error.
    // if (res.statusCode !== 200) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('您已经登出，您可以取消停留在此页面，或再次登录', '确认注销', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    // // 返回正确值
    // // console.log(res) 
    // }
    return res
  },
  error => {
    if (error.response === undefined) {
      Message({
        message: '网络出错,请检查网络连接或请求地址',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
    const res = error.response.data
    if (res.statusCode === 200) return res.data
    let err = error + ' '

    if (res.statusCode == 401) {
      // token错误
      store.commit('app/changeLoginModel', true)
      return Promise.reject(error)
    }
    if (res.statusCode == 400) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service