<template>
  <div>
    <!-- //操作日志 -->
      <div class="table-up">
      <el-table :data="CaozuoLogListData" ref="CaozuoLog"   border height="700">
        <!-- <el-table-column label="卡号" prop="card_id_out" align="center"  :show-overflow-tooltip="true" /> -->
        <el-table-column label="创建时间" prop="lct" width="180" align="center" :resizable="false" :show-overflow-tooltip="true" >
        <template slot-scope="scope">{{ scope.row.lct===null? '' :scope.row.lct|parseTime }}</template>
        </el-table-column>
        <el-table-column label="操作人" prop="name" align="center"  :show-overflow-tooltip="true" />
        <el-table-column label="状态(旧)" align="center"  >
          <template slot-scope="scope">
            <div>
              <span v-show="scope.row.optypeold == 0">未启用</span>
              <span v-show="scope.row.optypeold == 1">有效</span>
              <span v-show="scope.row.optypeold == 2">挂失</span>
              <span v-show="scope.row.optypeold == 3">失效</span>
              <span v-show="scope.row.optypeold == 4">作废</span>
              <span v-show="scope.row.optypeold == 5">写卡</span>
              <span v-show="scope.row.optypeold == 6">充值</span>
              <span v-show="scope.row.optypeold == 7">消费</span>
              <span v-show="scope.row.optypeold == 9">其它</span>
            </div>
          </template>
        </el-table-column>
           <el-table-column label="状态(新)" align="center"  >
          <template slot-scope="scope">
            <div>
              <span v-show="scope.row.optypenew == 0">未启用</span>
              <span v-show="scope.row.optypenew == 1">有效</span>
              <span v-show="scope.row.optypenew == 2">挂失</span>
              <span v-show="scope.row.optypenew == 3">失效</span>
              <span v-show="scope.row.optypenew == 4">作废</span>
              <span v-show="scope.row.optypenew == 5">写卡</span>
              <span v-show="scope.row.optypenew == 6">充值</span>
              <span v-show="scope.row.optypenew == 7">消费</span>
              <span v-show="scope.row.optypenew == 9">其它</span>
            </div>
          </template>
        </el-table-column>
      <el-table-column label="当前时间余额" prop="opmoney" align="center"  :show-overflow-tooltip="true" />
      </el-table>
    </div>
  </div>
</template>

<script>
import 会员api from '../api.js'
import { parseTime } from '@/utils'
export default {
filters: {
    parseTime
  },
   data() {
    return {
      CaozuoLogListData: [],
        // 查询参数
      queryParams: {
        offset: 1,
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        cardid: '',
      },

    }
  },

  methods: {
 /** 查询会员操作日志 */
     async getcardoperlog(id) {
      this.loading = true;
      this.queryParams.cardid=id
      this.queryParams.offset = (this.queryParams.pageNum - 1) * this.queryParams.pageSize  //分页
      this.queryParams.limit = this.queryParams.pageSize
      let [err, res] = await 会员api.查询会员操作日志(this.queryParams)
      if (err) {
        return this.$message.error('查询会员操作日志失败')
      }
          this.CaozuoLogListData = res.data.rows;
          this.total = res.data.pageTotalCount;
          this.loading = false;
    },
  }
}
</script>

<style lang="less" scoped>
.mingxi {
  margin-top: 10px;
  .tit {
    display: flex;

    .tit-item {
      background-color: #f5f5f5;
      color: #333;
      padding: 5px;
      border: 1px solid #ccc;
      border-bottom: none;
    }
  }
}
</style>
