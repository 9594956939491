<template>
  <div style="margin-top:10px;width:100%;">
    <div class="biao-box">
      <div class="form">
        <el-form ref="queryForm" :model="searchform" v-show="showSearch" label-width="80px" inline size="small">
          <!-- <el-form-item label="单据类型" prop="inv_whycat">
            <el-select v-model="searchform.inv_whycat" style="width:170px;">
              <el-option v-for="item in t_pos_inv_op" :key="item.id" :label="item.invop_namec" :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="单据类型" prop="inv_whycat" style="width:170px;">
            <span v-show="searchform.inv_whycat == item.invop_id" v-for="item in t_pos_inv_op" :key="item.id">{{item.invop_namec+DbType}}</span>
          </el-form-item>

          <el-form-item label="单据编号">
            <el-input v-model="searchform.inv_id"></el-input>
          </el-form-item>

          <el-form-item label="发方门店">
            <el-select v-model="searchform.inv_fmshop" clearable style="width:170px;" :disabled="is_to_look">
              <el-option v-for="item in sys_dept" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收方门店">
         <el-select v-model="searchform.inv_toshop" clearable style="width:170px;" :disabled="is_fm_look">
              <el-option v-for="item in sys_dept" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="确认日期" prop="inv_date">
            <el-date-picker size="small" v-model="dateRange" value-format="timestamp" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="handel-btn">
        <el-button v-if="DbType != '入库'" size="mini" type="primary" @click="add">新增</el-button>
        <el-button size="mini" type="danger" @click="goback">关 闭</el-button>
        <el-button size="mini" type="success" style="float:right;" v-if="DbType == '入库'"  @click="downloadList">下载</el-button>
        <el-button size="mini" type="success" style="float:right;" @click="uploadList">上传</el-button>
      </div>
      <div class="content">
        <el-table :data="tableData" border ref="tabledata" @select="onSelect" @select-all="onSelect">
          <el-table-column label="#" width="40" type="selection">
          </el-table-column>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="inv_id" label="单据编号" align="center">
          </el-table-column>
          <el-table-column label="确定时间" prop="inv_date1"  sortable align="center" width="180">
          <template slot-scope="scope">{{ scope.row.inv_date1===null? '' :scope.row.inv_date1|parseTime }}</template>
          </el-table-column>
          <el-table-column prop="inv_whycat" label="单据类型" align="center" width="120">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_whycat == item.invop_id" v-for="item in t_pos_inv_op" :key="item.id">{{item.invop_namec+DbType}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inv_fm" label="发方" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_fm == item.dict_value" v-for="item in t_region" :key="item.dict_value">{{item.dict_label}}</span>
            </template>
          </el-table-column>

           <el-table-column prop="inv_fmshop" label="发方门店" align="center" width="100" >
            <template slot-scope="scope">
              <span v-show="scope.row.inv_fmshop == item.id" v-for="item in sys_dept" :key="item.id">{{item.name}}</span>
            </template>
          </el-table-column>


          <el-table-column prop="inv_to" label="收方" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_to == item.dict_value" v-for="item in t_region" :key="item.dict_value">{{item.dict_label}}</span>
            </template>
          </el-table-column>
            <el-table-column prop="inv_toshop" label="收方门店" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_toshop == item.id" v-for="item in sys_dept" :key="item.id">{{item.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inv_prod" label="生产班次" align="center" width="180">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_prod == item.dict_value" v-for="item in shopprod" :key="item.dict_value">{{item.dict_label}}</span>
            </template>
          </el-table-column>
           <el-table-column label="上传" width="70">
            <template slot-scope="scope">
              <span v-show="scope.row.tstate == 0" style="color:#909399;">新建</span>
              <span v-show="scope.row.tstate == 1" style="color:#67c23a;">未上传</span>
              <span v-show="scope.row.tstate == 9" style="color:#67c23a;">已上传</span>
            </template>
          </el-table-column>

          <el-table-column prop="bstate" label="状态" width="60">
            <template slot-scope="scope">
              <span v-show="scope.row.bstate == 0" style="color:#909399;">起草</span>
              <span v-show="scope.row.bstate == 1" style="color:#e6a23c;">确认</span>
              <span v-show="scope.row.bstate == 2" style="color:#66b1ff;">发出</span>
              <span v-show="scope.row.bstate == 3" style="color:#67c23a;">收到</span>
            </template>
          </el-table-column>

          <el-table-column prop="lby" label="操作人" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.lby == item.id" v-for="item in users" :key="item.id">{{item.name}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="inv_billid" label="手工单号" align="center">
          </el-table-column>

          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="mini" icon="el-icon-view" @click="editRpt([scope.row],true)">查看</el-button>
              <!-- <el-button :disabled="scope.row.tstate == 1" type="text" size="mini" icon="el-icon-edit" @click="editRpt([scope.row])">修改</el-button> -->
              <el-button :disabled="scope.row.tstate == 1 || scope.row.bstate == 1 || scope.row.bstate == 3" type="text" size="mini" icon="el-icon-delete" @click="det([scope.row])">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="page_data.total > 0" :total="page_data.total" :page.sync="page_data.currentPage" :limit.sync="page_data.pagesize" @pagination="initData" />

        <!-- <div style="margin-top:10px;">  
          <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total"
            background hide-on-single-page>
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import 库存单api from './api.js'
import { getLocal, setLocal } from '@/utils/localstorage'
import { parseTime, pagePagination } from '@/utils/index.js'
import { mapGetters } from 'vuex'
export default {
  filters: {
    parseTime
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_pos_inv_op', 'dictdata','sys_dept']),
    ...mapGetters('user', ['users'])
  },
  data() {
    return {
      DbType: '',//调拨类型
      // 显示搜索条件
      showSearch: true,
      is_fm_look: false,
      is_to_look: false,
     // 日期范围
      dateRange: [],
      activeName: 'first',
      text: '',
      pos_invop: 0,
      //发方
      invfm: [],
      //收方
      invto: [],
      //门店班组
      shopround: [],
      //门店生产班组
      shopprod: [],

      searchform: {
        inv_id: '',
        inv_billtype: 0,//不用 门店单据类型0报货单1铺货单
        inv_region: 0,//不用
        inv_date1: '',
        inv_billid: '',//门店手工单据号
        inv_whycat: null,
        inv_whycat_name: '',
        inv_fm: '',
        inv_fmcat: '',
        inv_fmshop: '',//发送门店用于门店间调拨
        inv_to: '',
        inv_tocat: '',
        inv_toshop: '',//接收门店用于门店间调拨
        inv_extraid: null,//外部单位编号
        inv_round: null,//门店班次  登录班次
        inv_prod: null,//生产班次
        notes: '',//备注
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      radio: [],
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 20
      },
      query: {
        type: 0,
        look: 0,
        invop: 0,
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter',to);
    next(vm => {
      vm.$store.commit('app/setkucunPage', to.fullPath)
    })
  },
  async created() {
    console.log(this.$route.query)
    if (this.$route.query.dbtype=='DC'){
      this.DbType='出库'
      this.is_fm_look=false
      this.is_to_look=true
      this.searchform.inv_fmshop=getLocal('companyInfo').id
    }
     if (this.$route.query.dbtype=='DR'){
      this.DbType='入库'
       this.is_fm_look=true
       this.is_to_look=false
       this.searchform.inv_toshop=getLocal('companyInfo').id
     }
    this.pos_invop = this.$route.params.k_type//获取库存操作类别
    this.searchform.inv_whycat = this.$route.params.k_type

    //获得操作类型数据当前行数据
    this.t_pos_inv_op.forEach(item => {
      if (item.id == this.$route.params.k_type) {
        if (item.invop_sorc == 'KC') {
          this.invfm = this.t_region
        } else if (item.invop_dest == 'KC') {
          this.invto = this.t_region
        }
      }
    })
    this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
    this.shopprod = this.dictdata.filter(item => item.dict_type == "bus_shop_prod")//门店生产班组

    //  console.log(1111,shop_round,shop_prod)
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      this.loading = true;
      let 库存单 = await this.$localforage.getItem('t_pos_inv_bill') || []
      let user = this.$store.state.user.currUser
      //需要根据单据类型过滤数据
      let arr
       let subTime ,supTime
        subTime = this.dateRange[0];
        supTime = this.dateRange[1];
       if (this.dateRange.length==0){
          subTime=0
          supTime=4133865600000 //2100-12-31
       }
      if (this.$route.query.dbtype=='DC'){
       arr = 库存单.filter(item =>
        item.bstate >= 0 
        && item.inv_whycat == this.pos_invop //生产入库
        && item.inv_id.includes(this.searchform.inv_id)
        && Number(item.inv_fmshop).toString().includes(this.searchform.inv_fmshop)
        && Number(item.inv_toshop).toString().includes(this.searchform.inv_toshop)
         &&  subTime <= item.inv_date1 && item.inv_date1 <= supTime
        && Number(item.inv_fmshop).toString().includes(user.dept_id)//调拨出库
      )
      } else if (this.$route.query.dbtype=='DR'){
        arr = 库存单.filter(item =>
        item.bstate >= 0 
        && item.inv_whycat == this.pos_invop //生产入库
        && item.inv_id.includes(this.searchform.inv_id)
        && Number(item.inv_fmshop).toString().includes(this.searchform.inv_fmshop)
        && Number(item.inv_toshop).toString().includes(this.searchform.inv_toshop)
         &&  subTime <= item.inv_date1 && item.inv_date1 <= supTime
        && Number(item.inv_toshop).toString().includes(user.dept_id)//调拨出库
      )
      }
          //降序排列排序
      arr.sort(function(a,b){
			return b.inv_date1 - a.inv_date1
		  })
      this.tableData = pagePagination(arr, this.page_data.pagesize, this.page_data.currentPage)
      this.page_data.total = arr.length
      this.loading = false;
    },
    // 分页
    handleCurrentChange(v) {
      this.page_data.currentPage = v
      this.initData()
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.page_data.currentPage = 1;
      this.initData();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.reset()
      this.handleQuery();
    },
    // 表单重置
    reset() {
      this.searchform = {
        inv_id: '',
        inv_fm: '',
        inv_to: '',
        inv_needdate: '',
        inv_date1: ''
      };
      this.resetForm("queryForm");
    },

    goback() {
      this.$router.push('/goodsStorage')
      this.$store.commit('app/setkucunPage', null)
    },
    add() {
      this.query = {
         dbtype:this.$route.query.dbtype,
      }
       if (this.$route.query.dbtype=='DC'){
      this.$router.push({ path: `/warehousdbcing/${this.$route.params.k_type}/0`, query: this.query })
      // this.$router.push('/warehousing/' + this.pos_invop + '?type=' + '1')
       } else if (this.$route.query.dbtype=='DR'){
      this.$router.push({ path: `/warehousdbring/${this.$route.params.k_type}/0`, query: this.query })
      // this.$router.push('/warehousing/' + this.pos_invop + '?type=' + '1')
       }
    },
    editRpt(val, look) {
      this.query = {
        dbtype:this.$route.query.dbtype,
      }
      let id = val[0].id
      //  console.log(id)
      if (this.$route.query.dbtype=='DC'){
      this.$router.push({ path: `/warehousdbcing/${this.$route.params.k_type}/${id}`, query: this.query })
        } else if (this.$route.query.dbtype=='DR'){
          this.$router.push({ path: `/warehousdbring/${this.$route.params.k_type}/${id}`, query: this.query })
        } 
    },

    // 删除
    det(list) {
      this.$confirm('是否删除选中的报货单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let table = this.tableData
        let 报货明细表 = await this.$localforage.getItem('t_pos_inv_bill_items') || [] //明细表
        let 报货主表 = await this.$localforage.getItem('t_pos_inv_bill') || []//主表

        list.forEach(item => {
          for (let i = table.length - 1; i >= 0; i--) {
            if (item.id == table[i].id) { // 先删除页面上表格的数据
              table.splice(i, 1)
            }
          }
          // 删除本地存储的数据
          for (let i = 报货主表.length - 1; i >= 0; i--) {
            if (item.id == 报货主表[i].id) { // 删除主表数据
              报货主表.splice(i, 1)
            }
          }
          for (let i = 报货明细表.length - 1; i >= 0; i--) {
            if (item.id == 报货明细表[i].pid) { // 删除主表id等于id的明细表
              报货明细表.splice(i, 1)
            }
          }
        });
        // c存入数据
        await this.$localforage.setItem('t_pos_inv_bill_items', 报货明细表)
        await this.$localforage.setItem('t_pos_inv_bill', 报货主表)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击复选框
    onSelect(val) {
      this.radio = val
    },
    //上传
    async uploadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let 报货明细表 = await this.$localforage.getItem('t_pos_inv_bill_items') || [] //明细表
      let 报货主表 = await this.$localforage.getItem('t_pos_inv_bill') || []//主表
      let master = []
      let 主表上传的下标 = [] //保存住上传的数据的下标
      报货主表.forEach((item, i) => {
        console.log(item)
        if (item.tstate == 1 && item.bstate == 3 && item.inv_whycat == this.pos_invop && this.$route.query.dbtype=='DR') {
          master.push(JSON.parse(JSON.stringify(item)))
          主表上传的下标.push(i)
        }
        if (item.tstate == 1 && item.bstate == 1 && item.inv_whycat == this.pos_invop && this.$route.query.dbtype=='DC') {
          master.push(JSON.parse(JSON.stringify(item)))
          主表上传的下标.push(i)
        }
      })
   
      let detail = []
      let 明细下标 = []
      master.forEach(item => {
        let arr = []
        for (let i = 0; i < 报货明细表.length; i++) {
          if (报货明细表[i].pid == item.id) {
            arr.push(报货明细表[i])
            明细下标.push(i)
          }
        }
        detail.push(...arr)
      })
      if (master.length > 0) {
        let data = {
          "jsons": {
            master: master,
            detail: detail
          }
        }

        let [err, res] = await 库存单api.入库单上传(data).catch(err => { loading.close() })
        if (err) return loading.close()
        if (res.statusCode == 200) {
          loading.close()
          this.$message.success('数据上传成功')
          for (let i = 0; i < 主表上传的下标.length; i++) {
            报货主表[主表上传的下标[i]].tstate = 9
          }
          for (let i = 0; i < 明细下标.length; i++) {
            报货明细表[明细下标[i]].tstate = 9
          }
          await this.$localforage.setItem('t_pos_inv_bill_items', 报货明细表)
          await this.$localforage.setItem('t_pos_inv_bill', 报货主表)
          this.initData()
          return
        } else {
          loading.close()
          return this.$message.error(res.message)
        }
      }
      this.$message.error('没有需要上传的数据')
      loading.close()
    },
     //下载
    async downloadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deptid = getLocal('currUser').dept_id
      let 下载时间 = await this.$localforage.getItem('t_pos_lastdowndatatime') || []
      if (下载时间.length < 1) {
        loading.close()
        this.$message.error('没有找到下载时间,请在设置中重新下载数据')
      }
      let 报货单下载时间 = 0;
      let 报货单下载时间下标 = null
      下载时间.forEach((item,i) => {
        if (item.tableid == "t_pos_inv_bill" && item.isupdown == 1 && item.ismore == 1) {
          报货单下载时间 = item.lastdowntime
          报货单下载时间下标 = i
        }
      })
      let data = {
        "uct": 报货单下载时间,
        "deptid": deptid
      }
       console.log('报货单下载时间',data);
      let [err, res] = await 库存单api.下载调拨单(data)
       console.log('下载调拨单',res);
      if (err) {
        loading.close()
        return this.$message.error('报货单下载失败')
      }
      
      if (res.statusCode == 200) {
        let 下载报货单 = res.data.master
        let 下载报货单明细 = res.data.detail
        if(JSON.stringify(下载报货单) === '[]'||JSON.stringify(下载报货单明细) === '[]'){  loading.close(); return }
        let 本地报货单 = await this.$localforage.getItem('t_pos_inv_bill') || []
        let 本地报货单明细 = await this.$localforage.getItem('t_pos_inv_bill_items') || []
        for (let i = 0; i < 下载报货单.length; i++) {
          let item = 下载报货单[i]
          let j = 本地报货单.findIndex(v => v.id == item.id)
          if (j >= 0) {
            for (const key in item) {
              本地报货单[j][key] = item[key] //  将下载的数据替换到本地数据上
            }
          } else {
            // 如果订单不存在,直接加入本地
            本地报货单.push(item)
          }
        }
        for (let k = 0; k < 下载报货单明细.length; k++) {
          let item_i = 下载报货单明细[k]
          let l = 本地报货单明细.findIndex(w => w.id == item_i.id)
          if (l >= 0) {
            for (const key in item_i) {
              本地报货单明细[l][key] = item_i[key] //  将下载的数据替换到本地数据上
            }
          } else {
            本地报货单明细.push(item_i)
          }
        }
        await this.$localforage.setItem('t_pos_inv_bill', 本地报货单)
        await this.$localforage.setItem('t_pos_inv_bill_items', 本地报货单明细)
        下载时间[报货单下载时间下标].lastdowntime = new Date().getTime()
        await this.$localforage.setItem('t_pos_lastdowndatatime', 下载时间)
        console.log('下载时间2',下载时间[23].lastdowntime);
        // 重新获取本地数据
        this.initData()
        loading.close()
      }
      loading.close()
    },
  }

}
</script>

<style scoped lang="less">
.biao-box {
  width: 100%;
  // padding: 15px 40px;
}
.handel-btn {
  margin: 10px 0;
  padding: 0 10px;
}
</style>