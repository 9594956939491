<template>
  <div>
      <!-- //结存信息 -->
    <div class="table-up">
           <el-table :data="JiecunListData"  ref="Jiecun"   border
                     :summary-method="getSummaries" show-summary style="width: 100%;" height="700">
          <el-table-column label="结存时间" prop="inout_date" width="180" align="center" :resizable="false" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{ scope.row.inout_date===null? '' :scope.row.inout_date|parseTime }}</template>
        </el-table-column>
            <el-table-column label="上期金额" prop="inout_money"  align="center" :show-overflow-tooltip="true" />
            <el-table-column label="充值金额" prop="inout_top"  align="center" />
            <el-table-column label="赠送金额" prop="inout_zstop"  align="center" />
            <el-table-column label="消费金额" prop="inout_card" align="center"  />
            <el-table-column label="其它金额" prop="inout_other" align="center"  />
            <el-table-column label="结存金额" prop="inout_remoney" align="center"  />

          </el-table>
      </div>
  </div>
</template>

<script>
import 会员api from '../api.js'
import { parseTime } from '@/utils'
export default {
filters: {
    parseTime
  },
   data() {
    return {
      JiecunListData: [],
        // 查询参数
      queryParams: {
        offset: 1,
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        cardid: '',
      },

    }
  },

  methods: {
 /** 查询会员卡结存列表 */
   async getcardinout(id) {
      this.loading = true;
      this.queryParams.cardid=id
  let [err, res] = await 会员api.查询会员结存情况(this.queryParams)
      if (err) {
        return this.$message.error('查询会员结存情况失败')
      }
       this.JiecunListData = res.data.rows;
       this.loading = false;
      
    },

     // 表单重置
    reset() {
      this.JiecunListData=[]
      this.resetForm("JiecunListData");
    },

  //合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计:';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += ' ';
      } else {
        sums[index] = 'N/A';
      }
    });

    return sums;
  },

   },
}
</script>

<style lang="less" scoped>
.mingxi {
  margin-top: 10px;
  .tit {
    display: flex;

    .tit-item {
      background-color: #f5f5f5;
      color: #333;
      padding: 5px;
      border: 1px solid #ccc;
      border-bottom: none;
    }
  }
}
</style>
