<template>
  <div class="goods-type">
    <el-row>
      <el-col :span="24">
        <div class="goods-type">
          <el-tabs boder style="margin-left: 10px" type="card" @tab-click="onTabClick" v-model="select_tab">
            <el-tab-pane label="类别" name="类别">
              <type-card-list :data="type0Goods" @clickitem="chooseType"></type-card-list>
            </el-tab-pane>
            <el-tab-pane label="简码" name="简码">
              <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput"></all-keyboard>
            </el-tab-pane>
            <el-tab-pane label="条码" name="条码">
              <number-keyboard @input-num="onBarCodeInput"></number-keyboard>
            </el-tab-pane>
            <el-tab-pane label="仓库" name="仓库">
              <div class="warehouse">
                <div :class="item.id === now_warehouse? 'warehouse-item active' : 'warehouse-item'" v-for="item in warehouse_list" :key="item.id" @click="onWarehouseClick(item.id)">{{item.wrhus_name}}</div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="常用" name="常用">
              常用
            </el-tab-pane>
            <el-tab-pane label="销量" name="销量">
              销量
            </el-tab-pane>
            <el-tab-pane label="库存" name="库存">
              库存
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <div class="goods-list">
          <el-tabs boder type="card" style="margin-left: 10px">
            <el-tab-pane label="商品">
              <div class="goods-box">
                <div v-show="goodsBoxData.length > 0">
                  <goods-card :data="goodsBoxData" @chooseGoods="addOrderList"></goods-card>
                </div>
                <div v-show="goodsBoxData.length <= 0" class="noGoods">
                  <img src="../../../assets/00000000.jpg" alt="">
                  <span class="noGoods-text">暂无商品</span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="加工">
              加工
            </el-tab-pane>
            <el-tab-pane label="要求">
              要求
            </el-tab-pane>
            <el-tab-pane label="位置">
              位置
            </el-tab-pane>
            <el-tab-pane label="详细">
              详细
            </el-tab-pane>

          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import typeCardList from './TypeCardList.vue'
import GoodsCard from './GoodsCard.vue';
import AllKeyboard from '@/components/Keyboard.vue'
import NumberKeyboard from './NumberKeyboard.vue'
export default {
  props: {
    issale: {
      type: Boolean,
      default: false
    }
  },
  components: {
    typeCardList,
    GoodsCard,
    AllKeyboard,
    NumberKeyboard
  },
  data() {
    return {
      select_tab: '类别',
      now_type: null,
      type0Goods: [],
      //商品显示框的商品数据
      goodsBoxData: [],
      // 所有的商品数据
      goodsData: [],
      // 仓库数据
      warehouse_list:[],
      // 当前选择的仓库id
      now_warehouse:null
    }
  },
  computed: {
    ...mapGetters('user', ['t_pos_inv_bal'])
  },
  watch: {
    t_pos_inv_bal: {
      handler: function (val, oldval) {
        this.chooseType(this.now_type)
      },
      deep: true//对象内部的属性监听，也叫深度监听
    }
  },
  async created() {
    await this.getGoodsData()
    await this.getTypeData()
    await this.getWarehouse()
  },
  methods: {
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      this.type0Goods = typeData.filter(i => !i.isdel)
      if (this.type0Goods.length > 0) {
        this.chooseType(this.type0Goods[0])
      }
    },
    // 获取仓库列表
   async getWarehouse(){
     let warehouse_list = await this.$localforage.getItem('t_wrhus') || []
      this.warehouse_list = warehouse_list.filter(item => item.isdel !== 1)
      this.now_warehouse = this.warehouse_list[0].id

    },
    //点击分类显示分类下的商品
    chooseType(v) {
      if (!v) return
      this.now_type = v
      let id = v.id
      let arr = []
      let 总库存 = this.$store.state.user.t_pos_inv_bal||[]
       console.log('总库存11',this.$store.state.user)
      this.goodsData.forEach(item => {
        let typearr = item.partusertype.split(',')
        if (typearr.indexOf(id + '') >= 0 && !item.isdel) {
          let n = 总库存.findIndex(j => j.part_id == item.part_id)
          item.stock = null
          if (n >= 0) {
            item.stock = 总库存[n].bal_amnt
          }
          arr.push(item)
          // console.log('总库存11', n)
        }
      })
      this.goodsBoxData = arr
      this.$emit('chooseType', v)
    },
    // 根据简码显示商品
    onKeyInput(v) {
      if (!v) this.goodsBoxData = this.goodsData
      // this.goodsBoxData = this.goodsData.filter(item => { return (item.part_short == v && !item.isdel) })
      // console.log(' 根据简码显示商品',  this.goodsBoxData)

      this.goodsBoxData  = this.goodsData.filter(item => 
       !item.isdel 
       && 
       (
          item.part_short.includes(v)
       || item.part_id.includes(v)   
       || item.part_name.includes(v) 
       )
      ) 
    },
    // 根据条码显示商品
    onBarCodeInput(v){
      if (!v) this.goodsBoxData = this.goodsData
      this.goodsBoxData = this.goodsData.filter(item => { return (item.part_barcode == v && !item.isdel) })

     let part_barcode
    },
    // 根据仓库显示商品
    onWarehouseClick(v){
      if (!v) this.goodsBoxData = this.goodsData
      this.goodsBoxData = this.goodsData.filter(item => { return (item.part_stock_defhus == v && !item.isdel) })
      this.now_warehouse = v
    },
    // 点击的商品
    addOrderList(item) {

        // 是否为销售模式
    if (this.issale) {
      item.saledt_dis_1=0
      item.saledt_dis_2=0
      item.count=1
      if (item.r_shopsale === 1) { //是否允许销售
       if (item.r_salestore === 0) { //门店销售出库允许负库存出库0否1是
        if (item.r_service === 0 && item.stock <= 0) {  // 门店服务性商品0否1是不管理门店库存
            return this.$message.error('该商品库存不足,无法销售')
           } else {
              return this.$emit('chooseGoods', item) // 为负数库存可以销售 为门店服务性商品 可以销售
            }
        } else{
            this.$emit('chooseGoods', item)
          }
        } else {
           return this.$message.error('该商品不是销售商品,无法销售，后台设置是否允许销售属性')
        }
      } else {//退货模式
            this.$emit('chooseGoods', item)
      }

      // // 是否为销售模式
      // if (this.issale) {
      //   if (item.stock == 0) { // 先判断库存是否为0
      //     if (item.r_shopsale === 1) { //判断 负数库存是否可以销售
      //       if (item.r_service === 0) {  // 判断 是否门店服务性商品
      //         return this.$message.error('该商品库存不足,无法销售')
      //       } else {
      //         return this.$emit('chooseGoods', item) // 为负数库存可以销售 为门店服务性商品 可以销售
      //       }
      //     } else {
      //       return this.$message.error('该商品库存不足,无法销售')
      //     }
      //   } else {
      //     this.$emit('chooseGoods', item)
      //   }
      // } else {
      //   this.$emit('chooseGoods', item)
      // }


    },

    // 切换tab
    onTabClick() {
      this.goodsBoxData = this.goodsData
      this.$refs.allkeyboardRef.reset()
      if (this.select_tab === '类别') {
        this.chooseType(this.now_type)
      }
      if(this.select_tab === '仓库'){
        this.onWarehouseClick(this.now_warehouse)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-type {
  padding: 5px;
}
.box-item {
  list-style: none;
  width: 23%;
  border: 1px solid #e5e9f2;
  height: auot;
  overflow: hidden;
  background-color: #fff;
  padding: 2px;
  float: left;
  margin: 2px;
  cursor: pointer;
}
.mon-text {
  margin-top: 5px;
  color: #333;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.warehouse {
  display: flex;
  flex-wrap: wrap;
  .warehouse-item {
    background-color: #fff;
    color: #333;
    padding: 0 8px;
    height: 45px;
    border: 1px solid #999;
    text-align: center;
    line-height: 45px;
    margin-right: 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  .active {
    background-color: #2d8cf0;
    color: #fff;
    box-shadow: 1px 1px 3px #999;
  }
}
</style>