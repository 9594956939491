// import Cookies from 'js-cookie'
// import Storage from '@/utils/storage'
import { MessageBox, Message } from 'element-ui'
import localforage from 'localforage'

const state = {
    login_module_show: false,
    // 部门信息
    sys_dept: [],
    // 仓库类型
    t_wrhus: [],
    // 要货区域
    t_region: [],
     // 出入库单据类型
    t_pos_inv_op: [],
     //单据操作类型
    t_inv_op: [],
    //门店班次
    round: [],
    //生产班次
    prod: [],
    //t_sys_dict_data表数据
    dictdata: [],
    left_baohuo_currentPage:null,
    left_kucun_currentPage:null,
    left_lingshou_currentPage:null,
    
}

const getters = {
    login_module_show: state => state.login_module_show,
    //部门信息
    sys_dept: state =>{
        return state.sys_dept.filter(i => i.isdel === 0)
    },
    //仓库
    t_wrhus: state =>{
        return state.t_wrhus.filter(i => i.isdel === 0)
    },
    //门店区域
    t_region: state => {
        return state.t_region.filter(i => i.isdel === 0)
    },
    //出入库类型
    t_pos_inv_op: state => {
        return state.t_pos_inv_op.filter(i => i.isdel === 0)
    },
    //单据操作类型
     t_inv_op: state => {
        return state.t_inv_op.filter(i => i.isdel === 0)
    },
     //t_sys_dict_data表数据
     dictdata: state => {
        return state.dictdata.filter(i => i.isdel === 0)
    }, 
    left_baohuo_currentPage:state => state.left_baohuo_currentPage,
    left_kucun_currentPage:state => state.left_kucun_currentPage,
    left_lingshou_currentPage:state => state.left_lingshou_currentPage
}

const mutations = {
    changeLoginModel(state, data) {
        state.login_module_show = data
    },
    setSys_dept(state, data) {
        state.sys_dept = data
    },
    setT_wrhus(state, data) {
        state.t_wrhus = data
    },
    setYhqy(state, data) {
        state.t_region = data
    },
    setPosinvop(state, data) {
        state.t_pos_inv_op = data
    },
    setinvop(state, data) {
        state.t_inv_op = data
    },
    //公共数据
    setDictdata(state, data) {
        state.dictdata = data
    },
    setbaohuoPage(state, data){
        state.left_baohuo_currentPage = data
    },
    setkucunPage(state, data){
        state.left_kucun_currentPage = data
    },
    setlingshouPage(state, data){
        state.left_lingshou_currentPage = data
    }
}

const actions = {
    async getSys_dept(context, data) {
        let res = await localforage.getItem('sys_dept')
        if (!res) {
            Message({
                message: '暂无部门数据,请在设置中下载',
                type: 'error',
                duration: 5 * 1000
            })
            res = []
        }
        context.commit('setSys_dept', res)
    },
    async getT_wrhus(context, data) {
        let res = await localforage.getItem('t_wrhus')
        if (!res) {
            Message({
                message: '暂无仓库数据,请在设置中下载',
                type: 'error',
                duration: 5 * 1000
            })
            res = []
        }
        context.commit('setT_wrhus', res)
    },
    async getYhqy(context, data) {
        let res = await localforage.getItem('t_sys_dict_data')
        if (!res) {
            Message({
                message: '暂无关键数据,请在设置中下载全部',
                type: 'error',
                duration: 5 * 1000
            })
            res = []
        }
        let msg = res.filter(item => item.dict_type == "bus_shop_region")
        context.commit('setYhqy', msg)
    },
    async getPosinvop(context, data) {
        let res = await localforage.getItem('t_pos_inv_op')
        if (!res) {
            Message({
                message: '暂无关键数据,请在设置中下载全部',
                type: 'error',
                duration: 5 * 1000
            })
            res = []
        }
        context.commit('setPosinvop', res)
    },
    async getinvop(context, data) {
        let res = await localforage.getItem('t_inv_op')
        if (!res) {
            Message({
                message: '暂无关键数据,请在设置中下载全部',
                type: 'error',
                duration: 5 * 1000
            })
            res = []
        }
        context.commit('setinvop', res)
    },
    //公共数据
    async getDictdata(context, data) {
        let res = await localforage.getItem('t_sys_dict_data')
        if (!res) {
            Message({
                message: '暂无关键数据,请在设置中下载全部',
                type: 'error',
                duration: 5 * 1000
            })
            res = []
        }
        context.commit('setDictdata', res)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
