export function getSession(key) {
  if (sessionStorage.getItem(key)) return JSON.parse(sessionStorage.getItem(key))
  return []
}

export function setSession(key,value) {
  return sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeSession(key) {
  return sessionStorage.setItem(key,'')
}