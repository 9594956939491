
<template>
  <div style="margin-top:10px;width:100%;">
    <el-tabs v-model="activeName" type="wm">
    <el-tab-pane label="美团" name="first">
         <meituan  />
        </el-tab-pane>
     <el-tab-pane label="饿了么" name="second">
         <elm  />
     </el-tab-pane>
     
  
       <!-- <el-tab-pane label="会员合并" name="second">
         <memberhb  />
      </el-tab-pane> -->
     <!-- <el-tab-pane label="会员密码" name="second">
      </el-tab-pane>
      <el-tab-pane label="会员消费" name="second">
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>




<script>
import elm from "./elm";
import meituan from "./meituan";
export default {
  name: "wm",
  components: { meituan, elm},
   data() {
   return {
      user: {},
       activeName: 'first',
    };
     },
}
</script>

<style>

</style>

