<template>
  <div style="margin-top:10px;width:100%;">
    <div class="biao-box">
      <div class="form">
        <el-form ref="form" :model="searchform" label-width="80px" inline size="small">
          <el-form-item label="编号">
            <el-input v-model="searchform.inv_id"></el-input>
          </el-form-item>
          <el-form-item label="单据类型">
            <el-select v-model="searchform.inv_bustype" style="width:120px;">
              <el-option label="一报" :value="0"></el-option>
              <el-option label="二报" :value="1"></el-option>
              <el-option label="订单报货" :value="9"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="要货区域">
            <el-select v-model="searchform.inv_region" style="width:120px;">
              <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单号">
            <el-input v-model="searchform.name"></el-input>
          </el-form-item>
          <el-form-item label="仓库">
            <el-select v-model="searchform.inv_fm" style="width:170px;">
              <el-option v-for="item in t_wrhus" :key="item.id" :label="item.wrhus_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
       
          <el-form-item label="报货时间" prop="inv_needdate">
                <el-date-picker
                size="small"
                  v-model="inv_needdate"
                  value-format="timestamp"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="需求日期" prop="inv_date1">
                <el-date-picker
                size="small"
                  v-model="inv_date1"
                  value-format="timestamp"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
               <el-form-item>
                <el-button
                  type="cyan"
                  icon="el-icon-search"
                  size="small"
                  @click="handleQuery"
                  >搜索</el-button
                >
                <el-button icon="el-icon-refresh" size="small" @click="resetQuery"
                  >重置</el-button
                >
              </el-form-item>
        </el-form>
      </div>
      <div class="handel-btn">
        <el-button size="small" type="success" @click="downloadList">下载报货单</el-button>
        <el-button size="small" type="primary" @click="refresh">刷 新</el-button>
        <el-button style="float:right;" type="success" size="small" @click="uploadList">上传报货单</el-button>
      </div>
      <div class="content">
        <el-table :data="tableData" border ref="tabledata" @select="onSelect" @select-all="onSelect">
          <el-table-column label="#" width="40" type="selection">
          </el-table-column>
          <el-table-column prop="inv_id" label="单据编号" sortable align="center"  width="220">
          </el-table-column>
          <el-table-column prop="inv_needdate" label="报货时间" sortable align="center" width="180">
            <template slot-scope="scope">
              <div>{{scope.row.inv_needdate | parseTime}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="inv_date1" label="发送时间" sortable align="center" width="180">
            <template slot-scope="scope">
              <div>{{scope.row.inv_date1 | parseTime}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="inv_date2" label="收货时间" sortable align="center" width="180">
            <template slot-scope="scope">
              <div>{{scope.row.inv_date2 | parseTime}}</div>
            </template>
          </el-table-column>

          <el-table-column prop="inv_bustype" label="单据类型" sortable align="center" width="150">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_bustype == 0">一报</span>
              <span v-show="scope.row.inv_bustype == 1">二报</span>
              <span v-show="scope.row.inv_bustype == 3">订单报货</span>
            </template>
          </el-table-column>

          <el-table-column label="仓库" sortable align="center" >
            <template slot-scope="scope">
              <span v-show="scope.row.inv_fm == item.id" v-for="item in t_wrhus" :key="item.id">{{item.wrhus_name}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="inv_region" label="要货区域" sortable align="center" width="150">
            <template slot-scope="scope">
              <span v-show="scope.row.inv_region == item.dict_value" v-for="item in t_region" :key="item.id">{{item.dict_label}}</span>
            </template>
          </el-table-column>

          <el-table-column label="上传" sortable align="center" width="100">
            <template slot-scope="scope">
               <span v-show="scope.row.tstate == 0" style="color:#909399;">新建</span>
                    <span v-show="scope.row.tstate == 1" style="color:#67c23a;">待上传</span>
                    <span v-show="scope.row.tstate == 9" style="color:#67c23a;">已上传</span>
            </template>
          </el-table-column>

          <el-table-column prop="bstate" label="状态" sortable align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.bstate == 0" style="color:#909399;">起草</span>
              <span v-show="scope.row.bstate == 1" style="color:#e6a23c;">确认</span>
              <span v-show="scope.row.bstate == 2" style="color:#66b1ff;">发出</span>
              <span v-show="scope.row.bstate == 3" style="color:#67c23a;">收到</span>
            </template>
          </el-table-column>

         <el-table-column prop="lby" label="操作人" align="center" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.lby == item.id" v-for="item in users" :key="item.id">{{item.name}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="inv_billid" label="手工单号" sortable align="center" >
          </el-table-column>

          <el-table-column label="操作"  align="center"  width="70">
            <template slot-scope="scope">
              <el-button type="text" size="mini" icon="el-icon-view" @click="lookInfo(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;">
          <pagination v-show="page_data.total > 0" :total="page_data.total" :page.sync="page_data.currentPage" :limit.sync="page_data.pagesize" @pagination="initData" />
           
          <!-- <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total"
            background hide-on-single-page>
          </el-pagination> -->
        </div>
      </div>
    </div>
    <!-- <el-dialog title="收货详情" :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false">
      <div>
        <receive-info :defaultdata="nowList" @receive="onReceived"></receive-info>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import 收货api from '../api.js'
import { parseTime, pagePagination } from '@/utils/index.js'
import { getLocal, setLocal } from '@/utils/localstorage'
import { mapGetters } from 'vuex'
// import ReceiveInfo from './收货详情.vue'
export default {
  filters: {
    parseTime
  },
  // components: {
  //   ReceiveInfo
  // },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region','t_inv_op']),
    ...mapGetters('user', ['users'])
  },
  data() {
    return {
       // 显示搜索条件
      showSearch: true,
       // 日期范围
      inv_needdate: [],
      inv_date1: [],

      text: '',
      searchform: {
        inv_id: '',
        inv_bustype: '',
        inv_billid: '',
        inv_region: '',
        inv_fm: '',
        inv_needdate: '',
        inv_date1: ''
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      radio: [],
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 20
      },
      dialogVisible: false,
      nowList: {}
    }
  },
  
  mounted() {
    this.initData()
  },
  methods: {
    refresh(){
      this.initData()
    },
    async initData() {
      this.loading = true;
      let 报货单 = await this.$localforage.getItem('t_inv_bill') || []
      console.log('报货单',报货单)
       console.log('this.searchform.inv_to',this.inv_needdate)
       let invwhycat
      invwhycat = this.t_inv_op.filter(item => item.invop_shopop == 1)
       if (this.inv_needdate.length==0){this.inv_needdate[0]=0,this.inv_needdate[1]=4102329600000}

      let arr = 报货单.filter(item => 
      item.bstate > 1
      && item.inv_id.includes(this.searchform.inv_id ) 
      && item.inv_whycat.includes(invwhycat[0].invop_id)
      // && item.inv_bustype.toString().includes(this.searchform.inv_bustype)  
      // && item.inv_billid.includes(this.searchform.inv_billid) 
      && item.inv_region.includes(this.searchform.inv_region) 
      && item.inv_fm.toString().includes(this.searchform.inv_fm)  
      &&  (this.inv_needdate[0] <= item.inv_needdate && item.inv_needdate <= this.inv_needdate[1]) 
      )
             //降序排列排序
      arr.sort(function(a,b){
			return b.inv_date1 - a.inv_date1
		  })
      this.tableData = pagePagination(arr, this.page_data.pagesize, this.page_data.currentPage)
      this.page_data.total = arr.length
      this.loading = false;

    },


    // 分页
    handleCurrentChange(v) {
      this.page_data.currentPage = v
      this.initData()
    },

      /** 搜索按钮操作 */
    handleQuery() {
      this.page_data.currentPage = 1;
      this.initData();
    },
    /** 重置按钮操作 */
    resetQuery() {
      // this.resetForm("queryForm");
      this.searchform={};
      this.searchform.inv_id=''
      this.handleQuery();
    },

    add() {
      this.$router.push('/rptgoods')
    },
    editRpt(val) {
      let id = val[0].id
      this.$router.push('/editrpt/' + id)
    },
    // 点击复选框
    onSelect(val) {
      this.radio = val
    },
    //下载
    async downloadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let deptid = getLocal('currUser').dept_id
      let 下载时间 = await this.$localforage.getItem('t_pos_lastdowndatatime') || []
      if (下载时间.length < 1) {
        loading.close()
        this.$message.error('没有找到下载时间,请在设置中重新下载数据')
      }
      let 报货单下载时间 = 0;
      let 报货单下载时间下标 = null
      下载时间.forEach((item,i) => {
        if (item.tableid == "t_inv_bill") {
          报货单下载时间 = item.lastdowntime
          报货单下载时间下标 = i
        }
      })
      let data = {
        "uct": 报货单下载时间,
        "deptid": deptid
      }
      let [err, res] = await 收货api.下载报货单(data)
      if (err) {
        loading.close()
        return this.$message.error('报货单下载失败')
      }
      if (res.statusCode == 200) {
        let 下载报货单 = res.data.master
        let 下载报货单明细 = res.data.detail
        let 本地报货单 = await this.$localforage.getItem('t_inv_bill') || []
        let 本地报货单明细 = await this.$localforage.getItem('t_inv_bill_items') || []
        for (let i = 0; i < 下载报货单.length; i++) {
          let item = 下载报货单[i]
          let j = 本地报货单.findIndex(v => v.id == item.id)
          if (j >= 0) {
            for (const key in item) {
              本地报货单[j][key] = item[key] //  将下载的数据替换到本地数据上
            }
          } else {
            // 如果订单不存在,直接加入本地
            本地报货单.push(item)
          }
        }
        for (let k = 0; k < 下载报货单明细.length; k++) {
          let item_i = 下载报货单明细[k]
          let l = 本地报货单明细.findIndex(w => w.id == item_i.id)
          if (l >= 0) {
            for (const key in item_i) {
              本地报货单明细[l][key] = item_i[key] //  将下载的数据替换到本地数据上
            }
          } else {
            本地报货单明细.push(item_i)
          }
        }
        await this.$localforage.setItem('t_inv_bill', 本地报货单)
        await this.$localforage.setItem('t_inv_bill_items', 本地报货单明细)
        下载时间[报货单下载时间下标].lastdowntime = new Date().getTime()
        await this.$localforage.setItem('t_pos_lastdowndatatime', 下载时间)
        // 重新获取本地数据
        this.initData()
        loading.close()
      }
      loading.close()
    },
    // 确认接收
    onReceived() {
      this.dialogVisible = false
      this.initData()
    },
    // 查看详情
    lookInfo(val) {
      console.log(val);
      // this.nowList = val
      // this.dialogVisible = true
      this.$router.push(`/showinfo/${val.id}`)
    },
    // 上传已收货报货单
    async uploadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let 报货明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
      let 报货主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
      let master = []
      let 主表上传的下标 = [] //保存住上传的数据的下标
      报货主表.forEach((item, i) => {
        if (item.bstate == 3 && item.tstate == 0) {
          master.push(JSON.parse(JSON.stringify(item)))
          主表上传的下标.push(i)
        }
      })
      if (master.length > 0) {
        let detail = []
        let 明细下标 = []
        master.forEach(item => {
          let arr = []
          for (let i = 0; i < 报货明细表.length; i++) {
            if (报货明细表[i].pid == item.id) {
              arr.push(报货明细表[i])
              明细下标.push(i)
            }
          }
          detail.push(...arr)
        })
        let data = {
          "jsons": {
            master: master,
            detail: detail
          }
        }
        let [err, res] = await 收货api.报货单上传(data)
        if (err) return loading.close()
        if (res.statusCode == 200) {
          loading.close()
          this.$message.success('数据上传成功')
          for (let i = 0; i < 主表上传的下标.length; i++) {
            报货主表[主表上传的下标[i]].tstate = 1
          }
          for (let i = 0; i < 明细下标.length; i++) {
            报货明细表[明细下标[i]].tstate = 1
          }
          await this.$localforage.setItem('t_inv_bill_items', 报货明细表)
          await this.$localforage.setItem('t_inv_bill', 报货主表)
          this.initData()
          return
        } else {
          return this.$message.error(res.message)
        }
      }
      this.$message.error('没有需要上传的数据')
      loading.close()
    }
  }

}
</script>

<style scoped lang="less">
.biao-box {
  width: 100%;
  // padding: 5px 40px;
}
.handel-btn {
  margin: 10px 0;
  padding: 0 10px;
}
</style>