<template>
  <div class="contn-box">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card">
      <el-tab-pane name="销售统计" label="销售统计">配置管理</el-tab-pane>
      <el-tab-pane name="充值统计" label="充值统计">角色管理</el-tab-pane>
      </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
        activeName:'配置管理'
    }
  }
}
</script>

<style lang="scss" scoped>
.contn-box {
    width: 100%;
    height: 100%;
    padding: 15px;
}
</style>