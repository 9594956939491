import { 上传 } from '@/api'
import Http from '@/utils/request'
import {_to} from '@/utils'
//Authpay
export function Authpay(data) {
    // let res = 上传({ url: '/zfb/pay/Authpay', method: 'post', data: data })
    let res = _to( Http({url:'/zfb/pay/Authpay',method:'post',data:data}))
    // let res = Http({url:'/zfb/pay/Authpay',method:'post',data:data})
    return res
}
export function preCreate(data) {
    // let res = 上传({ url: '/zfb/pay/Authpay', method: 'post', data: data })
    let res = _to( Http({url:'/zfb/pay/preCreate',method:'post',data:data}))
    return res
}

export function query(data) {
    let res =  _to(Http({url:'/zfb/pay/query',method:'post',data:data}))
    return res
}
export function refund(data) {
    let res =  _to(Http({url:'/zfb/pay/refund',method:'post',data:data}))
    return res
}
export function refundQuery(data) {
    let res =  _to(Http({url:'/zfb/pay/refundQuery',method:'post',data:data}))
    return res
}

export function micropay(data) {
    let res =  _to(Http({url:'/wx/pay/micropay',method:'post',data:data}))
    return res
}
export function orderquery(data) {
    let res =  _to(Http({url:'/wx/pay/orderquery',method:'post',data:data}))
    return res
}
export function wxrefund(data) {
    let res =  _to(Http({url:'/wx/pay/refund',method:'post',data:data}))
    return res
}

export function sbauthpay(data) {  
    let res = _to( Http({url:'/sb/pay/authpay',method:'post',data:data}))
    return res
}
export function sbqueryorder(data) {  
    let res = _to( Http({url:'/sb/pay/queryorder',method:'post',data:data}))
    return res
}
export function sbrefundorder(data) {  
    let res = _to( Http({url:'/sb/pay/refundorder',method:'post',data:data}))
    return res
}
export default {
    Authpay,
    preCreate,
    query,
    refund,
    refundQuery,
    micropay,
    orderquery,
    wxrefund,
    sbauthpay,
    sbqueryorder,
    sbrefundorder,
}



