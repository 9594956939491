<template>
  <div style="margin-top:10px;width:100%;">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="报货管理" name="first">
        <div class="biao-box">
          <div class="form">
            <el-form ref="queryForm" :model="searchform" v-show="showSearch" label-width="80px" inline size="small">
              <el-form-item label="编号">
                <el-input v-model="searchform.inv_id" prop="inv_id" style="width:170px;"></el-input>
              </el-form-item>
              <el-form-item label="单据类型">
                <el-select v-model="searchform.inv_bustype" prop="inv_bustype" clearable style="width:170px;">
                  <el-option label="一报" :value="0"></el-option>
                  <el-option label="二报" :value="1"></el-option>
                  <el-option label="订单报货" :value="9"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="要货区域">
                <el-select v-model="searchform.inv_region" prop="inv_region" clearable style="width:170px;">
                  <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手工单号" prop="inv_billid">
                <el-input v-model="searchform.inv_billid" style="width:170px;"></el-input>
              </el-form-item>
              <el-form-item label="仓库" prop="inv_fm">
                <el-select v-model="searchform.inv_fm" clearable style="width:170px;">
                  <el-option v-for="item in t_wrhus" :key="item.id" :label="item.wrhus_name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="报货时间" prop="inv_needdate">
                <el-date-picker size="small" v-model="inv_needdate" value-format="timestamp" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"></el-date-picker>
              </el-form-item>
              <el-form-item label="需求日期" prop="inv_date1">
                <el-date-picker size="small" v-model="inv_date1" value-format="timestamp" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"></el-date-picker>
              </el-form-item>

              <el-form-item>
                <el-button type="cyan" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="handel-btn">
            <el-button size="small" type="primary" @click="add">新 增</el-button>
            <el-button size="small" type="primary" @click="refresh">刷 新</el-button>
            <el-button size="small" type="success" style="float:right;" @click="uploadList">上传报货单</el-button>
          </div>
          <div class="content">
            <el-table :data="tableData" border ref="tabledata" @select="onSelect" @select-all="onSelect">
              <el-table-column label="#" width="40" type="selection">
              </el-table-column>
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="inv_id" label="单据编号" sortable align="center" width="220">
              </el-table-column>
              <!-- <el-table-column prop="inv_needdate" label="报货时间" width="180">
              </el-table-column> -->
              <el-table-column label="报货时间" prop="inv_needdate" sortable align="center" width="180">
                <template slot-scope="scope">{{ scope.row.inv_needdate===null? '' :scope.row.inv_needdate|parseTime }}</template>
              </el-table-column>
              <el-table-column label="需求时间" prop="inv_date1" sortable align="center" width="180">
                <template slot-scope="scope">{{ scope.row.inv_date1===null? '' :scope.row.inv_date1|parseTime }}</template>
              </el-table-column>
              <el-table-column prop="inv_bustype" label="单据类型" sortable align="center" width="150">
                <template slot-scope="scope">
                  <span v-show="scope.row.inv_bustype == '0'">一报</span>
                  <span v-show="scope.row.inv_bustype == '1'">二报</span>
                  <span v-show="scope.row.inv_bustype == '3'">订单报货</span>
                </template>
              </el-table-column>

              <el-table-column prop="inv_fm" label="仓库" sortable align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.inv_fm == item.id" v-for="item in t_wrhus" :key="item.id">{{item.wrhus_name}}</span>
                </template>
              </el-table-column>

              <el-table-column prop="inv_region" label="要货区域" sortable align="center" width="150">
                <template slot-scope="scope">
                  <span v-show="scope.row.inv_region == item.dict_value" v-for="item in t_region" :key="item.dict_value">{{item.dict_label}}</span>
                </template>
              </el-table-column>

              <el-table-column label="上传" sortable align="center" width="100">
                <template slot-scope="scope">
                    <span v-show="scope.row.tstate == 0" style="color:#909399;">新建</span>
                    <span v-show="scope.row.tstate == 1" style="color:#67c23a;">待上传</span>
                    <span v-show="scope.row.tstate == 9" style="color:#67c23a;">已上传</span>
                </template>
              </el-table-column>

              <el-table-column prop="bstate" label="状态" sortable align="center" width="100">
                <template slot-scope="scope">
                  <span v-show="scope.row.bstate == 0" style="color:#909399;">起草</span>
                  <span v-show="scope.row.bstate == 1" style="color:#e6a23c;">确认</span>
                  <span v-show="scope.row.bstate == 2" style="color:#66b1ff;">发出</span>
                  <span v-show="scope.row.bstate == 3" style="color:#67c23a;">收到</span>
                </template>
              </el-table-column>

              <el-table-column prop="lby" label="操作人" align="center" width="100">
                <template slot-scope="scope">
                  <span v-show="scope.row.lby == item.id" v-for="item in users" :key="item.id">{{item.name}}</span>
                </template>
              </el-table-column>

              <el-table-column prop="inv_billid" label="手工单号" sortable align="center">
              </el-table-column>

              <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                  <!-- <el-button type="text" size="mini" icon="el-icon-view" @click="editRpt([scope.row],true)">查看</el-button> -->
                  <el-button type="text" size="mini" icon="el-icon-edit" @click="editRpt([scope.row])">查看</el-button>
                  <el-button :disabled="scope.row.tstate == 9" type="text" size="mini" icon="el-icon-delete" @click="det([scope.row])">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="page_data.total > 0" :total="page_data.total" :page.sync="page_data.currentPage" :limit.sync="page_data.pagesize" @pagination="initData" />
            <!-- <div style="margin-top:10px;">
              <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total"
                background hide-on-single-page>
              </el-pagination>
            </div> -->
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="收货管理" name="second">
        <show-huo ref="showhuoRef"></show-huo>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import 报货api from './api.js'
import { pagePagination } from '@/utils/index.js'
import ShowHuo from './收货/收货.vue'
import { mapGetters } from 'vuex'
import { parseTime } from '@/utils'
export default {
  filters: {
    parseTime
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 't_inv_op']),
    ...mapGetters('user', ['users'])
  },
  components: {
    ShowHuo
  },
  data() {
    return {
      // 显示搜索条件
      showSearch: true,
      // 日期范围
      inv_needdate: [],
      inv_date1: [],


      activeName: 'first',
      text: '',
      searchform: {
        inv_id: '',
        inv_bustype: '',
        inv_billid: '',
        inv_region: '',
        inv_fm: '',
        // inv_needdate: '',
        // inv_date1: ''
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      radio: [],
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 20
      },

    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.showhuoRef.refresh()
      vm.refresh()
      vm.$store.commit('app/setbaohuoPage', to.fullPath)
    })
  },
  async created() {
    // let [err, res] = await 报货api.获取操作类别()
    // if (err) return
    // this.$localforage.setItem('t_inv_op', res.data)
  },
  mounted() {
    this.initData()
  },
  methods: {
    refresh(){
      this.initData()
    },
    async initData() {
      this.loading = true;
      let 报货单 = await this.$localforage.getItem('t_inv_bill') || []
      console.log('报货单', 报货单)
      console.log('this.searchform.inv_to', this.inv_needdate)
      let invwhycat
      invwhycat = this.t_inv_op.filter(item => item.invop_shopop == 1)

      if (this.inv_needdate.length == 0) { this.inv_needdate[0] = 0, this.inv_needdate[1] = 4133865600000 }
      if (this.inv_date1.length == 0) { this.inv_date1[0] = 0, this.inv_date1[1] = 4133865600000 }

      let arr = 报货单.filter(item =>
        item.bstate <= 1
        && item.inv_id.includes(this.searchform.inv_id)
        && item.inv_whycat.includes(invwhycat[0].invop_id)
        && Number(item.inv_bustype).toString().includes(this.searchform.inv_bustype)
        && item.inv_billid.includes(this.searchform.inv_billid)
        && Number(item.inv_region).toString().includes(this.searchform.inv_region)
        && Number(item.inv_fm).toString().includes(this.searchform.inv_fm)
        && (this.inv_needdate[0] <= item.inv_needdate && item.inv_needdate <= this.inv_needdate[1])
        && (this.inv_date1[0] <= item.inv_date1 && item.inv_date1 <= this.inv_date1[1])
      )

       console.log('arr', arr)
            //降序排列排序
      arr.sort(function(a,b){
			return b.inv_date1 - a.inv_date1
		  })
      this.tableData = pagePagination(arr, this.page_data.pagesize, this.page_data.currentPage)
      this.page_data.total = arr.length
      this.loading = false;


    },



    // 分页
    handleCurrentChange(v) {
      this.page_data.currentPage = v
      this.initData()
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.page_data.currentPage = 1;
      this.initData();
    },
    /** 重置按钮操作 */
    resetQuery() {
      // this.resetForm("queryForm");
      // this.searchform={};
      // this.searchform.inv_id=''
      this.reset()
      this.handleQuery();
    },
    // 表单重置
    reset() {
      this.searchform = {
        inv_id: '',
        inv_bustype: '',
        inv_billid: '',
        inv_region: '',
        inv_fm: '',
        inv_needdate: '',
        inv_date1: ''
      };
      this.resetForm("queryForm");
    },

    add() {
      // this.$router.push('/rptgoods?tag=add')
      this.$router.push('/editrpt/' + 0)
    },
    editRpt(val, look) {
      let id = val[0].id
      if (look) {
        this.$router.push('/editrpt/' + id + '?look=' + look)
      } else {
        this.$router.push('/editrpt/' + id)
      }
    },
    // 删除
    det(list) {
      this.$confirm('是否删除选中的报货单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let table = this.tableData
        let 报货明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
        let 报货主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
        list.forEach(item => {
          for (let i = table.length - 1; i >= 0; i--) {
            if (item.id == table[i].id) { // 先删除页面上表格的数据
              table.splice(i, 1)
            }
          }
          // 删除本地存储的数据
          for (let i = 报货主表.length - 1; i >= 0; i--) {
            if (item.id == 报货主表[i].id) { // 删除主表数据
              报货主表.splice(i, 1)
            }
          }
          for (let i = 报货明细表.length - 1; i >= 0; i--) {
            if (item.id == 报货明细表[i].pid) { // 删除主表id等于id的明细表
              报货明细表.splice(i, 1)
            }
          }
        });
        // c存入数据
        await this.$localforage.setItem('t_inv_bill_items', 报货明细表)
        await this.$localforage.setItem('t_inv_bill', 报货主表)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击复选框
    onSelect(val) {
      this.radio = val
    },
    //上传
    async uploadList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let 报货明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
      let 报货主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
      let master = []
      let 主表上传的下标 = [] //保存住上传的数据的下标
      报货主表.forEach((item, i) => {
        if (item.tstate == 0 && item.bstate == 1) {
          master.push(JSON.parse(JSON.stringify(item)))
          主表上传的下标.push(i)
        }
      })
      master.forEach(item => {
        item.inv_needdate = (new Date(item.inv_needdate).getTime())
        item.inv_date1 = (new Date(item.inv_date1).getTime())
        item.inv_date2 = (new Date(item.inv_date2).getTime())
      })
      let detail = []
      let 明细下标 = []
      master.forEach(item => {
        let arr = []
        for (let i = 0; i < 报货明细表.length; i++) {
          if (报货明细表[i].pid == item.id) {
            arr.push(报货明细表[i])
            明细下标.push(i)
          }
        }
        detail.push(...arr)
      })
      if (master.length > 0) {
        let data = {
          "jsons": {
            master: master,
            detail: detail
          }
        }
        let [err, res] = await 报货api.报货单上传(data)
        console.log(err);
        if (err) return loading.close()
        if (res.statusCode == 200) {
          loading.close()
          this.$message.success('数据上传成功')
          for (let i = 0; i < 主表上传的下标.length; i++) {
            报货主表[主表上传的下标[i]].tstate = 1
          }
          for (let i = 0; i < 明细下标.length; i++) {
            报货明细表[明细下标[i]].tstate = 1
          }
          await this.$localforage.setItem('t_inv_bill_items', 报货明细表)
          await this.$localforage.setItem('t_inv_bill', 报货主表)
          this.initData()
          return
        } else {
          loading.close()
          return this.$message.error(res.message)
        }
      }
      this.$message.error('没有需要上传的数据')
      loading.close()
    }
  }

}
</script>

<style scoped lang="less">
.biao-box {
  width: 100%;
  // padding: 15px 40px;
}
.handel-btn {
  margin: 10px 0;
  padding: 0 10px;
}
</style>