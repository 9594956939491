<template>
  <div style="padding:10px 15px;width:100%;">
    <div class="btn">
      <el-button size="small" type="success" @click="seave(1)">保 存</el-button>
      <el-button size="small" type="danger">打 印</el-button>
      <el-button size="small" type="danger" @click="goback">关 闭</el-button>

      <el-button size="small" type="danger" @click="refreshPage">刷 新</el-button>
      <el-button size="small" type="danger" @click="backPage">返 回</el-button>
    </div>
    <div class="content">
      <el-row>
        <el-col :span="14">
          <div class="form">
            <el-form ref="form" :model="searchform" :rules="rules" label-width="80px" inline size="small">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="编号" prop="inv_id">
                    <el-input v-model="searchform.inv_id" readonly style="width:210px;"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="单据类型">
                    <el-select v-model="searchform.inv_bustype" style="width:210px;">
                      <el-option label="一报" :value="0"></el-option>
                      <el-option label="二报" :value="1"></el-option>
                      <el-option label="订单报货" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="要货区域" prop="inv_region">
                    <el-select v-model="searchform.inv_region" style="width:170px;">
                      <el-option v-for="item in t_region" :key="item.id" :label="item.dict_label" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="仓库" prop="inv_fm">
                    <el-select v-model="searchform.inv_fm" style="width:170px;">
                      <el-option v-for="item in t_wrhus" :key="item.id" :label="item.wrhus_name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="报货时间" prop="inv_needdate">
                    <el-date-picker disabled v-model="searchform.inv_needdate" type="datetime" placeholder="选择日期" value-format="timestamp" style="width:210px;">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="需求日期" prop="inv_date1">
                    <el-date-picker v-model="searchform.inv_date1" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" placeholder="选择日期时间" style="width:210px;"
                      :picker-options="pickerOptions" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="订单号">
                    <el-input v-model="searchform.name" disabled style="width:170px;"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" style="position: relative">
                  <el-form-item label="手工单号">
                    <el-popover placement="bottom" width="540" trigger="click">
                      <div class="keyboard">
                        <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput"></all-keyboard>
                      </div>
                      <el-input slot="reference" v-model="searchform.inv_billid" @focus.stop="all_keyboard_show=true" style="width:170px;"></el-input>
                    </el-popover>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-table ref="tableRef" :data="tableData" style="width: 100%" border max-height="700" highlight-current-row @row-click="onRowClick">
            <el-table-column prop="part_id" label="品号">
            </el-table-column>
            <el-table-column prop="part_name" label="品名">
            </el-table-column>
            <el-table-column prop="part_price" label="单价" width="70">
            </el-table-column>

            <el-table-column prop="part_usertype" label="商品类型">
            </el-table-column>

            <el-table-column label="报货量" width="150">
              <template slot-scope="scope">
                <div>
                  <el-input-number v-model="scope.row.invbi_plan" @focus="showkeyboard" size="mini" controls-position="right"></el-input-number>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="库存" label="库存">
            </el-table-column>

            <el-table-column prop="销量" label="销量">
            </el-table-column>

            <el-table-column prop="part_specs" label="规格">
            </el-table-column>

            <el-table-column prop="part_model" label="型号">
            </el-table-column>

            <el-table-column prop="notes" label="备注">
            </el-table-column>

            <el-table-column label="操作" width="80">
              <template scope="scope">
                <el-button size="mini" type="danger" @click="delItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="10" class="goods">
          <goods-menu @chooseGoods="addOrderList" @chooseType="nowType" />
        </el-col>
      </el-row>
    </div>
    <div class="keybord_box">
      <key-board ref="keyboard" @input-num="inputNum"></key-board>
    </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { NowTime } from '@/utils'
import { getLocal, setLocal, removeLocal } from '@/utils/localstorage.js'
import KeyBoard from '@/components/NumberKeyboard'
import GoodsMenu from '@/views/收银/GoodsMenu'
import AllKeyboard from '@/components/Keyboard.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    GoodsMenu,
    KeyBoard,
    AllKeyboard
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region'])
  },
  data() {
    return {
      //  时间格式
      pickerOptions: this.getPickerOptions(),
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      searchform: {
        inv_id: '',
        inv_bustype: 0,
        inv_region: '',
        inv_fm: '',
        inv_needdate: null,
        inv_date1: null,
        inv_billid: ''
      },
      rules: {
        inv_id: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        inv_needdate: [{ required: true, message: '请选择报货日期', trigger: 'change' }],
        inv_date1: [{ required: true, message: '请选择需求日期', trigger: 'change' }],
        inv_region: [{ required: true, message: '请选择要货区域', trigger: 'change' }],
        inv_fm: [{ required: true, message: '请选择仓库', trigger: 'change' }],
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      nowGoodsType: null,
      all_keyboard_show: false
    }
  },
  async created() {
    this.setData()
    await this.getGoodsData()
    await this.getTypeData()
    this.searchform.inv_id = this.制作编号()
  },
  beforeRouteEnter(to, from, next) {
    // 路由进入时查看有没有tag=add参数,有就重置页面
    next(vm => {
      if (to.query.tag === 'add') {
        vm.tableData = []
        vm.searchform.inv_id = vm.制作编号()
      }
      vm.$store.commit('app/setbaohuoPage', to.fullPath)
    })
  },
  methods: {
    async refreshPage() {
      this.setData()
      await this.getGoodsData()
      await this.getTypeData()
      this.searchform.inv_id = this.制作编号()
      this.tableData = []
    },
    setData() {
      this.searchform.inv_needdate = parseInt(new Date().getTime())
      this.searchform.inv_date1 = parseInt(new Date().getTime())
    },
    showkeyboard() {
      this.$refs.keyboard.open()
    },
    goback() {
      this.$router.push('/baohuo')
    },
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      let type0Goods = typeData.filter(i => !i.isdel)
      if (type0Goods.length > 0) {
        this.nowGoodsType = type0Goods[0]
      }
    },
    nowType(v) {
      if (!v) return
      this.nowGoodsType = v
    },
    addOrderList(v) {
      let ishave = this.tableData.findIndex(i => i.part_id == v.id)
      if (ishave >= 0) {
        this.tableData[ishave].invbi_plan++
        this.$refs.tableRef.setCurrentRow(this.tableData[ishave])
      } else {
        let 总库存 = this.$store.state.user.t_pos_inv_bal
        let n = 总库存.findIndex(item => item.id == v.id)
        let obj = {
          id: v.id,
          partid: v.id,
          part_id: v.part_id,
          part_usertype: v.part_usertype, //this.nowGoodsType.alias,
          part_name: v.part_name,
          part_price: v.part_price,
          part_specs: v.part_specs,
          part_model: v.part_model,
          invbi_plan: 1,
          库存: n >= 0 ? 总库存[n].bal_amnt : 0,
          销量: 0
        }
        this.tableData.push(obj)
      }

    },
    delItem(item) {
      let i = this.tableData.findIndex(v => v.id == item.id)
      if (i >= 0) {
        this.tableData.splice(i, 1)
      }
    },
    // 表格点击当前行
    onRowClick(v) {
      this.nowGoods = v
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods.invbi_plan = n
    },
    // 键盘输入
    onKeyInput(v) {
      this.searchform.inv_billid = v
      this.all_keyboard_show = false
    },
    //保存
    seave(bstate) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.tableData.length < 1) return this.$message.error('请选择报货的商品')
          let inv_whycat_arr = await this.$localforage.getItem('t_inv_op') || []
          if (inv_whycat_arr.length == 0) return this.$message.error('缺少基本数据,请跳转设置下载数据')
          let inv_whycat, inv_fmcat, inv_tocat
          inv_whycat_arr.forEach(item => {
            if (item.invop_shopop == 1) {
              inv_whycat = item.id
              inv_fmcat = item.invop_sorc
              inv_tocat = item.invop_dest
            }
          })
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          let seavetime = parseInt(new Date().getTime())
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 报货单明细id = makeid(this.tableData.length)
          let 报货单明细 = []
          let 总价值 = 0
          this.tableData.forEach((item, i) => {
            总价值 = 总价值 + (item.invbi_plan * item.part_price)
            let obj = {
              id: 报货单明细id[i],
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              tstate: 0,
              dept_id: user.dept_id,
              pid: 主表id,
              invbi_seq: i,
              partid: item.partid,
              invbi_plan: item.invbi_plan,
              invbi_cost_plan: item.invbi_plan * item.part_price,
              invbi_type: '',
              invbi_price: item.part_price,
              pos_id: shopBaseMsg.terminal,
              invbi_send: item.invbi_plan,
              invbi_amnt: item.invbi_plan,
              invbi_cost_send: item.invbi_plan * item.part_price,
              invbi_cost_amnt: item.invbi_plan * item.part_price,
              invbi_plan_f: 0,
              invbi_tag: 0,
              invbi_unitconv: 0,
              notes: "",

            }
            报货单明细.push(obj)
          })

          let 报货单据主表 = {
            id: 主表id,
            tenid: user.tenid,
            lct: seavetime,
            uct: seavetime,
            lby: user.id,
            uby: user.id,
            inv_id: this.searchform.inv_id,
            bstate: bstate,
            inv_date: parseInt(new Date().getTime()),
            inv_needdate: this.searchform.inv_needdate,
            inv_date1: this.searchform.inv_date1,
            inv_date2: this.searchform.inv_needdate,
            inv_fm: this.searchform.inv_fm,
            inv_cost: 总价值, //总价值
            inv_region: this.searchform.inv_region,
            inv_bustype: this.searchform.inv_bustype,
            inv_billid: this.searchform.inv_billid,
            pos_id: shopBaseMsg.terminal,
            tstate: 0, //0 未上传 1上传
            dept_id: user.dept_id,
            inv_to: user.dept_id,
            inv_whycat,
            inv_fmcat,
            inv_tocat,
          }



          let 报货明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
          let 报货主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
          报货主表.push(报货单据主表)
          报货明细表.push(...报货单明细)
          await this.$localforage.setItem('t_inv_bill_items', 报货明细表)
          await this.$localforage.setItem('t_inv_bill', 报货主表)
          if (bstate) {
            this.$message.success('保存成功')
            setTimeout(() => {
              this.tableData = []
              this.searchform.inv_id = this.制作编号()
              this.backPage()
            }, 500)
          }
        }
      })
    },
    backPage() {
      this.$store.commit('app/setbaohuoPage', null)
      this.$router.push('/baohuo')

    },
    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return 'ML' + str + 商户号 + id
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 10px;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.goods {
  background-color: #ccc;
  border-left: 5px solid #fff;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #82c0ff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #82c0ff;
}
.keyboard {
  width: 520px;
  padding: 10px;
  background-color: #ccc;
  z-index: 99;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
</style>