<template>
  <div class="contn-box">
      <el-tabs v-model="activeName" tab-position="left" style="height: 100%;" type="border-card">

        <el-tab-pane name="主页" label="主页">
            <echar-volume style="height: 100%;" />
        </el-tab-pane>
        <el-tab-pane name="零售报表" label="零售报表">
          <ling-lin :defaultdata="infodata" ></ling-lin>
        </el-tab-pane>
        <el-tab-pane name="库存报表" label="库存报表">
           <ku-Cun :defaultdata="infodata" ></ku-Cun>
        </el-tab-pane>
        <!-- <el-tab-pane name="会员报表" label="会员报表">
           <hui-Yuan :defaultdata="infodata" ></hui-Yuan>
        </el-tab-pane> -->
      </el-tabs>
  </div>
</template>

<script>
import EcharVolume from './cpmponents/EcharVolume'
import lingLin from './cpmponents/零售.vue'
import kuCun from './cpmponents/库存.vue'
import huiYuan from './cpmponents/会员.vue'
import { mapGetters } from 'vuex'
export default {
    computed: {
  ...mapGetters('app', ['t_wrhus', 't_region', 't_inv_op','t_pos_inv_op', 'dictdata','sys_dept']),
  ...mapGetters('user', ['users'])
  },
  data() {
    return {
        activeName:'主页',
        //基础数据
       infodata: {},
    }
  },
  components:{
      EcharVolume,
      lingLin,
      kuCun,
      huiYuan
  },
   created() {
        this.infodata.t_region=this.t_region
        this.infodata.t_inv_op=this.t_inv_op
        this.infodata.t_pos_inv_op=this.t_pos_inv_op
        this.infodata.dictdata=this.dictdata
        this.infodata.users=this.users
  },
}
</script>

<style lang="scss" scoped>
.contn-box {
    width: 100%;
    height: 100%;
    padding: 15px;
}
</style>