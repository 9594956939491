import Http from '@/utils/request'
import {_to} from '@/utils'
/**
 * 
 * @param {string} url 上传地址
 * @param {string} method 请求方式
 * @param {Object} data 上传参数
 */
export function 上传(data) {
    let res = _to(Http({ url: data.url, method: data.method, data: data.data }))
    return res
}