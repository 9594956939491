<template>
  <div>
    <!-- 消费详情 -->
      <div class="filter-box">
      <!-- <el-input v-model="filter_data"  @input="changeinput"  placeholder="请输入查询条件"  style="width:160px;" size="small" >
      </el-input> -->
      <el-input v-model="filter_data" @input="changeinput"  placeholder="请输入充值金额"  style="width:160px;" size="small" >
      </el-input>
      <el-button style="float: right;margin-right: 10px;" size="mini" @click="jiesuan" type="success"> <i class="el-icon-refresh"></i> 结算</el-button>
    </div>
    <div class="table-up">
      <el-table :data="tableData" ref="xiaofei" highlight-current-row    @current-change="handleCurrentChange" border height="400"
       style="width: 100%; ">
        <!-- <el-table-column label="ID" prop="id" sortable  align="center" /> -->
        <el-table-column label="策略说明" prop="name" sortable  align="center" width="200"/>
        <el-table-column label="开始时间" prop="bgn_date"  sortable align="center" width="180">
           <template slot-scope="scope">{{ scope.row.bgn_date===null? '' :scope.row.bgn_date|parseTime }}</template>
        </el-table-column>
        <el-table-column label="结束时间" prop="end_date"  sortable align="center" width="180">
          <template slot-scope="scope">{{ scope.row.end_date===null? '' :scope.row.end_date|parseTime }}</template>
        </el-table-column>
        <el-table-column label="满金额" prop="top_money" sortable  align="center" :show-overflow-tooltip="true" />
        <el-table-column label="赠送金额" prop="zs_money" sortable  align="center" :show-overflow-tooltip="true" />
        <el-table-column label="赠送积分" prop="zs_score" sortable  align="center" :show-overflow-tooltip="true" />
        <el-table-column label="备注" prop="notes" sortable  align="center" :show-overflow-tooltip="true" />
      
      </el-table>
    </div>
    <div class="tag-box">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="赠送商品" name="first">
          <el-table :data="xiaofeiListData" border height="250"
          :summary-method="getSummaries" show-summary style="width: 100%;">
            <!-- <el-table-column label="销售单号" prop="sale_id" align="center" :show-overflow-tooltip="true" /> -->
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column label="商品编号" prop="part_id" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="商品名称" prop="part_name" align="center" :show-overflow-tooltip="true"  />
            <el-table-column label="单价" prop="part_price" align="center" width="100" />
            <el-table-column label="单位" prop="part_unit" align="center" width="100" />
            <el-table-column label="赠送数量" prop="qty" align="center" width="100" />
            <el-table-column label="状态" prop="isstop" align="center">
            <template slot-scope="scope"> 
              <div>
                <span v-show="scope.row.isstop == 0">正常</span>
                <span v-show="scope.row.isstop == 1">停用</span>
              </div>
            </template>
          </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
      <!-- <div class="ticket-box" >
      <ticket-template1 ref="histprintcom" :adddata="ticket_data" v-show="show_ticket"></ticket-template1>
    </div> -->
  </div>
</template>

<script>
import { getLocal } from '@/utils/localstorage'
import 会员api from '../api.js'
import { parseTime } from '@/utils'
import ticketTemplate1 from '@/components/TicketTemplate/printsales.vue'
export default {
   components: {
    ticketTemplate1
  },
  filters: {
    parseTime
  },
  props: {
    isCancel:{
      default(){
        return true;
      },
      },
    data: {
      type: [Object,String],
      default() {
        return {};
      },
    },
  },
    watch: {
    data: {
      handler(n) {
      console.log('watch-data',n)
      this.cardData=n
      this.tableData=[]
      this.tableDataall=[]
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      // 所有的商品数据
      goodsData:[],
      topData:{},
      cardData:{},
      filter_data: '',
      all_table_data: [],
      tableData: [],
      tableDataall: [],
      activeName: 'first',
      goods_data: [],
      //消费主表表格数据
      xiaofeiData: [],
      //消费明细表格数据
      xiaofeiListData: [],
      pos_sale_detail: [],
      sale_detail: [],
      // 支付方式表格数据
      zhifuListData: [],
      pos_sale_cur: [],
      // 付款方式列表
      PayWay: [],
      ticket_data:{}, //小票数据
      show_ticket:false,
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28
      },
      topData: {
              id: null,
              dept_id: this.$store.state.user.currUser.dept_id,
              pos_id: getLocal('shopBaseMsg').terminal,
              computer_no: '',
              top_date:null,
              cardid: 0,
              top_type: 1,//充值类型0预存1充值2红冲3合并
              top_source: 1,//充值来源0公司1门店2微信3支付宝4银联9其它
              top_money: 0,
              grant_money: 0,
              grant_score: 0,
              top_remoney: 0,
              recharge_flag: 0,
              associate_id: 0,
              bstate: 0,
              salesid: 0,
              top_audit_flag: 0,
              top_audit_date:null,
              top_audit_staff:null,
              top_serialid:null,
              notes:'',
      },
    }
  },
  computed: {
  },

created(){
  //  this.liststrategytop()
},
  methods: {
      // 结算
   async jiesuan(){
     console.log('this.cardData',this.cardData)
      if (this.cardData.id==0){ return this.$message.error('会员卡信息不存在，不能充值结算')}


      let zsgoods=[]
      // this.goodsData = await this.$localforage.getItem('t_part') || []
      let czkobj = {
          id: 410018306326553,
          part_unit: '张',
          count: 1,
          saledt_uprice: Math.abs(Number(this.filter_data)), //价格
          saledt_sprice: Math.abs(Number(this.filter_data)),
          saledt_dis_1: 0,
          saledt_dis_2: 0, // 折让
          part_id: '9999czk', // 品号
          part_name: '充值卡', // 品名
          partid: 410018306326553,
          part_price: Math.abs(Number(this.filter_data)),
        }


if(this.xiaofeiListData){ //赠送商品
    this.xiaofeiListData.forEach(item => {
        let obj = {
          id: item.partid,
          part_unit: item.part_unit,
          count: item.qty,
          saledt_uprice: Number(item.part_price), //价格
          saledt_sprice: Number(item.part_price),
          saledt_dis_1: 0,
          saledt_dis_2: Number(item.part_price)*item.qty, // 折让
          part_id: item.part_id, // 品号
          part_name: item.part_name, // 品名
          partid: item.partid,
          part_price: Number(item.part_price),
        }
        zsgoods.push(obj)
      })
    }

      const newId = await  会员api.getOneID()//获得id
      this.topData.id=newId
      this.topData.cardid=this.cardData.id
      this.topData.top_money=Number(this.filter_data)
      this.topData.top_date=parseInt(new Date().getTime())

      this.$emit('jiesuan',{topdata:this.topData,zsgoods,czkdata:czkobj})
    },

   changeinput(e) {
     console.log('tableDataall',this.tableDataall)
     let nowtime =  parseInt(new Date().getTime())
     let arr = this.tableDataall.filter(item =>
           Number(item.top_money)<=Math.abs(Number(e))&&
           (item.bgn_date<=nowtime && item.end_date >=nowtime)
       
      )
       console.log('arr',arr)
        this.tableData = arr;
         this.setCurrent(this.tableData[0])
    },
 
 /** 查询卡类型字典列表 */
    async liststrategytop() {
      console.log('liststrategytop',this.cardData)
        // 查询参数
   let queryParams={
        deptid: this.$store.state.user.currUser.dept_id,
        card_type: this.cardData.card_type
      }

    let [err,res] =  await 会员api.查询充值策略列表(queryParams)
      if (err) {
            return this.$message.error('查询充值策略列表失败'+err)
          }
       if (res.statusCode!=200) {
          return this.$message.error('查询充值策略列表失败')
        }
      this.tableDataall = res.data.rows;
    },

    setCurrent(row) {
    console.log('setCurrent', row)
    //  this.$refs.xiaofei.setCurrentRow(row);
    },
    async handleCurrentChange(val) {
      console.log('val', val)
      if (val){
       this.topData.grant_money=val.zs_money//赠送金额
       this.topData.grant_score=val.zs_score//赠送积分
       }
    this.filterChange(val)
    },

 async filterChange(val) {
    if (!val) {return}
    let res =  await 会员api.查询充值赠送商品({id:val.id})
     if (res.statusCode!=200) {
          return this.$message.error('查询充值赠送商品失败')
        }
     this.xiaofeiListData = res.data.rows;
    },

// 部门字典翻译
    FormatPayname(row, column) {
      console.log('FormatPayname',this.PayWay,row)
      return this.selectDictLabelid(this.PayWay, row.pay_id);
       
    },
    onFilterChange() {
      console.log('onFilterChange')
      this.initData()
    },

   cardtoptest() {
     if (this.cardData.id>0){
        this.liststrategytop()
     }
    
      console.log('cardtoptest')
    },


  //合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value)) 
            && column.property != 'sale_id' 
            && column.property != 'sale_date'
            && column.property != 'sale_type'
            && column.property != 'saledt_id'
            && column.property != 'part_id'
            && column.property != 'saledt_uprice'
            && column.property != 'bgn_date'
            && column.property != 'end_date'
            && column.property != 'notes'
         ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        sums[index] = '';
      }
    });

    return sums;
  },
  }
}
</script>

<style lang="scss" scoped>
.lately-sale-box {
  width: 100%;
  height: 100%;
  .filter-box {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>