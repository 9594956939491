import { getToken, setToken, removeToken } from '@/utils/auth'
import { getLocal, setLocal } from '@/utils/localstorage'
import tools from '@/utils/tools'
import Http from '@/utils/request'
import localforage from 'localforage'
const state = {
    // token
    token: getLocal('Admin-Token'),
    // 是否登陆 true未登录
    loginFlag: true,
    // 当前的页面
    currentPage: getToken('currentPage') || '/',
    // 用户列表
    users: {
        name: 'users',
        userList: []
    },
    // 当前用户
    currUser: getLocal('currUser') || null,
    model: 1, //1销售-1退货
    // 库存数据
    t_pos_inv_bal: null,
}

const getters = {
    loginFlag: state => state.loginFlag,
    token: state => state.token,
    model: state => state.model,
    currentPage: state => state.currentPage,
    users: state => state.users.userList,
    t_pos_inv_bal: state => state.t_pos_inv_bal,
    currUser: state => state.currUser,
}

const mutations = {
    // 修改模式销售或退货
    changeModel(state, data) {
        state.model = data
    },
    // 获取数据库用户数据
    setUsers(state, users) {
        state.users.userList = users
    },
    // 设置当前用户
    setCurrUser(state, user) {
        state.currUser = user;
    },
    // 当前用户退出登录
    logoutAccount(state) {
        removeToken()
        state.loginFlag = true;
        state.currUser = null;
    },
    // 设置token
    SET_TOKEN(state, token) {
        state.token = token
    },
    // 修改是否登陆状态
    changeloginFlag(state, flag) {
        state.loginFlag = flag
    },
    // 更改当前页面
    changeCurrentPage(state, root) {
        state.currentPage = root
    },
    // 收货修改结存数量
    收货修改结存数量(state, data) {
        // for (let i = 0; i < state.t_pos_inv_bal.length; i++) {
        //     let item = state.t_pos_inv_bal[i]
        //     console.log('收货修改结存数量22', item)
        //     if (item.id == data.part_id) {
        //         console.log('收货修改结存数量33',item.partid , item.bal_amnt,data.invbi_amnt)
        //         item.bal_amnt = item.bal_amnt + (data.invbi_amnt - 0)
        //         item.bal_cost = item.bal_amnt * (data.part_price - 0)
        //         break
        //     }
        // }
    },
    // 出入库修改结存数量
    出入库修改结存数量(state, data) {
        // console.log('出入库修改结存数量11', state,data)
        // for (let i = 0; i < state.t_pos_inv_bal.length; i++) {
        //     let item = state.t_pos_inv_bal[i]
        //     // console.log('出入库修改结存数量22', item)
        //     if (item.part_id == data.part_id) {
        //         console.log('出入库修改结存数量33',item.partid , item.bal_amnt,data.invbi_plan)
        //         // item.bal_amnt = item.bal_amnt + (data.invbi_plan - 0)
        //         // item.bal_cost = item.bal_amnt * (data.invbi_price - 0)
        //         // console.log('出入库修改结存数量344',item.partid , item.bal_amnt,data.invbi_plan)
        //         break
        //     }
        // }
    },
    // 收银修改结存数量
    收银修改结存数量(state, data) {
        console.log('收银修改结存数量', state, data)
        for (let i = 0; i < state.t_pos_inv_bal.length; i++) {
            let item = state.t_pos_inv_bal[i]
            if (item.partid == data.id) {
                if (state.model === 1) {
                    item.bal_amnt = item.bal_amnt - (data.count - 0)
                    item.bal_cost = item.bal_cost - (data.count * data.part_price)
                } else {
                    item.bal_amnt = item.bal_amnt + (data.count - 0)
                    item.bal_cost = item.bal_cost + (data.count * data.part_price)
                }
                break
            }
        }
    },

    // 盘点修改结存数量
    盘点修改结存数量(state, data) {
        // for (let i = 0; i < state.t_pos_inv_bal.length; i++) {
        //     let item = state.t_pos_inv_bal[i]
        //     if (item.partid == data.partid) {
        //         item.bal_amnt = data.invchk_act_qty
        //         item.bal_cost = data.invchk_act_qty * data.invchk_price
        //         // console.log('盘点修改结存数量',item,data)
        //         break
        //     }
        // }
    },

    获取结存表(state, data) {
        state.t_pos_inv_bal = data
    },
    // 重置新消息数字
    resetMessageNumber(state) {
        state.messages.number = 0;
    },
    // 设置用户列表
    setUsers(state, data) {
        state.users.userList = data
    }
}

const actions = {
    //登录
    async Login({ commit }, userInfo) {
        const tenid = userInfo.coding //.trim()
        const username = userInfo.username //.trim()
        const password = userInfo.password
        const data = {
            tenid: tenid,
            username: username,
            password: tools.makeAE1pass(password),
            info: 0,
            code: undefined,
            uuid: undefined
        }

        let res = await Http({ url: '/login', method: 'post', data })
        if (res.statusCode == 200) {
            setLocal('Admin-Token', res.data.token)
            commit('SET_TOKEN', res.data.token)
        }
        return res
    },
    async 获取结存表(context, data) {
        let res = await localforage.getItem('t_pos_inv_bal')|| []
        context.commit('获取结存表', res)
    },
    async getUsers(context, data) {
        let res = await localforage.getItem('sys_user') || []
        context.commit('setUsers', res)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
