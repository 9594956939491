import { makeid } from '@/components/makeid.js'
import { setSession, getSession, removeSession } from '@/utils/sessionStorage.js'
import { getLocal } from '@/utils/localstorage.js'
import 会员api from '../../会员/api.js'

import vipnummethods from './vipnummethods.js'
import { tidanmethods } from './tidanmethods.js'
import { tidanmethodse } from './tidanmethodse.js'
export default {
    //业务数据字典
    GetDictData(id) {
        if (!id) {
            return ''
        }
        let dictdata = this.stateoptions.filter(v => v.dictValue == id)
        if (dictdata.length > 0) {
            return dictdata[0].dictLabel
        }
        return ''
    },

    handleClick(tab, event) {
        console.log(tab.index, event);
        switch (tab.index) {

            case '4': //会员充值
                console.log('会员充值')
                this.$refs.cardtop.cardtoptest()
                break;
            case '5': //积分兑换
                console.log('积分兑换')
                this.$refs.cardscoredh.cardtoptest()
                break;
        }
    },

    // 获取支付方式 获取消费群体类型
    async getPay_way() {
        let dictdata = await this.$localforage.getItem('t_sys_dict_data') || []
        let arr = dictdata.filter(i => i.dict_type == 'bus_pay_way')
        this.PayWay = arr.filter(i => !i.isdel)
        let job = dictdata.filter(i => i.dict_type == 'bus_cons_job')
        this.getBus_cons_job = job.filter(i => !i.isdel)
    },
    // 添加商品
    addOrderList: function (item) {
        console.log('item', item)
        //先判断是否存在订单中
        // 据判断结果编写逻辑
        // 总数量和总金额清零
        this.allMoney = 0;
        this.allCount = 0;
        let isHave = false;
        for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].id == item.id) {
                isHave = true;
            }
        }
        if (isHave) {
            // 改变列表中商品数量
            let arr = this.tableData.filter((o) => o.id == item.id);
            arr[0].count++;
            this.goodsClick(arr[0])//获得当前行
            //  console.log('arr',arr)
            //获得会员卡折扣计算
        } else {
            // 新添加商品信息,把新信息push进 商品数组内
            let goods = JSON.parse(JSON.stringify(item))
            goods.count = goods.count
            goods.checked = 0
            goods.saledt_unit = goods.part_unit
            goods.saledt_dis_1 = goods.saledt_dis_1
            goods.saledt_dis_2 = goods.saledt_dis_2
            // goods.count = 1
            // goods.saledt_dis_1 = 0
            // goods.saledt_dis_2 = 0
            goods.实际价格 = goods.part_price || 0
            this.tableData.unshift(goods);//方法可向数组的开头添加一个或更多元素，并返回新的长度
            //获得当前行
            this.goodsClick(this.tableData[0])
            if (this.CardListCurren.id > 0) {//会员卡
                console.log('CardListCurren', this.CardListCurren,goods.part_cardprice)
                if (goods.part_carddprice_if) { goods.part_price = goods.part_cardprice;  goods.实际价格 = goods.part_price || 0; return } //是否执行会员价0否1是

                this.zhekou({ type: 1, num: this.CardListCurren.card_dis_rate * 100 })//  zhekou {type: 1, num: 80}  
            }
        }
        this.getAll();
    },

    // 减少商品
    changeGoodsNum(item) {
        this.allMoney = 0;
        this.allCount = 0;
        let arr = this.tableData.filter((o) => o.part_name == item.part_name);
        arr[0].count--;
        this.getAll();
    },
    //删除商品
    delGoods: function (item) {
        this.tableData = this.tableData.filter((o) => o.part_id != item.part_id);
        this.getAll();
    },
    //计算价格
    getAll: function () {
        // 总数量和总金额清零
        this.allMoney = 0;
        this.allCount = 0;
        this.alldis1 = 0;
        this.alldis2 = 0;
        this.pageData = []
        let num = 0
        let dis1 = 0
        if (this.tableData) {
            // 计算总金额和数量

            this.tableData.forEach((el) => {
                let 明细折扣
                if (!el.saledt_dis_1) {
                    明细折扣 = 0
                } else {
                    明细折扣 = ((el.part_price * el.count) * (1 - el.saledt_dis_1 / 100))
                }
                this.allCount += el.count;
                dis1 += 明细折扣;
                this.alldis2 += el.saledt_dis_2;
                this.计算实际价格(el)
                let price = el.实际价格 ? el.实际价格 : 0
                num += parseFloat(price);
            });
            this.alldis1 = dis1.toFixed(2)
            this.allMoney = num.toFixed(2)
            setSession('orderList', this.tableData)
        }
    },

    //清空
    delTab: function () {
        this.queryParams.carddata = ''
        this.resetcard()//会员表单重置
        if (this.tableData) {
            this.tableData = [];
            this.rtnnowPayWayname = [];
            this.nowPayWayname = [],// 选择的付款方式名字 多种
            this.sale_rtntype = 0//退货类型 0一般退货1按单退货
            this.salesourceid = 0
            this.cardsaletopflag = 1//会员充值2销售1
            this.cardsaleredeemflag = 1//会员兑换2销售1
            this.cardtopitem = {};//会员充值记录
            this.cardredeemitem = {};//会员兑换记录
            this.apipayrefund = false//标记微信支付宝退货true是退货

            //支付宝微信
            this.paydata = {}//支付结果返回
            this.getAll();
            this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success",
            });
        }
    },
    // 折扣
    zhekou(val) {
        console.log('zhekou', val, this.tableData)
        this.tableData.forEach(item => {
            if (val.type == 1 || val.type == 2) {
                if (item.id == this.selectedGoodsId) { //是否执行允许门店代销0否1是 && item.r_dxsale == 0
                    if (item.r_dxsale == 1) this.$message.error(item.part_name + ' 商品，门店代销商品，不能折扣销售')
                    if (val.type == 1 && item.r_dxsale == 0) {//折扣
                        item.saledt_dis_1 = val.num || 0
                    }
                    if (val.type == 2 && item.r_dxsale == 0) {//折让
                        item.saledt_dis_2 = val.num || 0
                    }
                    this.计算实际价格(item)
                    this.getAll();
                }
            } else if (val.type == 3 || val.type == 4) {//全单
                if (item.r_dxsale == 1) this.$message.error(item.part_name + ' 商品，门店代销商品，不能折扣销售')
                if (val.type == 3 && item.r_dxsale == 0) {//全单折扣
                    item.saledt_dis_1 = val.num || 0
                }
                if (val.type == 4 && item.r_dxsale == 0) {//全单折让
                    item.saledt_dis_2 = val.num || 0
                }
                this.计算实际价格(item)
                this.getAll();
            }
        })
    },
    // 会员充值
    handlecardtop(val) {
        this.leftTopTabs = '会员充值'
    },
    // 会员兑换
    handlecarddh(val) {
        console.log('carddh', 1)
    },
    /** 提交按钮 */
    submitFormtop: function () {
        // this.$refs.wuliaoFromRef.$refs.cardinfo.seaveData();
    },


    //  商品数量
    inputGoodsNum(num) {
        this.tableData.forEach(item => {
            if (item.id == this.selectedGoodsId) {
                item.count = num || 1
                this.getAll();
            }
        })
    },
    // 计算实际价格
    计算实际价格(item) {
        // if (this.sale_rtntype!=1){//按单退货
        // 实际价格 = (单价*数量)*折扣 - 折让 
        let price
        if (item.saledt_dis_1 == 0) {
            price = ((item.part_price * item.count) - item.saledt_dis_2).toFixed(2)
        } else {
            price = ((item.part_price * item.count) * (item.saledt_dis_1 / 100) - item.saledt_dis_2).toFixed(2)
        }
        item.实际价格 = this.model > 0 ? Number(price) : Number(-price)
        // }
    },

    //充值判断
    onCardTop(cardtopdata) {
        console.log('onCardTop', cardtopdata)
        if (cardtopdata) {//如果有会员充值信息
            this.cardsaletopflag = 1//会员充值2销售1
            this.cardsaleredeemflag = 1//会员兑换2销售1
            this.cardtopitem = {};//会员充值记录
            this.cardredeemitem = {};//会员兑换记录

            this.cardtopitem = cardtopdata.topdata //会员充值信息
            this.cardsaletopflag = 2   //会员是消费1还是充值2
            if (cardtopdata.topdata.top_money >= 0) { this.$store.commit('user/changeModel', 1) } else { this.$store.commit('user/changeModel', -1) }
            if (this.tableData) {//清空销售明细
                this.tableData = [];
                this.rtnnowPayWayname = [];
                this.sale_rtntype = 0//退货类型 0一般退货1按单退货
                this.salesourceid = 0
                this.getAll();
            }
            //增加充值销售明细金额
            this.addOrderList(cardtopdata.czkdata)
            //有赠送增加赠送
            cardtopdata.zsgoods.forEach(item => {
                this.addOrderList(item)
            })
        }
        this.changeCheckoutFlag(cardtopdata)// 弹出结算框
    },


    //积分兑换判断
    onCardScoredh(cardtopdata) {
        console.log('onCardScoredh', cardtopdata)
        if (cardtopdata) {//如果有会员充值信息
            this.cardsaletopflag = 1//会员充值2销售1
            this.cardsaleredeemflag = 1//会员兑换2销售1
            this.cardtopitem = {};//会员充值记录
            this.cardredeemitem = {};//会员兑换记录

            this.cardtopitem = cardtopdata.topdata //会员充值信息
            this.cardredeemitem = cardtopdata.redeemdata //会员兑换信息
            this.cardsaleredeemflag = 2//会员兑换2销售1
            this.cardsaletopflag = 2   //会员是消费1还是充值2
            this.$store.commit('user/changeModel', 1)
            if (this.tableData) {//清空销售明细
                this.tableData = [];
                this.rtnnowPayWayname = [];
                this.sale_rtntype = 0//退货类型 0一般退货1按单退货
                this.salesourceid = 0
                this.getAll();
            }

            if (cardtopdata.redeemdata.redeem_dh_flag > 0) {//会员兑换2销售1
                //增加充值销售明细金额
                this.addOrderList(cardtopdata.czkdata)
            }
            //有赠送增加赠送
            cardtopdata.zsgoods.forEach(item => {
                this.addOrderList(item)
            })
        }
        this.changeCheckoutFlag(cardtopdata)// 弹出结算框
    },

    //弹出结算框
    changeCheckoutFlag(cardtopdata) {
        console.log('弹出结算框', cardtopdata)
        let Settlement = getLocal('Settlement') || []//开市数据
        if (Settlement && Settlement.settle_bstate > 0) {//已日结
            this.$message.error("已日结，不能在销售");
            return;
        }
        if (this.tableData.length === 0) {
            this.$message.error("没有待结算商品，请添加后再操作");
        } else {
            this.dialogVisible = true;
            let 实价 = 0
            let 折扣价格 = 0
            let 折让价格 = 0
            this.tableData.forEach(item => {
                let all = item.count * item.part_price
                if (!item.saledt_dis_1) {
                    折扣价格 += 0
                } else {
                    let 折扣 = (all * (1 - item.saledt_dis_1 / 100)).toFixed(2)
                    折扣价格 += Number(折扣)
                }
                折让价格 += item.saledt_dis_2
                实价 += item.part_price * item.count
            })
            this.allMoneyType.实价 = this.model > 0 ? 实价.toFixed(2) : -实价.toFixed(2)
            this.allMoneyType.原优惠 = this.model > 0 ? (折扣价格 + 折让价格).toFixed(2) : -(折扣价格 + 折让价格).toFixed(2)
            this.allMoneyType.优惠 = this.model > 0 ? (折扣价格 + 折让价格).toFixed(2) : -(折扣价格 + 折让价格).toFixed(2)
            this.allMoneyType.原应收 = this.allMoneyType.实价 - this.allMoneyType.优惠


            console.log('CardListCurren', this.CardListCurren)   //会员卡   
        }
    },
    // 结算框打开时
    onDialogOpend() {
        console.log('结算框打开时',this)
        if (this.model < 0){//提单或电子券退货
            let arr1 = this.Returnrec_gift.filter(i => i.rec_gifttype == 0)//手工券提单
            let arr2 = this.Returnrec_gift.filter(i => i.rec_gifttype == 1)//电子券
            this.coupons_table_data =arr1
            this.ecoupons_table_data =arr2
        }
      
        console.log('使用状态电子券', this.ecoupons_table_data)
        // 默认选择支付方式
        if (this.PayWay.length > 0) {
            if (this.model > 0) {//销售
                if (this.CardListCurren.id && this.cardsaletopflag === 1) {//如果有会员充值信息) { // 会员卡
                    console.log('this.PayWay', this.PayWay)
                    let i = this.PayWay.findIndex(v => v.dict_value === "12")//会员卡
                    if (~i) {
                        let k = this.PayWay[i]
                        // 余额大于应收金额填入应收金额否则填入余额
                        this.nowPayWayname.push({
                            id: k.dict_value,
                            name: k.dict_label,
                            money: Number(this.CardListCurren.card_remoney) > this.应收金额 ? this.应收金额 : Number(this.CardListCurren.card_remoney)
                        })
                        this.whichPayWay = k.dict_value
                        console.log('是会员卡', this.nowPayWayname, this.whichPayWay)
                        // 左边方块显示选中状态
                        let el = document.getElementsByClassName('payway-item')
                        el[1].classList.add('active')
                        // el.forEach(item => {
                        //   if (item.innerText === '会员卡') {
                        //     item.classList.add('active')
                        //   }
                        // })
                    }
//?
                    let j = this.PayWay.findIndex(v => v.dict_value === "16")//电子券
                    if (~j) {
                        let k = this.PayWay[j]
                        // 余额大于应收金额填入应收金额否则填入余额
                        this.nowPayWayname.push({
                            id: k.dict_value,
                            name: k.dict_label,
                            money: Number(100) 
                        })
                        this.whichPayWay = k.dict_value
                        console.log('电子券', this.nowPayWayname, this.whichPayWay)
                        // 左边方块显示选中状态
                        let el = document.getElementsByClassName('payway-item')
                        el[1].classList.add('active')
                       
                    }

                } else {
                    let k = this.PayWay[0]
                    console.log('this.PayWay[0]', this.PayWay[0])
                    // this.whichPayWay = k.id
                    this.whichPayWay = k.dict_value
                    // this.nowPayWayname.push({ id: k.dict_value, name: k.dict_label, money: this.应收金额 })  //打开就是默认金额
                    console.log('不是会员卡', this.nowPayWayname, this.whichPayWay)
                    // this.whichPayWay = k.dict_value
                    // let el = document.getElementsByClassName('payway-item')
                    // el[0].classList.add('active')
                }
            } else//退货
                console.log('退货', this.sale_rtntype, this.apitradeno)
            {
                if (this.sale_rtntype == 1 && this.apitradeno != 0) {//按单退货  && this.apitradeno>0
                    console.log('退货', this.sale_rtntype, this.apitradeno)
                    this.nowPayWayname = this.rtnnowPayWayname
                    let i
                    i = this.rtnnowPayWayname.findIndex(v => v.id === "13")
                    if (i < 0) {
                        i = this.rtnnowPayWayname.findIndex(v => v.id === "14")
                    }
                    if (i < 0) { return this.$message.error("不是微信支付宝退款"); }
                    let dictvalue = this.rtnnowPayWayname[i].id
                    this.elePayVisible = true
                    this.onPaywayChange(this.rtnnowPayWayname[i]) //微信支付{dict_value:'13'}
                    //  this.onPaywayChange({dict_value:dictvalue}) //微信支付{dict_value:'13'}
                } else if (this.sale_rtntype == 1) {//按单退货  
                    this.nowPayWayname = this.rtnnowPayWayname
                }

                else {
                    this.whichPayWay = this.PayWay[0].dict_value //当前选中
                }


            }
        }
    },
    // 切换支付方式
    onPaywayChange(k) {
        this.tuanPayVisibletype=0//提单显示1提单2电子券主要区分talbe
        // console.log('this.allMoneyType.再收',this.allMoneyType.再收)
        // if(this.allMoneyType.再收==0){
        //     console.log('this.allMoneyType.再收',this.allMoneyType.再收)
        //     return this.$message.error("支付金额已够，不需其它支付了"); 
        // }
        // if (k.id === this.whichPayWay) {
        //   return this.whichPayWay = ''
        // }
        // this.whichPayWay = k.id
        this.whichPayWay = k.dict_value
        console.log('onPaywayChange', k)
        if (k.dict_value == "13") { //微信在线
            if (this.model > 0) {//销售
                this.elePayVisible = true
                this.paytitle = '微信收款'
                console.log('微信收款', this.再收金额)
                let obj = {
                    type: k.relationattri,
                    pay_type: '400',
                    model: 1,
                    money: this.再收金额,
                    goodslist: this.tableData // 传入选到的商品
                }
                this.elepaymsg = obj
                console.log('elepaymsg', this.elepaymsg)
            } else {
                let nowPayrow = this.nowPayWayname.find(v => v.id === "13")//微信退款
                if (!nowPayrow) { return this.$message.error("不是微信退款"); }
                this.elePayVisible = true
                this.paytitle = '微信退款'
                this.apipayrefund = true//标记微信支付宝退货true是退货
                let _money = nowPayrow.money
                console.log('微信退款', this.再收金额, this.nowPayWayname, nowPayrow, k)

                let obj = {
                    type: k.relationattri,
                    pay_type: '400',
                    model: -1,
                    money: _money,
                    out_trade_no: this.apitradeno,
                }
                this.elepaymsg = obj
            }
        }
        if (k.dict_value == "14") { //支付宝在线
            if (this.model > 0) {//销售
                this.elePayVisible = true
                this.paytitle = '支付宝'
                let obj = {
                    type: k.relationattri,
                    pay_type: '300',
                    model: 1,
                    money: this.再收金额,
                    goodslist: this.tableData // 传入选到的商品
                }
                this.elepaymsg = obj
            } else {
                console.log('支付宝退款')
                let nowPayrow = this.nowPayWayname.find(v => v.id === "14")//支付宝退款
                if (!nowPayrow) { return this.$message.error("不是支付宝退款"); }
                this.elePayVisible = true
                this.paytitle = '支付宝退款'
                this.apipayrefund = true//标记微信支付宝退货true是退货
                let _money = nowPayrow.money
                console.log('支付宝退款', this.再收金额, this.nowPayWayname, _money)

                let obj = {
                    type: k.relationattri,
                    pay_type: '300',
                    model: -1,
                    money: _money,
                    out_trade_no: this.apitradeno,
                }
                this.elepaymsg = obj
            }
        }
        if (k.dict_value == "15") { // 提单
            this.tuanPayVisibletype=1//提单显示1提单2电子券主要区分talbe
            this.tuanPayVisible = true
        }
        if (k.dict_value == "16") { // 电子券
            this.tuanPayVisibletype=2//提单显示1提单2电子券主要区分talbe
            this.tuanPayVisiblee = true
            
        }
        var cardmoney = 0
        //判断是否会员支付 
        if (this.CardListCurren.id > 0) {
            cardmoney = Number(this.CardListCurren.card_remoney) //余额
            if (cardmoney > this.allMoneyType.应收) {    //余额>应收
                cardmoney = this.allMoneyType.应收
            }
        }
    },
     // 提单显示1提单2电子券主要区分talbe
     tuanPaytype(num) {
        this.tuanPayVisibletype=num
        if (num==1){this.onPaywayChange({dict_value:'15'})}
        if (num==2){this.onPaywayChange({dict_value:'16'})}
    },
    // 支付宝 微信支付完成
    /*支付结果返回
          this.paydata={
            status:false,
            api_type:1,//1微信2支付宝
            api_trade_no:this.out_trade_no,
          },
  */
    onPayDown(val) {
        ///支付结果返回
        this.paydata.status = val.status
        this.paydata.api_type = val.api_type
        this.paydata.api_trade_no = val.api_trade_no
        if (val.status) {
            //apipayrefund标记微信支付宝退货true是退货
            if (!this.apipayrefund) {
                this.enterNuminPayWay(this.再收金额)  //判断  微信支付宝退货 就要关闭 
            }
            this.elePayVisible = false
            setTimeout(() => {
                this.payforAll()
            }, 200)
        } else {
            this.$message.error('支付失败')
        }
    },
    // 支付弹框开启
    onElePayOpen() {
        this.$refs.elePay.openPay()
    },
    // 支付弹框关闭
    onElePayClose() {
        this.elepaymsg = {}//支付传入   
        this.$refs.elePay.onpageClose()
        //回到自动选择现金
        let k = this.PayWay[0]
        this.whichPayWay = k.dict_value
    },
    // 生成门店销售结算明细表数据存入本地
    async t_pos_sale_cur(tableData, allMoney) {
        let user = this.$store.state.user.currUser
        let 主表id = makeid(1)
        let 结算明细id = makeid(this.nowPayWayname.length)//makeid(1)
        // console.log('结算明细id', 结算明细id,this.nowPayWayname)
        let 销售明细id = makeid(tableData.length)
        let seavetime = parseInt(new Date().getTime())
        // 订单编号 = 店编号 + 机器号+ 日期+ 第几单
        let sale_cur_销售主表 = await this.$localforage.getItem('t_pos_sale') || []
        let curDate = new Date();
        let NowData = this.getNowFormatDate(curDate) + ' ' + '00:00:00';
        let NowDataInt = parseInt(new Date(NowData).getTime())//转换成数值
        // console.log('NowData', NowDataInt,1618790400000,this.formatConversion(NowDataInt) ,this.formatConversion(1618763694000))
        let 销售主表length = sale_cur_销售主表.filter(f => parseInt(new Date((this.getNowFormatDate(f.sale_date) + ' ' + '00:00:00')).getTime()) == NowDataInt)//当天销售单总数
        let 销售单号 = this.生成销售单号(销售主表length.length + 1)
        let sale_detail_销售明细 = await this.$localforage.getItem('t_pos_sale_detail') || []
        let sale_cur_结算表 = await this.$localforage.getItem('t_pos_sale_cur') || []
        // 获取历史销售商品类型
        let goosds_types = await this.$localforage.getItem('goosds_types') || []
        // 获取库存
        let 库存信息 = await this.$localforage.getItem('t_pos_inv_bal') || []
        
        // 获取提单信息
        let 提单信息 = await  this.$localforage.getItem('t_pos_rec_gift') || []   
        // 统计销售的商品类型


        let 主表折扣
        let shopBaseMsg = getLocal('shopBaseMsg')
        if (!this.allMoneyType.sale_dis_1) {
            主表折扣 = 0
        } else {
            主表折扣 = ((1 - this.allMoneyType.sale_dis_1 / 100) * this.allMoneyType.原应收).toFixed(2)
        }
        let 销售主表 = {
            id: 主表id[0],
            tenid: user.tenid,
            lct: seavetime,
            uct: seavetime,
            lby: user.id,
            uby: user.id,
            tstate: 1,
            dept_id: user.dept_id,
            pos_id: shopBaseMsg.terminal,
            bstate: 1,
            shifts_shop: getLocal('classesType').id,
            sale_id: 销售单号,
            sale_date: seavetime,
            sale_userid: user.id,
            sale_count: this.allCount,
            sale_amount: Number(allMoney),
            sale_dis_1: Number(主表折扣),
            sale_dis_2: Number(this.allMoneyType.sale_dis_2),
            sale_act: Number(allMoney),
            sale_more: Number(this.allMoneyType.溢收),
            sale_rou: 0,//抹零
            sale_type: this.model,
            sale_other_id: 0,
            sale_source_id: this.salesourceid || 0,//源单号(按单退货)
            sale_rece: Number(this.allMoneyType.已收),
            sale_again: Number(this.allMoneyType.找零),
            sale_dis_rate: 0,
            sale_ladingdis: 0,
            sale_card_flag: 0,
            sale_top_flag: 0,
            sale_curcount: this.nowPayWayname.length, //支付类型付款明细行数
            sale_head: 0,
            prom_id: 0,
            api_type: this.paydata.api_type || 0,//1微信2支付宝
            api_trade_no: this.paydata.api_trade_no || 0,//第三方接口
            print_status: 0,
            cardid: this.CardListCurren.id,
            card_id_out: this.CardListCurren.card_id_out,
            card_remoney: Number(this.CardListCurren.card_remoney) || 0,
            card_score: this.CardListCurren.card_score || 0,
            card_serial_id: 0,
            cont_id: 0,
            rep_counter: 0,
            rep_orderid: 0,
            rep_counter_ple: 1,
            rep_acc_bills: 1
        }
        sale_cur_销售主表.push(销售主表)
        let 销售明细arr = []
        let 打印arr = []
        //结算明细
        let 结算明细arr = []
        let 支付金额
 
        this.nowPayWayname.forEach((item, i) => {
            //判断是否现金 只有现金可找零，退货也只能退现金
            if(item.id=='11'){
                支付金额= this.Subtr(Number(item.money),this.allMoneyType.找零) //Number(item.money)-this.allMoneyType.找零 
                // console.log(' 支付金额', 支付金额,Number(item.money),this.allMoneyType.找零);
            }else{
                支付金额=  Number(item.money)
            }
            let 结算明细 = {
                id: 结算明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 1,
                dept_id: user.dept_id,
                pos_id: shopBaseMsg.terminal,
                pid: 主表id[0],
                sale_id: 销售单号,
                pay_id: item.id,
                pay_amt:Number(支付金额), //  Number(item.money)
                // pay_id: item.typeid,
                // pay_amt: Number(item.allmoney)
            }
            结算明细arr.push(结算明细)
        })
        sale_cur_结算表.push(...结算明细arr)

        //判断会员消费
        if (this.CardListCurren.id > 0) {
            //有会员信息
            let 会员消费明细arr = []
            tableData.forEach((el, i) => {
                let 明细折扣
                if (!el.saledt_dis_1) {
                    明细折扣 = 0
                } else {
                    明细折扣 = ((el.part_price * el.count) * (1 - el.saledt_dis_1 / 100)).toFixed(2)
                }
                //判断会员消费先上传销售信息
                let 销售明细 = {
                    id: 销售明细id[i],
                    tenid: user.tenid,
                    lct: seavetime,
                    uct: seavetime,
                    lby: user.id,
                    uby: user.id,
                    tstate: 1,
                    dept_id: user.dept_id,
                    pos_id: shopBaseMsg.terminal,
                    pid: 主表id[0],
                    sale_id: 销售单号,
                    saledt_id: i + 1,
                    partid: el.id,
                    saledt_unit: el.part_unit,
                    saledt_uprice: Number(el.part_price),//销售明细单价
                    saledt_sprice: Number(el.part_price),//实售价
                    saledt_qty: this.model > 0 ? el.count : - el.count,
                    // saledt_qty: - el.count,
                    saledt_dis_1: Number(明细折扣),
                    saledt_dis_2: el.saledt_dis_2,
                    // saledt_amount: this.model > 0 ? (el.part_price * el.count) : -(el.part_price * el.count),
                    saledt_amount: el.实际价格,// this.model > 0 ? el.实际价格 : - el.实际价格,
                    saledt_dis_rate: 0,
                    saledt_des: '',
                    saledt_head: 0,
                    prom_id: 0,
                    made_des: '',
                    dis_share: 0.0,
                    print_status: 0,
                    print_count: 1
                }
                会员消费明细arr.push(销售明细)
            })
            // sale_detail_销售明细.push(...销售明细arr)

            console.log('会员消费', this.CardListCurren, this.nowPayWayname)
            let cashsum
            let cardsum
            this.nowPayWayname.forEach(item => {
                if (item.id == 12) {//会员
                    cardsum = Number(item.money)
                } else {//其它消费
                    cashsum = Number(item.money)
                }
            })
            let saledata = {
                sale: [销售主表],
                sale_detail: 会员消费明细arr,
                sale_cur: 结算明细arr,
            }

            if (this.cardsaletopflag == 2) {//会员是消费1还是充值2
                this.cardtopitem.top_serialid = 主表id[0]
            }
            if (this.cardsaleredeemflag == 2) {//会员兑换2销售1
                this.cardredeemitem.pid = 主表id[0]
                this.cardredeemitem.redeem_id = 销售单号
            }

            console.log('this.cardtopitem', this.cardtopitem);
            let card = {
                id: this.CardListCurren.id,//'会员卡id'
                cashsum: cashsum || 0,//现金消费,
                cardsum: cardsum || 0,//会员消费,
                totalsum: this.allMoneyType.已收 || 0,//消费总金额,
                score: this.allMoneyType.已收 || 0,//消费积分,
                saleid: 销售单号,//销售单号,
                saledata,//销售数据,
                topdata: this.cardtopitem,//充值记录数据,
                redeemdata: this.cardredeemitem,//兑换记录数据,
            }
            let res = await 会员api.会员卡消费(card)
            if (res.statusCode != 200) {
                return this.$message.error('会员卡消费失败')
            }
            Object.assign(this.CardListCurren, res.data)
        }

        // 判断是否有提单或者电子券
        let hastidan = this.nowPayWayname.findIndex(el => el.id == '15'||el.id == '16')
        console.log('判断是否有提单', hastidan,this.nowPayWayname);
        let t_pos_rec_gift = []
        if (hastidan >= 0) {
            let obj  =  this.coupons_table_data.concat( this.ecoupons_table_data) //两个数组对象合并
            obj.forEach(item => {
                let 提单明细 = {
                    id: item.id[0],
                    tenid: user.tenid,
                    lct: seavetime,
                    uct: seavetime,
                    lby: user.id,
                    uby: user.id,
                    tstate: 1,
                    dept_id: user.dept_id,
                    pos_id: shopBaseMsg.terminal,
                    pid: 主表id[0],
                    sale_id: 销售单号,
                    sale_type: this.model,
                    rec_id: item.rec_id||'',
                    rec_name:item.rec_name||'',
                    rec_qty: Number(item.rec_qty),
                    rec_amt: Number(item.rec_amt),
                    rec_giftid:item.rec_giftid,
                    rec_gifttype:item.rec_gifttype,
                }
                t_pos_rec_gift.push(提单明细)  
            })
            提单信息.push(...t_pos_rec_gift)
        }


        let ehastidan = this.nowPayWayname.findIndex(el =>el.id == '16')
            if (ehastidan >= 0) {
            let ecouponsdata=[]= t_pos_rec_gift.filter(i => i.rec_gifttype == 1)
            // console.log('this.ecoupons_table_data',ecouponsdata);
            // return 
            let res = await 会员api.使用电子券消费({consumedata:ecouponsdata})
            console.log('使用电子券消费', res);
            if (res.statusCode != 200) {
                return this.$message.error(`使用电子券消费失败，${res.message}`)
            }
            }
        // 销售明细
        console.log('销售明细arr', tableData);
        tableData.forEach((el, i) => {
            let 明细折扣
            if (!el.saledt_dis_1) {
                明细折扣 = 0
            } else {
                明细折扣 = ((el.part_price * el.count) * (1 - el.saledt_dis_1 / 100)).toFixed(2)
            }

            let 销售明细 = {
                id: 销售明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 1,
                dept_id: user.dept_id,
                pos_id: shopBaseMsg.terminal,
                pid: 主表id[0],
                sale_id: 销售单号,
                saledt_id: i + 1,
                partid: el.id,
                saledt_unit: el.part_unit,
                saledt_uprice: Number(el.part_price),//销售明细单价
                saledt_sprice: Number(el.part_price),//实售价
                saledt_qty: this.model > 0 ? el.count : - el.count,
                // saledt_qty: - el.count,
                saledt_dis_1: Number(明细折扣),
                saledt_dis_2: el.saledt_dis_2,
                // saledt_amount: this.model > 0 ? (el.part_price * el.count) : -(el.part_price * el.count),
                saledt_amount: el.实际价格,// this.model > 0 ? el.实际价格 : - el.实际价格,
                saledt_dis_rate: 0,
                saledt_des: '',
                saledt_head: 0,
                prom_id: 0,
                made_des: '',
                dis_share: 0.0,
                print_status: 0,
                print_count: 1
            }
            销售明细arr.push(销售明细)

            //打印模板数据
            let printobj = {
                saledt_id: i + 1,
                partid: el.id ? el.id : '',
                part_name: el.part_name ? el.part_name : '',
                saledt_uprice: el.part_price,
                saledt_unit: el.saledt_unit,
                saledt_qty: el.count,
                saledt_dis_1: Number(明细折扣),
                saledt_dis_2: el.saledt_dis_2,
                saledt_amount: el.实际价格,
                // saledt_amount: this.model > 0 ? (el.part_price * el.count) : -(el.part_price * el.count),
                优惠: Number(明细折扣) + el.saledt_dis_2
            }
            打印arr.push(printobj)


            //  减去对应商品的库存数量
            let j = 库存信息.findIndex(c => c.partid === el.id)
            if (j >= 0) {
                if (this.model === 1) {
                    库存信息[j].bal_amnt = 库存信息[j].bal_amnt - el.count // 库存数量减去销售数量
                    库存信息[j].bal_cost = 库存信息[j].bal_cost - (el.count * el.part_price) // 结存金额减去 销售金额
                } else {
                    库存信息[j].bal_amnt = 库存信息[j].bal_amnt + el.count // 库存数量加上销售数量
                    库存信息[j].bal_cost = 库存信息[j].bal_cost + (el.count * el.part_price) // 结存金额加上 销售金额
                }
                this.$store.commit('user/收银修改结存数量', el);
                console.log('收银修改结存数量', 1)
            } else {

                let obj = {
                    id: el.id,
                    tenid: user.tenid,
                    lct: seavetime,
                    uct: seavetime,
                    lby: user.id,
                    uby: user.id,
                    part_name: el.part_name, // 品名
                    tstate: 1,
                    dept_id: user.dept_id,
                    part_id: el.part_id, // 品号
                    partid: el.id, // 系统品号
                    region_id: el.r_store,
                    bal_date: seavetime,
                    bal_amnt: - el.count, //库存
                    bal_cost: -(el.count * el.part_price), //价值
                    sales_volume: 0,//销量
                    part_price: el.part_price,
                    part_model: el.part_model,
                    part_specs: el.part_specs,
                    part_usertype: el.part_usertype,
                    partusertype: el.partusertype
                }
                库存信息.push(obj)

                // this.$localforage.setItem('t_pos_inv_bal', 库存信息)
                this.$store.commit('user/获取结存表', 库存信息);
                console.log('收银修改结存数量', 2)
                // this.$store.commit('user/收银修改结存数量', el);
            }
            // 统计各类商品销售占比
            let type_in = goosds_types.findIndex(v => v.type == el.part_usertype)
            if (type_in >= 0) {
                goosds_types[type_in].num = goosds_types[type_in].num + el.count
            } else {
                let obj = {
                    type: el.part_usertype,
                    num: el.count,
                    lct: seavetime
                }
                goosds_types.push(obj)
            }

        })
        sale_detail_销售明细.push(...销售明细arr)


        // 打印小票
        if (getLocal('shopBaseMsg').saleprint==0){
        let ticketdata = {
            has_data: true,
            销售主表: 销售主表,
            销售明细arr: JSON.parse(JSON.stringify(打印arr)),//销售明细arr
            结算明细arr: JSON.parse(JSON.stringify(结算明细arr))
        }
        this.ticket_data = ticketdata
        // this.show_ticket = true
        setTimeout(() => {
            this.printLodop()
        }, 500)
       }
        this.$store.commit('user/changeModel', 1)
        await this.$localforage.setItem('t_pos_sale', sale_cur_销售主表)
        await this.$localforage.setItem('t_pos_sale_detail', sale_detail_销售明细)
        await this.$localforage.setItem('t_pos_sale_cur', sale_cur_结算表)
        await this.$localforage.setItem('t_pos_inv_bal', 库存信息)
        await this.$localforage.setItem('goosds_types', goosds_types)
        await this.$localforage.setItem('t_pos_rec_gift', 提单信息)

        //清空
        this.tableData = []
        this.allMoney = 0
        this.allCount = 0
        this.cashChange = null;
        this.checkoutFlag = false;
        this.dialogVisible = false
        //退货
        this.rtnnowPayWayname = [];
        this.nowPayWayname = [],// 选择的付款方式名字 多种
            this.sale_rtntype = 0//退货类型 0一般退货1按单退货
        this.salesourceid = 0
        this.cardsaletopflag = 1//会员充值2销售1
        this.cardtopitem = {};//会员充值记录
        this.cardsaleredeemflag = 1//会员充值2销售1
        this.cardredeemitem = {};//会员兑换记录
        this.apipayrefund = false//标记微信支付宝退货true是退货
        //支付宝微信
        this.paydata = {}//支付结果返回

        this.cardempty()//清空会员信息
        removeSession('orderList')
        this.$message.success("商品结算成功");

        // 提单及电子券清空
        if (this.$refs.tidanRef) {this.$refs.tidanRef.close()}
        if (this.$refs.tidanRefe) {this.$refs.tidanRefe.close()}
        this.coupons_table_data = []
        this.ecoupons_table_data = []

    },
    生成销售单号(num) {
        let shopmsg = getLocal('shopBaseMsg')
        var time = new Date();
        // var year = now.getFullYear();       //年
        // var month = now.getMonth() + 1;     //月
        // var day = now.getDate();            //日
        // if (month < 10) month = "0" + month;
        // if (day < 10) day = "0" + day;
        var y = time.getFullYear();
        var m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
        var d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
        var h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
        var mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
        var s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
        // let 店编号 = '911005'
        let 机器号 = shopmsg.terminal
        let 日期 = '' + y + m + d + h + mm + s
        // let 日期 = '' + year + month + day
        return 机器号 + 日期 + '-' + num
    },
    //结算弹框关闭
    onDialogClose() {
        this.moneyInput = ''
        this.allMoneyType.原应收 = 0
        this.allMoneyType.原优惠 = 0
        this.allMoneyType.已收 = 0
        this.allMoneyType.优惠 = 0
        this.allMoneyType.实价 = 0
        this.allMoneyType.应收 = 0
        this.allMoneyType.再收 = 0
        this.allMoneyType.溢收 = 0
        this.allMoneyType.整单优惠 = 0
        this.allMoneyType.sale_dis_1 = 0
        this.allMoneyType.sale_dis_2 = 0
        this.nowPayWayname = []
        let el = document.getElementsByClassName('payway-item')
        for (let i = 0; i < el.length; i++) {
            el[i].classList.remove('active')
        }
        //提单及电子券清空
        if (this.model > 0) {this.coupons_table_data = []}
        if (this.$refs.tidanRef) {this.$refs.tidanRef.close()}
        if (this.model > 0) {this.ecoupons_table_data = []}
        if (this.$refs.tidanRefe) {this.$refs.tidanRefe.close()}

    },
    // 整单折扣
    zhengZheKou(val) {
        val = this.model > 0 ? val : -val
        if (this.nowPayWayname.length > 0) return this.$message.error('请清除收款项目后进行折扣')
        this.allMoneyType.sale_dis_1 = Number(val) || 0
        let 优惠
        if (!this.allMoneyType.sale_dis_1) {
            优惠 = this.allMoneyType.原应收 - this.allMoneyType.sale_dis_2
        } else {
            优惠 = this.allMoneyType.原应收 * (1 - this.allMoneyType.sale_dis_1 / 100) + this.allMoneyType.sale_dis_2
        }
        this.allMoneyType.整单优惠 = 优惠.toFixed(2)
        this.allMoneyType.优惠 = (this.allMoneyType.原优惠 + 优惠).toFixed(2)
    },
    // 整单折让
    zhengZheRang(val) {
        val = this.model > 0 ? val : -val
        if (this.nowPayWayname.length > 0) return this.$message.error('请清除收款项目后进行折扣')
        this.allMoneyType.sale_dis_2 = Number(val) || 0
        let 优惠
        if (!this.allMoneyType.sale_dis_1) {
            优惠 = this.allMoneyType.sale_dis_2
        } else {
            优惠 = this.allMoneyType.原应收 * (1 - this.allMoneyType.sale_dis_1 / 100) + this.allMoneyType.sale_dis_2
        }
        this.allMoneyType.整单优惠 = 优惠.toFixed(2)
        this.allMoneyType.优惠 = (this.allMoneyType.原优惠 + 优惠).toFixed(2)
    },

    // 将输入框里的数字填在收款方式金额里面
    enterNuminPayWay(num) {
        console.log('enterNuminPayWay',num, this.nowPayWayname,this.whichPayWay) 
       let ni = this.nowPayWayname.findIndex(e => e.id == this.whichPayWay)
       let k = this.PayWay.find(item => item.dict_value === this.whichPayWay)
       // let k = this.PayWay.find(item => item.id === this.whichPayWay)
       console.log('ni',ni) 
       if (ni >= 0) {
           this.nowPayWayname[ni].money = num
           if (num == 0) {
               this.nowPayWayname.splice(ni, 1)
               // 判断提单 要清空
               if(this.whichPayWay=='15'){
                   console.log('15') 
                       this.coupons_table_data=[]
                       this.cancelTidan()//弹窗提单窗口清空
               }
               if(this.whichPayWay=='16'){
                   console.log('16') 
                       this.ecoupons_table_data=[]
                       this.cancelTidane()//弹窗提单窗口清空
               }
           }
       } else {
           if(num!=0){
           let obj = { id: k.dict_value, name: k.dict_label, money: num }
           this.nowPayWayname.push(obj)
           }
       }
   },

// 结算按钮
payforAll() {
    //溢收金额提醒
    if (Math.abs(this.allMoneyType.溢收) > 0) {
        this.$confirm(`该单结算出现【溢收】${this.allMoneyType.溢收}，请仔细检查，是否结算?`, "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
           }).then(() => {
            this.payforAllExe() 
            }).catch(() => {return});
    }else{
        this.payforAllExe()    
    }
},
    // 结算按钮执行
    payforAllExe() {
        if (this.$store.state.user.currUser) {
            if (this.nowPayWayname.length == 0) {
                this.$message.error('请选择支付方式')
                return
            }
            if ((Math.abs(this.allMoneyType.应收) - Math.abs(this.allMoneyType.已收)) > 0) {
                this.$message.error('应收金额不足')
                return
            }

            //支付
            //如果是会员消费数据实时写回后台

            this.t_pos_sale_cur(this.tableData, this.allMoney)
            //  console.log('t_pos_sale_cur', msg)
            // this.tableData = []
            // this.allMoney = 0
            // this.allCount = 0
            // this.cashChange = null;
            // this.checkoutFlag = false;
            // this.dialogVisible = false
            // removeSession('orderList')
            // this.$message.success("商品结算成功");
        } else {
            this.$message.error("用户未登录无法进行此项操作，请登录后再试");
        }
    },
    // 消费群体确认
    consumerGroupsConfirm() { },
    // 消费群体弹框关闭
    consumerGroupsClose() { },
    manjine() {
        this.$refs.keybord.moneyInput = this.allMoneyType.应收
    },
    // 点击已选择的商品
    goodsClick(item) {
        this.selectedGoodsId = item.id
        this.setCurrent(item)
    },
    setCurrent(row) {
        this.$refs.diandan.setCurrentRow(row);//选中行高亮
    },
    // 挂单
    keepList(_type) {
        if (_type == 1) {
            //挂单
            if (this.tableData.length == 0) return this.$message.error('没有需要挂起的订单')
            let guadanList = getSession('guadanList') || []
            let list = JSON.parse(JSON.stringify(this.tableData))
            let count = 0
            let allMoney = 0
            let num = 0
            let name = ''
            list.forEach(item => {
                name += ' ' + item.part_name
                count += item.count;
                this.计算实际价格(item)
                let price = item.实际价格 ? item.实际价格 : 0
                num += parseFloat(price);
            })
            let obj = {
                id: guadanList.length,
                goodsName: name,
                count: count,
                part_price: num.toFixed(2),
                listData: list
            }
            guadanList.push(obj)
            this.tableData = []
            this.guadanList = guadanList
            setSession('orderList', this.tableData)
            setSession('guadanList', this.guadanList)
        } else {
            // 取单
            this.leftTopTabs = '挂单'
        }
    },
    // 取单
    takeList(list) {
        if (this.tableData.length > 0) return this.$message.error('点单列表中还有未结账订单')
        let guadanList = getSession('guadanList') || []
        this.tableData = JSON.parse(JSON.stringify(list.listData))
        let i = guadanList.findIndex(v => v.id == list.id)
        if (i >= 0) guadanList.splice(i, 1)
        setSession('guadanList', guadanList)
        this.guadanList = guadanList
        //获得当前行
        this.goodsClick(this.tableData[i])
        if (this.CardListCurren.id == 0) {
            // 没有会员信息
        } else {
            this.zhekou({ type: 1, num: this.CardListCurren.card_dis_rate * 100 })//  zhekou {type: 1, num: 80} 
        }
        this.getAll()
        this.leftTopTabs = '点单'
    },
    //按单退货

    // 结账弹框支付方式点击变色
    zhifufangshi(item) {
        this.whichPayWay = item.id
    },
    // 选择销售或退货
    changeModel() {
        this.getAll()
    },

    // 取消按钮
    cancelMemberOpen() {
        this.resetcard()//会员表单重置
        this.MemberOpenVisible = false
    },
    // 会员表单重置
    resetcard() {
        this.CardListCurren = {
            id: 0,
            card_id_out: '',
        };
    },
    //多会员弹窗
    cardselect(row) {
        console.log('cardselect', row)
        this.resetcard()//会员表单重置
        this.CardListCurren = row
        this.MemberOpenVisible = false
        //获得当前行
        for (let i = 0; i < this.tableData.length; i++) {
            this.goodsClick(this.tableData[i])
            if (this.CardListCurren.id == 0) {
                // 没有会员信息
                this.resetcard()//会员表单重置

            } else {
                this.cardid = this.CardListCurren.id
                this.zhekou({ type: 1, num: this.CardListCurren.card_dis_rate * 100 })
            }
            this.getAll()
            this.leftTopTabs = '点单'
        }
    },


    inputNum(n) {
        this.queryParams.carddata = n
        this.searchVipCard(this.queryParams.carddata)
        // 后台请求搜索结果
        // this.searchVipCard()
    },
    showkeyboard() {
        this.$refs.keyboard.open()
    },

    //历史销售按单退货 
    onReturnGoods(msg) {
        console.log('this.rec_gift', msg.rec_gift)
        this.Returnrec_gift = msg.rec_gift//提单
        
        console.log('this.$refs.giftRef', this)

        console.log('onReturnGoods退货', msg)
        let j = msg.sale_cur.findIndex(v => v.pay_id == "12")//会员卡
        // 判断是否会员消费，必须输入卡号
        if (j >= 0) {//会员消费
            if (this.CardListCurren.id > 0 && msg.sale.cardid == this.CardListCurren.id) {
            } else if (msg.sale.cardid != this.CardListCurren.id) {
                return this.$message.error('当前退货会员卡号不是消费卡信息,不能退货')
            } else {
                return this.$message.error('会员卡信息不存在,不能退货')
            }
        }
        //折扣反算    
        let arr = []
        msg.sale_detail.forEach(item => {
            let obj = {
                id: item.id,
                part_id: item.part_id,
                part_name: item.part_name,
                part_price: item.part_price,
                saledt_amount: item.saledt_amount,
                saledt_dis_1: (1 - item.优惠 / (item.saledt_amount + item.优惠)) * 100,//折扣反算    
                saledt_dis_2: item.saledt_dis_2,
                saledt_id: item.saledt_id,
                saledt_qty: item.saledt_qty,
                saledt_unit: item.saledt_unit,
                saledt_uprice: item.saledt_uprice,
                count: item.saledt_qty,
                优惠: item.优惠,
                实际价格: item.实际价格,
            }
            arr.push(obj)
        })


        this.tableData = arr//msg.sale_detail //明细
        this.rtnnowPayWayname = []

        msg.sale_cur.forEach(item => {
            let i = this.PayWay.findIndex(v => v.dict_value === item.pay_id)
            if (i >= 0) {
                let obj = {
                    id: item.pay_id,
                    name: this.PayWay[i].dict_label,
                    money: -item.pay_amt,
                    relationattri: this.PayWay[i].relationattri,
                    dict_label: this.PayWay[i].dict_label,
                    dict_value: this.PayWay[i].dict_value,
                    is_default: this.PayWay[i].is_default,
                }
                this.rtnnowPayWayname.push(obj)
                this.whichPayWay = this.PayWay[i].dict_value //当前选中
                console.log('this.rtnnowPayWayname', this.rtnnowPayWayname)
            }
        })
     
        // this.Tidanedata(this.coupons_table_data )
        // console.log('this.coupons_table_data',  this.coupons_table_data)
        this.sale_rtntype = msg.sale_rtntype//退货类型 0一般退货1按单退货
        this.salesourceid = msg.sale.id //按单退货原单号
        this.apitradeno = msg.sale.api_trade_no //微信支付宝退货原单号
        this.$store.commit('user/changeModel', -1)
        this.getAll()
        this.leftTopTabs = '点单'
    },
    //打印
    printLodop() {
        // var LODOP; //声明为全局变量 
        // function CreateOneFormPage() {
        //   LODOP = getLodop();
        //   LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_表单一");
        //   LODOP.SET_PRINT_MODE("POS_BASEON_PAPER",true);
        //   LODOP.SET_PRINT_STYLE("FontSize", 18);
        //   LODOP.SET_PRINT_STYLE("Bold", 1);
        //   LODOP.ADD_PRINT_TEXT(50, 120, 260, 39, " ");
        //   LODOP.SET_PRINT_STYLEA(0,"TextNeatRow",true);
        //   LODOP.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById("ticket-box").innerHTML);

        //   // LODOP.SET_PRINT_PAGESIZE(3, 800,20,'')
        // };
        // CreateOneFormPage()
        // LODOP.PREVIEW();
        // // LODOP.PRINT();

        this.$refs.printcom.printTicket()

    },

    mobilPayColor(item) {
        let color = "#de9c53"
        if (item.dict_value == "13") {
            color = '#369000'
        }
        if (item.dict_value == "14") {
            color = '#007ffe'
        }

        if (this.whichPayWay == item.dict_value) { color = '#CC3333' }//选中颜色
        return { backgroundColor: color }
    },
    ...vipnummethods,
    ...tidanmethods,
    ...tidanmethodse,
}