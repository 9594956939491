<template>
  <div class="tuan-box" >
    <div class="left-box">
      <div class="search-box">
        <el-popover
          placement="right-start"
          width="340"
          trigger="manual"
          key="authcodebord"
          v-model="visible">
          <NumberBoard  @bordclose="onBorderClose" @input-num="onKeyInput" @btnclick="onBtnClick"/>
          <el-input ref="inputbox"
            slot="reference" 
            v-model="queryParams.carddata" 
            @input="valueChange" 
            @focus="visible = true"
            @keyup.enter.native="sedPayMsg"
            size="small" />
        </el-popover>
      </div>

      <div class="box-bottom">
        <div class="table-box">
          <el-table :data="activecoup" stripe border size="mini" style="width: 100%">
            <el-table-column prop="receive_time" label="时间" width="140">
              <template slot-scope="scope">
                <div>
                  {{scope.row.receive_time | parseTime}}
                </div>
              </template>
            </el-table-column>
      
            <el-table-column prop="rec_name" label="券名">
            </el-table-column>
            <el-table-column prop="rec_amt" label="金额" >
            </el-table-column>
            <!-- <el-table-column label="数量">
              <template slot-scope="scope">
                <div  @click.stop="closeOther(scope.row.id)">  
                  <table-num-bord
                    :ref="'tablebord'+scope.row.id"
                    :key="'table'+scope.row.id"
                    :defnum="Number(scope.row.rec_qty)"
                    @input-num="onTableNumInput($event,scope.row)">
                  </table-num-bord>
                </div>
              </template>
            </el-table-column> -->
             <el-table-column prop="rec_qty" label="数量"  />
             <el-table-column prop="rec_giftid" label="券号" />
            <!-- <el-table-column label="礼券号">
              <template slot-scope="scope">
                <div>
                    <el-popover placement="bottom" width="540" trigger="click">
                      <div class="keyboard">
                        <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput($event,scope.row)"></all-keyboard>
                      </div>
                      <el-input slot="reference" size="mini" v-model="scope.row.rec_giftid" @focus.stop="all_keyboard_show=true"></el-input>
                    </el-popover>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocal } from '@/utils/localstorage.js'
import 会员api from '../../views/会员/api'
import { makeid } from '@/components/makeid.js'
import { parseTime } from '@/utils'
import tableNumBord from '@/components/TableNumberbord/index.vue'
import AllKeyboard from '@/components/Keyboard.vue'
import NumberBoard from '@/components/NumberBord'
export default {
  components:{
    tableNumBord,
    AllKeyboard,
    NumberBoard
  },

  filters:{
    parseTime(data){
      return data ? parseTime(data) : ''
    }
  },
  data() {
    return {
      activecoup:[],
      gift_coupons:[],
      //  // 查询参数
      // queryParams: {
      //   carddata: "",
      // },
       visible: false,
       // 查询参数
    queryParams: {
      carddata: "",
      deptid: getLocal('companyInfo').id,
    },
    }
  },

  methods: {
    chooseGift(item){
      let i = this.activecoup.findIndex(el =>el.typeid === item.id)
      if (i>=0) {
        // this.activecoup.splice(i,1)
      }else {
        let id = makeid(1)
        let obj = {
          id: id,
          typeid: item.id,
          receive_time: new Date().getTime(),
          rec_id: item.id,
          rec_name: item.card_name,
          rec_qty: 1,
          relationattri: item.card_remoney || 0,
          rec_giftid: item.card_id_out,
          rec_gifttype:1,
        }
        obj.rec_amt = (obj.relationattri * obj.rec_qty).toFixed(2)
        this.activecoup.push(obj)
      }
    },
    onTableNumInput(num,item){
      item.rec_qty = num
      item.rec_amt = (item.relationattri * item.rec_qty).toFixed(2)
    },
    closeBordInput(){
      let that = this
      this.activecoup.forEach(el => {
        let ref = 'tablebord' + el.id
          that.$refs[ref].close()
      });
    },
    closeOther(id){
      let that = this
      this.activecoup.forEach(el => {
        if (el.id != id) {
          let ref = 'tablebord' + el.id
          that.$refs[ref].close()
        }
      });
    },
    // 键盘输入
    onKeyInput(v,item) {
      item.rec_giftid = v
    },
    close(){ // 弹窗关闭了
      this.activecoup = []
       this.$emit('tidanCanfirme',this.activecoup)
    },
    canfirm(){ // 提交礼券电子券
      this.$emit('tidanCanfirme',this.activecoup)
    },
     async sedPayMsg() {
       //查询电子券
       console.log('sedPayMsg')

        if (!this.queryParams.carddata) { return this.$message.warning('请输入查询条件') }
        this.loading = true;
        let [err, res] = await 会员api.查询使用电子券列表(this.queryParams)

        if (err) {
            this.resetcard()//会员表单重置
            return this.$message.error('查询使用电子券列表失败' + err)
        }
        if (res.statusCode != 200) {
            this.resetcard()//会员表单重置
              return this.$message.error(`查询使用电子券列表失败，${res.message}`)
        }
         console.log('res.data.rows', res.data.rows)
         let resdata=res.data.rows
         if (resdata.length>0){
         this.chooseGift(resdata[0])
         }
     },
       //监听input框内的输入事件
    valueChange(e){
        
    },
     onKeyInput(num){
      // this.queryParams.carddata = num
      // this.visible = false
      this.sedPayMsg()
    },
    onBtnClick(num){
      this.queryParams.carddata = num

    },
     onBorderClose(){
      this.visible = false
      // this.$emit('Ecouponclose')
    },
     // 会员表单重置
    resetcard() {
        this.queryParams.carddata='' 
    },
  }
}
</script>

<style lang="scss" scoped>
.tuan-box {
  width: 100%;
  height: 600px;
  display: flex;
  .left-box {
    height: 100%;
    margin-right: 30px;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .box-top {
      .coupons-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        padding: 5px;
        .coupons-item {
          width: 100px;
          height: 55px;
          border: 2px solid #ccc;
          color: #333;
          margin-bottom: 10px;
          margin-right: 10px;
          line-height: 55px;
          text-align: center;
          background-color: #de9c53;
          cursor: pointer;
          &:hover {
            background-color: #f56c6c;
          }
        }
        .activecoup {
          background-color: #cc3333;
        }
      }
    }
  }
  div {
    height: 100%;
  }
}
</style>
