<template>
  <div style="padding:10px 15px;width:100%;" @click="closeBordInput">
    <div class="btn">
      <el-button size="small" type="success" :disabled="is_look" @click="seave(0)">保 存</el-button>
      <el-button size="small" type="primary" :disabled="is_look" @click="seave(1)">确 定</el-button>
      <el-button size="small" type="danger" @click="handlePrint()">打 印</el-button>
      <el-button size="small" type="danger" @click="goback">关 闭</el-button>
    </div>
    <div class="content">
      <el-row>
        <el-col :span="14">
          <div class="form">
            <el-form ref="form" :model="searchform" :rules="rules" label-width="80px" inline size="small" :disabled="is_look">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="编号" prop="inv_id">
                    <el-input v-model="searchform.inv_id" readonly style="width:170px;"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="要货区域" prop="inv_region">
                    <el-select v-model="searchform.inv_region" style="width:170px;">
                      <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>

                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="单据类型">
                    <el-select v-model="searchform.inv_bustype" style="width:170px;">
                      <el-option label="一报" :value="0"></el-option>
                      <el-option label="二报" :value="1"></el-option>
                      <el-option label="订单报货" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="手工单号">
                    <el-popover placement="bottom" width="540" trigger="click">
                      <div class="keyboard">
                        <all-keyboard ref="allkeyboardRef" @input-text="onKeyInput"></all-keyboard>
                      </div>
                      <el-input slot="reference" v-model="searchform.inv_billid" @focus.stop="all_keyboard_show=true" style="width:170px;"></el-input>
                    </el-popover>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>

                <el-col :span="6">
                  <el-form-item label="报货时间" prop="inv_needdate">
                    <el-date-picker disabled v-model="searchform.inv_needdate" type="datetime" placeholder="选择日期" value-format="timestamp" style="width:170px;">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="仓库" prop="inv_fm">
                    <el-select v-model="searchform.inv_fm" style="width:170px;">
                      <el-option v-for="item in t_wrhus" :key="item.id" :label="item.wrhus_name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="班次" prop="inv_round">
                    <el-select v-model="searchform.inv_round" style="width:170px;">
                      <el-option v-for="item in shopround" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="订单号">
                    <el-input v-model="searchform.name" disabled style="width:170px;"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="需求日期" prop="inv_date1">
                    <el-date-picker v-model="searchform.inv_date1" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" placeholder="选择日期时间" style="width:170px;"
                      :picker-options="pickerOptions" />
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="上传" prop="tstate">
                    <el-select v-model="searchform.tstate" style="width:170px;" :disabled="true">
                      <el-option label="新建" :value="0"></el-option>
                      <el-option label="待上传" :value="1"></el-option>
                      <el-option label="已上传" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="状态" prop="bstate">
                    <el-select v-model="searchform.bstate" style="width:170px;" :disabled="true">
                      <el-option label="未确定" :value="0"></el-option>
                      <el-option label="已确定" :value="1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-table ref="tableRef" :data="tableData" style="width: 100%" border max-height="700" highlight-current-row @row-click="onRowClick">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="part_id" label="品号">
            </el-table-column>
            <el-table-column prop="part_name" label="品名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="part_price" label="单价" width="70">
            </el-table-column>

          
             <el-table-column prop="part_usertype" label="商品类型" sortable >
                <template slot-scope="scope">
                  <span v-show="scope.row.part_usertype == item.id" v-for="item in type0Goods" :key="item.id">{{item.name}}</span>
                </template>
              </el-table-column>

            <el-table-column label="报货量" width="130">
              <template slot-scope="scope">
                <div v-if="!is_look" @click.stop="closeOther(scope.row.part_id)">
                  <table-num-bord
                    :ref="'tablebord'+scope.row.part_id"
                    :key="scope.row.part_id"
                    :defnum="Number(scope.row.invbi_plan)"
                    @input-num="onTableNumInput($event,scope.row)">
                  </table-num-bord>
                </div>
                <div v-else>{{scope.row.invbi_plan}}</div>
              </template>
            </el-table-column>

               <el-table-column prop="bal_amnt" label="库存" sortable align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.partid == item.id" v-for="item in t_pos_inv_bal" :key="item.id">{{item.bal_amnt}}</span> 
                </template>
              </el-table-column>


            <!-- <el-table-column prop="销量" label="销量">
            </el-table-column> -->

            <el-table-column prop="part_specs" label="规格">
            </el-table-column>

            <el-table-column prop="part_model" label="型号">
            </el-table-column>

            <el-table-column prop="notes" label="备注">
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template scope="scope">
                <el-button :disabled="is_look" size="mini" type="danger" @click="delItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="10" class="goods">
          <goods-menu @chooseGoods="addOrderList" @chooseType="nowType" />
        </el-col>
      </el-row>
    </div>
    <div class="keybord_box">
      <key-board ref="keyboard" @input-num="inputNum"></key-board>
    </div>
    <div class="ticket-box">
      <ticket-template ref="printneed" name="need" :ticketdata="ticket_data" v-show="show_ticketI"></ticket-template>
    </div>
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { NowTime } from '@/utils'
import { getLocal, setLocal, removeLocal } from '@/utils/localstorage.js'
import KeyBoard from '@/components/NumberKeyboard'
import GoodsMenu from '@/views/收银/GoodsMenu'
import AllKeyboard from '@/components/Keyboard.vue'
import tableNumBord from '@/components/TableNumberbord/index.vue'
import { mapGetters } from 'vuex'
import ticketTemplate from '@/components/TicketTemplate/printneed.vue'//报货打印
export default {
  components: {
    GoodsMenu,
    KeyBoard,
    AllKeyboard,
    ticketTemplate,
    tableNumBord
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region', 'dictdata']),
    ...mapGetters('user', ['t_pos_inv_bal'])
  },
  data() {
    return {
      ticket_data: {}, //小票数据
      show_ticketI: false,
      //  时间格式
      pickerOptions: this.getPickerOptions(),
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      editid: null,
      报货单主表: {},
      searchform: {
        inv_id: '',
        tstate: 0,
        bstate: 0,
        inv_bustype: 0,
        inv_region: 0,
        inv_needdate: null,
        inv_date1: null,
        inv_billid: '',
        inv_fm: null,
        inv_round: null,//门店班次  登录班次
        inv_reason: null,//门店退库原因
      },
      rules: {
        inv_id: [{ required: true, message: '请输入编号', trigger: 'blur' }],
        inv_needdate: [{ required: true, message: '请选择报货日期', trigger: 'change' }],
        inv_date1: [{ required: true, message: '请选择需求日期', trigger: 'change' }],
        inv_region: [{ required: true, message: '请选择要货区域', trigger: 'change' }],
        inv_fm: [{ required: true, message: '请选择仓库', trigger: 'change' }],
      },
      //门店班次
      shopround: [],
      tableData: [],
      type0Goods: [],
      goodsData: [],
      goodsBoxData: [],
      nowGoodsType: null,
      all_keyboard_show: false,
      is_look: false // 是否是查看状态
    }
  },

   beforeRouteEnter(to, from, next) {
    next(async vm => {
      let isadd = to.params.rptid
      // vm.setData()
      // await vm.getTypeData()
      // await vm.getGoodsData()
      if (isadd) {
        vm.initData(isadd)
      }else{
        vm.tableData = []
      }
      vm.$store.commit('app/setbaohuoPage', to.fullPath)
       vm.initData()
    })
  },

  // beforeRouteEnter(to, from, next) {
  //   next(async vm => {
  //     let isadd = to.params.rptid
  //     vm.setData()
  //     await vm.getTypeData()
  //     await vm.getGoodsData()
  //     if (isadd) {
  //       vm.initData(isadd)
  //     }
  //     vm.$store.commit('app/setbaohuoPage', to.fullPath)
  //   })
  // },
  async created() {
    this.initData()
  },
  methods: {
    setData() {
      this.searchform.inv_needdate = parseInt(new Date().getTime())
    },
    goback() {
      this.editid = 0
      this.is_look = false
      this.$refs.form.resetFields()
      this.tableData = []
      this.$router.push('/baohuo')
    },
    // showkeyboard() {
    //   this.$refs.keyboard.open()
    // },
    onTableNumInput(num,item){
      item.invbi_plan = num
    },
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      let type0Goods = typeData.filter(i => !i.isdel)
      this.type0Goods = type0Goods
      if (type0Goods.length > 0) {
        this.nowGoodsType = type0Goods[0]
      }
    },
    async initData() {
      await this.getGoodsData()
      await this.getTypeData()

       this.editid = Number(this.$route.params.rptid) 
      this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
      if (this.editid == 0) { // 类型为0是新增
        this.setData()
        this.searchform.inv_id = this.制作编号()
        // this.is_look = false
      } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
        console.log('this.$route.params1', this.$route.params)
        this.initEditData(this.editid)

      }
      //默认
      let isdefault = this.t_region.filter(item => item.is_default == 1)//默认
       this.searchform.inv_region = isdefault[0].dict_value
    },
    async initEditData(id) {

      this.editid = this.$route.params.rptid
      let 报货单 = await this.$localforage.getItem('t_inv_bill')
      let 报货单明细 = await this.$localforage.getItem('t_inv_bill_items')
      报货单.forEach(item => {
        if (item.id == id) {
          this.报货单主表 = item
        }
      })
      for (const key in this.searchform) {
        this.searchform[key] = this.报货单主表[key]
      }
      //状态
      if (this.searchform.bstate == 0) {
        this.is_look = false
      } else {
        this.is_look = true
      }

      let arr = []
      报货单明细.forEach(item => {
        if (item.pid == id) {
          let goodsItem = this.goodsData.filter(f => f.id == item.partid)
          let obj = {
            partid: goodsItem[0].id,
            part_id: goodsItem[0].part_id,
            part_name: goodsItem[0].part_name,
            part_price: goodsItem[0].part_price,
            part_unit: goodsItem[0].part_unit,
            part_specs: goodsItem[0].part_specs,
            invbi_price: item.invbi_price,
            invbi_plan: item.invbi_plan,
            part_model: goodsItem[0].part_model,
            part_usertype: goodsItem[0].part_usertype,
            notes: item.notes,
            库存: 0,
            销量: 0
          }
          arr.push(obj)
        }
      })
     
      // 报货单明细.forEach(item => {
      //   if (item.pid == id) {
      //     let 总库存 = this.$store.state.user.t_pos_inv_bal
      //     let n = 总库存.findIndex(v => v.id == item.partid)
      //     let goodsItem = this.goodsData.filter(f => f.id == item.partid)
      //     let part_usertype = this.type0Goods.find(item => item.id == goodsItem[0].part_usertype).alias
      //     let obj = {
      //       id: item.id,
      //       // part_id: item.partid,
      //       partid: goodsItem[0].id,
      //       part_id: goodsItem[0].part_id,
      //       part_usertype: part_usertype,
      //       part_name: goodsItem[0].part_name,
      //       part_price: goodsItem[0].part_price,
      //       part_specs: goodsItem[0].part_specs,
      //       part_model: goodsItem[0].part_model,
      //       invbi_plan: item.invbi_plan,
      //       库存: n >= 0 ? 总库存[n].bal_amnt : 0,
      //       销量: 0
      //     }
      //     arr.push(obj)
      //   }
      // })
      this.tableData = arr
    },
    nowType(v) {
      if (!v) return
      this.nowGoodsType = v
    },
    // 表格点击当前行
    onRowClick(v) {
      this.nowGoods = v
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods.invbi_plan = n
    },
    // 键盘输入
    onKeyInput(v) {
      this.searchform.inv_billid = v
      this.all_keyboard_show = false
    },
    addOrderList(v) {
      let ishave = this.tableData.findIndex(i => i.partid == v.id)
      if (ishave >= 0) {
        console.log(ishave);
        this.$refs.tableRef.setCurrentRow(this.tableData[ishave])
        if (this.is_look) return
        this.tableData[ishave].invbi_plan++
      } else {
        if (this.is_look) return
        let data = {
          partid: v.id,
          part_id: v.part_id,
          part_unit: v.part_unit,
          part_name: v.part_name,
          part_price: v.part_price,
          part_specs: v.part_specs,
          invbi_price: v.part_price,
          invbi_plan: 1,
          part_model: v.part_model,
          part_usertype: v.part_usertype,
          partusertype: v.partusertype,
          notes: '',
          库存:  0,
          销量: 0
        }
        this.tableData.push(data)
      }
    },
    // addOrderList(v) {
    //   let ishave = this.tableData.findIndex(i => i.part_id == v.id)
    //   if (ishave >= 0) {
    //     this.$refs.tableRef.setCurrentRow(this.tableData[ishave])
    //     if (this.is_look) return
    //     this.tableData[ishave].invbi_plan++
    //   } else {
    //     if (this.is_look) return
    //     let 总库存 = this.$store.state.user.t_pos_inv_bal
    //     let n = 总库存.findIndex(item => item.id == v.part_id)
    //     let obj = {
    //       id: v.id,
    //       partid: v.id,
    //       part_id: v.part_id,
    //       part_usertype: this.nowGoodsType.alias,
    //       part_name: v.part_name,
    //       part_price: v.part_price,
    //       part_specs: v.part_specs,
    //       part_model: v.part_model,
    //       invbi_plan: 1,
    //       库存: n >= 0 ? 总库存[n].bal_amnt : 0,
    //       销量: 0
    //     }
    //     this.tableData.push(obj)
    //   }
    // },
    delItem(item) {
      let i = this.tableData.findIndex(v => v.part_id == item.part_id)
      if (i >= 0) {
        this.tableData.splice(i, 1)
      }
    },
    //保存
    seave(bstate) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.tableData.length <= 0) return this.$message.error('没有报货的商品')
          let inv_whycat_arr = await this.$localforage.getItem('t_inv_op') || []
          if (inv_whycat_arr.length == 0) return this.$message.error('缺少基本数据,请跳转设置下载数据')
          let 明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
          let 主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
          let  上传状态  //传输状态0-新建, 1-待上传,2-未批复, 3-已批复,4-已确认,4-完成
          if (bstate==0){
              上传状态=0
          }else if (bstate==1){
              上传状态=1
          }
          let inv_whycat, inv_fmcat, inv_tocat
          inv_whycat_arr.forEach(item => {
            if (item.invop_shopop == 1) {
              inv_whycat = item.invop_id
              inv_fmcat = item.invop_sorc
              inv_tocat = item.invop_dest
            }
          })
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          let seavetime = parseInt(new Date().getTime())
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 报货单明细 = []
          let 报货单明细id = makeid(this.tableData.length)
          let 总价值 = 0
          if (this.editid == 0) {//新增
            console.log('seave', '新增', 主表id)
            this.tableData.forEach((item, i) => {
              总价值 = 总价值 + (item.invbi_plan * item.part_price)
              let obj = {
                id: 报货单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: 主表id,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_plan,
                invbi_amnt: item.invbi_plan,
                invbi_cost_plan: item.invbi_plan * item.part_price,
                invbi_cost_send: item.invbi_plan * item.part_price,
                invbi_cost_amnt: item.invbi_plan * item.part_price,
                invbi_type: '',
                invbi_plan_f: 0,
                invbi_unitconv: 0,
                invbi_price: item.part_price,
                invbi_tag: 0,
                pos_id: shopBaseMsg.terminal, 
                notes: "",
              }
              报货单明细.push(obj)
            })

            let 报货单据主表 = {
              id: 主表id,
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              tstate: 上传状态, //0 未上传 1上传
              bstate: bstate,
              inv_date: parseInt(new Date().getTime()),
              inv_date1: this.searchform.inv_date1,
              inv_date2: this.searchform.inv_needdate,
              inv_whycat,
              inv_whyabs: "",
              inv_who1: 0,
              inv_who2: 0,
              inv_fmcat,
              inv_fm: this.searchform.inv_fm,
              inv_fmbill: "",
              inv_tocat,
              inv_to: user.dept_id,
              inv_tobill: "",
              inv_cost: 总价值, //总价值
              inv_extraid: 0,//外部单位编号
              inv_needdate: this.searchform.inv_needdate,
              inv_region: this.searchform.inv_region,
              inv_bustype: this.searchform.inv_bustype,
              inv_billtype: 0 , //门店单据类型0报货单1铺货单
              inv_round: this.searchform.inv_round,
              inv_billid: this.searchform.inv_billid,
              inv_reason: 0,    
              dept_id: user.dept_id,
              pos_id: shopBaseMsg.terminal,
              notes: "",
            }
            主表.push(报货单据主表)
            明细表.push(...报货单明细)

          } else {//修改
            console.log('seave', '修改', this.editid)
            this.tableData.forEach((item, i) => {
              总价值 = 总价值 + (item.invbi_plan * item.part_price)
              let obj = {
                id: 报货单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: this.editid,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_plan,
                invbi_amnt: item.invbi_plan,
                invbi_cost_plan: item.invbi_plan * item.part_price,
                invbi_cost_send: item.invbi_plan * item.part_price,
                invbi_cost_amnt: item.invbi_plan * item.part_price,
                invbi_type: '',
                invbi_plan_f: 0,
                invbi_unitconv: 0,
                invbi_price: item.part_price,
                invbi_tag: 0,
                pos_id: shopBaseMsg.terminal, 
                notes: "",


              }
              报货单明细.push(obj)
            })

            let 报货单据主表 = {
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              tstate: 上传状态, //0 未上传 1上传
              bstate: bstate,
              inv_date: parseInt(new Date().getTime()),
              inv_date1: this.searchform.inv_date1,
              inv_date2: this.searchform.inv_needdate,
              inv_whycat,
              inv_whyabs: "",
              inv_who1: 0,
              inv_who2: 0,
              inv_fmcat,
              inv_fm: this.searchform.inv_fm,
              inv_fmbill: "",
              inv_tocat,
              inv_to: user.dept_id,
              inv_tobill: "",
              inv_cost: 总价值, //总价值
              inv_extraid: 0,//外部单位编号
              inv_needdate: this.searchform.inv_needdate,
              inv_region: this.searchform.inv_region,
              inv_bustype: this.searchform.inv_bustype,
              inv_billtype: 0 , //门店单据类型0报货单1铺货单
              inv_round: this.searchform.inv_round,
              inv_billid: this.searchform.inv_billid,
              inv_reason: 0,    
              dept_id: user.dept_id,
              pos_id: shopBaseMsg.terminal,
              notes: "",
            }
            主表.forEach(item => {
              if (item.id == this.editid) {
                for (const key in 报货单据主表) {
                  // 更新主表中对应的数据
                  item[key] = 报货单据主表[key]
                }
              }
            })
            //  console.log('报货主表', 报货主表)
            // 删除明细表中对应的数据
            for (let index = 明细表.length - 1; index >= 0; index--) {
              let item = 明细表[index]
              if (item.pid == this.editid) {
                let i = 明细表.findIndex(v => v.id == item.id)
                if (i >= 0) {
                  明细表.splice(i, 1)
                }
              }
            }
            明细表.push(...报货单明细)

          }
          // 储存
          await this.$localforage.setItem('t_inv_bill_items', 明细表)
          await this.$localforage.setItem('t_inv_bill', 主表)

          if (bstate == 0) {

            this.$message.success('保存成功')
            if (this.editid == 0) {
              this.$route.params.rptid = 主表id
              this.editid = 主表id
              this.initEditData(this.editid)
              console.log('保存成功主表id', 主表id)
            }
          } else if (bstate == 1) {
            this.$message.success('确认成功')
            this.searchform.bstate=bstate
            this.searchform.tstate=1
            this.is_look = true
          }

          // setTimeout(() => {
          //   this.goback()
          // }, 200)
        }
      })
    },
    handlePrint(val) {
      // 打印小票
      let ticketdata = {
        has_data: true,
        主表: this.searchform,
        明细arr: JSON.parse(JSON.stringify(this.tableData)),
      }
      this.ticket_data = ticketdata
      // console.log('ticket_data',this.ticket_data)

      this.printLodop()
    },

    printLodop() {
      this.$refs.printneed.printTicket(this.ticket_data)

    },
    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return 'ML' + str + 商户号 + id
    },
    closeBordInput(){
      let that = this
      this.tableData.forEach(el => {
        let ref = 'tablebord' + el.part_id
          that.$refs[ref].close()
      });
    },
    closeOther(id){
      let that = this
      this.tableData.forEach(el => {
        if (el.part_id != id) {
          let ref = 'tablebord' + el.part_id
          that.$refs[ref].close()
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 10px;
}
.noGoods {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid transparent;
}
.noGoods img {
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
.noGoods-text {
  font-size: 18px;
  color: #666;
}
.goods {
  background-color: #ccc;
  border-left: 5px solid #fff;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #409eff;
  color: #fff;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
  color:#333;
}
.keyboard {
  width: 520px;
  padding: 10px;
  background-color: #ccc;
  z-index: 99;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
</style>