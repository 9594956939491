<template>
  <div class="box">
    <div class="form">
      <div class="btn">
         <el-button size="small" type="success" :disabled="is_look" @click="seave(2)">保 存</el-button>
        <el-button :disabled="is_look" type="success" icon="el-icon-edit" size="small" @click="seave(3)">接 收</el-button>
        <el-button size="small" type="danger" @click="handlePrint()">打 印</el-button>
        <el-button size="small" type="danger" @click="goback">返 回</el-button>
      </div>
      <el-form ref="baseform" :model="searchform" label-width="80px" inline size="small"  :disabled="is_look">
        <el-row>
          <el-col :span="6">
            <el-form-item label="编号">
              <el-input v-model="searchform.inv_id" :disabled="true" style="width:210px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单据类型">
              <el-select v-model="searchform.inv_billtype" clearable disabled>
                <el-option label="报货单" :value="0"></el-option>
                <el-option label="铺货单" :value="1"></el-option>
                <el-option label="常规单" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchform.bstate" :disabled="true">
                <el-option label="起草" :value="0"></el-option>
                <el-option label="确认" :value="1"></el-option>
                <el-option label="发出" :value="2"></el-option>
                <el-option label="收到" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="要货区域">
              <el-select v-model="searchform.inv_region" placeholder="请选择" clearable disabled>
                <el-option v-for="item in t_region" :key="item.dict_value" :label="item.dict_label" :value="item.dict_value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="报货时间">
              <el-date-picker v-model="searchform.inv_needdate" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" disabled style="width:210px;" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发送日期">
              <el-date-picker v-model="searchform.inv_date1" type="datetime" format="yyyy-MM-dd HH:mm:ss" disabled placeholder="选择日期时间" style="width:210px;" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="接收日期">
              <el-date-picker v-model="searchform.inv_date2" type="datetime" format="yyyy-MM-dd HH:mm:ss" :disabled="searchform.bstate == 3" placeholder="选择日期时间" style="width:210px;" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="备注" style="width:100%;">
              <el-input v-model="searchform.notes" placeholder="" disabled style="width:500px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <el-table ref="tableRef" :data="tableData" style="width: 100%" border max-height="740" highlight-current-row @row-click="onRowClick">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="part_id" label="编号">
        </el-table-column>
        <el-table-column prop="part_name" label="品名">
        </el-table-column>
        <el-table-column prop="part_price" label="单价" width="70">
        </el-table-column>

      <el-table-column prop="part_usertype" label="商品类型" sortable >
                <template slot-scope="scope">
                  <span v-show="scope.row.part_usertype == item.id" v-for="item in type0Goods" :key="item.id">{{item.name}}</span>
                </template>
              </el-table-column>

        <el-table-column label="报货量" prop="invbi_plan" width="150">
        </el-table-column>
        <el-table-column label="发货量" prop="invbi_send" width="150">
        </el-table-column>
        <el-table-column label="收货量" prop="invbi_amnt" width="170">
          <template slot-scope="scope">
            <div v-if="!is_look">
              <el-input-number v-model="scope.row.invbi_amnt" @focus="showkeyboard" size="mini" controls-position="right"></el-input-number>
            </div>
            <div v-else>{{scope.row.invbi_amnt}}</div>
          </template>
        </el-table-column>
          <el-table-column prop="bal_amnt" label="库存" sortable align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.partid == item.id" v-for="item in t_pos_inv_bal" :key="item.id">{{item.bal_amnt}}</span> 
                </template>
              </el-table-column>

        <el-table-column prop="part_specs" label="规格">
        </el-table-column>

        <el-table-column prop="part_model" label="型号">
        </el-table-column>

        <el-table-column prop="part_description" label="描述">
        </el-table-column>
      </el-table>
    </div>
    <div class="keybord_box">
      <key-board ref="keyboard" @input-num="inputNum"></key-board>
    </div>
    <div class="ticket-box">
      <ticket-template ref="printneed" name="need" :ticketdata="ticket_data" v-show="show_ticketI"></ticket-template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { makeid } from '@/components/makeid.js'
import { getLocal } from '@/utils/localstorage.js'
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
import KeyBoard from '@/components/NumberKeyboard'
import ticketTemplate from '@/components/TicketTemplate/printneed.vue'//报货打印
export default {
   filters: {
    parseTime
  },
  components: {
    KeyBoard,
    ticketTemplate
  },
  //   props: {
  //     defaultdata: {
  //       type: Object,
  //       default: false
  //     },
  //   },
  // watch: {
  //   defaultdata: {
  //     async handler(n) {
  //       console.log(n);
  //       await this.getTypeData()
  //       await this.getGoodsData()
  //       this.initData(n)
  //     },
  //     deep: true
  //   },

  // },
  data() {
    return {
      ticket_data: {}, //小票数据
      show_ticketI: false,
      loading: false,
      editid: null,
      searchform: {
        id: undefined,
        lct: 0,
        isEdit: '1',
        inv_id: '',
        bstate: 0,
        inv_billtype: 0,
        inv_needdate: null,
        inv_date: parseInt(new Date().getTime()),
        inv_date1:parseInt(new Date().getTime()),
        inv_date2:parseInt(new Date().getTime()),
        inv_fmcat: '',
        inv_fm: null,
        inv_tocat: '',
        inv_to: null,
        inv_region: null,
        inv_whyabs: '',
        inv_extraid: null,
        inv_billid: '',
        inv_bustype: 0,
        inv_round: 0,
      },
      tableData: [],
      type0Goods: [],
      goodsData: [],
      nowGoods: {},
      defaultdata: {},
      is_look: false // 是否是查看状态
    }
  },
  mounted() {
    // this.getMainInfo()
  },
  computed: {
    ...mapGetters('app', ['t_wrhus', 't_region']),
    ...mapGetters('user', ['users','t_pos_inv_bal'])
  },

    beforeRouteEnter(to, from, next) {
    next(async vm => {
      let isadd = to.params.rptid
      // vm.setData()
      // await vm.getTypeData()
      // await vm.getGoodsData()
      if (isadd) {
        vm.initData(isadd)
      }else{
        vm.tableData = []
      }
      vm.$store.commit('app/setbaohuoPage', to.fullPath)
       vm.initData()
    })
  },

  methods: {
    async getMainInfo() {
      let id = this.$route.params.inv_bill_id
      let arr = await this.$localforage.getItem('t_inv_bill') || []
      let i = arr.findIndex(v => v.id == id)
      if (~i) {
        this.defaultdata = JSON.parse(JSON.stringify(arr[i]))
      }
    },
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      let type0Goods = typeData.filter(i => !i.isdel)
      this.type0Goods = type0Goods
    },

     async initData() {
      await this.getGoodsData()
      await this.getTypeData()

       this.editid = Number(this.$route.params.rptid) 
       console.log('this.editid',this.editid)
      // this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
      if (this.editid == 0) { // 类型为0是新增
        // this.setData()
        this.searchform.inv_id = this.制作编号()
        // this.is_look = false
      } else {//查看  判断上传状态来确定是否编辑上传的单据不可编辑
        console.log('this.$route.params1', this.$route.params)
        this.initEditData(this.editid)

      }
      // //默认
      // let isdefault = this.t_region.filter(item => item.is_default == 1)//默认
      //  this.searchform.inv_region = isdefault[0].dict_value
    },


    async initEditData(id) {

      this.editid = this.$route.params.rptid
      let 报货单 = await this.$localforage.getItem('t_inv_bill')
      let 报货单明细 = await this.$localforage.getItem('t_inv_bill_items')
      报货单.forEach(item => {
        if (item.id == id) {
          this.报货单主表 = item
        }
      })
      for (const key in this.searchform) {
        this.searchform[key] = this.报货单主表[key]
      }
      //状态
      if (this.searchform.bstate == 2) {
        this.is_look = false
      } else {
        this.is_look = true
      }

      let arr = []
      报货单明细.forEach(item => {
        if (item.pid == id) {
          let goodsItem = this.goodsData.filter(f => f.id == item.partid)
            let obj = {
            partid: goodsItem[0].id,
            part_id: goodsItem[0].part_id,
            part_name: goodsItem[0].part_name,
            part_price: goodsItem[0].part_price,
            part_unit: goodsItem[0].part_unit,
            part_specs: goodsItem[0].part_specs,
            invbi_price: item.invbi_price,
            invbi_plan: item.invbi_plan,
            invbi_send: item.invbi_send,
            invbi_amnt: item.invbi_amnt,
            part_model: goodsItem[0].part_model,
            part_usertype: goodsItem[0].part_usertype,
            partusertype: goodsItem[0].partusertype,
            notes: item.notes,
            库存: 0,
            销量: 0
          }
          arr.push(obj)
        }
      })
     
      this.tableData = arr
    },

    showkeyboard() {
      this.$refs.keyboard.open()
    },
    onRowClick(v) {
      this.nowGoods = v
    },
    // 输入商品数量
    inputNum(n) {
      this.nowGoods.invbi_amnt = n
    },
    // 接收
    receive() {
      let that = this
      let seavetime = parseInt(new Date().getTime()) 
      let user = this.$store.state.user.currUser
      this.$confirm('是否已核对接收数量,确定接收?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 收货
        // if (that.defaultdata.bstate == 3) return this.$message.error('该订单已接收')
        let 总库存 = this.$store.state.user.t_pos_inv_bal
        let 报货明细 = await that.$localforage.getItem('t_inv_bill_items') || []
        this.tableData.forEach(item => {
          let n = 总库存.findIndex(j => j.partid == item.partid)
          let m = 报货明细.findIndex(j => j.id == item.id)
          if (n >= 0) {
            总库存[n].bal_amnt = 总库存[n].bal_amnt + (item.invbi_amnt - 0)
            总库存[n].bal_cost = (总库存[n].bal_amnt - 0) * (item.part_price - 0)
            // 修改vuex中的库存数
            this.$store.commit('user/收货修改结存数量', item);
          }else {//没有记录时增加一条
              let obj = {
                      id: item.partid,
                      tenid: user.tenid,
                      lct: seavetime,
                      uct: seavetime,
                      lby: user.id,
                      uby: user.id,
                      part_name: item.part_name, // 品名
                      tstate: 1,
                      dept_id: user.dept_id,
                      part_id: item.part_id, // 品号
                      partid: item.partid, // 系统品号
                      region_id: this.searchform.inv_region, //入库区域
                      bal_date: seavetime,
                      bal_amnt: item.invbi_amnt, //库存
                      bal_cost: (item.invbi_amnt * item.part_price), //价值
                      sales_volume: 0,//销量
                      part_price: item.part_price,
                      part_model: item.part_model,
                      part_specs: item.part_specs,
                      part_usertype: item.part_usertype,
                      partusertype: item.partusertype
                    }
                    总库存.push(obj)
          }
          // 修改报货详情中的接收数量
          if (m >= 0) {
            报货明细[m].invbi_amnt = item.invbi_amnt
          }
        })
        that.$localforage.setItem('t_inv_bill_items', 报货明细)
        // 修改本地数据库中的库存数
        this.$localforage.setItem('t_pos_inv_bal', 总库存)
        // 修改本地数据库中主表数据
        let 报货主表 = await that.$localforage.getItem('t_inv_bill') || []
        for (let i = 0; i < 报货主表.length; i++) {
          let item = 报货主表[i]
          if (item.id == that.defaultdata.id) {
            item.inv_date2 = (new Date(that.searchform.inv_date2).getTime())  // 修改主表接收日期
            item.bstate = 3 // 主表状态设置为收到(3)
            item.tstate = 1 // 收到上传状态改为待上传
            break
          }
        }
        await that.$localforage.setItem('t_inv_bill', 报货主表)
        this.$message.success('收货成功')
        this.is_look = true
        that.$emit('receive')
      })
        .catch(() => {
          this.$message.info('已取消')
        })
    },

  //保存
    seave(bstate) {
      this.$refs.baseform.validate(async (valid) => {
        if (valid) {
          if (this.tableData.length <= 0) return this.$message.error('没有报货的商品')
          let inv_whycat_arr = await this.$localforage.getItem('t_inv_op') || []
          if (inv_whycat_arr.length == 0) return this.$message.error('缺少基本数据,请跳转设置下载数据')
          let 明细表 = await this.$localforage.getItem('t_inv_bill_items') || [] //明细表
          let 主表 = await this.$localforage.getItem('t_inv_bill') || []//主表
          let  上传状态  //传输状态0-新建, 1-待上传,2-未批复, 3-已批复,4-已确认,4-完成
          if (bstate==2){
              上传状态=0
          }else if (bstate==3){
              上传状态=1
          }
          let inv_whycat, inv_fmcat, inv_tocat
          inv_whycat_arr.forEach(item => {
            if (item.invop_shopop == 1) {
              inv_whycat = item.invop_id
              inv_fmcat = item.invop_sorc
              inv_tocat = item.invop_dest
            }
          })
          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          let seavetime = parseInt(new Date().getTime())
          let shopBaseMsg = getLocal('shopBaseMsg')
          let 报货单明细 = []
          let 报货单明细id = makeid(this.tableData.length)
          let 总价值 = 0
          let 总库存 = this.$store.state.user.t_pos_inv_bal||[] //库存
          if (this.editid == 0) {//新增
            console.log('seave', '新增', 主表id)
            this.tableData.forEach((item, i) => {
              总价值 = 总价值 + (item.invbi_plan * item.part_price)
              let obj = {
                id: 报货单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: 主表id,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_send,
                invbi_amnt: item.invbi_amnt,
                invbi_cost_plan: item.invbi_plan * item.part_price,
                invbi_cost_send: item.invbi_send * item.part_price,
                invbi_cost_amnt: item.invbi_amnt * item.part_price,
                invbi_type: '',
                invbi_plan_f: 0,
                invbi_unitconv: 0,
                invbi_price: item.part_price,
                invbi_tag: 0,
                pos_id: shopBaseMsg.terminal, 
                notes: "",
              }
              报货单明细.push(obj)
            })

            let 报货单据主表 = {
              id: 主表id,
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              tstate: 上传状态, //0 未上传 1上传
              bstate: bstate,
              inv_date: parseInt(new Date().getTime()),
              inv_date1: this.searchform.inv_date1,
              inv_date2: this.searchform.inv_needdate,
              inv_whycat,
              inv_whyabs: "",
              inv_who1: 0,
              inv_who2: 0,
              inv_fmcat,
              inv_fm: this.searchform.inv_fm,
              inv_fmbill: "",
              inv_tocat,
              inv_to: user.dept_id,
              inv_tobill: "",
              inv_cost: 总价值, //总价值
              inv_extraid: 0,//外部单位编号
              inv_needdate: this.searchform.inv_needdate,
              inv_region: this.searchform.inv_region,
              inv_bustype: this.searchform.inv_bustype,
              inv_billtype: 0 , //门店单据类型0报货单1铺货单
              inv_round: this.searchform.inv_round,
              inv_billid: this.searchform.inv_billid,
              inv_reason: 0,    
              dept_id: user.dept_id,
              pos_id: shopBaseMsg.terminal,
              notes: "",
            }
            主表.push(报货单据主表)
            明细表.push(...报货单明细)

          } else {//修改
            console.log('seave', '修改', this.editid)
            this.tableData.forEach((item, i) => {
               if (bstate == 3) {
                let n = 总库存.findIndex(j => j.partid == item.partid)//库存
                if (n >= 0) {
            总库存[n].bal_amnt = 总库存[n].bal_amnt + (item.invbi_amnt - 0)
            总库存[n].bal_cost = (总库存[n].bal_amnt - 0) * (item.part_price - 0)
            // 修改vuex中的库存数
            this.$store.commit('user/收货修改结存数量', item);
          }else {//没有记录时增加一条
              let obj = {
                      id: item.partid,
                      tenid: user.tenid,
                      lct: seavetime,
                      uct: seavetime,
                      lby: user.id,
                      uby: user.id,
                      part_name: item.part_name, // 品名
                      tstate: 1,
                      dept_id: user.dept_id,
                      part_id: item.part_id, // 品号
                      partid: item.partid, // 系统品号
                      region_id: this.searchform.inv_region, //入库区域
                      bal_date: seavetime,
                      bal_amnt: item.invbi_amnt, //库存
                      bal_cost: (item.invbi_amnt * item.part_price), //价值
                      sales_volume: 0,//销量
                      part_price: item.part_price,
                      part_model: item.part_model,
                      part_specs: item.part_specs,
                      part_usertype: item.part_usertype,
                      partusertype: item.partusertype
                    }
                    总库存.push(obj)
                    this.$store.commit('user/获取结存表', 总库存);
                  }
              }

              总价值 = 总价值 + (item.invbi_plan * item.part_price)
              let obj = {
                id: 报货单明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                lby: user.id,
                uby: user.id,
                tstate: 上传状态,
                dept_id: user.dept_id,
                pid: this.editid,
                invbi_seq: i,
                partid: item.partid,
                invbi_plan: item.invbi_plan,
                invbi_send: item.invbi_send,
                invbi_amnt: item.invbi_amnt,
                invbi_cost_plan: item.invbi_plan * item.part_price,
                invbi_cost_send: item.invbi_send * item.part_price,
                invbi_cost_amnt: item.invbi_amnt * item.part_price,
                invbi_type: '',
                invbi_plan_f: 0,
                invbi_unitconv: 0,
                invbi_price: item.part_price,
                invbi_tag: 0,
                pos_id: shopBaseMsg.terminal, 
                notes: "",


              }
              报货单明细.push(obj)
            })

            let 报货单据主表 = {
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              inv_id: this.searchform.inv_id,
              tstate: 上传状态, //0 未上传 1上传
              bstate: bstate,
              inv_date: parseInt(new Date().getTime()),
              inv_date1: this.searchform.inv_date1,
              inv_date2: this.searchform.inv_needdate,
              inv_whycat,
              inv_whyabs: "",
              inv_who1: 0,
              inv_who2: 0,
              inv_fmcat,
              inv_fm: this.searchform.inv_fm,
              inv_fmbill: "",
              inv_tocat,
              inv_to: user.dept_id,
              inv_tobill: "",
              inv_cost: 总价值, //总价值
              inv_extraid: 0,//外部单位编号
              inv_needdate: this.searchform.inv_needdate,
              inv_region: this.searchform.inv_region,
              inv_bustype: this.searchform.inv_bustype,
              inv_billtype: 0 , //门店单据类型0报货单1铺货单
              inv_round: this.searchform.inv_round,
              inv_billid: this.searchform.inv_billid,
              inv_reason: 0,    
              dept_id: user.dept_id,
              pos_id: shopBaseMsg.terminal,
              notes: "",
            }
            主表.forEach(item => {
              if (item.id == this.editid) {
                for (const key in 报货单据主表) {
                  // 更新主表中对应的数据
                  item[key] = 报货单据主表[key]
                }
              }
            })
            //  console.log('报货主表', 报货主表)
            // 删除明细表中对应的数据
            for (let index = 明细表.length - 1; index >= 0; index--) {
              let item = 明细表[index]
              if (item.pid == this.editid) {
                let i = 明细表.findIndex(v => v.id == item.id)
                if (i >= 0) {
                  明细表.splice(i, 1)
                }
              }
            }
            明细表.push(...报货单明细)

          }
          // 储存
          await this.$localforage.setItem('t_inv_bill_items', 明细表)
          await this.$localforage.setItem('t_inv_bill', 主表)

          if (bstate == 2) {

            this.$message.success('保存成功')
            if (this.editid == 0) {
              this.$route.params.rptid = 主表id
              this.editid = 主表id
              this.initEditData(this.editid)
              console.log('保存成功主表id', 主表id)
            }
          } else if (bstate == 3) {
              this.$message.success('确认成功')
              this.searchform.bstate=bstate
              this.searchform.tstate=1
              this.is_look = true
              this.$localforage.setItem('t_pos_inv_bal', 总库存)
          }

          // setTimeout(() => {
          //   this.goback()
          // }, 200)
        }
      })
    },

    goback() {
      this.editid = 0
      this.is_look = false
      this.$refs.baseform.resetFields()
      this.tableData = []
      this.$router.push('/baohuo')
    },
    handlePrint(val) {
      // 打印小票
      let ticketdata = {
        has_data: true,
        主表: this.searchform,
        明细arr: JSON.parse(JSON.stringify(this.tableData)),
      }
      this.ticket_data = ticketdata
      // console.log('ticket_data',this.ticket_data)

      this.printLodop()
    },

    printLodop() {
      this.$refs.printneed.printTicket(this.ticket_data)

    },
      制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return 'ML' + str + 商户号 + id
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  padding: 10px 20px;
  .form {
    .btn {
      margin: 10px 0;
    }
  }
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

