<template>
  <div class="app-container">
    
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
    
        <el-form-item  prop="partid"  >
         <el-select v-model="queryParams.shifts_shop" placeholder="请选择物料" clearable size="small">
            <el-option v-for="item in shopround" :key="item.id" :label="item.dict_label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item  prop="region_id" >
         <el-select v-model="queryParams.region_id" placeholder="请选择区域" clearable size="small">
            <el-option v-for="item in defaultdata.t_region" :key="item.id" :label="item.dict_label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
   
         <el-form-item label="商品分类" prop="part_usertype" >
            <el-cascader
            size="small"
              v-model="queryParams.part_usertype"
              :clearable="true"
              :show-all-levels="false"
              :options="cascaderOptions"
              :props="cascaderProps"
              filterable
              @change="handleChange"
            ></el-cascader>
           </el-form-item>

     
      <el-form-item label="结存日期">
        <el-date-picker
         size="small"
          v-model="dateRange"
          value-format="timestamp"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

   

      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <!-- <el-button type="primary" size="mini" @click="QuerySwitch('汇总')" v-show="!is_show">汇总</el-button>
        <el-button type="success" size="mini" @click="QuerySwitch('明细')" v-show="is_show">明细</el-button> -->
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
    
      <!-- <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar> -->
    </el-row>

   <div class="table-box">
      <el-table :data="tableData"  border highlight-current-row :summary-method="getSummaries" show-summary height="700" style="width: 100%; " >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        
        <el-table-column prop="part_id" label="商品编码">
        </el-table-column>
        <el-table-column prop="part_name" label="商品名称">
        </el-table-column>
          <el-table-column prop="part_usertype" label="商品类型">
        </el-table-column>
        <el-table-column prop="part_specs" label="规格">
        </el-table-column>
         <el-table-column prop="part_model" label="型号">
        </el-table-column>
        <el-table-column prop="invbi_price" label="单价" width="80px">
        </el-table-column>
        <el-table-column prop="part_unit" label="单位" width="60px">
        </el-table-column>
        <el-table-column prop="ini_amnt" label="期初数量" width="90px">
        </el-table-column>
        <el-table-column prop="ini_cost" label="期初金额" width="90px">
        </el-table-column>
         <el-table-column prop="ini_amnt" label="入库数量" width="90px">
        </el-table-column>
        <el-table-column prop="ini_cost" label="入库金额" width="90px">
        </el-table-column>
         <el-table-column prop="ini_amnt" label="出库数量" width="90px">
        </el-table-column>
        <el-table-column prop="ini_cost" label="出库金额" width="90px">
        </el-table-column>
         <el-table-column prop="ini_amnt" label="结存数量" width="90px">
        </el-table-column>
        <el-table-column prop="ini_cost" label="结存金额" width="90px">
        </el-table-column>
      
      </el-table>
      <pagination v-show="page_data.total > 0" :total="page_data.total" :page.sync="page_data.currentPage" :limit.sync="page_data.pagesize" @pagination="getList" />
    </div>

    <!-- <pagination
      v-show="total>0"
      :total="total"
      :page.sync="querypaging.pageNum"
      :limit.sync="querypaging.pageSize"
      @pagination="getList"
    /> -->

    
  </div>
</template>

<script>
import { parseTime, pagePagination } from '@/utils/index.js'
export default {
   filters: {
    parseTime
  },
    props: {
    defaultdata: {
      type: Object,
      default() {
        return {}
      }
    },
  },
 
  name: "config",
 
  data() {
    return { 
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28,
      },
      is_show: true,
      //时间选择器
      pickerOptions: this.getPickerOptionsold(),
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      all_table_data: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
       //门店班组
      shopround: [],
      // 查询参数
      queryParams: {
        offset:0,
        limit: 0,
        partid:'',
        region_id: '',
        part_usertype: '',
      },
          //分页步长
       querypaging: {
        pageNum:0,
        pageSize: 10,
      },
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 10
      },
      // 表单参数
      form: {},
      t_part: [],
      //类别级联菜单
      cascaderOptions: [],
      cascaderProps: {
        expandTrigger: "hover",
        label: "name",
        checkStrictly: true,
        emitPath: true,
        value: "id",
      },
    };
  },
  created() {
 // this.getList();
  console.log(' this.defaultdata',  this.defaultdata)
 this.getpartusertype();
    this.shopround = this.defaultdata.dictdata.filter(item => item.dict_type == "bus_shop_prod")//门店班组   
  },
  methods: {

    // 级联选择器选择
    handleChange(value) {
      if (value.length == 0) {
        this.queryParams.part_usertype = 0;
      } else {
        this.queryParams.part_usertype = value[value.length - 1];
      }
    },
    //物料类型级联菜单
    async getpartusertype() {
        let t_part_class = await this.$localforage.getItem('t_part_class') || []  
         let dataList = this.parse2Tree(t_part_class);
         this.cascaderOptions = JSON.parse(JSON.stringify(dataList));
    },

    QuerySwitch(val){
      if (val=='汇总'){
        this.is_show = true
        this.handleQuery()}
      if (val=='明细'){
        this.is_show = false
      this.handleQuery()
      }
    },

  /** 查询参数列表 */
   async getList() {
    let tableData = []
    let t_pos_inv_bal = await this.$localforage.getItem('t_pos_inv_bal') || []
    let t_pos_inv_bill_items = await this.$localforage.getItem('t_pos_inv_bill_items') || []
    let t_part = await this.$localforage.getItem('t_part') || []
   //需要根据单据类型过滤数据dateRange
    console.log('dateRange', this.dateRange,t_pos_inv_bal)
       let subTime ,supTime
        subTime = this.dateRange[0];
        supTime = this.dateRange[1];
       if (this.dateRange.length==0){
          subTime=0
          supTime=4133865600000 //2100-12-31
       }
      let t_pos_inv_bal_arr = t_pos_inv_bal.filter(item =>
           Number(item.partid).toString().includes(this.queryParams.partid) 
        && Number(item.region_id).toString().includes(this.queryParams.region_id)
        && Number(item.part_usertype).toString().includes(this.queryParams.part_usertype)
        && subTime <= item.bal_date && item.bal_date <= supTime
      )
    
        tableData=t_pos_inv_bal_arr
      tableData.forEach((item,index)  => {
          let i = t_part.findIndex(v => v.id == item.partid)
       if (i >= 0) {
          tableData[index].part_id = t_part[i].part_id
          tableData[index].part_name = t_part[i].part_name
          tableData[index].part_specs = t_part[i].part_specs
          tableData[index].part_model = t_part[i].part_model
          tableData[index].part_unit = t_part[i].part_unit
          tableData[index].part_usertype = t_part[i].part_usertype
       }
          });

      
  this.tableData = pagePagination(tableData, this.page_data.pagesize, this.page_data.currentPage)
  this.page_data.total = tableData.length


       //js多字段排序
      let that=this
      tableData.sort(function(a, b) {
        return that.sortByProps(a, b, {
            bal_date: 'asc',
            part_id: 'asc',
        });
      });
      // this.tableData=tableData

     

    //      console.log('pos_sale_detail_arr', tableData)
    //  //升序排列
    //   tableData.sort(function(a,b){
		// 	return a.part_id - b.part_id
		//   })
    //   this.tableData=tableData
      
      // this.all_table_data = tableData
      // this.makeData()
    },
   
  
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.querypaging.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
  
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.configId)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },

    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有参数数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportConfig(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
    },
      //合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 1) {
        sums[index] = '合计:';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value)) 
            && column.property != 'part_id' 
            && column.property != 'part_name'
            && column.property != 'part_specs'
            && column.property != 'part_model'
            && column.property != 'part_unit'
            && column.property != 'part_usertype'
             && column.property != 'region_id'
             && column.property != 'bal_date'
         ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        sums[index] = '';
      }
    });

    return sums;
  },
 
 
  }
};
</script>
