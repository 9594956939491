<template>
  <div class="app-container">
    
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
    
        <el-form-item  prop="shifts_shop"  >
         <el-select v-model="queryParams.shifts_shop" placeholder="请选择班次" clearable size="small">
            <el-option v-for="item in shopround" :key="item.id" :label="item.dict_label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item  prop="uby" >
         <el-select v-model="queryParams.uby" placeholder="请选择操作人" clearable size="small">
            <el-option v-for="item in defaultdata.users" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
     <el-form-item  prop="inv_whycat" >
         <el-select v-model="queryParams.inv_whycat" placeholder="请选择类型" clearable size="small">
            <el-option v-for="item in defaultdata.t_pos_inv_op" :key="item.id" :label="item.invop_namec" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="确定日期">
        <el-date-picker
         size="small"
          v-model="dateRange"
          value-format="timestamp"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

   

      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button type="primary" size="mini" @click="QuerySwitch('汇总')" v-show="!is_show">汇总</el-button>
        <el-button type="success" size="mini" @click="QuerySwitch('明细')" v-show="is_show">明细</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
    
      <!-- <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar> -->
    </el-row>

   <div class="table-box">
      <el-table :data="tableData"  border highlight-current-row :summary-method="getSummaries" show-summary height="700" style="width: 100%; ">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
         <el-table-column v-if="is_show "  prop="inv_id" label="单号" >
        </el-table-column>
        <el-table-column prop="part_id" label="商品编码">
        </el-table-column>
        <el-table-column prop="part_name" label="商品名称">
        </el-table-column>
          <el-table-column prop="part_usertype" label="商品类型">
        </el-table-column>
        <el-table-column prop="part_specs" label="规格">
        </el-table-column>
         <el-table-column prop="part_model" label="型号">
        </el-table-column>
        <el-table-column prop="invbi_price" label="单价" width="80px">
        </el-table-column>
        <el-table-column prop="part_unit" label="单位" width="60px">
        </el-table-column>
        <el-table-column prop="invbi_plan" label="数量" width="90px">
        </el-table-column>
        <el-table-column prop="invbi_cost_plan" label="金额(小计)">
        </el-table-column>
      </el-table>
    </div>

    <!-- <pagination
      v-show="total>0"
      :total="total"
      :page.sync="querypaging.pageNum"
      :limit.sync="querypaging.pageSize"
      @pagination="getList"
    /> -->

    
  </div>
</template>

<script>

export default {
    props: {
    defaultdata: {
      type: Object,
      default() {
        return {}
      }
    },
  },
 
  name: "config",
 
  data() {
    return { 
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28,
      },
      is_show: true,
      //时间选择器
      pickerOptions: this.getPickerOptionsold(),
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      all_table_data: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
       //门店班组
      shopround: [],
      // 查询参数
      queryParams: {
        offset:0,
        limit: 0,
        shifts_shop:'',
        uby: '',
        inv_whycat: '',
      },
          //分页步长
       querypaging: {
        pageNum:0,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      t_part: [],
    };
  },
  created() {
 // this.getList();
    this.shopround = this.defaultdata.dictdata.filter(item => item.dict_type == "bus_shop_prod")//门店班组   
  },
  methods: {

    QuerySwitch(val){
      if (val=='汇总'){
        this.is_show = true
        this.handleQuery()}
      if (val=='明细'){
        this.is_show = false
      this.handleQuery()
      }
    },

  /** 查询参数列表 */
   async getList() {
    let tableData = []
    let t_pos_inv_bill = await this.$localforage.getItem('t_pos_inv_bill') || []
    let t_pos_inv_bill_items = await this.$localforage.getItem('t_pos_inv_bill_items') || []
    let t_part = await this.$localforage.getItem('t_part') || []
   //需要根据单据类型过滤数据dateRange
    console.log('dateRange', this.dateRange,t_pos_inv_bill)
       let subTime ,supTime
        subTime = this.dateRange[0];
        supTime = this.dateRange[1];
       if (this.dateRange.length==0){
          subTime=0
          supTime=4133865600000 //2100-12-31
       }
      let pos_sale_arr = t_pos_inv_bill.filter(item =>
           Number(item.inv_prod).toString().includes(this.queryParams.shifts_shop) 
        && Number(item.uby).toString().includes(this.queryParams.uby)
        && Number(item.inv_whycat).toString().includes(this.queryParams.inv_whycat)
        && subTime <= item.inv_date1 && item.inv_date1 <= supTime
      )
   
      let arr = []
       let pos_sale_detail_arr
        console.log('pos_sale_arr', pos_sale_arr)
      pos_sale_arr.forEach((item,i) => {
      pos_sale_detail_arr = t_pos_inv_bill_items.filter(i => Number(i.pid) == item.id)
      // console.log('t_pos_inv_bill_items', pos_sale_detail_arr)
      for (let i = 0; i < pos_sale_detail_arr.length; i++) {   
        Object.assign(pos_sale_detail_arr[i], {inv_id:item.inv_id}) 
      arr.push(pos_sale_detail_arr[i])
    }
     })
    if (!this.is_show){
      arr.forEach(el => {
          // console.log('el.part_id', el)
        let i = tableData.findIndex(v => v.partid === el.partid)
        if (i >= 0) {
          tableData[i].invbi_plan = tableData[i].invbi_plan + el.invbi_plan
          tableData[i].invbi_cost_plan = tableData[i].invbi_cost_plan + el.invbi_cost_plan
        } else {
         
          let j = t_part.findIndex(v => v.id === el.partid)
          let obj = {
             part_name: j >= 0 ? t_part[j].part_name : '',
             part_id: t_part[j].part_id,
             partid: t_part[j].id,
             part_specs: t_part[j].part_specs,
             part_model: t_part[j].part_model,
             part_unit: t_part[j].part_unit,
             part_usertype: t_part[j].part_usertype,
             invbi_price: el.invbi_price,
             invbi_plan: el.invbi_plan,
             invbi_cost_plan: el.invbi_cost_plan,
          }
          tableData.push(obj)
        }
      });

       } else{
        tableData=arr
         console.log('mx', tableData)
      tableData.forEach((item,index)  => {
          let i = t_part.findIndex(v => v.id == item.partid)
       if (i >= 0) {
          tableData[index].part_id = t_part[i].part_id
          tableData[index].part_name = t_part[i].part_name
          tableData[index].part_specs = t_part[i].part_specs
          tableData[index].part_model = t_part[i].part_model
          tableData[index].part_unit = t_part[i].part_unit
          tableData[index].part_usertype = t_part[i].part_usertype
       }
          });

      }



       //js多字段排序
      let that=this
      tableData.sort(function(a, b) {
        return that.sortByProps(a, b, {
            inv_id: 'asc',
            part_id: 'asc',
        });
      });
      this.tableData=tableData

      
    //      console.log('pos_sale_detail_arr', tableData)
    //  //升序排列
    //   tableData.sort(function(a,b){
		// 	return a.part_id - b.part_id
		//   })
    //   this.tableData=tableData
      
      // this.all_table_data = tableData
      // this.makeData()
    },
   
  
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.querypaging.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
  
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.configId)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },

    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有参数数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportConfig(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
    },
      //合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 1) {
        sums[index] = '合计:';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value)) 
            && column.property != 'part_id' 
            && column.property != 'part_name'
            && column.property != 'part_specs'
            && column.property != 'part_model'
            && column.property != 'part_unit'
            && column.property != 'part_usertype'
             && column.property != 'invbi_price'
         ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        sums[index] = '';
      }
    });

    return sums;
  },
 
 
  }
};
</script>
