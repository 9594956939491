<template>
  <div class="box">
      
    <el-table v-loading="loading" :data="tableData" border height="600"   @selection-change="handleSelectionChange"
     :summary-method="getSummaries" show-summary style="width: 100%; ">
     <el-table-column type="selection" width="55" align="center" />

      <el-table-column label="审核" prop="top_audit_flag" align="center">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.top_audit_flag == 0" type="danger" effect="dark">未审核</el-tag>
          <el-tag v-show="scope.row.top_audit_flag == 1" type="success" effect="dark">已审核</el-tag>
        </template>
      </el-table-column>

         <!-- <el-table-column label="审核" prop="top_audit_flag"  align="center"  sortable>
                <template slot-scope="scope">
                  <el-checkbox  v-model="scope.row.top_audit_flag" :true-label="1" :false-label="0"  disabled></el-checkbox>
                </template>
              </el-table-column> -->

  <el-table-column label="生效" prop="bstate" align="center">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.bstate == 0" type="danger" effect="dark">否</el-tag>
          <el-tag v-show="scope.row.bstate == 1" type="success" effect="dark">是</el-tag>
        </template>
      </el-table-column>

          <!-- <el-table-column label="生效" prop="bstate"  align="center"  sortable>
                <template slot-scope="scope">
                  <el-checkbox  v-model="scope.row.bstate" :true-label="1" :false-label="0"  disabled></el-checkbox>
                </template>
              </el-table-column> -->


          <el-table-column label="充值来源" prop="top_source" :show-overflow-tooltip="true" align="center">
        <template slot-scope="scope">
          <div>
            <span v-show="scope.row.top_source == 0">公司</span>
            <span v-show="scope.row.top_source == 1">门店</span>
            <span v-show="scope.row.top_source == 2">微信</span>
            <span v-show="scope.row.top_source == 3">支付宝</span>
            <span v-show="scope.row.top_source == 4">银联</span>
            <span v-show="scope.row.top_source == 9">其他</span>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="卡号" prop="cardid" :show-overflow-tooltip="true" align="center" /> -->
      <el-table-column label="卡号" prop="card_id_out" :show-overflow-tooltip="true" align="center" />
      <!-- <el-table-column label="充值时间" prop="top_date" width="100" /> -->
      <el-table-column label="充值时间" prop="top_date" width="180" :resizable="false">
          <template slot-scope="scope">{{ scope.row.top_date===null? '' :scope.row.top_date | parseTime }}</template>
        </el-table-column>

 <el-table-column label="充值类型" align="center">
        <template slot-scope="scope">
          <div>
            <span v-show="scope.row.top_type == 0">预存</span>
            <span v-show="scope.row.top_type == 1">充值</span>
            <span v-show="scope.row.top_type == 2">红冲</span>
            <span v-show="scope.row.top_type == 3">合并</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="充值金额" sortable align="center" prop="top_money" :show-overflow-tooltip="true"/>
      <el-table-column label="赠送金额" sortable align="center" prop="grant_money" :show-overflow-tooltip="true"/>
      <el-table-column label="赠送积分" sortable align="center" prop="grant_score" :show-overflow-tooltip="true"/>
      <el-table-column label="操作人" prop="name" align="center" />
      <el-table-column label="流水号" prop="top_serialid" align="center" />

    </el-table>

     <!-- 添加或修改参数配置对话框 -->
    <!-- <el-dialog :title="isEdit =='2'?'编辑会员充值信息':'添加会员充值信息'" :visible.sync="open" :close-on-click-modal="false" width="1000px" append-to-body>
      <member-top-form :defaultdata="form" ref="topFromRef" @getFormData="seaveBaseInfo"></member-top-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog> -->

  </div>
</template>

<script>
 import Vue from 'vue'
 import { parseTime } from '@/utils'
//  import memberTopForm from "@/views/business/member/memberprofile/components/MemberTopForm";
//  import {addcardtop,updatecardtopaudit} from "@/api/business/member/cardtop";
//  import { apicardtopitems} from "@/api/business/member/memberprofile";
// import { apicardtopitems} from "@/api/business/member/memberprofile";
export default {
  // components: { memberTopForm },
  name: "membertop",
filters: {
    parseTime
  },
  props: {
    tabledata: {
      type:[Array,Object,String] ,
      default() {
        return [];
      },
    }
  },
  watch: {
    tabledata: {
      handler(n) {
        // console.log('n', n)
         this.tableData = n.gsData
          this.cardid =n.cardid //卡id


      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      isEdit:'0',
        // 是否显示弹出层
      open: false,
      cardid:'',

      loading: false,
      // 表格数据
      tableData: [],
      multipleSelection: [],
       // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
       // 弹出层标题
      title: "",
       // 表单参数
      form: {},
        // 查询参数
      queryParams: {
        offset: 0,
        limit: 0,
        cardid: '',
        top_source: '',
      },
          //分页步长
       querypaging: {
        pageNum:0,
        pageSize: 10,
      },
      //审核参数
      auditParams:{
        auditIds:0,
        audit:0
      }
    }
  },

   methods: {

// 表单重置
    reset() {
      this.form = {
              id: null,
              isEdit:'0',
              dept_id: 0,
              pos_id: '',
              computer_no: '',
              top_date:null,
              cardid: '',
              card_id_out: '',
              top_type: 0,
              top_source: 0,
              top_money: 0,
              grant_money: 0,
              grant_score: 0,
              top_remoney: 0,
              recharge_flag: 0,
              associate_id: 0,
              bstate: 0,
              salesid: 0,
              top_audit_flag: 0,
              top_audit_date:null,
              top_audit_staff:null,
              top_serialid:null,
              notes:'',

      };
      this.resetForm("form");
    },
       // 取消按钮
    cancel() {
      this.open = false;
      // this.reset();
    },

  // 多选框选中数据
    handleSelectionChange(selection) {
     console.log('selection', selection)
      this.ids = selection.map(item => item.id)
       console.log('ids', this.ids)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },

  /** 查询库存操作类型列表 */
    getList() {
      this.loading = true;
      this.queryParams.cardid=this.cardid
      this.queryParams.top_source='0'
      apicardtopitems(this.queryParams).then(
          response => {
          this.tableData = response.data.rows;
          this.loading = false;
      });
    },

     /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.isEdit = '1'
      this.form.isEdit='1'
      this.form.cardid=this.cardid
      if (this.cardid==undefined||this.cardid==''||this.cardid==null){
        this.msgWarning("没有卡信息不能充值");
        return
      }
      this.open = true;
      this.title = "添加充值";
    },


    /** 审核按钮操作 */
      handleUpdate(row) {
      const Ids = row.id || this.ids;
        this.auditParams.auditIds=Ids,
        this.auditParams.audit=1,
      updatecardtopaudit(this.auditParams).then(response => {
              if (response.statusCode === 200) {
                this.msgSuccess("审核成功");
                this.open = false;
                this.getList();
              }
            });
    },
  /** 提交按钮 */
   seaveBaseInfo(val) {
    // seaveBaseInfo: function() {
      // this.$refs["form"].validate(valid => {
      //   if (valid) {
          if (this.form.isEdit=='2') {
          console.log('seaveBaseInfo',val);
            Addstrategyscore(val).then(response => {
              if (response.statusCode === 200) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              }
            });
          } else if (this.form.isEdit=='1') {
            this.$delete(this.form,'isEdit')//删除对象中某一项
            this.$delete(this.form,'card_id_out')//删除对象中某一项
            this.form.bstate=1
            this.form.top_date=parseInt(new Date().getTime())
            console.log('seaveBaseInfo',val);
            addcardtop(val).then(response => {

              if (response.statusCode === 200) {
                this.msgSuccess("充值成功");
                this.open = false;
                this.getList();
              }
            });
          }
      //   }
      // });
    },

   /** 提交按钮 $refs.baseform*/
    submitForm: function () {
        console.log('submitForm', this.$refs.topFromRef)
      this.$refs.topFromRef.seaveData()
    },

       /** 删除按钮操作 */
    handleDelete(row) {
      const Ids = row.id || this.ids;

      console.log('Ids', Ids)
        // this.delectAll()
      this.$confirm('是否确认删除编号为"' + Ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return Delstrategytop(Ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有仓库数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportPost(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
    },
//合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计:';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += ' ';
      } else {
        sums[index] = 'N/A';
      }
    });

    return sums;
  },
    }
}


</script>

<style lang="less" scoped>
.box {
  border: 1px solid #ccc;
}
</style>
