<template>
  <div class="border-box">
    <div class="title">
      <div class="nav-item" v-for="item in list" :key="item.id" @click="onTagcheck(item.id)">
        <div class="tip" v-show="now_tag === item.id"><i class="el-icon-success"></i></div>
        <!-- <div class="image"><img :src="item.img" alt=""></div> -->
         <i :class="item.img"></i>
        <div class="text">{{item.name}}</div>
      </div>
    </div>
    
    <div class="content">
      <div class="left">
        <div class="title">库存总览</div>
         <!-- <el-button @click="scrollBottom">滚动到底部位置</el-button> -->
        <el-table ref="tableRef" :data="table_data" style="width: 100%" border max-height="850" highlight-current-row>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="part_id" label="品号"> </el-table-column>
          <el-table-column prop="part_name" label="品名" show-overflow-tooltip></el-table-column>
          <el-table-column prop="part_price" label="单价" width="70"></el-table-column>
          <el-table-column prop="part_usertype" label="商品类型" show-overflow-tooltip>
          </el-table-column>

          <el-table-column prop="bal_amnt" label="库存">
             <template slot-scope="scope">
              <div :style="{color:scope.row.bal_amnt<=10?'red':'#363636'}">{{scope.row.bal_amnt}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="sales_volume" label="销量"></el-table-column>
          <el-table-column prop="part_specs" label="规格"> </el-table-column>
          <el-table-column prop="part_model" label="型号"></el-table-column>
          <el-table-column prop="notes" label="备注"></el-table-column>
        </el-table>

        <el-pagination @current-change="handleCurrentChange" :current-page="page_data.currentPage" 
        :page-size="page_data.pagesize" layout="total, prev, pager, next" :total="page_data.total" background
          hide-on-single-page>
        </el-pagination>
      </div>
      <div class="right">
        <goods-menu @chooseGoods="addOrderList" @chooseType="nowType"  @chooseGoodsCurrent="addOrderpage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GoodsMenu from '@/views/收银/GoodsMenu'
import { parseTime, pagePagination } from '@/utils/index.js'
export default {
  components: {
    GoodsMenu
  },
  computed: {
     ...mapGetters('app', ['t_pos_inv_op']),
    商品类型() {
      return this.nowGoodsType.name
    }
  },
  data() {
    return {
      now_tag:null,
      list: [
        {
          id: 'shopshift',
          name: '交班管理',
          img: "entypo-box"
        },
        {
          id: 'shopcheck',
          name: '门店盘点',
          img: 'entypo-check'
          
        },
        {
          id: 'settlement',
          name: '门店日结',
          img: 'entypo-flashlight'
        },
        // {
        //   id: 'bankmoney',
        //   name: '货款管理',
        //   img: '/assets/00005.png'
        // },
        // {
        //   id: 'settleold',
        //   name: '历史日结',
        //   img: '/assets/00006.png'
        // },
      ],
      all_table_data: [],
      table_data: [],
      goodsData: [],
      type0Goods: [],
      nowGoodsType: [],
      inv_bal: [],

      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('app/setlingshouPage', to.fullPath)
    })
  },
  async created() {
    await this.getGoodsData()
    await this.getTypeData()
    await this.getStock()
    this.nowType(this.type0Goods[0])
  },
  methods: {
    // 获取商品信息
    async getGoodsData() {
      this.goodsData = await this.$localforage.getItem('t_part') || []
      if (this.goodsData.length <= 0) return this.$message.error('关键信息丢失,请前往设置同步信息')
    },
    // 获取分类信息
    async getTypeData() {
      let typeData = await this.$localforage.getItem('t_part_class') || []
      if (typeData.length <= 0) return this.$message.error('关键信息丢失,请前往设置同步信息')
      this.type0Goods = typeData.filter(i => !i.isdel)
      if (this.type0Goods.length >= 0) {
        this.nowGoodsType = this.type0Goods[0]
      }
      console.log(1111,this.type0Goods[0])
    },
    // 获取库存信息
    async getStock() {
      let inv_bal = await this.$localforage.getItem('t_pos_inv_bal') || []
      // if (inv_bal.length <= 0) return this.$message.error('库存信息丢失,请前往设置重置库存信息')
      this.inv_bal = inv_bal
    },
    addOrderList(v) {
      console.log('addOrderList',this.inv_bal,v)
    let ishave = this.inv_bal.findIndex(i => i.id == v.id)
       console.log('ishave',ishave) //判断下标值ishave
      if (ishave >= 0) {
        this.$refs.tableRef.setCurrentRow(this.inv_bal[ishave])
      } 
      let currentPage=Math.ceil((ishave+1)/this.page_data.pagesize)
       console.log('currentPage',currentPage,this.page_data.currentPage) 
      if (currentPage!=this.page_data.currentPage){
        this.handleCurrentChange(currentPage)
      }
//滚动条
      // let currentPage=Math.ceil((ishave+1)/this.page_data.pagesize)
      let sumpagesize=this.page_data.pagesize * this.page_data.currentPage 
      let 差异=this.page_data.pagesize- (sumpagesize - (ishave+1))
       if (差异>13){
         //滚动到底部位置
        this.scrollBottom()
      } else {
        this.scrollTop()
      }
    },
    //点击的商品分页
     addOrderpage(currentPage) {
      console.log('addOrderpage',currentPage)
      this.handleCurrentChange(currentPage)
     },
    // 根据类型筛选商品
    nowType(v) {
      this.nowGoodsType = v
      if (!v) return
      let id = v.id
      let arr = []
      this.inv_bal.forEach(item => {
        let typearr = item.partusertype.split(',')
        let 类型 = this.type0Goods.find(i => i.id == item.part_usertype)
        if (类型){item.part_usertype=类型.name}
        if (typearr.indexOf(id + '') >= 0 && !item.isdel) {
          arr.push(item)
        }
      })
      this.all_table_data = arr //this.inv_bal
      this.page_data.total = arr.length //this.inv_bal.length
      this.makeData()
    },
    // 分页
    handleCurrentChange(n) {
      this.page_data.currentPage = n
      this.makeData()
    },
    makeData(){
      this.table_data = pagePagination(this.all_table_data, this.page_data.pagesize, this.page_data.currentPage)
    },
    // 标签切换
    onTagcheck(id){
      
      //   //获得操作类型数据当前行数据
      // this.t_pos_inv_op.forEach(item => {
      //     if (item.invop_id == id ) { 
      //       this.now_tag = item.id
      //     } 
      //   })
      // if (!this.now_tag) return this.$message.error('操作类型错误')
      // // this.now_tag = id
      // this.$router.push('/dianpulist/' + this.now_tag ) //区分单据类型  
        this.$router.push('/'+id ) //区分单据类型
    },
    //滚动到顶部位置
   scrollTop () {
      this.$refs.tableRef.bodyWrapper.scrollTop = 0;
       },
    //滚动到底部位置
   scrollBottom () {
      this.$refs.tableRef.bodyWrapper.scrollTop = this.$refs.tableRef.bodyWrapper.scrollHeight;
    },
  }
}
</script>

<style lang="less" scoped>
.border-box {
  padding: 10px 15px;
  width: 100%;
  .title {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #cccccc;
    .nav-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
      .text {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
      .tip {
        position: absolute;
        top: 0px;
        right: 0px;
        i {
          font-size: 22px;
          color: #51c332;
        }
      }
    }
  }
  .content {
    display: flex;
    .left {
      flex: 1;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
    }
    .right {
      flex: 1;
      background-color: #ccc;
      border-left: 5px solid #fff;
    }
  }
}

/deep/.el-table__body tr.current-row > td {
  background-color: #82c0ff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #82c0ff;
}
</style>