<template>
  <header class="header">
    <h5 class="logo">
      Cloud-Pos
    </h5>
    <h5 class="logo">
      公司:{{companyInfo.公司名称}}
    </h5>
    <h5 class="logo">
      店名:{{companyInfo.名称}}
    </h5>
    <h5 class="logo">
      登录名: {{user.name}}
    </h5>
    <h5 class="logo">
      班次:{{班次}}
    </h5>
    <h5 class="logo">
      商户号:{{shopBaseMsg.terminal}}
    </h5>
    <h5 class="logo" style="width:262px;">
      {{time}}
    </h5>
    <div class="inform">
      <el-badge :value="''" class="ivu-badge">
        <router-link to="/dingdan">
          <i class="el-icon-message" style="color:#2d8cf0;"></i>
        </router-link>
      </el-badge>
      <el-badge :value="12" class="ivu-badge">
        <router-link to="/messageList">
          <i class="el-icon-shopping-cart-2" style="color:#2d8cf0;"></i>
        </router-link>
      </el-badge>
        <el-badge :value="12" class="ivu-badge">
        <router-link to="/messageList">
          <i class="iconfont icon-shezhi" style="color:#2d8cf0;"></i>
        </router-link>
      </el-badge>
      <el-button class="change-account" @click.native="logoutAccount" type="primary" size="mini">退出</el-button>
    </div>
    <el-dialog title="登录" :visible.sync="login_module_show" width="500px" top="50px" :show-close="false" :close-on-click-modal="false" @open="onOpen">
      <div>
        <el-form ref="login" :model="from" :rules="checkUser" label-width="80px">
          <el-form-item label="公司ID" prop="coding">
            <el-input v-model="from.coding"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input v-model="from.username"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input v-model="from.password" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="login" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </header>
</template>

<script>

import { getLocal, setLocal, removeLocal } from '@/utils/localstorage'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('app', ['login_module_show'])
  },
  data() {
    return {
      companyInfo: null,
      班次: '',
      user: '',
      shopBaseMsg: null,
      time: '',
      clock: null,
      dialogVisible: true,
      from: {
        coding: '',
        password: '',
        username: ''
      },
      checkUser: {
        coding: { required: true, message: '公司ID不能为空', trigger: 'blur' },
        password: { required: true, message: '登录密码不能为空', trigger: 'blur' },
        username: { required: true, message: '用户名不能为空', trigger: 'blur' },
      },
      loading: false
    }
  },
  created() {
    this.clock = setInterval(function () {
      that.show()
    }, 1000)
    let companyInfo = getLocal('companyInfo')
    this.companyInfo = companyInfo
    this.班次 = getLocal('classesType').dict_label||''
    this.shopBaseMsg = getLocal('shopBaseMsg')
    this.user = this.$store.state.user.currUser
    let that = this
    this.$store.dispatch('user/获取结存表')
    this.$store.dispatch('user/getUsers')

    this.$store.dispatch('app/getSys_dept')
    this.$store.dispatch('app/getT_wrhus')
    this.$store.dispatch('app/getYhqy')
    this.$store.dispatch('app/getPosinvop')//出入库单据类型
    this.$store.dispatch('app/getinvop')//出入库单据类型
    this.$store.dispatch('app/getDictdata')//公共数据

    // console.log('vHeader')
  },
  methods: {
    // 重置消息通知
    resetMessageNumber() {
      this.$store.commit('user/resetMessageNumber');
    },
    // 退出登录
    logoutAccount() {
      this.$confirm('确认是否退出当前账号？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setLocal('logintime', 0)
        setLocal('currUser', '')
        this.$router.push('/staff/login')
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      }).catch(() => { });
    },
    show() {
      var now = new Date();

      var year = now.getFullYear(); //得到年份

      var month = now.getMonth() + 1;//得到月份

      var date = now.getDate();//得到日期

      // var day = now.getDay();//得到周几

      var hour = now.getHours();//得到小时数

      var minute = now.getMinutes();//得到分钟数

      var second = now.getSeconds();//得到秒数

      month = month < 10 ? '0' + month : month
      date = date < 10 ? '0' + date : date
      hour = hour < 10 ? '0' + hour : hour
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      this.time = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
    },
    onOpen() {
      let curr_user = getLocal('currUser')
      this.from.coding = curr_user.tenid + ' '
      this.from.username = curr_user.user
    },
    // 取消
    cancel() {
      this.$store.commit('app/changeLoginModel', false)
    },
    // 登录
    login() {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let res = await this.$store.dispatch('user/Login', this.from)
          this.loading = false
          if (res.statusCode == 200) {
            if (res.data.token) {
              this.$refs['login'].resetFields();
              this.$message.success('登录成功')
              this.$store.commit('app/changeLoginModel', false)
            }
          }else{
            this.$message.error(res.message)
          }
        } else {
          this.$message.error('表单验证失败！');
        }
      });
    }
  },
  destroyed() {
    clearInterval(this.clock)
  }
}

</script>

<style>
.header {
  min-width: 400px;
  height: 50px;
  background-color: #1c2438;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 2em;
}
.logo {
  color: #2d8cf0;
  font-size: 20px;
}
.logo {
  padding: 0 1em;
}

.inform {
  padding-right: 1em;
}
.inform > .el-badge {
  margin-right: 1em;
}
.header .ivu-badge-count {
  box-shadow: none;
}
.change-account {
  position: relative;
  top: -5px;
}
</style>

