<template>
  <div style="height:calc(100% - 50px );">
    <v-header></v-header>
    <leftNav></leftNav>
    <div class="main">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/vHeader'
import leftNav from '@/components/leftNav.vue'
export default {
  components: {
    vHeader,
    leftNav,
  },
  data() {
    return {
      // 
    }
  },
  async created() {
    // 当前时间
    // let date = new Date();
    // let nowTime = date.getTime();
    // let year = date.getFullYear();
    // let nowMonth = date.getMonth();
    // let month = date.getMonth() + 1;
    // let day = date.getDate();
    // function addZero(val) {
    //     if (val < 10) {
    //         val = '0' + val;
    //     } 
    //     return val;
    // }
    // let today = year + '-' + addZero(month) + '-' + addZero(day);
  }
}
</script>

<style>
html,
body,
#app {
  min-width: 1520px;
}
body::after {
  content: "";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
}
.main {
  display: flex;
  min-width: 1200px;
  margin-left: 65px;
  padding-left: 5px;
  height: 100%;
}
</style>