import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


import '@/styles/icon.css'
import '@/styles/index.css'
// import '@/styles/ruoyi.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Http from '@/utils/request'
import Pagination from "@/components/Pagination";
Vue.component('Pagination', Pagination)

// 引入localforage
import localforage from 'localforage'
Vue.prototype.$localforage = localforage

// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

// 引入路由守卫
import '@/router/permission'
Vue.prototype.$http = Http

// 全局方法挂载
import { getPickerOptions,getPickerOptionsold,selectDictLabelid, selectDictLabel,selectDictLabellocal, selectDictLabels,
          inserted,resetForm,NowTime,formatConversion,formattransdate,getNowFormatDate,sortByProps,parse2Tree,getNetworkIp,
          generateTimeReqestNumber,accAdd,Subtr,accMul,accDiv
} from '@/utils';
Vue.prototype.getPickerOptions = getPickerOptions
Vue.prototype.getPickerOptionsold = getPickerOptionsold
Vue.prototype.selectDictLabelid = selectDictLabelid
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabellocal = selectDictLabellocal
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.resetForm = resetForm
Vue.prototype.NowTime = NowTime
Vue.prototype.formatConversion = formatConversion
Vue.prototype.formattransdate = formattransdate
Vue.prototype.getNowFormatDate = getNowFormatDate
Vue.prototype.sortByProps = sortByProps  //js多字段排序
Vue.prototype.parse2Tree = parse2Tree  //生成树结构
Vue.prototype.getNetworkIp = getNetworkIp  //获取本机ip
Vue.prototype.generateTimeReqestNumber = generateTimeReqestNumber  //js生成yyyymmddhhmmss格式时间戳
Vue.prototype.accAdd = accAdd
Vue.prototype.Subtr = Subtr
Vue.prototype.accMul = accMul
Vue.prototype.accDiv = accDiv

//全局方法  限制input输入框只能输入纯数字 组件中调用如（<el-input v-enter-number v-model="number" ></el-input>）
// 对于全局自定义指令的创建，我们需要使用 Vue.directive接口
Vue.directive('enterNumber', inserted)

// #if NOCDN
console.log('测试条件编译');
// #endif

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
