<template>
  <div class="contn-box">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" > 
      <el-tab-pane name="营业日报" label="营业日报">
      <yinye-Ribao />
      </el-tab-pane>
      <el-tab-pane name="单品分析" label="单品分析">
        <cashRegister :defaultdata="infodata" ></cashRegister>
      </el-tab-pane>
      <!-- <el-tab-pane name="销售明细" label="销售明细">定时任务补偿</el-tab-pane> -->
       <el-tab-pane name="结算统计" label="结算统计">
      <jiesuan-Tongji :defaultdata="infodata" ></jiesuan-Tongji>
      </el-tab-pane>
      <el-tab-pane name="优惠统计" label="优惠统计">
          <youhui-Tongji :defaultdata="infodata" ></youhui-Tongji>
      </el-tab-pane>
      <el-tab-pane name="提单统计" label="提单统计">
         <tidan-Tongji :defaultdata="infodata" ></tidan-Tongji>
      </el-tab-pane>
      </el-tabs>
  </div>
</template>

<script>
import yinyeRibao from '../零售/营业日报.vue'
import cashRegister from '../零售/单品分析.vue'
import jiesuanTongji from '../零售/结算统计.vue'
import tidanTongji from '../零售/提单统计.vue'
import youhuiTongji from '../零售/优惠统计.vue'

// import { mapGetters } from 'vuex'
export default {
    props: {
    defaultdata: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  //   computed: {
  //   ...mapGetters('app', [ 'dictdata']),
  //   ...mapGetters('user', ['users'])
  // },
  data() {
    return {
      activeName:'营业日报',
        //基础数据
       infodata: {},
    }
  },
   components:{
     yinyeRibao,
     cashRegister,
     jiesuanTongji,
     tidanTongji,
     youhuiTongji,
  },

 created() {
       this.infodata.dictdata=this.defaultdata.dictdata
        this.infodata.users=this.defaultdata.users
        // this.infodata.dictdata=this.dictdata
        // this.infodata.users=this.users
  },
}
</script>

<style lang="scss" scoped>
.contn-box {
    width: 100%;
    height: 100%;
    padding: 15px;
}
</style>