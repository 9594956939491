<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
    
        <el-form-item  prop="shifts_shop"  >
         <el-select v-model="queryParams.shifts_shop" placeholder="请选择班次" clearable size="small">
            <el-option v-for="item in shopround" :key="item.id" :label="item.dict_label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item  prop="uby" >
         <el-select v-model="queryParams.uby" placeholder="请选择操作人" clearable size="small">
            <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
         

      <el-form-item label="销售日期">
        <el-date-picker
         size="small"
          v-model="dateRange"
          value-format="timestamp"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

   

      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
    
      <!-- <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar> -->
    </el-row>

   <div class="table-box">
      <el-table :data="tableData"  border highlight-current-row :summary-method="getSummaries" show-summary height="700" style="width: 100%; ">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="part_id" label="类型">
        </el-table-column>
        <el-table-column prop="part_name" label="商品名称">
        </el-table-column>
        <el-table-column prop="saledt_amount" label="原价">
        </el-table-column>
        <el-table-column prop="saledt_dis_1" label="实收">
        </el-table-column>
        <el-table-column prop="saledt_dis_2" label="优惠">
        </el-table-column>
        <el-table-column prop="saledt_dis_2" label="优惠率">
        </el-table-column>
         <el-table-column prop="saledt_dis_2" label="优惠率">
        </el-table-column>
      </el-table>
    </div>

    <!-- <pagination
      v-show="total>0"
      :total="total"
      :page.sync="querypaging.pageNum"
      :limit.sync="querypaging.pageSize"
      @pagination="getList"
    /> -->

    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  //  props: {
  //   ticketdata: {
  //     required: true,
  //     type: Object,
  //     default() {
  //       return {}
  //     }
  //   },
  // },
  //  watch: {
  //   ticketdata: {
  //     handler(n) {
  //       if (n) {
  //        console.log('n',n)
  //       }
  //     },
  //     deep: true
  //   },  
  // },
  name: "config",
   computed: {
    ...mapGetters('app', [ 'dictdata']),
    ...mapGetters('user', ['users'])
  },
  data() {
    return { 
      page_data: {
        total: 0,
        currentPage: 1,
        pagesize: 28,
      },
      //时间选择器
      pickerOptions: this.getPickerOptionsold(),
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      all_table_data: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
       //门店班组
      shopround: [],
      // 查询参数
      queryParams: {
        offset:0,
        limit: 0,
        shifts_shop:'',
        uby: '',
      },
          //分页步长
       querypaging: {
        pageNum:0,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        configName: [
          { required: true, message: "参数名称不能为空", trigger: "blur" }
        ],
        configKey: [
          { required: true, message: "参数键名不能为空", trigger: "blur" }
        ],
        configValue: [
          { required: true, message: "参数键值不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getList();
    this.shopround = this.dictdata.filter(item => item.dict_type == "bus_shop_round")//门店班组
  },
  methods: {
  /** 查询参数列表 */
   async getList() {
     let t_pos_sale = await this.$localforage.getItem('t_pos_sale') || []
      let t_pos_sale_detail = await this.$localforage.getItem('t_pos_sale_detail') || []
      let t_part = await this.$localforage.getItem('t_part') || []


console.log('sale_date', this.sale_date)
   //需要根据单据类型过滤数据dateRange
    console.log('dateRange', this.dateRange)
let subTime ,supTime
        subTime = this.dateRange[0];
        supTime = this.dateRange[1];
       if (this.dateRange.length==0){
          subTime=0
          supTime=4133865600000 //2100-12-31
       }
      let pos_sale_arr = t_pos_sale.filter(item =>
           Number(item.shifts_shop).toString().includes(this.queryParams.shifts_shop) 
        && Number(item.uby).toString().includes(this.queryParams.uby)
        // && Number(item.sale_date).toString().includes(this.searchform.inv_to)
        &&  subTime <= item.sale_date && item.sale_date <= supTime
      )
      let arr = []
       let pos_sale_detail_arr
      pos_sale_arr.forEach((item,i) => {
      pos_sale_detail_arr = t_pos_sale_detail.filter(i => Number(i.pid) == item.id)
      for (let i = 0; i < pos_sale_detail_arr.length; i++) {    
      arr.push(pos_sale_detail_arr[i])
      // console.log('arr', arr)
    }


     })

      let tableData = []
      arr.forEach(el => {
          // console.log('el.part_id', el)
        let i = tableData.findIndex(v => v.part_id === el.part_id)
        if (i >= 0) {
          tableData[i].saledt_qty = tableData[i].saledt_qty + el.saledt_qty
          tableData[i].saledt_dis_1 = tableData[i].saledt_dis_1 + el.saledt_dis_1
          tableData[i].saledt_dis_2 = tableData[i].saledt_dis_2 + el.saledt_dis_2
          tableData[i].saledt_amount = tableData[i].saledt_amount + el.saledt_amount
        } else {
         
          let j = t_part.findIndex(v => v.part_id === el.part_id)
          let obj = {
            part_name: j >= 0 ? t_part[j].part_name : '',
            part_id: el.part_id,
            saledt_sprice: el.saledt_sprice,
            saledt_unit: el.saledt_unit,
            saledt_qty: el.saledt_qty,
            saledt_dis_1: el.saledt_dis_1,
            saledt_dis_2: el.saledt_dis_2,
            saledt_amount: el.saledt_amount
          }
          tableData.push(obj)
        }
      });
         console.log('pos_sale_detail_arr', tableData)

         this.tableData = tableData
      // this.all_table_data = tableData
      // this.makeData()
    },
    /** 查询参数列表 */
    // getList() {
    //   this.loading = true;
    //   this.queryParams.offset=(this.querypaging.pageNum -1 )* this.querypaging.pageSize  //分页
    //   this.queryParams.limit=this.querypaging.pageSize
    //   listConfig(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
    //     console.log('listConfig', response)
    //       this.configList = response.data.rows;
    //       this.total = response.data.pageTotalCount;
    //       this.loading = false;
    //     }
    //   );
    // },
   
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.querypaging.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
  
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.configId)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },

    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有参数数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportConfig(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
    },
      //合计
    getSummaries(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 1) {
        sums[index] = '合计:';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value)) 
            && column.property != 'sale_id' 
            && column.property != 'sale_date'
            && column.property != 'sale_type'
            && column.property != 'saledt_id'
            && column.property != 'part_id'
            && column.property != 'saledt_sprice'
         ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += '';
      } else {
        sums[index] = '';
      }
    });

    return sums;
  },
 
  }
};
</script>
