
<template>
  <div style="margin-top:10px;width:100%;">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="会员档案" name="first">
         <memberlist :user="user" />
        </el-tab-pane>
       <!-- <el-tab-pane label="会员合并" name="second">
         <memberhb  />
      </el-tab-pane> -->
     <!-- <el-tab-pane label="会员密码" name="second">
      </el-tab-pane>
      <el-tab-pane label="会员消费" name="second">
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>




<script>
import memberlist from "./memberlist.vue";
import memberhb from "../会员/memberhb";
export default {
  name: "card",
  components: { memberlist,memberhb },
   data() {
   return {
      user: {},
       activeName: 'first',
    };
     },
}
</script>

<style>

</style>

