<template>
  <div style="margin-top:10px;width:100%;">
    <div class="biao-box">
      <div class="form">
      <el-form :model="queryParams" ref="queryForm" :rules="rules" :inline="true" v-show="showSearch" label-width="68px">
        <el-form-item label="" prop="carddata" size="small">
          <el-input  v-model="queryParams.carddata"  placeholder="请输入查询条件"  clearable @keyup.enter.native="handleQuery"/>
        </el-form-item>
        <el-form-item>
          <el-button type="cyan" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" icon="el-icon-plus" size="small" @click="handleAdd">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" icon="el-icon-edit" size="small" :disabled="single" @click="handleUpdate">修改</el-button>
        </el-col>
      </el-row>
   </div>
  <div class="content">
      <el-table v-loading="loading"  :data="postList" border @selection-change="handleSelectionChange" >
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column label="卡号" align="center" prop="card_id_out" :show-overflow-tooltip="true"/>
        <el-table-column label="名称" sortable align="center" prop="card_name" :show-overflow-tooltip="true"/>
        <el-table-column label="手机" sortable align="center" prop="card_mobile" :show-overflow-tooltip="true"/>
        <el-table-column label="折扣" sortable align="center" prop="card_dis_rate" :show-overflow-tooltip="true"/>
        <el-table-column label="消费总金额" sortable align="center" prop="card_sum" :show-overflow-tooltip="true"/>
        <el-table-column label="预存金额" sortable align="center" prop="card_money" :show-overflow-tooltip="true"/>
        <el-table-column label="余额" sortable align="center" prop="card_remoney" :show-overflow-tooltip="true"/>
        <el-table-column label="积分" sortable align="center" prop="card_score" :show-overflow-tooltip="true"/>
        <el-table-column label="状态" sortable align="center" prop="card_state" :show-overflow-tooltip="true" :formatter="stateFormat"/>
        <el-table-column label="卡到期限" sortable align="center" :show-overflow-tooltip="true" :resizable="false" width="160">
          <template slot-scope="scope">{{
            scope.row.card_expire_date === null ? "" : scope.row.card_expire_date | parseTime}}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button size="mini"  type="text" icon="el-icon-edit"  @click="handleUpdate(scope.row)">修改</el-button>
            <el-button size="mini"  type="text" icon="el-icon-edit"  @click="handleMemberhb(scope.row)">合并</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="querypaging.pageNum" :limit.sync="querypaging.pageSize" @pagination="getList"/>
        <!-- 添加或修改参数配置对话框 -->
        <el-dialog
          :title="isEdit == '2' ? '编辑会员信息' : '添加会员信息'" :visible.sync="open" :close-on-click-modal="false" width="1500px" append-to-body :before-close="beforeClose">
          <card-from :data="form" ref="wuliaoFromRef" @getFormData="seaveBaseInfo" :isCancel="isCancel" ></card-from>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>

         <el-dialog
          :title="'会员合并'" :visible.sync="open_hb" :close-on-click-modal="false" width="1000px" height="600" append-to-body :before-close="beforeClose">
          <member-Hb :data="form" ref="memberhbRef"   @getFormData="seaveMemberhb"  ></member-Hb>
          <div slot="footer" class="dialog-footer">
            <!-- <el-button type="primary" @click="submitFormhb">合 并</el-button> -->
            <el-button @click="cancel_hb">返 回</el-button>
          </div>
        </el-dialog>

         <!-- <el-dialog
          :title="isEdit == '2' ? '会员充值' : '会员充值'" :visible.sync="open_top" :close-on-click-modal="false" width="1500px" append-to-body :before-close="beforeClose">
          <card-Top :data="form" ref="wuliaoFromRef" @getFormData="seaveBaseInfo" :isCancel="isCancel" ></card-Top>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog> -->

    </div>
  </div>
</div>
</template>

<script>
import 会员api from './api.js'
import CardFrom from "./memberprofile";
import MemberHb from "../会员/memberhb";
import { parseTime } from '@/utils'
export default {
filters: {
    parseTime
  },
  name: "memberlist",
  components: { CardFrom,MemberHb },

  data() {
    return {
      isCancel: true,
      isEdit: "0",
      //卡类型
      typeOptions: [],
      //卡状态状态
      stateoptions: [
        {
          dictValue: "0",
          dictLabel: "未启用",
        },
        {
          dictValue: "1",
          dictLabel: "有效",
        },
        {
          dictValue: "2",
          dictLabel: "挂失",
        },
        {
          dictValue: "3",
          dictLabel: "失效",
        },
        {
          dictValue: "4",
          dictLabel: "作废",
        },
      ],
      dictValue: "",
      // pickerOptions: this.getPickerOptions(),
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 岗位表格数据
      postList: [],
      // 弹出层标题
      title: "",
      // 部门树选项
      deptOptions: undefined,
      // 是否显示弹出层
      open: false,
      open_hb: false,//合并
      // 状态数据字典
      statusOptions: [],
      //门店分组
      shopOptions: [],
      //商品分组
      goodsOptions: [],
      // 查询参数
      queryParams: {
        offset:0,
        limit: 0,
        // type: 0,
        carddata: "",
      },
            //分页步长
       querypaging: {
        pageNum:0,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        // type: [
        //   { required: true, message: "类型不能为空", trigger: "blur" },
        // ],
        carddata: [{ required: true, message: "输入数据不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
   this.listDataCom();//查询公共字典
    // this.getList();
  },
  methods: {
    /** 查询卡类型字典列表 */
    async listDataCom() {
       let [err, res] = await 会员api.查询会员卡分类列表({type:'card_group'})
       console.log(res)
      if (err) {
        return this.$message.error('查询会员卡分类列表失败')
      }
     this.typeOptions = res.data.rows;
     console.log('this.typeOptions', this.typeOptions)

    },

    disabletime(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    /** 查询会员列表 */
    async getList() {
      if(!this.queryParams.carddata){
         return this.$message.warning('请输入查询条件')
      }
      // this.loading = true;
      this.queryParams.offset =(this.querypaging.pageNum - 1) * this.querypaging.pageSize; //分页
      this.queryParams.limit = this.querypaging.pageSize;
       let [err, res] = await 会员api.查询会员卡模块列表(this.queryParams)
       console.log(res)
      if (err) {
        // loading.close()
        return this.$message.error('查询会员卡列表失败')
      }
      console.log(' res.data',  res.data)
      this.postList = res.data.rows;
      // this.total = res.data.pageTotalCount;
      // this.loading = false;
    },

    // 操作状态字典翻译
    stateFormat(row, column) {
      return this.selectDictLabel(this.stateoptions, row.card_state);
    },
    // 卡类型字典翻译
    cardtypeFormat(row, column) {
      return this.selectDictLabel(this.typeOptions, row.card_type);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      setTimeout(() => {
              this.isCancel = false;
            }, 300);
      setTimeout(() => {
        this.isCancel = true;
      }, 500);
    },
      // 取消按钮
    cancel_hb() {
      this.open_hb = false;
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.querypaging.pageNum = 0;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.idscard_id_out = selection.map((item) => item.card_id_out);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;

      this.isEdit = "1";
      this.form = {};
      this.form.isEdit = "1";
      this.form.isEdits = false;
      this.title = "添加会员信息";
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      // this.reset();
      this.form = row;
      this.form.isEdit = "2";
      this.open = true;
      this.isEdit = "2";
      this.form.isEdits = true; //卡号编辑控制
      this.title = "修改会员信息";
    },

  /** 合并按钮操作 */
    async handleMemberhb(row) {
      // this.reset();
      this.form = row;
      this.open_hb = true;
    },

 async seaveMemberhb(val) {
   
    //  this.$refs.memberhbRef.$refs.cardinfo.seaveData();
     console.log('seaveMemberhb'  , val)
     this.open_hb = false;
     this.handleQuery()
 },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.wuliaoFromRef.$refs.cardinfo.seaveData();
    },

   /** 提交按钮 */
    submitFormhb: function () {
           console.log(' this.$refs.memberhbRef.$refs', this.$refs.memberhbRef)
     this.$refs.memberhbRef.seaveData();
    },

    /** 提交按钮 */
    async seaveBaseInfo(val) {
      if (val.id != undefined) {
      let [err, res] = await 会员api.修改会员信息(val)
          if (err) {
            return this.$message.error('修改会员信息失败')
          }
           console.log('res.statusCode', res.statusCode)
          if (res.statusCode === 200) {
                this.$message.success("修改成功");
                this.open = false;
                this.getList();
                setTimeout(() => {
                  this.isCancel = false;
                }, 300);
                setTimeout(() => {
                  this.isCancel = true;
                }, 500);
              }
      } else {
            const newId = await  会员api.getOneID()//获得id
             console.log('newId', newId)
            val.id = newId;
            let [err, res] = await 会员api.新增会员信息(val)
              if (err) {
                return this.$message.error('新增会员信息失败')
              }
              if (res.statusCode === 200) {
                this.$message.success("新增成功");
                this.open = false;
                this.queryParams.type=0
                this.queryParams.carddata=val.card_id_out
                this.getList();
            setTimeout(() => {
              this.isCancel = false;
            }, 300);
            setTimeout(() => {
              this.isCancel = true;
            }, 500);
            }
      }
    },

    beforeClose(done){
        done()
         setTimeout(() => {
              this.isCancel = false;
            }, 300);

            setTimeout(() => {
              this.isCancel = true;
            }, 500);
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const Ids = row.id || this.ids;
      const idsname = row.card_id_out || this.idscard_id_out;

      this.$confirm(
        '是否确认删除会员编号为"' + idsname + '"的数据项?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          return apidel(Ids);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出所有会员数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return exportPost(queryParams);
        })
        .then((response) => {
          this.download(response.msg);
        })
        .catch(function () {});
    },
  },
};
</script>

<style scoped lang="less">
.biao-box {
  width: 100%;
  // padding: 15px 40px;
}
.handel-btn {
  margin: 10px 0;
  padding: 0 10px;
}
</style>
