<template>
  <div class="box-bord">
    <el-popover placement="right-start" width="340" trigger="manual" v-model="vipinputV">
      <NumberBoard @bordclose="onBorderClose" @input-num="onKeyInput" @btnclick="onBtnClick" />
      <el-input-number slot="reference" style="width:110px" v-model="num" 
        @focus="vipinputV=true" 
        @change="onNumChange"
        :min="1"
        size="mini" 
        controls-position="right">
      </el-input-number>
    </el-popover>
  </div>
</template>

<script>
import NumberBoard from '@/components/NumberBord'
export default {
  components: {
    NumberBoard
  },
  props:{
      defnum:{
          type:Number,
          default:0
      }
  },
  watch:{
     defnum(n){
      this.num = n
     } 
  },
  created(){
      this.num = this.defnum
  },
  data() {
    return {
      num: '',
      vipinputV: false
    }
  },
  methods: {
    onBorderClose() {
      this.vipinputV = false
    },
    onKeyInput(num) {
      this.num = num
      this.vipinputV = false
      this.$emit('input-num', this.num)
    },
    onBtnClick(num) {
      this.num = num
      this.$emit('input-num', this.num)
    },
    onNumChange(num){
      this.$emit('input-num', num)
    },
    close() {
      this.vipinputV = false
    }
  },
}
</script>

<style scoped lang="scss">
.box-bord {
  width: 100%;
  height: 100%;
}
</style>