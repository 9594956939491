<template>
  <div class="login-container">
    <div class="login-wrap" />
    <el-form v-show="!hasBaseData" ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <el-card class="box-card">
        <div class="title-container">
          <h3 class="title">开市</h3>
        </div>

        <el-form-item label="营业日期" prop="settle_bgn_date">
          <el-date-picker v-model="loginForm.settle_bgn_date" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" style="width:100%;"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="钱箱金额" prop="settle_bgn_cash">
          <el-input-number v-model="loginForm.settle_bgn_cash" prop="settle_bgn_cash"  @focus="setNowInput('settle_bgn_cash')" style="width:100%;" @keyup.enter.native="handleLogin"></el-input-number>
        </el-form-item>
        <el-button :loading="loading" type="primary" style="width:30%;font-size:16px;margin-bottom:30px;" @click.native.prevent="handleLogin">确 定</el-button>
        <el-button :loading="loading" type="primary" style="width:30%;font-size:16px;margin-bottom:30px;" @click.native.prevent="handleReturn">返 回</el-button>
        <div class="key-board">
          <key-board ref="keyboardRef" @input-text="onKeyInput"></key-board>
        </div>
      </el-card>
    </el-form>
    <!-- <register v-show="hasBaseData" @canlogin="canlogin" /> -->
  </div>
</template>

<script>
import { makeid } from '@/components/makeid.js'
import { getLocal, setLocal } from '@/utils/localstorage'
import KeyBoard from '@/components/Keyboard.vue'

export default {
  components: { KeyBoard},
  name: 'Openinitial',
  data() {
    return {
      //时间选择器
      pickerOptions: this.getPickerOptions(),
      //班次
      ShopRound:[],
      //付款方式列表
      PayWay: [],
      tableData: [],
      ShopRound: [],
      hasBaseData: false,
      userList: [],
      loginForm: {
        settle_bgn_cash: '',
        settle_bgn_date: parseInt(new Date().getTime()),
        settle_bstate: '0',
      },
      loginRules: {
        settle_bgn_date: [{ required: true, trigger: 'blur', message: '请选择营业日期' }],
        settle_bgn_cash: [{ required: true, trigger: 'blur', message: '钱箱金额' }],
      },
      loading: false,
      now_input: ''
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },

  methods: {
    async handleLogin() {
      this.setSettlement()
    },

     //初始化-新增
    async setSettlement() {
//获得当前时间的前一天
      let curDate = new Date();
      let newDate=new Date(curDate.setDate(curDate.getDate()-1));
      let BeforeData= this.getNowFormatDate(newDate) +' '+'23:59:59';
      let BeforeDataInt=parseInt(new Date(BeforeData).getTime())//转换成数值
      let dictdata = await this.$localforage.getItem('t_sys_dict_data') || []
      let bus_pay_way = dictdata.filter(i => i.dict_type == 'bus_pay_way')
      this.PayWay = bus_pay_way.filter(i => !i.isdel)
      let Settlement = getLocal('Settlement')||[]//开市数据

        // console.log('this.Settlement',Settlement)
      if (Settlement.length==0){
        Settlement={settle_bgn_cash:0,settle_bgn_date:0,settle_bstate:0}
      }
      let sale_cur =  await this.$localforage.getItem('t_pos_sale_cur') || []
      let shopBaseMsg = await getLocal('shopBaseMsg')
      let arr = []
       console.log('this.PayWay',this.PayWay)
      this.PayWay.forEach(item => {
         let salecurItem = sale_cur.filter(f => f.pay_id == item.dict_value && f.uct>=Settlement.settle_bgn_date )
         console.log('salecurItem',salecurItem)
          let i = salecurItem.findIndex(v => v.pay_id === item.dict_value)
            if (i >= 0) {
                var s = 0;
                salecurItem.forEach((item ,i)=> { 
                  s += item.pay_amt        
                  })   
                 console.log('data_arr',s)   
            }else{s=0}
                 
          let obj = {
            pay_id: item.dict_value,
            pay_name: item.dict_label,
            settle_act: s, //item.settle_act,//钱箱总金额
            settle_pay: s,//解款金额
            settle_remain: item.settle_remain,
            pos_id: shopBaseMsg.terminal,
            notes: '',
          }
          arr.push(obj)
      })
       this.tableData = arr 
      console.log('this.tableData',arr,this.tableData)

          let 主表id = makeid(1)[0]
          let user = this.$store.state.user.currUser
          let seavetime = parseInt(new Date().getTime())
          let 明细id = makeid(this.tableData.length)
          let 明细 = []
          let 明细表 = await this.$localforage.getItem('t_pos_settlement_items') || [] //明细表
          let 主表 =   await this.$localforage.getItem('t_pos_settlement') || []//主表
            // 新增
            this.tableData.forEach((item, i) => {
              let obj = {
                id: 明细id[i],
                tenid: user.tenid,
                lct: seavetime,
                uct: seavetime,
                tstate: 1,
                dept_id: user.dept_id,
                pid: 主表id,
                pay_id: item.pay_id,
                settle_act: item.settle_act,
                settle_pay: item.settle_pay,
                settle_remain: item.settle_act -item.settle_pay,
                pos_id: item.pos_id,
                notes: item.notes,
              }
              明细.push(obj)
            })
            console.log('新增明细',明细,this.tableData)
            let 单据主表 = {
              id: 主表id,
              tenid: user.tenid,
              lct: seavetime,
              uct: seavetime,
              lby: user.id,
              uby: user.id,
              sett_id: this.制作编号(),
              bstate: 1,
              settle_bgn_date:  Settlement.settle_bgn_date,
              settle_end_date:  BeforeDataInt,
              settle_bgn_cash:  Settlement.settle_bgn_cash,
              notes:'自动',
              pos_id: shopBaseMsg.terminal,
              tstate: 1, //1 未上传 9上传
              dept_id: user.dept_id,
            }
            console.log('单据主表',单据主表)
            主表.push(单据主表)
            明细表.push(...明细)     
// 储存
          await this.$localforage.setItem('t_pos_settlement_items', 明细表)
          await this.$localforage.setItem('t_pos_settlement', 主表)

          setLocal('Settlement',this.loginForm)//当前开市时间
          let url = sessionStorage.getItem('currentPage') || '/'
          this.$router.push(url)
    },

    //返回
     handleReturn() {
        setLocal('logintime', 0)
        setLocal('currUser', '')
        this.$router.push('/staff/login')
     },
  
    async getUser() {
      let list = await this.$localforage.getItem('sys_user')
      let arr = []
      list.forEach(el => {
        let o = {
          id: el.id,
          user: el.user,
          name: el.name
        }
        arr.push(o)
      });
      this.userList = arr
    },
    canlogin() {
      this.hasBaseData = false
      this.getUser()
    },
    setNowInput(v) {
      this.now_input = v
      this.$refs.keyboardRef.reset()
    },
    onKeyInput(v) {
      this.loginForm[this.now_input] = v
    },
    制作编号() {
      let shopmsg = getLocal('shopBaseMsg')
      let id = makeid(1)[0]
      let 商户号 = shopmsg.terminal
      let str = ''
      for (let i = 0; i < 6 - 商户号.length; i++) {
        str += '0'
      }
      return 'RJ' + str + 商户号 + id
    }
  }
}
</script>

<style scoped>
.login-container {
  position: relative;
  background-size: 100%;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.login-wrap {
  /* background: linear-gradient(135deg, #002329, #01596e); */
  background-color: #01596e;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
}
.box-card {
  background: rgba(255, 255, 255, 0.4);
  border: 0;
}
.login-form {
  position: relative;
  width: 611px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.title-container {
  position: relative;
}
.title {
  font-size: 28px;
  color: #fff;
  margin: 10px auto 30px auto;
  text-align: center;
  font-weight: bold;
}
</style>

