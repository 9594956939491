/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (time === '') {
        return ''
    }
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
        return value.toString().padStart(2, '0')
    })
    return time_str
}


export function NowTime() {
    var now = new Date()
    var year = now.getFullYear() // 年
    var month = now.getMonth() + 1 // 月
    var day = now.getDate() // 日
    var hh = now.getHours() // 时
    var mm = now.getMinutes() // 分
    var ss = now.getSeconds() // 秒

    var clock = year + '-'
    if (month < 10) { clock += '0' }
    clock += month + '-'
    if (day < 10) { clock += '0' }
    clock += day + ' '
    if (hh < 10) { clock += '0' }
    clock += hh + ':'
    if (mm < 10) clock += '0'
    clock += mm + ":"
    if (ss < 10) ss += '0'
    clock += ss
    return (clock)
}

export function _to(promise) {
    return promise.then(function (data) { return [null, data]; }).catch(function (err) {
        return [err, undefined];
    });
}

  /**
    * 返回一个时间选择器的共用快捷设置
  */
 export function getPickerOptions() {
    return {
        shortcuts: [
            {
                text: '今天',
                onClick(picker) {
                    picker.$emit('pick', new Date())
                }
            }, {
                text: '昨天',
                onClick(picker) {
                    const date = new Date()
                    date.setTime(date.getTime() - 3600 * 1000 * 24)
                    picker.$emit('pick', date)
                }
            }, {
                text: '一周前',
                onClick(picker) {
                    const date = new Date()
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                    picker.$emit('pick', date)
                }
            }
        ]
    }
  }


  export function getPickerOptionsold() {
    return {
        shortcuts: [
          {
                text: '最近一周',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                }
            },
            {
                text: '最近一个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                }
            },
            {
                text: '最近三个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                }
            }
        ]
    }
  }
  
/**
 * 分页显示 
 * @param {Array} data  
 * @param {number} pagesize 每页数量
 * @param {number} currentpage 当前页数
 */
export function pagePagination(data = [], pagesize = 20, currentpage = 1) {
        if (Array.isArray(data)) {
            let arr = []
            if (currentpage <= 0) currentpage = 1
            let s = (currentpage - 1) * pagesize //0 , 20
            let e = currentpage * pagesize //20 , 40
            for (let i = 0; i < data.length; i++) {
                if (i >= s && i < e) {
                    arr.push(data[i])
                }
            }
            return arr
        } else {
            return []
        }
    }

//全局方法  限制input输入框只能输入纯数字 组件中调用如（<el-input v-enter-number v-model="number" ></el-input>）
   export  function inserted(el) {
    el.addEventListener("keypress", function(e) {
      e = e || window.event;
      let charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  }
// 回显数据字典id值
export function selectDictLabelid(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].id == ('' + value)) {    
			actions.push(datas[key].dict_label);
			return true;
		}
	})
	return actions.join('');
}

// 回显数据字典本地值
export function selectDictLabellocal(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].dict_value == ('' + value)) {    
			actions.push(datas[key].dict_label);
			return true;
		}
	})
	return actions.join('');
}
// 回显数据字典Value值
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].dictValue == ('' + value)) {
			actions.push(datas[key].dictLabel);
			return true;
		}
	})
	return actions.join('');
}
// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].dictValue == ('' + temp[val])) {
				actions.push(datas[key].dictLabel + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}
// 表单重置
export function resetForm(refName) {
	if (this.$refs[refName]) {
		this.$refs[refName].resetFields();
	}
}

//时间戳转化成格式时间
export function formatConversion (te){
	if(te == ''){
		return '';
	}else if(te.length == 10){
		var time = new Date(te * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		var y = time.getFullYear();
		var m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
		var d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
		var h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
		var mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
		var s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
		var timedate = y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
		return timedate;

		// var date = new Date(te * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
	    // var Y = date.getFullYear() + '-';
	    // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	    // var D = date.getDate() + ' ';
	    // var h = date.getHours() + ':';
	    // var m = date.getMinutes() + ':';
	    // var s = date.getSeconds();
		// return Y + M + D + h + m + s;

	}else{
		var time = new Date(te);
		var y = time.getFullYear();
		var m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
		var d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
		var h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
		var mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
		var s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
		var timedate = y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
		return timedate;
	}
}

//日期转时间戳
export function formattransdate(time){
    var date = new Date();
    date.setFullYear(time.substring(0, 4));
    date.setMonth(time.substring(5, 7) - 1);
    date.setDate(time.substring(8, 10));
    date.setHours(time.substring(11, 13));
    date.setMinutes(time.substring(14, 16));
    date.setSeconds(time.substring(17, 19));
    return Date.parse(date) / 1000;
}

//获取当前时间，格式YYYY-MM-DD
export  function getNowFormatDate(te) {
    var date =new Date(te);
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

 
 //js生成yyyymmddhhmmss格式时间戳   
export function generateTimeReqestNumber() {
    var date = new Date();
    return date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds());
}

function pad2(n) { return n < 10 ? '0' + n : n }

  /**
  * 生成树结构
  * @param {Array} data
  * @returns {Array | null}
  */
   export function parse2Tree(data) {
    if (!Array.isArray(data)) {
        return null
    }
    const copyData = JSON.parse(JSON.stringify(data))
    const map = {}
    const res = []
    // 生成数据对象集合
    copyData.forEach(item => {
        map[item.id] = item
    })
    // 生成结果集
    copyData.forEach(item => {
        const parent = map[item.pid]
        if (parent) {
            if (!Array.isArray(parent.children)) {
                parent.children = []
            }
            parent.children.push(item)
        } else {
            res.push(item)
        }
    })
    return res
  }
  
export  function sortByProps(item1, item2, obj) {
    // 多字段排序 
    //asc升序 desc 降序
    var props = [];
    if (obj) {
        props.push(obj);
    }
    var cps = [], // 存储排序属性比较结果。
        // 如果未指定排序属性(即obj不存在)，则按照全属性升序排序。
        // 记录下两个排序项按照各个排序属性进行比较得到的结果
        asc = true;
  
    function handleNull(val) {
        return val === '' || val === null;
    }
    if (props.length < 1) {
        for (var p in item1) {
            let a1, b1;
            a1 = item1[p];
            b1 = item2[p];
            if (handleNull(a1) && handleNull(b1)) {
                cps.push(0);
            } else if (handleNull(a1) && !handleNull(b1)) {
                cps.push(1);
                break;
            } else if (!handleNull(a1) && handleNull(b1)) {
                cps.push(-1);
                break; // 小于时跳出循环。
            } else {
                if (a1 > b1) {
                    cps.push(1);
                    break; // 大于时跳出循环。
                } else if (a1 === b1) {
                    cps.push(0);
                } else {
                    cps.push(-1);
                    break; // 小于时跳出循环。
                }
            }
        }
    } else {
        for (var i = 0; i < props.length; i++) {
            var prop = props[i];
            for (var o in prop) {
                asc = prop[o] === 'asc';
                let a2, b2;
                a2 = item1[o];
                b2 = item2[o];
                if (handleNull(a2) && handleNull(b2)) {
                    cps.push(0);
                } else if (handleNull(a2) && !handleNull(b2)) {
                    cps.push(asc ? 1 : -1);
                    break; // 大于时跳出循环。
                } else if (!handleNull(a2) && handleNull(b2)) {
                    cps.push(asc ? -1 : 1);
                    break; // 小于时跳出循环。。
                } else {
                    if (a2 > b2) {
                        cps.push(asc ? 1 : -1);
                        break; // 大于时跳出循环。
                    } else if (a2 === b2) {
                        cps.push(0);
                    } else {
                        cps.push(asc ? -1 : 1);
                        break; // 小于时跳出循环。。
                    }
                }
            }
        }
    }
    // 根据各排序属性比较结果综合判断得出两个比较项的最终大小关系
    for (var j = 0; j < cps.length; j++) {
        if (cps[j] === 1 || cps[j] === -1) {
            return cps[j];
        }
    }
    return false;
  }

  export  function getNetworkIp() {
    let needHost = ''; // 打开的host
    try {
        // 获得网络接口列表
        let network = os.networkInterfaces();
        for (let dev in network) {
            let iface = network[dev];
            for (let i = 0; i < iface.length; i++) {
                let alias = iface[i];
                if (alias.family === 'IPv4' && alias.address !== '127.0.0.1' && !alias.internal) {
                    needHost = alias.address;
                }
            }
        }
    } catch (e) {
        needHost = 'localhost';
    }
    return needHost;
}
    //除法
    export function accDiv(arg1,arg2){ 
    var t1=0,t2=0,r1,r2; 
    try{t1=arg1.toString().split(".")[1].length}catch(e){} 
    try{t2=arg2.toString().split(".")[1].length}catch(e){} 
    // with(Math){ 
    r1=Number(arg1.toString().replace(".","")) 
    r2=Number(arg2.toString().replace(".","")) 
    return accMul((r1/r2),pow(10,t2-t1)); 
    // } 
    } 
    //乘法 
    export function accMul(arg1,arg2) 
    { 
    var m=0,s1=arg1.toString(),s2=arg2.toString(); 
    try{m+=s1.split(".")[1].length}catch(e){} 
    try{m+=s2.split(".")[1].length}catch(e){} 
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m) 
    } 
   //加法 
   export function accAdd(arg1,arg2){ 
   var r1,r2,m; 
   try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0} 
   try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0} 
   m=Math.pow(10,Math.max(r1,r2)) 
   return (arg1*m+arg2*m)/m 
   } 
   //减法 
   export function Subtr(arg1,arg2){ 
    var r1,r2,m,n; 
    try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0} 
    try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0} 
    m=Math.pow(10,Math.max(r1,r2)); 
    n=(r1>=r2)?r1:r2; 
    return ((arg1*m-arg2*m)/m).toFixed(n); 
   } 