import { 上传 } from '@/api'
import Http from '@/utils/request'
import {_to} from '@/utils'
//会员卡消费
export function 会员卡消费(data) {
    let res = Http({ url: '/business/member/pos/consume', method: 'post', data: data })
    return res
}
//查询会员卡消费列表
export function 查询会员卡列表(data) {
    let res = 上传({ url: '/business/member/pos/consume/query', method: 'post', data: data })
    return res
}
//查询会员卡模块列表
export function 查询会员卡模块列表(data) {
    let res = 上传({ url: '/business/member/pos/card/query', method: 'post', data: data })
    return res
}
//修改会员信息
export function 修改会员信息(data) {
    let res = 上传({ url: '/business/member/memberprofile/update', method: 'put', data: data })
    return res
}
//新增会员信息
export function 新增会员信息(data) {
    let res = 上传({ url: '/business/member/memberprofile/insert', method: 'post', data: data })
    return res
}
//卡分类列表
export function 查询会员卡分类列表(data) {
    let res = 上传({ url: '/system/dict/data/com', method: 'post', data: data })
    return res
}
//查询会员卡列表详细
export function 查询会员卡列表详细(data) {
    let res = 上传({ url: '/business/member/memberprofile/byid', method: 'post', data: data })
    return res
}
//查询会员充值情况
export function 查询会员充值情况(data) {
    let res = 上传({ url: '/business/member/memberprofile/cardtopitems', method: 'post', data: data })
    return res
}
//查询会员消费记录
export function 查询会员消费记录(data) {
    let res = 上传({ url: '/business/member/memberprofile/possale', method: 'post', data: data })
    return res
}


//查询会员消费明细
export function 查询会员消费明细(data) {
    let res = 上传({ url: '/business/member/memberprofile/possaledetail', method: 'post', data: data })
    return res
}
//查询会员消费支付方式
export function 查询会员消费支付方式(data) {
    let res = 上传({ url: '/business/member/memberprofile/possalecur', method: 'post', data: data })
    return res
}
//查询会员结存情况
export function 查询会员结存情况(data) {
    let res = 上传({ url: '/business/member/memberprofile/cardinout', method: 'post', data: data })
    return res
}
//查询会员积分兑换
export function 查询会员积分兑换(data) {
    let res = 上传({ url: '/business/member/memberprofile/cardscoreredeem', method: 'post', data: data })
    return res
}
//查询会员操作日志
export function 查询会员操作日志(data) {
    let res = 上传({ url: '/business/member/memberprofile/cardoperlog', method: 'post', data: data })
    return res
}
//查询会员领用礼券
export function 查询会员领用礼券(data) {
    let res = 上传({ url: '/business/member/memberprofile/cardgiftcollect', method: 'post', data: data })
    return res
}

//查询充值策略列表
export function 查询充值策略列表(data) {
    let res = 上传({ url: '/business/member/strategytop/listshop', method: 'post', data: data })
    return res
}
//查询充值策略赠送商品详细
export function 查询充值赠送商品(data) {
    let res = Http({ url: '/business/member/strategytop/byzsgoods', method: 'post', data: data })
    return res
}

//查询充值策略列表
export function 查询积分兑换策略列表(data) {
    let res = 上传({ url: '/business/member/strategyscoredh/listshop', method: 'post', data: data })
    return res
}
//查询充值策略赠送商品详细
export function 查询积分兑换赠送商品(data) {
    let res = Http({ url: '/business/member/strategyscore/byzsgoods', method: 'post', data: data })
    return res
}



//会员卡充值
export function 会员卡充值(data) {
    let  res = 上传({ url: '/business/member/cardtop/add', method: 'post', data: data })
    return res 
}

//会员卡合并
export function 会员卡合并(data) {
    let  res = 上传({ url: '/business/member/cardjoin', method: 'post', data: data })
    return res 
}


export async function makeid(number) {
    if (!number) number = 1
    const ret =  await 上传({ method: 'get', url: '/makeid/' + number.toString() })
    return  ret.data
}

export async function getOneID() {
    const ret = await 上传({ method: 'get', url: '/makeid/1' })
    console.log('getOneID', ret)
    return ret[1].data.idArray
}
// export function getOneID(){
//     let res = Http({ method: 'get', url: '/makeid/1' })
//     console.log('getOneID', ret)
//     return res
// }
  
export function 下载报货单(data){
    let res = _to(Http({url:'/business/inventbill/posneed',method:'post',data:data}))
    return res
}

export function 获取操作类别(){
    let res = _to(Http({url:'/pos/down/invop',method:'post',data:{"uct":0}}))
    return res
}


//查询使用电子券列表
export function 查询使用电子券列表(data) {
    let res = 上传({ url: '/business/gift/pos/query', method: 'post', data: data })
    return res
}
//使用电子券消费
export function 使用电子券消费(data) {
    let res = Http({ url: '/business/gift/pos/consume', method: 'post', data: data })
    return res
}
export default {
    会员卡消费,
    查询会员卡列表,
    查询会员卡模块列表,
    修改会员信息,
    新增会员信息,
    查询会员卡分类列表,
    查询会员卡列表详细,
    查询会员充值情况,
    查询会员消费记录,
    查询会员消费明细,
    查询会员消费支付方式,
    查询会员结存情况,
    查询会员积分兑换,
    查询会员操作日志,
    查询会员领用礼券,
    查询充值策略列表,
    查询充值赠送商品,
    查询积分兑换策略列表,
    查询积分兑换赠送商品,
    会员卡充值,
    会员卡合并,
    makeid,
    getOneID,
    查询使用电子券列表,
    使用电子券消费,


}



