import { 上传 } from '@/api'
import Http from '@/utils/request'
import {_to} from '@/utils'

export function 报货单上传(data) {
    let res = 上传({ url: '/business/inventbill/add', method: 'post', data: data })
    return res
}

export function 下载报货单(data){
    let res = _to(Http({url:'/business/inventbill/posneed',method:'post',data:data}))
    return res
}

export function 获取操作类别(){
    let res = _to(Http({url:'/pos/down/invop',method:'post',data:{"uct":0}}))
    return res
}

export default {
    报货单上传,
    下载报货单,
    获取操作类别
}