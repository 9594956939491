const TokenKey = 'Admin-Token'

export function getToken(key=TokenKey) {
  return sessionStorage.getItem(key)
}

export function setToken(key=TokenKey,token) {
  return sessionStorage.setItem(key, token)
}

export function removeToken(key=TokenKey) {
  return sessionStorage.setItem(key,'')
}