<template>
  <div class="contn-box">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card">
      <el-tab-pane name="分类统计" label="分类统计">
        <kucunFenlei :defaultdata="infodata" ></kucunFenlei>
      </el-tab-pane>
      <el-tab-pane name="收货差异" label="收货差异">
       <shouhuoChayi :defaultdata="infodata" ></shouhuoChayi>
      </el-tab-pane>
      <el-tab-pane name="退库统计" label="退库统计">
        <tuikuTongji :defaultdata="infodata" ></tuikuTongji>
      </el-tab-pane>
      <el-tab-pane name="本店库存" label="本店库存">
         <bendianKucun :defaultdata="infodata" ></bendianKucun>
      </el-tab-pane>
      <el-tab-pane name="邻店库存" label="邻店库存">
        <lindianKucun :defaultdata="infodata" ></lindianKucun>
      </el-tab-pane>
      <el-tab-pane name="进销存" label="进销存">
        <jinxiaoCun :defaultdata="infodata" ></jinxiaoCun>
      </el-tab-pane>
      </el-tabs>
  </div>
</template>

<script>
import kucunFenlei from '../库存/分类统计.vue'
import shouhuoChayi from '../库存/收货差异.vue'
import tuikuTongji from '../库存/退库统计.vue'
import bendianKucun from '../库存/本店库存.vue'
import lindianKucun from '../库存/邻店库存.vue'
import jinxiaoCun from '../库存/进销存.vue'

export default {
     props: {
    defaultdata: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
        activeName:'分类统计',
         //基础数据
       infodata: {},
    }
  },
   components:{
     kucunFenlei,
     shouhuoChayi,
     tuikuTongji,
     bendianKucun,
     lindianKucun,
     jinxiaoCun,
    
  },
   created() {
     this.infodata=this.defaultdata
        // this.infodata.t_inv_op=this.t_inv_op
        // this.infodata.t_pos_inv_op=this.t_pos_inv_op
        // this.infodata.dictdata=this.defaultdata.dictdata
        // this.infodata.users=this.defaultdata.users
      
  },
}
</script>

<style lang="scss" scoped>
.contn-box {
    width: 100%;
    height: 100%;
    padding: 15px;
}
</style>