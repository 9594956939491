<template>
  <div>
    <!-- //领用券 -->
      <div class="table-up">
      <el-table :data="CaozuoLogListData" ref="giftCollect"   border height="700">
        <!-- <el-table-column label="卡号" prop="card_id_out" align="center"  :show-overflow-tooltip="true" /> -->
        <el-table-column label="创建时间" prop="lct"  align="center" :resizable="false" :show-overflow-tooltip="true" >
        <template slot-scope="scope">{{ scope.row.lct===null? '' :scope.row.lct|parseTime }}</template>
        </el-table-column>
        <el-table-column label="礼券号" prop="card_id_out" align="center"  :show-overflow-tooltip="true" />
        <el-table-column label="礼券名称" prop="card_name" align="center"  :show-overflow-tooltip="true" />
        <el-table-column label="礼券面值" prop="card_money" align="center"  :show-overflow-tooltip="true" />
         <el-table-column label="状态" align="center"  >
          <template slot-scope="scope">
            <div>
              <span v-show="scope.row.card_state == 0">创建</span>
              <span v-show="scope.row.card_state == 1">发放</span>
              <span v-show="scope.row.card_state == 2">领取</span>
              <span v-show="scope.row.card_state == 3">使用</span>
              <span v-show="scope.row.card_state == 4">核销</span>
              <span v-show="scope.row.card_state == 5">失效</span>
              <span v-show="scope.row.card_state == 6">充值</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="有效期" prop="card_expire_date" align="center" :resizable="false" :show-overflow-tooltip="true" >
        <template slot-scope="scope">{{ scope.row.card_expire_date===null? '' :scope.row.card_expire_date|parseTime }}</template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import 会员api from '../api.js'
import { parseTime } from '@/utils'
export default {
filters: {
    parseTime
  },
   data() {
    return {
      CaozuoLogListData: [],
        // 查询参数
      queryParams: {
        offset: 1,
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        cardid: '',
      },

    }
  },

  methods: {
 /** 查询会员领用礼券 */
      async getcardgiftcollect(id) {
      this.loading = true;
      this.queryParams.cardid=id
      this.queryParams.offset = (this.queryParams.pageNum - 1) * this.queryParams.pageSize  //分页
      this.queryParams.limit = this.queryParams.pageSize
      let [err, res] = await 会员api.查询会员领用礼券(this.queryParams)
      if (err) {
        return this.$message.error('查询会员领用礼券失败')
      }
          this.CaozuoLogListData = res.data.rows;
          this.total = res.data.pageTotalCount;
          this.loading = false;
    },
  }
}
</script>

<style lang="less" scoped>
.mingxi {
  margin-top: 10px;
  .tit {
    display: flex;

    .tit-item {
      background-color: #f5f5f5;
      color: #333;
      padding: 5px;
      border: 1px solid #ccc;
      border-bottom: none;
    }
  }
}
</style>
