<template>
  <div>
    <!-- 充值信息 -->
    <el-tabs v-model="activeName"  @tab-click="handleClick" type="card">
  
      <el-tab-pane  label=" 公 司 " name="first" >
          <table-list :tabledata="obj" key="gs"  ref="gs"  ></table-list>
      </el-tab-pane>
      <el-tab-pane label=" 门 店 " name="second">
          <table-list :tabledata="obj" key="md" ref="gs" ></table-list>
      </el-tab-pane>
      <el-tab-pane label=" 网 络 " name="third">
        <table-list :tabledata="obj" key="wl"  ref="gs" ></table-list>
      </el-tab-pane>
      <el-tab-pane label=" 全 部 " name="fourth">
        <table-list :tabledata="obj" key="qb" ref="gs"></table-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import 会员api from '../api.js'
// import { apicardtopitems} from "@/api/business/member/memberprofile";
import tableList from './RechargeMsgTable'
export default {
  components:{tableList},
 props: {
    data: {
      type: [Object,String],
      default() {
        return {};
      },
    },
  },
  watch: {
    data: {
      handler(n) { 
          console.log('RechargeMsg',n)  
      },
      deep: true,
      immediate: true
    }
  },
  

  data() {
    return {
      activeName: 'first',
      obj:{gsData:[],
           cardid:''
          },
      mdData:[],
      wlData:[],
      qbData:[],
      //表单
      formData: {},
       // 查询参数 
      queryParams: {
        offset: 1,
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        cardid: '',
        top_source: '',
      },
    }
  },
    methods: {
 /** 查询会员卡结存列表 */
    getcardtopitems: function(id) {
      // console.log('getcardtopitemsgetcardtopitems', id)
      this.formData.id=id
      this.activeName='first'
     this.cardtopitems(this.formData.id, 0 )
    },

     handleClick(tab, event) {
      switch (tab.index) {
      case '0': //公 司
        this.cardtopitems(this.formData.id, 0 )  // getcardtopitems(this.formData.id)

        // console.log('this.$refs', this.$refs)
        // this.$refs.gs.getcardtopitems(this.formData.id)  
          break;
      case '1': //门 店
        this.cardtopitems(this.formData.id,1)
          break;
      case '2': //'网 络
        this.cardtopitems(this.formData.id,2)
          break;
      case '3': //全 部
        this.cardtopitems(this.formData.id,9)

          break;
    
      }
     },

      /** 查询会员卡结存列表 */
     async cardtopitems(id1,inde) {
      console.log('cardtopitems234', this.formData.id,inde)
       this.loading = true;
       this.queryParams.cardid=id1
       this.queryParams.top_source = inde === 9 ? '' : inde.toString()
      // this.queryParams.offset = (this.queryParams.pageNum - 1) * this.queryParams.pageSize  //分页
      // this.queryParams.limit = this.queryParams.pageSize


       /** 查询卡类型字典列表 */

       let [err, res] = await 会员api.查询会员充值情况(this.queryParams)
       console.log(res)
      if (err) {
        return this.$message.error('查询会员卡分类列表失败')
      }
 
      this.obj.gsData = res.data.rows;  
      this.obj.cardid = this.formData.id;  



    
      // await apicardtopitems(this.queryParams).then(
      //   (response) => {
      //         this.obj.gsData = response.data.rows;  
      //          this.obj.cardid = this.formData.id;  
      //      console.log('apicardtopitems456', response.data.rows)
      //     //  if (inde==0){
      //     //     //  this.gsData = response.data.rows;  
      //     //      this.obj.gsData = response.data.rows;  
      //     //      this.obj.cardid = this.formData.id;  
      //     //  }else if (inde==1){
      //     //     this.obj1.mdData = response.data.rows;  
      //     //     this.obj1.cardid = this.formData.id;  
      //     //     // this.mdData = response.data.rows;
      //     //  }else if (inde==2){
      //     //    this.obj.wlData = response.data.rows;  
      //     //      this.obj.cardid = this.formData.id; 
      //     //     this.wlData = response.data.rows;
      //     //  }else if (inde==9){
      //     //     this.obj.gsData = response.data.rows; 
      //     //     this.obj.cardid = this.formData.id; 
        
      //     //     // this.qbData = response.data.rows;
      //     //     console.log('this.qbData', this.obj.qbData)
      //     //  }
         
      //     this.loading = false;
      //     // this.total = response.data.pageTotalCount;
         
      //   }
      // );
    }

    }
}
</script>

<style lang="less" scoped>
</style>